@if (workspace) {
  <ng-container *ngFor="let navItem of workspaceItems; trackBy: trackByWorkspaceItem">
    @if (navItem.workspaceSubHeadline) {
      <div class="flex gap-2 !pt-3 !px-2 !pb-1 w-full flex-align-center align-self-stretch first-of-type:pt-0">
        <p class="typo-body-brandon-14px-medium text-gray-300">
          {{ navItem.workspaceSubHeadline | translate }}
        </p>
      </div>
    }

    <navigation-entry
      *ngIf="showNavigationEntry(navItem)"
      [isActive]="activeWorkspaceItem && activeWorkspaceItem.path === navItem.path"
      [item]="navItem"
      [workspace]="workspace"
    />
  </ng-container>

  <!-- @TODO CRM-1637 add categorization using these classes, replace "Business information" with the group name -->
  <!-- <div class="flex flex-col gap-1 pt-3 w-full first-of-type:pt-0">
    <div class="typo-body-inter-14px-medium text-gray-300">
      Business information
    </div>
    <ng-container *ngFor="let navItem of workspaceItems; trackBy: trackByWorkspaceItem">
      <navigation-entry
        *ngIf="showNavigationEntry(navItem)"
        [isActive]="activeWorkspaceItem && activeWorkspaceItem.path === navItem.path"
        [item]="navItem"
        [workspace]="workspace"
      />
    </ng-container>
  </div> -->
}
