import { Shop } from './shop.model';

export const RISE = 'rise';
export const EVOLVE = 'evolve';
export const BEYOND = 'beyond';

export const MIGRATION_PLAN_PE_RISE = 'migrationPlanPeRise';
export const MIGRATION_PLAN_PE_EVOLVE = 'migrationPlanPeEvolve';

export const MIGRATION_PLAN_PE_PLUS_EE_EVOLVE = 'migrationPlanPe+EeEvolve';
export const MIGRATION_PLAN_EE_BEYOND = 'migrationPlanEeBeyond';

export const UPGRADE_PLAN_PE_RISE = 'upgradePlanPeRise';
export const UPGRADE_PLAN_PE_EVOLVE = 'upgradePlanPeEvolve';

export const UPGRADE_PLAN_PE_BEYOND = 'upgradePlanPeBeyond';

export const RESELLER_PLAN_GWS_EVOLVE = 'resellerPlanGwsEvolve';

// constants can not be used in type
export type CommercialPlanValues = 'rise' | 'evolve' | 'beyond';
export type AdditionalResellerPlanNames = typeof RESELLER_PLAN_GWS_EVOLVE;

export interface CommercialPlan {
  id: number;
  name: CommercialPlanValues;
  label: string;
  basePrice: number;
  includedGmv: number;
  includedOrderAmount: number;
  gmvUnits: PlanUnit[];
  orderUnits: PlanUnit[];
  sortPriority: number;
  isReseller: boolean;
  displayName: string;
  isMigrationIncludingCE: boolean;
}

export interface PlanUnit {
  id: number;
  price: number;
  size: number;
  amountRangeStart: number;
  amountRangeEnd: number;
}

export interface ContractPricingUnit {
  id: number;
  price: number;
  size: number;
  amountRangeStart: number;
  amountRangeEnd: number;
}

export interface ContractPricingPeriods {
  id: number;
  includedAmount: number;
  plannedAmount: number;
  discountAmount: number;
  periodContractValue: number;
  plannedAmountFixed: boolean;
  startDate: string;
  endDate: string;
  consultingServiceDefinition: ConsultingServiceDefinition | null;
  bookingPrice: number;
}

export interface ConsultingServiceDefinition {
  serviceDays: number;
  serviceDayRate: number;
  absoluteDiscount: number;
}

export interface CommercialContract {
  id: number;
  creationDate: string;
  startDate: string;
  expirationDate: string;
  automaticExtension: boolean;
  status: ContractStatus;
  shop: Pick<Shop, 'id' | 'domain'>;
  bookedCommercialPlan: CommercialPlan;
  pricing: ContractPricing;
  charging: ContractCharging;
  minimumDuration: number;
  isActivePeriodTrial: boolean;
  trialEndDate: string;
}

export interface ContractPricing {
  id: number;
  mode: 'orders' | 'gmv';
  name: CommercialPlanValues;
  label: string;
  basePrice: number;
  units: ContractPricingUnit[];
  calculatedPrice: number;
  planContractPriceInfos: SelfServiceContractPriceInfos;
  chargingInterval: number;
  monthlySurchargeIgnored: boolean;
  pricingPeriods: ContractPricingPeriods[];
}

export interface ContractCharging {
  id: number;
  lastBookingDate: string | null;
  nextBookingDate: string;
}

export interface ContractStatus {
  id: number;
  name: 'open' | 'booked' | 'cancelled';
}

export interface ContractCancellation {
  id: number;
}

export interface SelfServiceContractPriceInfos {
  currentPricingPeriodPricePerMonth: number;
  nextPricingPeriodPricePerMonth: number | null;
  currentPricingPeriodPricePerChargingInterval: number;
  nextPricingPeriodPricePerChargingInterval: number | null;
}
