<div class="splitted-container" [class.collapsible]="collapsible">
  <ng-container *ngIf="collapsible; else simpleSplit">
    <div class="collapsible-container visible" [class.collapsible-collapsed]="collapsed">
      <div class="split left-split">
        <list-item-shared-collapsible-container [collapsed]="collapsed" (onToggleCollapsible)="toggleCollapsible()">
          <ng-container *ngTemplateOutlet="leftSplit" />
        </list-item-shared-collapsible-container>
      </div>
      <div class="split right-split">
        <list-item-shared-collapsible-container [showToggle]="false">
          <ng-container *ngTemplateOutlet="rightSplit" />
        </list-item-shared-collapsible-container>
      </div>
    </div>
    <div *ngIf="!collapsed" class="collapsible-container collapsible" [class.collapsed]="collapsed">
      <div class="split left-split">
        <hr *ngIf="!collapsed" class="collapsible-divider" />
        <list-item-shared-collapsible-container [collapsible]="true" [collapsed]="collapsed">
          <ng-content select="splitted-list-item-left-collapsible" />
        </list-item-shared-collapsible-container>
      </div>
      <div class="split right-split">
        <hr class="collapsible-divider" />
        <list-item-shared-collapsible-container [collapsible]="true" [collapsed]="collapsed">
          <ng-content select="splitted-list-item-right-collapsible" />
        </list-item-shared-collapsible-container>
      </div>
    </div>
  </ng-container>

  <ng-template #simpleSplit>
    <div class="split left-split">
      <ng-container *ngTemplateOutlet="leftSplit" />
    </div>
    <div class="split right-split">
      <ng-container *ngTemplateOutlet="rightSplit" />
    </div>
  </ng-template>
</div>

<ng-template #leftSplit>
  <ng-content select="splitted-list-item-left" />
</ng-template>

<ng-template #rightSplit>
  <ng-content select="splitted-list-item-right" />
</ng-template>
