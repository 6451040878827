<div class="a_control is_required" [class.is_error]="control.dirty && progressBarValue < visibleChecks.length">
  <div class="control_description">
    <strong>{{ passwordString | translate }}</strong>
    <account-progress-bar
      *ngIf="isHelpShown === 'helpAndProgressBar' || isHelpShown === 'onlyProgressBar'"
      [bgColor]="progressBarValue === visibleChecks.length ? '#36B37E' : ''"
      [value]="progressBarValue"
      [showMaxValue]="true"
      [maxValue]="visibleChecks.length"
      [height]="5" />
  </div>
  <account-toggleable-password [control]="control" (showHelp)="showHelp()" (blurEmitter)="hideHelp()" />
  <div class="not_all_requirements_resolved" *ngIf="control.dirty && progressBarValue < visibleChecks.length">
    {{ 'FRONT.REGISTER.PASSWORD_REQUIREMENTS.NOT_ALL_REQUIREMENTS_RESOLVED' | translate }}
  </div>
  <div *ngIf="control.dirty && progressBarValue >= visibleChecks.length">
    {{ 'FRONT.REGISTER.PASSWORD_REQUIREMENTS.ALL_REQUIREMENTS_RESOLVED' | translate }}
  </div>
</div>
<div class="password_help_text">
  <account-password-help-texts
    [showHelpTexts]="isHelpShown === 'helpAndProgressBar'"
    [visibleChecks]="visibleChecks"
    [passwordValidation]="passwordValidation" />
</div>
