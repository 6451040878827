import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ExtensionPartnerRevenuesGateway } from '../../gateways/plugins';
import {
  ExtensionPartnerRevenueListItem,
  ListResult,
  PartnerPayoutListItem,
  QueryFilter,
  QueryFilterGroup,
  SbpException,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { FinancialFacade } from '../company';
import { ProducersFacade } from './producers.facade';

@Injectable({
  providedIn: 'root',
})
export class ExtensionPartnerRevenuesFacade {
  private readonly extensionPartnerRevenuesGateway = inject(ExtensionPartnerRevenuesGateway);
  private readonly financialFacade = inject(FinancialFacade);
  private readonly producersFacade = inject(ProducersFacade);

  getExtensionPartnerRevenuePayoutList(metaData: RequestMetaData): Observable<ListResult<PartnerPayoutListItem[]>> {
    if (metaData.filter && metaData.filter.length > 0) {
      const filterGroup = {
        active: true,
        items: [{ property: 'context', value: 'extensionPartnerRevenue' }],
      } as QueryFilterGroup;
      metaData.filter.push(filterGroup);
    } else {
      metaData.filter = { context: 'extensionPartnerRevenue' };
    }

    return this.financialFacade.getPartnerPayoutList(metaData);
  }

  downloadPayoutList(locale: string, metaData: RequestMetaData): Observable<Blob | SbpException> {
    if (metaData.filter && metaData.filter.length > 0) {
      const filterGroup = {
        active: true,
        items: [{ property: 'context', value: 'extensionPartnerRevenue' }],
      } as QueryFilterGroup;
      metaData.filter.push(filterGroup);
    } else {
      metaData.filter = { context: 'extensionPartnerRevenue' };
    }
    return this.financialFacade.downloadPartnerPayoutList(locale, metaData);
  }

  getRevenueBalance(filters: Record<string, any> | QueryFilter[]): Observable<number> {
    return this.producersFacade
      .getProducerIdOnce()
      .pipe(
        switchMap((producerId: number) => this.extensionPartnerRevenuesGateway.getRevenueBalance(producerId, filters))
      );
  }

  getRevenueList(metaData: RequestMetaData): Observable<ListResult<ExtensionPartnerRevenueListItem[]>> {
    return this.producersFacade
      .getProducerIdOnce()
      .pipe(
        switchMap((producerId: number) => this.extensionPartnerRevenuesGateway.getRevenueList(producerId, metaData))
      );
  }

  downloadRevenueListAsExport(locale: string, metaData: RequestMetaData): Observable<Blob | SbpException> {
    return this.producersFacade
      .getProducerIdOnce()
      .pipe(
        switchMap((producerId: number) =>
          this.extensionPartnerRevenuesGateway.downloadRevenueListAsExport(producerId, locale, metaData)
        )
      );
  }
}
