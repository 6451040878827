import { SwIconComponent } from '@account/shared/components';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'account-navigation-onboarding-step',
  standalone: true,
  imports: [SwIconComponent],
  templateUrl: './navigation-onboarding-step.component.html',
  preserveWhitespaces: false,
})
export class NavigationOnboardingStepComponent {
  @Input() completed: boolean = false;
  @Input() index: number;
  @Input() heading: string;

  @Output() readonly action = new EventEmitter<void>();

  emitAction(): void {
    this.action.emit();
  }
}
