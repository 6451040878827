import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { PluginReviewsGateway } from '../../gateways/plugins';
import { ListResult, PluginReview, PluginReviewHistory, PluginReviewInformationSet } from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { CompaniesFacade } from '../company';

@Injectable({
  providedIn: 'root',
})
export class PluginReviewsFacade {
  private readonly pluginReviewsGateway = inject(PluginReviewsGateway);
  private readonly companiesFacade = inject(CompaniesFacade);

  getPluginReviewsList(
    metaData: RequestMetaData,
    filter: Record<string, any> = {}
  ): Observable<ListResult<PluginReview[]>> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.pluginReviewsGateway.getPluginReviewsList(companyId, false, metaData, filter)
        )
      );
  }

  addPluginReview(pluginId: number, review: PluginReview, pluginLicenseOwnerId?: number): Observable<PluginReview> {
    return this.pluginReviewsGateway.addPluginReview(pluginId, review, pluginLicenseOwnerId);
  }

  updatePluginReview(review: PluginReview): Observable<PluginReview> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.pluginReviewsGateway.updatePluginReview(companyId, review)));
  }

  sendPrivatePluginReviewMessageToProducer(review: PluginReview, message: string): Observable<PluginReviewHistory> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.pluginReviewsGateway.sendPrivatePluginReviewMessageToProducer(companyId, review, message)
        )
      );
  }

  getPluginReviewInformationSet(technicalName: string): Observable<PluginReviewInformationSet> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.pluginReviewsGateway.getPluginReviewInformationSet(companyId, technicalName)
        )
      );
  }
}
