import { CompanySupportPermissionGateway } from '@account/core/gateways';
import { SbpException, SupportPermissions } from '@account/core/models';
import { companyActions } from '@account/core/store/actions';
import { companySelectors } from '@account/core/store/selectors';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { RootState } from '../../../store/root.state';
import { CompaniesFacade } from '../companies.facade';

@Injectable({
  providedIn: 'root',
})
export class SupportPermissionFacade {
  private readonly store = inject<Store<RootState>>(Store<RootState>);
  private readonly companiesFacade = inject(CompaniesFacade);
  private readonly gateway = inject(CompanySupportPermissionGateway);

  getSupportPermissions(): Observable<SupportPermissions> {
    return this.store.select(companySelectors.supportPermissions.isLoaded).pipe(
      switchMap((isLoaded: boolean) => {
        if (isLoaded) {
          return this.store.select(companySelectors.supportPermissions.getError).pipe(
            switchMap((error: SbpException | undefined) => {
              if (error) {
                return throwError(() => error);
              }
              return this.store.select(companySelectors.supportPermissions.getSupportPermissions);
            })
          );
        }
        return this.companiesFacade.getCompanyIdOnce().pipe(
          tap((companyId: number) =>
            this.store.dispatch(companyActions.supportPermissions.getSupportPermissions({ payload: companyId }))
          ),
          switchMap(() => this.store.select(companySelectors.supportPermissions.isLoaded)),
          filter((loaded: boolean) => loaded),
          switchMap(() => this.getSupportPermissions())
        );
      })
    );
  }

  getSupportPermissionForShop(shopId: number): Observable<SupportPermissions> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.gateway.getSupportPermissionForShop(companyId, shopId)));
  }
}
