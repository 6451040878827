export enum CompanyOnboardingStatus {
  SKIPPED = 'skipped',
  COMPLETED = 'completed',
  NOT_STARTED = 'notStarted',
  IN_PROGRESS = 'inProgress',
}

export interface CompanyOnboardingDTO {
  status: CompanyOnboardingStatus | null;
  hasVisitedExtensionStore: boolean | null;
  hasExploredShopwarePlans: boolean | null;
}

export interface CompanyOnboardingData {
  isFirstStoreRegistered: boolean;
  isDefaultPaymentMeanRegistered: boolean;
  hasVisitedExtensionStore: boolean;
  hasExploredShopwarePlans: boolean;
}
