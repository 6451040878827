import { NgIf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AbstractModalComponent } from '../abstract-modal.component';
import { ModalBodyDirective, ModalComponent, ModalFooterDirective } from '../modal.component';

@Component({
  selector: 'account-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrl: './../modal.component.less',
  standalone: true,
  imports: [ModalComponent, NgIf, ModalBodyDirective, ModalFooterDirective],
})
export class ConfirmModalComponent extends AbstractModalComponent implements OnInit {
  cancelButtonText = 'Abbrechen';
  confirmButtonText = 'Bestätigen';

  constructor() {
    super();
    this.heading = this.translateService.instant('COMMON.MODAL.CONFIRMATION');
    this.cancelButtonText = this.translateService.instant('COMMON.MODAL.CANCEL');
    this.confirmButtonText = this.translateService.instant('COMMON.MODAL.CONFIRM');
  }

  private readonly translateService = inject(TranslateService);

  override ngOnInit(): void {
    super.ngOnInit();
    // if cancel button text has been injected use the injected data
    if (
      undefined !== this.modalData.cancelButtonText &&
      null !== this.modalData.cancelButtonText &&
      this.modalData.cancelButtonText.length > 0
    ) {
      this.cancelButtonText = this.modalData.cancelButtonText;
    }
    // if confirm button text has been injected use the injected data
    if (
      undefined !== this.modalData.confirmButtonText &&
      null !== this.modalData.confirmButtonText &&
      this.modalData.confirmButtonText.length > 0
    ) {
      this.confirmButtonText = this.modalData.confirmButtonText;
    }
    if (this.modalData.maxWidth) {
      this.maxWidth = this.modalData.maxWidth;
    }
  }

  cancel(): void {
    this.modalRef.close(false);
  }

  confirm(): void {
    this.modalRef.close(true);
  }
}
