import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AoIconComponent } from '@shopware/aorta';

import { SwIconComponent } from '../../../../shared/components/sw-icon/sw-icon.component';
import { Workspace } from '../../../models';
import { NavigationEntryComponent } from '../navigation-workspace/navigation-entry/navigation-entry.component';

@Component({
  selector: 'navigation-workspaces-selector',
  templateUrl: './workspaces-selector.component.html',
  styleUrl: './workspaces-selector.component.less',
  standalone: true,
  imports: [CommonModule, TranslateModule, SwIconComponent, AoIconComponent, NavigationEntryComponent],
})
export class WorkspacesSelectorComponent {
  private readonly elementRef = inject(ElementRef);
  private readonly renderer = inject(Renderer2);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  @Input() workspaces: Workspace[];
  @Input() selected: Workspace;
  @Input() disabled: boolean;
  @Input() isExpanded: boolean;
  @Output() readonly workspaceSelected = new EventEmitter<Workspace>();
  open = false;

  private selfClick = false;
  private itemClick = false;
  private documentClickListener: () => void;

  selectWorkspace(workspace: Workspace): void {
    this.workspaceSelected.emit(workspace);
  }

  toggle(): void {
    this.bindDocumentClickListener();
    this.open = !this.open;
    this.selfClick = true;
  }

  private bindDocumentClickListener(): void {
    if (!this.documentClickListener) {
      this.documentClickListener = this.renderer.listen('document', 'click', () => {
        if (!this.selfClick && !this.itemClick) {
          this.open = false;
          this.unbindDocumentClickListener();
        }

        this.selfClick = false;
        this.itemClick = false;
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  private unbindDocumentClickListener(): void {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }
}
