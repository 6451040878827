import { PartnerStatusName } from '@account/core/models';

import { Permission, PermissionContext } from '../../types/acl.type';
import { StringUtils } from '../../utils';

export class NavigationAccessMapper {
  static urlToPartnerStatus(url: string): null | PartnerStatusName[] {
    const relativeUrl = StringUtils.trimSlashes(url);
    const urlParts = relativeUrl.split('/');
    if ('partner' !== urlParts[0]) {
      return null;
    }
    const partnerWorkspaceEntry = urlParts[1];
    switch (partnerWorkspaceEntry) {
      case 'deals':
        return ['bronze', 'silver', 'gold', 'platinum', 'reseller', 'stp', 'stpSilver', 'stpGold', 'stpPlatinum'];
      case 'customers':
        return ['consultant', 'sar', 'sbp', 'sep', 'ssp', 'reseller', 'bronze', 'silver', 'gold', 'platinum'];
      case 'references':
        return ['consultant', 'sar', 'sbp', 'sep', 'ssp', 'reseller', 'bronze', 'silver', 'gold', 'platinum'];
      case 'profile':
        return ['consultant', 'sar', 'sbp', 'sep', 'ssp', 'reseller', 'bronze', 'silver', 'gold', 'platinum'];
      case 'technologyprofile':
        return ['stp', 'stpSilver', 'stpGold', 'stpPlatinum'];
      case 'account':
        return ['consultant', 'sar', 'sbp', 'sep', 'ssp', 'reseller', 'bronze', 'silver', 'gold', 'platinum'];
      case 'support':
        return ['sbp', 'sep', 'ssp', 'reseller', 'bronze', 'silver', 'gold', 'platinum'];
      case 'commissions':
        return [
          'consultant',
          'stp',
          'stpSilver',
          'stpGold',
          'stpPlatinum',
          'sar',
          'sbp',
          'sep',
          'ssp',
          'reseller',
          'bronze',
          'silver',
          'gold',
          'platinum',
        ];
      case 'wildcardenvironments':
        return [
          'consultant',
          'sar',
          'sbp',
          'sep',
          'ssp',
          'reseller',
          'bronze',
          'silver',
          'gold',
          'platinum',
          'stpSilver',
          'stpGold',
          'stpPlatinum',
        ];
      case 'marketing':
        return [
          'consultant',
          'sar',
          'sbp',
          'sep',
          'ssp',
          'stp',
          'stpSilver',
          'stpGold',
          'stpPlatinum',
          'reseller',
          'bronze',
          'silver',
          'gold',
          'platinum',
        ];
      case 'points':
        return ['silver', 'gold', 'platinum'];
      case 'budget':
        return ['silver', 'gold', 'platinum'];
      default:
        return null;
    }
  }

  static urlToMembershipPermissions(url: string): null | Permission[] | PermissionContext[] {
    const relativeUrl = StringUtils.trimSlashes(url);
    const urlParts = relativeUrl.split('/');
    if (urlParts.length === 0) {
      return null;
    }
    const workspace = urlParts[0];
    const workspaceEntry = urlParts.length > 1 ? urlParts[1] : '';
    const relativeUrlParts = urlParts.slice(2);
    switch (workspace) {
      case 'portal':
        return NavigationAccessMapper.getPermissionsForPortalWorkspaceEntry(workspaceEntry);
      case 'partner':
        return NavigationAccessMapper.getPermissionsForPartnerWorkspaceEntry(workspaceEntry);
      case 'producer':
        return NavigationAccessMapper.getPermissionsForProducerWorkspaceEntry(workspaceEntry);
      case 'shops':
        return NavigationAccessMapper.getPermissionsForShopWorkspaceEntry(workspaceEntry, relativeUrlParts);
      case 'academy':
        return NavigationAccessMapper.getPermissionsForAcademyWorkspaceEntry(workspaceEntry);
      case 'settings':
        return NavigationAccessMapper.getPermissionsForSettingsWorkspaceEntry(workspaceEntry);
      default:
        return null;
    }
  }

  static urlToPermissionDeniedTranslationKey(url: string): string {
    const contextMessage = NavigationAccessMapper.urlToPermissionContextDeniedTranslationKey(url);
    if (null !== contextMessage) {
      return contextMessage;
    }
    const defaultKey = 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.DEFAULT';
    const permissions = NavigationAccessMapper.urlToMembershipPermissions(url);
    if (null === permissions) {
      return defaultKey;
    }
    switch (permissions[0]) {
      case 'academy_certifications':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.ACADEMY.CERTIFICATIONS';

      case 'account_accounting':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.COMPANY.ACCOUNTING';
      case 'account_membershipManagement':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.COMPANY.MEMBERSHIP_MANAGEMENT';
      case 'account_masterData':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.COMPANY.MASTER_DATA';

      case 'partner_accounting':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.PARTNER.ACCOUNTING';
      case 'partner_customerManagement':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.PARTNER.CUSTOMER_MANAGEMENT';
      case 'partner_marketing':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.PARTNER.MARKETING';
      case 'partner_profile':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.PARTNER.PROFILE';
      case 'partner_support':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.PARTNER.SUPPORT';
      case 'partner_wildcard':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.PARTNER.WILDCARD';

      case 'pluginProducer_plugins':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.PRODUCER.PLUGINS';
      case 'pluginProducer_marketing':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.PRODUCER.MARKETING';
      case 'pluginProducer_profile':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.PRODUCER.PROFILE';
      case 'pluginProducer_sales':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.PRODUCER.SALES';
      case 'pluginProducer_support':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.PRODUCER.SUPPORT';
      case 'pluginProducer_ratings':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.PRODUCER.REVIEWS';
      case 'pluginProducer_wildcard':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.PARTNER.WILDCARD';

      case 'shopOwner_shops':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.SHOP_OWNER.SHOPS';
      case 'shopOwner_support':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.SHOP_OWNER.SUPPORT';
      default:
        return defaultKey;
    }
  }

  static urlToPermissionDeniedTranslationKeyForPartnerStatus(url: string): null | string {
    const partnerStatus = NavigationAccessMapper.urlToPartnerStatus(url);
    if (null === partnerStatus) {
      return null;
    }

    const agencyPartnerStatus: Set<PartnerStatusName> = new Set([
      'consultant',
      'sar',
      'sbp',
      'sep',
      'ssp',
      'reseller',
      'bronze',
      'silver',
      'gold',
      'platinum',
    ]);
    const isAgency = partnerStatus.every((status: PartnerStatusName) => agencyPartnerStatus.has(status));

    const isSTP = partnerStatus.includes('stp');
    if (isAgency && isSTP) {
      return 'CORE.NO_ACCESS.SUBHEADING.PARTNER.ONLY_AGENCY_AND_STP';
    }
    if (isAgency) {
      return 'CORE.NO_ACCESS.SUBHEADING.PARTNER.ONLY_AGENCY';
    }
    if (isSTP) {
      return 'CORE.NO_ACCESS.SUBHEADING.PARTNER.ONLY_STP';
    }
    return null;
  }

  private static urlToPermissionContextDeniedTranslationKey(url: string): null | string {
    const context = NavigationAccessMapper.urlToPermissionContext(url);
    switch (context) {
      case 'academy':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.ACADEMY.WORKSPACE';
      case 'account':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.COMPANY.WORKSPACE';
      case 'partner':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.PARTNER.WORKSPACE';
      case 'pluginProducer':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.PRODUCER.WORKSPACE';
      case 'shopOwner':
        return 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.SHOP_OWNER.WORKSPACE';
      default:
        return null;
    }
  }

  private static getPermissionsForPortalWorkspaceEntry(workspaceEntryUrl: string): null | Permission[] {
    switch (workspaceEntryUrl) {
      case 'licensetransfer':
      case 'cloudshops':
        return ['shopOwner_shops'];
      default:
        return null;
    }
  }

  private static getPermissionsForPartnerWorkspaceEntry(
    workspaceEntryUrl: string
  ): null | Permission[] | PermissionContext[] {
    switch (workspaceEntryUrl) {
      case 'deals':
      case 'customers':
        return ['partner_customerManagement'];
      case 'references':
      case 'profile':
      case 'technologyprofile':
        return ['partner_profile'];
      case 'account':
      case 'commissions':
      case 'points':
      case 'budget':
        return ['partner_accounting'];
      case 'support':
        return ['partner_support'];
      case 'wildcardenvironments':
        return ['partner_wildcard'];
      case 'marketing':
        return ['partner_marketing'];
      default:
        return ['partner'];
    }
  }

  private static getPermissionsForProducerWorkspaceEntry(
    workspaceEntryUrl: string
  ): null | Permission[] | PermissionContext[] {
    switch (workspaceEntryUrl) {
      case 'plugins':
      case 'compatibility':
        return ['pluginProducer_plugins'];
      case 'discounts':
      case 'marketing':
        return ['pluginProducer_marketing'];
      case 'support':
        return ['pluginProducer_support'];
      case 'profile':
        return ['pluginProducer_profile'];
      case 'revenues':
      case 'sales':
        return ['pluginProducer_sales'];
      case 'reviews':
        return ['pluginProducer_ratings'];
      case 'wildcardenvironments':
        return ['pluginProducer_wildcard'];
      default:
        return ['pluginProducer'];
    }
  }

  private static getPermissionsForShopWorkspaceEntry(
    workspaceEntryUrl: string,
    relativeUrlParts: string[]
  ): null | Permission[] | PermissionContext[] {
    switch (workspaceEntryUrl) {
      case 'shops': {
        const allowedFor: Permission[] = ['shopOwner_shops'];
        if (relativeUrlParts.includes('account')) {
          allowedFor.push('account_accounting');
        }
        return allowedFor;
      }
      case 'support':
        return ['shopOwner_support'];
      default:
        return ['shopOwner'];
    }
  }

  private static getPermissionsForAcademyWorkspaceEntry(workspaceEntryUrl: string): null | PermissionContext[] {
    switch (workspaceEntryUrl) {
      case 'certifications':
      case 'bookings':
        return ['academy'];
      default:
        return null;
    }
  }

  private static getPermissionsForSettingsWorkspaceEntry(workspaceEntryUrl: string): null | Permission[] {
    switch (workspaceEntryUrl) {
      case 'data':
      case 'my-partner':
        return ['account_masterData'];
      case 'billing':
        return ['account_accounting'];
      case 'memberships':
        return ['account_membershipManagement'];
      default:
        return null;
    }
  }

  private static urlToPermissionContext(url: string): null | PermissionContext {
    const relativeUrl = StringUtils.trimSlashes(url);
    if (relativeUrl.length === 0) {
      return null;
    }
    switch (relativeUrl) {
      case 'partner':
        return 'partner';
      case 'producer':
        return 'pluginProducer';
      case 'shops':
        return 'shopOwner';
      case 'academy':
        return 'academy';
      case 'settings':
        return 'account';
      default:
        return null;
    }
  }
}
