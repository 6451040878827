import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListResult, PluginLicense, PluginWithdrawalRequest } from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { ApiService } from '../../services';
import { HttpParamsBuilder } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class PluginWithdrawalGateway {
  private readonly apiService = inject(ApiService);

  getPluginWithdrawalRequestsForCompany(
    companyId: number,
    filter: Record<string, any> = {}
  ): Observable<ListResult<PluginWithdrawalRequest[]>> {
    let params = new HttpParams();

    if (filter) {
      params = HttpParamsBuilder.applyFilters(params, filter);
    }
    return this.apiService.getList(`/companies/${companyId}/pluginwithdrawalrequests`, params);
  }

  getPluginWithdrawalRequestsForProducer(
    producerId: number,
    metaData: RequestMetaData,
    filter: Record<string, any> = {}
  ): Observable<ListResult<PluginWithdrawalRequest[]>> {
    let params = new HttpParams();
    params = HttpParamsBuilder.applyMetaData(params, metaData);
    if (filter) {
      params = HttpParamsBuilder.applyFilters(params, filter);
    }
    return this.apiService.getList(`/producers/${producerId}/pluginwithdrawalrequests`, params);
  }

  sendPluginWithdrawalRequest(
    companyId: number,
    pluginWithdrawalRequest: PluginWithdrawalRequest
  ): Observable<PluginWithdrawalRequest> {
    return this.apiService.put(
      `/companies/${companyId}/pluginwithdrawalrequests/${pluginWithdrawalRequest.id}`,
      pluginWithdrawalRequest
    );
  }

  createPluginWithdrawalRequest(
    companyId: number,
    license: PluginLicense,
    description: string
  ): Observable<PluginWithdrawalRequest> {
    const data = {
      licenseId: license.id,
      description: description,
    };
    return this.apiService.post(`/companies/${companyId}/pluginwithdrawalrequests`, data);
  }

  declineOrAcceptPluginWithdrawalRequest(
    producerId: number,
    pluginWithdrawalRequest: PluginWithdrawalRequest
  ): Observable<PluginWithdrawalRequest> {
    return this.apiService.put(
      `/producers/${producerId}/pluginwithdrawalrequests/${pluginWithdrawalRequest.id}/declineoraccept`,
      pluginWithdrawalRequest
    );
  }
}
