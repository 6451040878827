<div class="list-container" [class.is-embedded]="isEmbedded">
  <div *ngIf="showHeader" class="list-header">
    <ng-container *ngIf="isContentLoading || hasCustomHeader; else defaultHeader">
      <ng-content select="list-header" />
    </ng-container>
    <ng-template #defaultHeader>
      <div class="header-info">
        <strong *ngIf="heading" class="heading">{{ heading }}</strong>
        <strong *ngIf="showItemCount" class="item-count">({{ countItems$ | async }})</strong>
      </div>
      <div *ngIf="isContentLoading || hasCustomHeaderActions" class="header-actions">
        <ng-content select="list-header-actions" />
      </div>
    </ng-template>
  </div>
  <div class="list-body">
    <div class="list-content">
      <ng-container *ngIf="isContentLoading || (hasItems$ | async); else emptyState">
        <ng-content />
      </ng-container>

      <ng-template #emptyState>
        <ng-container>
          <ng-container *ngIf="hasCustomEmptyState; else defaultEmptyState">
            <ng-content select="list-empty-state" />
          </ng-container>
          <ng-template #defaultEmptyState>
            <simple-list-item class="list-empty-state">
              {{ emptyStateText ? emptyStateText : 'No Entries' }}
            </simple-list-item>
          </ng-template>
        </ng-container>
      </ng-template>
    </div>

    <div
      *ngIf="isContentLoading || hasCustomFooter"
      class="list-footer"
      [class.is-sticky]="isFooterSticky"
      [style.bottom.px]="negativeSpaceToViewportEnd">
      <ng-content select="list-footer" />
    </div>
  </div>
</div>
