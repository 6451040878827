import {
  CloudShop,
  CompanyAllocations,
  DomainVerificationTokenData,
  ListResult,
  PackagesToken,
  PluginLicense,
  PluginLicenseMigrationData,
  PluginLicenseMigrationResponse,
  ProductAcceleratorLicense,
  QueryFilter,
  RelatedShops,
  SbpException,
  SelfHostedShop,
  Shop,
  ShopBooking,
  ShopCreationSkeleton,
  ShopEnvironment,
  ShopMigrationData,
  ShopUpgradeCommercialPlanBookingInformation,
  ShopUpgradeStatusToShopware5,
  Subscription,
} from '@account/core/models';
import { inject, Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { ShopsGateway } from '../../gateways/shop';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { HttpParamsBuilder } from '../../utils';
import { CompaniesFacade } from '../company';
import { PartnersFacade } from '../partner';
import { CloudShopsFacade } from './cloud-shops.facade';

@Injectable({
  providedIn: 'root',
})
export class ShopsFacade {
  private readonly companiesFacade = inject(CompaniesFacade);
  private readonly cloudShopsFacade = inject(CloudShopsFacade);
  private readonly shopsGateway = inject(ShopsGateway);
  private readonly partnersFacade = inject(PartnersFacade);

  getSelfHostedShops(
    requestMetaData?: RequestMetaData,
    filter: Record<string, any> = {}
  ): Observable<ListResult<SelfHostedShop[]>> {
    const metaData = requestMetaData ? requestMetaData : HttpParamsBuilder.createMetaData(0, 0, 'creationDate', 'asc');
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.shopsGateway.getSelfHostedShops(companyId, metaData, filter)));
  }

  getSelfHostedShopsForCompany(
    companyId: number,
    requestMetaData?: RequestMetaData,
    filter: Record<string, any> = {}
  ): Observable<ListResult<SelfHostedShop[]>> {
    const metaData = requestMetaData ? requestMetaData : HttpParamsBuilder.createMetaData(0, 0, 'creationDate', 'asc');
    return this.shopsGateway.getSelfHostedShops(companyId, metaData, filter);
  }

  getShop(shopId: number): Observable<SelfHostedShop> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.shopsGateway.getShop(companyId, shopId)));
  }

  getCloudOrSelfHostedShop(shopId: number): Observable<Shop> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.shopsGateway.getCloudOrSelfHostedShop(companyId, shopId)));
  }

  getAllShopsForCompany(
    companyId: number,
    cloudFilter: QueryFilter,
    selfHostedFilter: Record<string, any> = {},
    metaData: RequestMetaData = null
  ): Observable<Shop[]> {
    const selfHostedShops$ = this.getSelfHostedShopsForCompany(companyId, metaData, selfHostedFilter).pipe(
      map((listResult: ListResult<SelfHostedShop[]>) => listResult.list)
    );

    return forkJoin([selfHostedShops$, this.cloudShopsFacade.getCloudShopsForCompany([cloudFilter])]).pipe(
      map(([selfHostedShops, cloudShops]: [SelfHostedShop[], CloudShop[]]) => {
        const shops = [...selfHostedShops, ...cloudShops];
        shops.sort((a: Shop, b: Shop) => (a.domain < b.domain ? -1 : 1));
        return shops;
      })
    );
  }

  getPluginLicensesByShop(
    shopId: number,
    requestMetaData: RequestMetaData,
    filter: Record<string, any> = null
  ): Observable<ListResult<PluginLicense[]>> {
    return this.shopsGateway.getPluginLicensesByShop(shopId, requestMetaData, filter);
  }

  getPluginLicenseById(shopId: number, licenseId: number): Observable<PluginLicense> {
    return this.shopsGateway.getPluginLicenseById(shopId, licenseId);
  }

  getProductAcceleratorLicense(shopId: number, licenseId: number): Observable<ProductAcceleratorLicense> {
    return this.shopsGateway.getProductAcceleratorLicense(shopId, licenseId);
  }

  getBookings(shopId: number, requestMetaData: RequestMetaData): Observable<ListResult<ShopBooking[]>> {
    return this.shopsGateway.getBookings(shopId, requestMetaData).pipe(
      catchError((exception: SbpException) => {
        switch (exception.code) {
          case 'UsersException-4':
            return of({ list: [], total: 0 });
          default:
            return throwError(() => exception);
        }
      })
    );
  }

  movePluginLicense(
    pluginLicense: PluginLicense,
    newLicenseShop: SelfHostedShop,
    newBookingShop: SelfHostedShop | null
  ): Observable<any> {
    return this.shopsGateway.movePluginLicense(pluginLicense, newLicenseShop, newBookingShop);
  }

  migratePluginLicenses(migrationData: PluginLicenseMigrationData[]): Observable<PluginLicenseMigrationResponse[]> {
    return this.shopsGateway.migratePluginLicenses(migrationData);
  }

  migrateShop(migrationData: ShopMigrationData): Observable<SelfHostedShop | SbpException> {
    return this.shopsGateway.migrateShop(migrationData);
  }

  requestVerificationHashForDomain(domain: string): Observable<DomainVerificationTokenData> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.shopsGateway.requestVerificationHashForDomain(companyId, domain)));
  }

  verifyDomainVerificationHash(domain: string): Observable<{ success: true }> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.shopsGateway.verifyDomainVerificationHash(companyId, domain)));
  }

  precheckDomain(domain: string): Observable<{ success: boolean }> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.shopsGateway.precheckDomain(companyId, domain)));
  }

  getShopEnvironments(): Observable<ShopEnvironment[]> {
    return this.shopsGateway.getShopEnvironments().pipe(
      map((shopEnvironments: ShopEnvironment[]) =>
        shopEnvironments.sort((a: ShopEnvironment, b: ShopEnvironment) => {
          if (a.name !== 'unknown' && b.name !== 'unknown') {
            return 0;
          }
          return a.name === 'unknown' ? -1 : 1;
        })
      )
    );
  }

  addShop(shop: ShopCreationSkeleton): Observable<SelfHostedShop> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) => this.shopsGateway.addShop(companyId, shop)),
      mergeMap((shop: SelfHostedShop) =>
        this.companiesFacade.getAllocations().pipe(
          take(1),
          switchMap((allocations: CompanyAllocations) => {
            if (!allocations.hasShops) {
              return this.companiesFacade.refreshAllocations();
            }
            return of(allocations);
          }),
          map(() => shop)
        )
      )
    );
  }

  updateShop(shop: SelfHostedShop): Observable<SelfHostedShop> {
    return this.shopsGateway.updateShop(shop.companyId, shop);
  }

  verifyShop(shop: SelfHostedShop): Observable<'domainVerificationFailed' | 'domainVerificationSuccessFul'> {
    return this.shopsGateway.verifyShop(shop.companyId, shop);
  }

  getUpgradeStatusToShopware5(shop: SelfHostedShop, partnerId?: number): Observable<ShopUpgradeStatusToShopware5> {
    return this.shopsGateway.getUpgradeStatusToShopware5(shop, partnerId);
  }

  performUpgradeToShopware5(shop: SelfHostedShop, partnerId?: number): Observable<{ code: string }[]> {
    return this.shopsGateway.performUpgradeToShopware5(shop, partnerId);
  }

  cancelSubscription(shop: SelfHostedShop, subscription: Subscription): Observable<Subscription> {
    subscription.status = { name: 'cancelled', description: '' };
    return this.shopsGateway.updateSubscription(shop, subscription);
  }

  revokeSubscriptionCancellation(shop: SelfHostedShop, subscription: Subscription): Observable<Subscription> {
    subscription.status = { name: 'booked', description: '' };
    return this.shopsGateway.updateSubscription(shop, subscription);
  }

  getRelatedShops(shop: SelfHostedShop): Observable<RelatedShops> {
    return this.shopsGateway.getRelatedShops(shop.companyId, shop.id);
  }

  downloadBookingsExport(shopId: number, locale: string): Observable<Blob | SbpException> {
    return this.shopsGateway.downloadBookingsExport(shopId, locale);
  }

  transferBalance(shopId: number, targetShopId: number, amount: number): Observable<void> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) => this.shopsGateway.transferBalance(companyId, shopId, targetShopId, amount))
      );
  }

  getPackagesComposerToken(shopId: number): Observable<PackagesToken> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.shopsGateway.getPackagesComposerToken(companyId, shopId)));
  }

  generatePackagesComposerToken(shopId: number): Observable<PackagesToken> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.shopsGateway.generatePackagesComposerToken(companyId, shopId)));
  }

  verifyPackagesComposerToken(shopId: number, token: string): Observable<void> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.shopsGateway.verifyPackagesComposerToken(companyId, shopId, token)));
  }

  revokePackagesComposerToken(shopId: number, token: string): Observable<void> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.shopsGateway.revokePackagesComposerToken(companyId, shopId, token)));
  }

  getShopLicenseAgreement(companyId: number, locale: string): Observable<Blob> {
    return this.shopsGateway.getShopLicenseAgreement(companyId, locale);
  }

  getShopLicenseAgreementForCustomer(companyId: number, locale: string): Observable<Blob> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.shopsGateway.getShopLicenseAgreementForCustomer(partnerId, companyId, locale)
        )
      );
  }

  getPriceIndicationForCustomer(
    companyId: number,
    shopId: number,
    data: ShopUpgradeCommercialPlanBookingInformation
  ): Observable<Blob> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.shopsGateway.getPriceIndicationForCustomer(partnerId, companyId, shopId, data)
        )
      );
  }

  handleCommercialPlanBooking(
    companyId: number,
    migrationSourceShopId: number,
    shopMigrationCommercialPlanBookingGeneralData: ShopUpgradeCommercialPlanBookingInformation
  ): Observable<CompanyAllocations> {
    return this.shopsGateway
      .handleCommercialPlanBooking(companyId, migrationSourceShopId, shopMigrationCommercialPlanBookingGeneralData)
      .pipe(switchMap(() => this.companiesFacade.refreshAllocations()));
  }

  handleCommercialPlanBookingForCustomer(
    companyId: number,
    migrationSourceShopId: number,
    shopMigrationCommercialPlanBookingGeneralData: ShopUpgradeCommercialPlanBookingInformation
  ): Observable<CompanyAllocations> {
    return this.partnersFacade.getPartnerIdOnce().pipe(
      switchMap((partnerId: number) =>
        this.shopsGateway.handleCommercialPlanBookingForCustomer(
          partnerId,
          companyId,
          migrationSourceShopId,
          shopMigrationCommercialPlanBookingGeneralData
        )
      ),
      switchMap(() => this.companiesFacade.refreshAllocations())
    );
  }
}
