import { SelfHostedShop, SwPlatformShopPluginMigration } from '@account/core/models';
import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class ShopSuccessorGateway {
  private readonly apiService = inject(ApiService);

  createShopForSwPlatform(companyId: number, shopId: number, migrationShopDomain: string): Observable<SelfHostedShop> {
    return this.apiService.post(`/companies/${companyId}/shops/${shopId}/swplatformmigrationshop`, {
      domain: migrationShopDomain,
    });
  }

  getSwPlatformSuccessorShopByPredecessor(
    companyId: number,
    predecessorShopId: number,
  ): Observable<SelfHostedShop | null> {
    return this.apiService.get(`/companies/${companyId}/shops/${predecessorShopId}/swplatformmigrationshop`).pipe(
      map((shop: SelfHostedShop | object) => {
        if (Object.keys(shop).length === 0) {
          return null;
        }
        return shop as SelfHostedShop;
      }),
    );
  }

  getPluginMigrationListForSWPlatformShop(
    successorShopId: number,
    mode: 'free' | 'commercial',
  ): Observable<SwPlatformShopPluginMigration[]> {
    const params = new HttpParams().set('mode', mode);
    return this.apiService.get(`/shops/${successorShopId}/pluginlicensesuccessormigrations`, params);
  }

  bookPluginSuccessorsForSWPlatformShop(
    successorShopId: number,
    mode: 'free' | 'commercial',
    migrations: SwPlatformShopPluginMigration[],
  ): Observable<SwPlatformShopPluginMigration[]> {
    return this.apiService.post(`/shops/${successorShopId}/pluginlicensesuccessormigrations`, {
      mode: mode,
      migrations: migrations,
    });
  }

  releaseSwPlatformShop(companyId: number, shopId: number): Observable<SelfHostedShop> {
    return this.apiService.post(`/companies/${companyId}/shops/${shopId}/swplatformrelease`);
  }
}
