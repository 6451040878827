import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ListResult,
  MarketingBudget,
  MarketingBudgetRequest,
  MarketingDocument,
  MarketingPrintBrochures,
  ValuePoints,
  ValuePointsCreditableAction,
  ValuePointsCreditRequest,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { ApiService } from '../../services';
import { HttpParamsBuilder } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class PartnerMarketingGateway {
  private readonly apiService = inject(ApiService);

  getMarketingMaterials(companyId: number): Observable<ListResult<MarketingDocument[]>> {
    return this.apiService.getList(`/companies/${companyId}/marketingmaterial`);
  }

  requestPrintBrochures(printBrochures: MarketingPrintBrochures): Observable<MarketingPrintBrochures> {
    return this.apiService.post('/printmaterial', printBrochures);
  }

  getValuePoints(partnerId: number, year: number): Observable<null | ValuePoints> {
    const params = new HttpParams().set('year', year);
    return this.apiService.get(`/partners/${partnerId}/marketing/points`, params);
  }

  getCreditableActions(partnerId: number): Observable<ValuePointsCreditableAction[]> {
    return this.apiService.get(`/partners/${partnerId}/marketing/points/actions`);
  }

  getCreditRequestList(
    partnerId: number,
    metaData: RequestMetaData,
    filter: Record<string, any>
  ): Observable<ListResult<ValuePointsCreditRequest[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    params = HttpParamsBuilder.applyFilters(params, filter);

    return this.apiService.getList(`/partners/${partnerId}/marketing/points/requests`, params);
  }

  submitCreditRequest(partnerId: number, creditRequest: ValuePointsCreditRequest): Observable<void> {
    return this.apiService.post(`/partners/${partnerId}/marketing/points/requests`, creditRequest);
  }

  deleteCreditRequest(partnerId: number, creditRequestId: number): Observable<void> {
    return this.apiService.delete(`/partners/${partnerId}/marketing/points/requests/${creditRequestId}`);
  }

  getMarketingBudget(partnerId: number, year: number): Observable<null | MarketingBudget> {
    const params = new HttpParams().set('year', year);
    return this.apiService.get(`/partners/${partnerId}/marketing/budget`, params);
  }

  getBudgetRequestList(
    partnerId: number,
    metaData: RequestMetaData,
    filter: Record<string, any>
  ): Observable<ListResult<MarketingBudgetRequest[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    params = HttpParamsBuilder.applyFilters(params, filter);

    return this.apiService.getList(`/partners/${partnerId}/marketing/budget/requests`, params);
  }

  submitBudgetRequest(partnerId: number, budgetRequest: MarketingBudgetRequest): Observable<MarketingBudgetRequest> {
    return this.apiService.post(`/partners/${partnerId}/marketing/budget/requests`, budgetRequest);
  }

  updateBudgetRequest(
    partnerId: number,
    budgetRequest: MarketingBudgetRequest,
    budgetRequestId: number
  ): Observable<MarketingBudgetRequest> {
    return this.apiService.put(`/partners/${partnerId}/marketing/budget/requests/${budgetRequestId}`, budgetRequest);
  }

  deleteBudgetRequest(partnerId: number, budgetRequestId: number): Observable<void> {
    return this.apiService.delete(`/partners/${partnerId}/marketing/budget/requests/${budgetRequestId}`);
  }

  uploadBudgetRequestAttachments(
    partnerId: number,
    budgetRequestId: number,
    attachments: File[]
  ): Observable<MarketingBudgetRequest> {
    return this.apiService.uploadMultiple(
      `/partners/${partnerId}/marketing/budget/requests/${budgetRequestId}/attachments`,
      attachments
    );
  }

  deleteBudgetRequestAttachment(
    partnerId: number,
    budgetRequestId: number,
    attachmentId: number
  ): Observable<MarketingBudgetRequest> {
    return this.apiService.delete(
      `/partners/${partnerId}/marketing/budget/requests/${budgetRequestId}/attachments/${attachmentId}`
    );
  }

  uploadBudgetRequestInvoice(
    partnerId: number,
    budgetRequestId: number,
    invoice: File
  ): Observable<MarketingBudgetRequest> {
    return this.apiService.upload(
      `/partners/${partnerId}/marketing/budget/requests/${budgetRequestId}/invoice`,
      invoice
    );
  }

  getBudgetRequestAttachmentDownloadUrl(
    partnerId: number,
    budgetRequestId: number,
    attachmentId: number
  ): Observable<{ downloadUrl: string }> {
    return this.apiService.get(
      `/partners/${partnerId}/marketing/budget/requests/${budgetRequestId}/attachments/${attachmentId}/download`
    );
  }

  deleteBudgetRequestInvoice(partnerId: number, budgetRequestId: number): Observable<MarketingBudgetRequest> {
    return this.apiService.delete(`/partners/${partnerId}/marketing/budget/requests/${budgetRequestId}/invoice`);
  }

  getBudgetActivities(partnerId: number): Observable<string[]> {
    return this.apiService.get(`/partners/${partnerId}/marketing/budget/requests/activities`);
  }
}
