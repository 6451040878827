import { Action, createReducer, on } from '@ngrx/store';

import { CompanyOnboardingStatus } from '../../models/onboarding/onboarding.model';
import * as actions from './onboarding.actions';
import { CompanyOnboardingState } from './onboarding.state';

export const initialOnboardingState: CompanyOnboardingState = {
  status: CompanyOnboardingStatus.SKIPPED,
  data: null,
};

const onboardingReducerInternal = createReducer(
  initialOnboardingState,

  on(actions.setOnboardingDataForCompanySuccess, (state: CompanyOnboardingState, { payload }) => ({
    ...state,
    status: payload.companyOnboardingState.status,
    data: payload.companyOnboardingState.data,
  })),

  on(actions.updateOnboardingDataForCompanyByDTO, (state: CompanyOnboardingState, { payload }) => ({
    ...state,
    status: payload.companyOnboardingDTO.status,
    data: {
      ...state.data,
      hasVisitedExtensionStore: payload.companyOnboardingDTO.hasVisitedExtensionStore,
      hasExploredShopwarePlans: payload.companyOnboardingDTO.hasExploredShopwarePlans,
    },
  })),

  on(actions.resetOnboardingData, () => ({
    ...initialOnboardingState,
  }))
);

export const reducer = (state: CompanyOnboardingState | undefined, action: Action): CompanyOnboardingState =>
  onboardingReducerInternal(state, action);
