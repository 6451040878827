import { NgFor } from '@angular/common';
import { AfterViewChecked, Component, DoCheck, HostBinding, inject, Input, OnInit } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ScrollingService } from '../../services/scrolling.service';
import { SwIconComponent } from '../sw-icon/sw-icon.component';
import { MenuBarItem } from './menu-bar-item.model';

@Component({
  selector: 'account-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrl: './menu-bar.component.less',
  standalone: true,
  imports: [NgFor, RouterLink, SwIconComponent, MatTooltipModule, TranslateModule],
})
export class MenuBarComponent implements OnInit, DoCheck, AfterViewChecked {
  private readonly route = inject(ActivatedRoute);
  private readonly scrollingService = inject(ScrollingService);
  @Input() items: MenuBarItem[];
  @HostBinding('class.is--active') active = false;

  baseUrl: string;

  private fragment: string;
  private mainContentContainer: Element;
  private lastScrollTop: number;
  private initialScrollExecuted = false;

  ngOnInit(): void {
    this.mainContentContainer = document.querySelector('.a_content-inner');
    this.lastScrollTop = this.mainContentContainer.scrollTop;
    this.baseUrl = window.location.pathname;
    this.active = false;

    this.scrollingService.onScroll.subscribe(() => {
      this.setActiveItem();
    });
    this.scrollingService.onScrollFinished.subscribe(() => {
      this.setActiveItem();
    });

    this.route.fragment.subscribe((fragment: string) => {
      this.fragment = fragment;
      const element: HTMLElement = document.querySelector(`#${this.fragment}`);
      if (element) {
        (element.parentNode as HTMLElement).scrollTop = element.offsetTop;
      }
    });
  }

  ngDoCheck(): void {
    this.setActiveItem();
  }

  ngAfterViewChecked(): void {
    if (!this.initialScrollExecuted) {
      // scroll to anchor of url
      try {
        /*
         * normally this should be working in ngAfterViewInit
         * but somehow the document is not fully available document.querySelector('#' + this.fragment) returns null
         * therefore this is placed inside ngAfterViewChecked as workaround
         */
        const target: HTMLElement = document.querySelector(`#${this.fragment}`);
        (target.parentNode as HTMLElement).scrollTop = target.offsetTop;
        this.initialScrollExecuted = true;
      } catch {
        // ignore
      }
    }
  }

  toggle(): void {
    this.active = !this.active;
  }

  private setActiveItem(): void {
    let firstVisibleItemFound = false;
    let lastVisibleItem: MenuBarItem = null;
    for (const item of this.items) {
      if (this.isInViewPort(item.refId)) {
        if (!firstVisibleItemFound) {
          item.first = true;
          firstVisibleItemFound = true;
        } else {
          item.first = false;
        }
        if (lastVisibleItem) {
          lastVisibleItem.last = false;
        }
        lastVisibleItem = item;
        item.active = true;
      } else {
        if (item.first) {
          item.first = false;
        }
        if (item.last) {
          item.last = false;
        }
        item.active = false;
      }
    }
    if (lastVisibleItem) {
      lastVisibleItem.last = true;
    }
  }

  private isInViewPort(elementId: string): boolean {
    const currentSection = this.findElementById(elementId);
    if (!currentSection) {
      return false;
    }
    const currentScrollTop = this.mainContentContainer.scrollTop;
    const scrollDown = currentScrollTop >= this.lastScrollTop;
    const mainContentRect = this.mainContentContainer.getBoundingClientRect();
    const elementRect = currentSection.getBoundingClientRect();

    // offset to ignore top and bottom edge regions of viewport
    const boundaryOffset = 50;

    if (scrollDown) {
      return (
        mainContentRect.top < elementRect.bottom - boundaryOffset &&
        mainContentRect.bottom - boundaryOffset > elementRect.top
      );
    } else {
      return mainContentRect.bottom + boundaryOffset < elementRect.top;
    }
  }

  private findElementById(elementId: string): any {
    return window.document.querySelector(`[id^="${elementId}"]`);
  }
}
