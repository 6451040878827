<div class="flex flex-row gap-2 items-center">
  @if (completed) {
    <account-sw-icon icon="check-circle-s" variant="solid" class="text-green-600" size="16px" />
    <span class="typo-body-inter-14px-regular text-gray-300">{{ heading }}</span>
  } @else {
    <div
      class="rounded-full flex items-center justify-center bg-gray-700 w-4 h-4 typo-body-inter-12px-bold text-inverted">
      {{ index }}
    </div>

    <a (click)="emitAction()" class="typo-body-inter-14px-medium text-inverted underline" tabindex="0">{{ heading }}</a>
  }
</div>
