import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Country, TimeZone } from '../../models';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class CountriesGateway {
  private readonly apiService = inject(ApiService);

  getCountries(): Observable<Country[]> {
    return this.apiService.get('/countries');
  }

  getTimeZones(): Observable<TimeZone[]> {
    return this.apiService.get('/timezones');
  }
}
