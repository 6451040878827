import {
  CompaniesFacade,
  LanguagesFacade,
  NavigationFacade,
  UserAccountMembershipsFacade,
  UserAccountsFacade,
} from '@account/core/facades';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Configuration, FrontendApi, LogoutFlow } from '@ory/client';
import { AoButtonDirective, AoIconComponent } from '@shopware/aorta';
import { merge, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FeatureFlagPipe } from '../../../shared/pipes';
import { AuthFacade } from '../../facades/auth';
import { CompanyOnboardingFacade } from '../../facades/onboarding/company-onboarding.facade';
import { CompanyAllocations, CompanyMembership, Language, UserAccount, Workspace, WorkspaceItem } from '../../models';
import { CompanyOnboardingStatus } from '../../models/onboarding/onboarding.model';
import { EnvironmentService, FeatureFlagService } from '../../services';
import { MenuOverlayComponent } from './menu-overlay/menu-overlay.component';
import { NavigationExpanderComponent } from './navigation-expander/navigation-expander.component';
import { NavigationHeaderComponent } from './navigation-header/navigation-header.component';
import { NavigationOnboardingComponent } from './navigation-onboarding/navigation-onboarding.component';
import { NavigationSeparatorComponent } from './navigation-separator/navigation-separator.component';
import { NavigationEntryComponent } from './navigation-workspace/navigation-entry/navigation-entry.component';
import { NavigationWorkspaceComponent } from './navigation-workspace/navigation-workspace.component';
import { WorkspacesSelectorComponent } from './workspaces-selector/workspaces-selector.component';

@Component({
  selector: 'account-navigation',
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MenuOverlayComponent,
    NavigationHeaderComponent,
    NavigationWorkspaceComponent,
    NavigationEntryComponent,
    NavigationExpanderComponent,
    WorkspacesSelectorComponent,
    FeatureFlagPipe,
    NavigationOnboardingComponent,
    NavigationSeparatorComponent,
    AoIconComponent,
    AoButtonDirective,
  ],
  preserveWhitespaces: false,
})
export class NavigationComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly navigationFacade = inject(NavigationFacade);
  private readonly authFacade = inject(AuthFacade);
  private readonly companiesFacade = inject(CompaniesFacade);
  private readonly userAccountsFacade = inject(UserAccountsFacade);
  private readonly userAccountMembershipsFacade = inject(UserAccountMembershipsFacade);
  private readonly languagesFacade = inject(LanguagesFacade);
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly environmentService = inject(EnvironmentService);
  private readonly featureFlagService = inject(FeatureFlagService);
  private readonly companyOnboardingFacade = inject(CompanyOnboardingFacade);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  @HostBinding('class.is-collapsed') isCollapsed = true;

  protected readonly CompanyOnboardingStatus = CompanyOnboardingStatus;

  languages: Language[];
  activeUser$: Observable<UserAccount>;
  selectedLanguage$: Observable<Language>;
  onboardingStatus$: Observable<CompanyOnboardingStatus>;

  workspaces$: Observable<Workspace[]>;
  activeWorkspace$: Observable<null | Workspace>;
  activeWorkspaceItems$: Observable<WorkspaceItem[]>;
  activeWorkspaceItem$: Observable<null | WorkspaceItem>;

  isOverlayMenuOpen = false;
  isMobileViewport: boolean;

  showTeasers: boolean;
  showProducerTeaser: boolean;
  showPartnerTeaser: boolean;

  ngOnInit(): void {
    this.languages = this.languagesFacade.getAvailableLanguages();
    this.activeUser$ = this.userAccountsFacade.getUserAccount();
    this.selectedLanguage$ = this.languagesFacade.getSelectedLanguage();
    this.onboardingStatus$ = this.companyOnboardingFacade.getStoredOnboardingStatus();

    this.workspaces$ = this.navigationFacade.getWorkspaces();
    this.activeWorkspace$ = this.navigationFacade.getActiveWorkspace();
    this.activeWorkspaceItems$ = this.navigationFacade.getActiveWorkspaceItems();
    this.activeWorkspaceItem$ = this.navigationFacade.getActiveWorkspaceItem();

    const navigationExpanded$ = this.navigationFacade
      .isExpanded()
      .pipe(tap((isExpanded: boolean) => (this.isCollapsed = !isExpanded)));

    if (this.breakpointObserver.isMatched('(max-width: 1024px)')) {
      this.isMobileViewport = true;
      this.isCollapsed = this.isMobileViewport ? true : !this.navigationFacade.expanded;
    }

    const toggleNavigationUponViewportChange$ = this.breakpointObserver.observe(['(max-width: 1024px)']).pipe(
      tap((viewport: BreakpointState) => {
        this.isMobileViewport = viewport.matches;
        this.changeDetectorRef.detectChanges();

        if (viewport.matches && !this.navigationFacade.expanded) {
          this.toggleNavigation();
        }
      })
    );

    const routeChange$ = this.navigationFacade.onRouteChange();

    const allocations$ = this.companiesFacade.getAllocations().pipe(
      tap((allocations: CompanyAllocations) => {
        this.showTeasers = true;
        this.showProducerTeaser = !allocations.isProducer;
        this.showPartnerTeaser = !allocations.isPartner;
      })
    );

    merge(navigationExpanded$, routeChange$, toggleNavigationUponViewportChange$, allocations$).subscribe();
  }

  selectWorkspace(workspace: Workspace): void {
    this.router.navigate([workspace.path]);
  }

  changeSelectedMembership(membership: CompanyMembership): void {
    this.userAccountMembershipsFacade.changeSelectedMembership(membership).subscribe(() => {
      this.router.navigate(['changecompany'], { skipLocationChange: true });
    });
  }

  logout(): void {
    if (this.featureFlagService.flagActivated('ory')) {
      const basePath = this.environmentService.current.auth_api_url;
      const ory = new FrontendApi(
        new Configuration({
          basePath,
          baseOptions: {
            withCredentials: true,
          },
        })
      );

      const returnTo = `${window.location.protocol}//${window.location.host}`;
      ory
        .createBrowserLogoutFlow({
          returnTo: returnTo,
        })
        .then((response) => response.data)
        .then((logoutFlow: LogoutFlow) => {
          window.location.href = logoutFlow.logout_url;
        });

      return;
    }

    this.authFacade.logout().subscribe(() => {
      // explicitly clear redirect url, if user has logged in via deeplink and than logs out and in again he should not be redirected to the original deeplink url
      this.authFacade.setRedirectUrl();
      this.router.navigate(['front']);
    });
  }

  toggleNavigation(): void {
    this.navigationFacade.toggleNavigation();
  }

  changeLanguage(language: Language): void {
    this.languagesFacade.selectLanguage(language);
  }

  toggleOverlayMenu(): void {
    this.isOverlayMenuOpen = !this.isOverlayMenuOpen;
  }

  goToPortal(): void {
    this.router.navigate(['/portal']);
  }

  trackByWorkspace(index: number, item: Workspace): string {
    return item.name;
  }
}
