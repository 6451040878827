import { CommercialPlanGateway } from '@account/core/gateways';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CommercialPlan } from '../../models/shop/commercial-plan.model';

@Injectable({
  providedIn: 'root',
})
export class CommercialPlanFacade {
  private readonly commercialPlanGateway = inject(CommercialPlanGateway);

  getCommercialMigrationPlans(): Observable<CommercialPlan[]> {
    return this.commercialPlanGateway.getCommercialMigrationPlans();
  }

  getCommercialUpgradePlans(): Observable<CommercialPlan[]> {
    return this.commercialPlanGateway.getCommercialUpgradePlans();
  }

  getCommercialRegularPlans(): Observable<CommercialPlan[]> {
    return this.commercialPlanGateway.getCommercialRegularPlans();
  }
}
