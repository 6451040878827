@if (activeUserContext$ | async; as activeUserContext) {
  <button ao-button variant="link" class="px-2" (click)="goToPortal()">
    <ao-icon name="shopware" class="size-7" />
  </button>

  <button class="flex-1 overflow-hidden min-w-[1px]" ao-button variant="link" (click)="toggleOverlay()">
    <div class="size-7 min-w-7 min-h-7 rounded-full bg-gray-500 flex items-center justify-center">
      {{ user.personalData.firstName.charAt(0) }}{{ user.personalData.lastName.charAt(0) }}
    </div>
    <div class="grid grid-row justify-start w-full">
      <p class="typo-body-inter-15px-medium text-inverted w-full text-ellipsis text-left">
        {{ activeUserContext.company?.name || user.personalData.firstName + ' ' + user.personalData.lastName }}
      </p>
    </div>
    <ao-icon name="chevron-down-s" class="size-3" />
  </button>

  @if (notifications$ | async) {
    <button #notificationWrap (click)="openNotificationCenter()" ao-button variant="link" class="relative">
      <ao-icon name="bell" variant="regular" class="size-5" />
      @if (showNotificationBubble) {
        <div class="size-2 bg-blue-500 rounded-full absolute top-1 right-1"></div>
      }
    </button>
  }
}
