import { PartnerSupportPermissionGateway } from '@account/core/gateways';
import { SbpException, SupportPermissions } from '@account/core/models';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';

import { partnerStore } from '../../../store/partner';
import { RootState } from '../../../store/root.state';
import { PartnersFacade } from '../partners.facade';

@Injectable({
  providedIn: 'root',
})
export class SupportPermissionFacade {
  private readonly store = inject<Store<RootState>>(Store<RootState>);
  private readonly partnersFacade = inject(PartnersFacade);
  private readonly gateway = inject(PartnerSupportPermissionGateway);

  getSupportPermissions(): Observable<SupportPermissions> {
    return this.store.select(partnerStore.selectors.supportPermissions.selectLoaded).pipe(
      switchMap((isLoaded: boolean) => {
        if (isLoaded) {
          return this.store.select(partnerStore.selectors.supportPermissions.selectError).pipe(
            switchMap((error: SbpException | undefined) => {
              if (error) {
                return throwError(() => error);
              }
              return this.store.select(partnerStore.selectors.supportPermissions.selectSupportPermissions);
            })
          );
        }
        return this.partnersFacade.getPartnerIdOnce().pipe(
          tap((partnerId: number) =>
            this.store.dispatch(partnerStore.actions.supportPermissions.getPermissions({ payload: partnerId }))
          ),
          switchMap(() => this.store.select(partnerStore.selectors.supportPermissions.selectLoaded)),
          filter((loaded: boolean) => loaded),
          switchMap(() => this.getSupportPermissions())
        );
      }),
      take(1)
    );
  }

  getSupportPermissionForShop(companyId: number, shopId: number): Observable<SupportPermissions> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(switchMap((partnerId: number) => this.gateway.getSupportPermissionForShop(partnerId, companyId, shopId)));
  }
}
