import {
  ActivationStatus,
  Addon,
  ApprovalStatus,
  BinaryStatus,
  DemoType,
  Info,
  License,
  LifecycleStatus,
  Localization,
  PluginGeneration,
  PluginLocale,
  PluginPriceModel,
  ProductType,
  SoftwareVersion,
  StoreAvailability,
} from '@account/core/models';
import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Category, QueryFilter, Statics } from '../../models';
import { ApiService } from '../../services';
import { HttpParamsBuilder } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class PluginStaticsGateway {
  private readonly apiService = inject(ApiService);

  getPluginStatics(
    key: string,
    filter: QueryFilter[] = null
  ): Observable<
    | Statics
    | ActivationStatus[]
    | Addon[]
    | ApprovalStatus[]
    | BinaryStatus[]
    | Category[]
    | DemoType[]
    | Info[]
    | License[]
    | LifecycleStatus[]
    | PluginLocale[]
    | Localization[]
    | PluginPriceModel[]
    | SoftwareVersion[]
    | StoreAvailability[]
    | ProductType[]
    | PluginGeneration[]
  > {
    let params = new HttpParams();
    if (filter) {
      params = HttpParamsBuilder.applyFilters(params, filter, true);
    }
    return this.apiService.get(`/pluginstatics/${key}`, params);
  }
}
