<ng-template ngFor [ngForOf]="loopingArray" let-index="index">
  <ng-container *ngIf="renderEmptyStar(index); else filledRatingStar">
    <account-star size="full" [filled]="false" [disabled]="disabled" />
  </ng-container>

  <ng-template #filledRatingStar>
    <ng-container *ngIf="normalizedAmount - (index + 1) > 0; else lastStar">
      <account-star [disabled]="disabled" />
    </ng-container>

    <ng-template #lastStar>
      <account-star *ngIf="!showHalfStar" size="full" [disabled]="disabled" />
      <account-star *ngIf="showHalfStar" size="half" [disabled]="disabled" />
    </ng-template>
  </ng-template>
</ng-template>
