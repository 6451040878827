@switch (plan) {
  @case (null) {
    <div>
      <div class="pre-title">Shopware</div>
      <div class="title-section">
        <div class="title notranslate">
          Community
        </div>
      </div>
      <div class="sub-title">{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.COMMUNITY.SLOGAN' | translate }}</div>
    </div>

    <div>
      <span class="price notranslate">
        {{ 'COMMON.PRICE_MODEL.FREE' | translate }}
      </span>
    </div>

    <ul class="advantages">
      <li>
        <account-sw-icon icon="times-s" size="12px" color="var(--sw-crimson-500)" />
        <span>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.COMMUNITY.NO_SUPPORT' | translate }}</span>
      </li>
      <li>
        <account-sw-icon icon="times-s" size="12px" color="var(--sw-crimson-500)" />
        <span>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.COMMUNITY.NO_AI_COPILOT' | translate }}</span>
      </li>
      <li>
        <account-sw-icon icon="times-s" size="12px" color="var(--sw-crimson-500)" />
        <span>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.COMMUNITY.NO_AUTOMATION' | translate }}</span>
      </li>
    </ul>
  }
  @case ('rise') {
    <div>
      <div class="pre-title">Shopware</div>
      <div class="title-section">
        <div class="title notranslate">
          {{ plan }}
          @if (isSpecialOfferPhase) {
            <span class="special">
              {{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.EVOLVE.SPECIAL_OFFER' | translate }}
            </span>
          }
        </div>
        @if (allowedForTrialPhase && !isCustomPrice) {
          <span class="special">{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.FREE_TRIAL' | translate }}</span>
        }
      </div>
      <div class="sub-title">{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.RISE.SLOGAN' | translate }}</div>
    </div>

    <div>
      @if (isCustomPrice) {
        <span class="price notranslate">{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.CUSTOM_PRICE' | translate }}</span>
      } @else {
        <span class="price notranslate">
          {{ calculatedPrice?.currentPricingPeriodPricePerMonth | localeCurrency: '' : 'symbol' : '1.0-0' }}
        </span>
        <span class="per-month">{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.PER_MONTH' | translate }}</span>
      }
      <br />
      @if (
        calculatedPrice?.nextPricingPeriodPricePerMonth !== null &&
        calculatedPrice?.currentPricingPeriodPricePerMonth !== calculatedPrice?.nextPricingPeriodPricePerMonth
      ) {
        <span class="price-after notranslate">
          {{ calculatedPrice?.nextPricingPeriodPricePerMonth | localeCurrency: '' : 'symbol' : '1.0-0' }}
        </span>
        <span class="price-from">{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.THIRTEENTH_MONTH_ON' | translate }}</span>
      }
    </div>

    <ul class="advantages">
      <li>
        <account-sw-icon icon="checkmark-s" size="12px" color="var(--sw-vivid-500)" />
        <span>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.RISE.SUPPORT' | translate }}</span>
      </li>
      <li>
        <account-sw-icon icon="checkmark-s" size="12px" color="var(--sw-vivid-500)" />
        <span>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.RISE.AI_COPILOT' | translate }}</span>
      </li>
      <li>
        <account-sw-icon icon="checkmark-s" size="12px" color="var(--sw-vivid-500)" />
        <span>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.RISE.AUTOMATION' | translate }}</span>
      </li>
    </ul>

    @if (subscriptionRefund) {
      <div>
        <span class="price-refund">{{ subscriptionRefund | localeCurrency: '' : 'symbol' : '1.0-0' }}</span>
        <span class="refund">{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.SUBSCRIPTION_REFUND' | translate }}</span>
      </div>
    }
  }
  @case ('evolve') {
    <div>
      <div class="pre-title">Shopware</div>
      <section>
        <div class="title notranslate">
          {{ plan }}
          @if (isSpecialOfferPhase) {
            <span class="special">
              {{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.EVOLVE.SPECIAL_OFFER' | translate }}
            </span>
          }
        </div>
      </section>
      <div class="sub-title">{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.EVOLVE.SLOGAN' | translate }}</div>
    </div>

    <div>
      @if (allowedForTrialPhase || isCustomPrice) {
        <span class="price notranslate">{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.CUSTOM_PRICE' | translate }}</span>
      } @else {
        <span class="price notranslate">
          {{ calculatedPrice?.currentPricingPeriodPricePerMonth | localeCurrency: '' : 'symbol' : '1.0-0' }}
        </span>
        <span class="per-month">{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.PER_MONTH' | translate }}</span>
        <br />
        @if (
          calculatedPrice?.nextPricingPeriodPricePerMonth !== null &&
          calculatedPrice?.currentPricingPeriodPricePerMonth !== calculatedPrice?.nextPricingPeriodPricePerMonth
        ) {
          <span class="price-after notranslate">
            {{ calculatedPrice?.nextPricingPeriodPricePerMonth | localeCurrency: '' : 'symbol' : '1.0-0' }}
          </span>
          <span class="price-from">
            {{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.THIRTEENTH_MONTH_ON' | translate }}
          </span>
        }
      }
    </div>

    <ul class="advantages">
      <li>
        <account-sw-icon icon="checkmark-s" size="12px" color="var(--sw-vivid-500)" />
        <span>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.EVOLVE.SUPPORT' | translate }}</span>
      </li>
      <li>
        <account-sw-icon icon="checkmark-s" size="12px" color="var(--sw-vivid-500)" />
        <span>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.EVOLVE.B2B_SOLUTIONS' | translate }}</span>
      </li>
      <li>
        <account-sw-icon icon="checkmark-s" size="12px" color="var(--sw-vivid-500)" />
        <span>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.EVOLVE.ADVANCED_SEARCH' | translate }}</span>
      </li>
      <li>
        <account-sw-icon icon="checkmark-s" size="12px" color="var(--sw-vivid-500)" />
        <span>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.RISE.AI_COPILOT' | translate }}</span>
      </li>
      <li>
        <account-sw-icon icon="checkmark-s" size="12px" color="var(--sw-vivid-500)" />
        <span>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.RISE.AUTOMATION' | translate }}</span>
      </li>
      @if (showAndMoreLink) {
        <li>
          <a
            [href]="'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.AND_MORE_URL' | translate"
            target="_blank"
            rel="noreferrer">
            {{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.EVOLVE.AND_MORE' | translate }}
          </a>
        </li>
      }
    </ul>

    @if (subscriptionRefund) {
      <div>
        <span class="price-refund">{{ subscriptionRefund | localeCurrency: '' : 'symbol' : '1.0-0' }}</span>
        <span class="refund">{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.SUBSCRIPTION_REFUND' | translate }}</span>
      </div>
    }
  }
  @case ('beyond') {
    <div>
      <div class="pre-title">Shopware</div>
      <div class="title notranslate">{{ plan }}</div>
      <div class="sub-title">{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.BEYOND.SLOGAN' | translate }}</div>
    </div>
    <div>
      @if (isCustomPrice) {
        <span class="price notranslate">{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.CUSTOM_PRICE' | translate }}</span>
      } @else {
        <span class="price notranslate">
          {{ calculatedPrice?.currentPricingPeriodPricePerMonth | localeCurrency: '' : 'symbol' : '1.0-0' }}
        </span>
        <span class="per-month">{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.PER_MONTH' | translate }}</span>
        <br />
        @if (
          calculatedPrice?.nextPricingPeriodPricePerMonth !== null &&
          calculatedPrice?.currentPricingPeriodPricePerMonth !== calculatedPrice?.nextPricingPeriodPricePerMonth
        ) {
          <span class="price-after notranslate">
            {{ calculatedPrice?.nextPricingPeriodPricePerMonth | localeCurrency: '' : 'symbol' : '1.0-0' }}
          </span>
          <span class="price-from">
            {{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.THIRTEENTH_MONTH_ON' | translate }}
          </span>
        }
      }
    </div>

    <ul class="advantages">
      <li>
        <account-sw-icon icon="checkmark-s" size="12px" color="var(--sw-vivid-500)" />
        <span>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.BEYOND.SUPPORT' | translate }}</span>
      </li>
      <li>
        <account-sw-icon icon="checkmark-s" size="12px" color="var(--sw-vivid-500)" />
        <span>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.BEYOND.DIGITAL_SALES_ROOM' | translate }}</span>
      </li>
      <li>
        <account-sw-icon icon="checkmark-s" size="12px" color="var(--sw-vivid-500)" />
        <span>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.EVOLVE.B2B_SOLUTIONS' | translate }}</span>
      </li>
      <li>
        <account-sw-icon icon="checkmark-s" size="12px" color="var(--sw-vivid-500)" />
        <span>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.BEYOND.SUBSCRIPTIONS' | translate }}</span>
      </li>
      <li>
        <account-sw-icon icon="checkmark-s" size="12px" color="var(--sw-vivid-500)" />
        <span>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.BEYOND.CUSTOMER_PRICES' | translate }}</span>
      </li>
      <li>
        <account-sw-icon icon="checkmark-s" size="12px" color="var(--sw-vivid-500)" />
        <span>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.BEYOND.MULTI_INVENTORY' | translate }}</span>
      </li>
    </ul>

    @if (subscriptionRefund) {
      <div>
        <span class="price-refund">{{ subscriptionRefund | localeCurrency: '' : 'symbol' : '1.0-0' }}</span>
        <span class="refund">{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.SUBSCRIPTION_REFUND' | translate }}</span>
      </div>
    }
  }
}
