import { Environment } from '@account/core/models';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private readonly http = inject(HttpClient);
  private readonly ENVIRONMENT_URL = 'assets/config/environment.json';
  private readonly environment$ = new BehaviorSubject<Environment>(null);

  loadEnvironment(): Observable<boolean> {
    const dynamicEnvironment$ = this.http
      .get<Environment>(this.ENVIRONMENT_URL)
      .pipe(map((dynamicEnvironment: Environment) => ({ ...environment, ...dynamicEnvironment })));

    const environment$ = environment.envName !== 'dynamic' ? of(environment) : dynamicEnvironment$;

    return environment$.pipe(
      tap((environment: Environment) => {
        this.environment$.next(environment);
      }),
      map(() => true)
    );
  }

  get current(): Environment {
    return this.environment$.value;
  }
}
