import { SelectionModel } from '@angular/cdk/collections';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NgClass, NgIf } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { merge, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RequestMetaData } from '../../../core/models/api/request-meta-data.model';
import { LoadingSpinnerDirective } from '../../../shared/directives/loading-spinner.directive';
import { EmptyStateComponent } from '../empty-state/empty-state.component';
import { SbpTableActions } from './actions/actions';
import { TableAction } from './actions/table-action';
import { tableExpandAnimation } from './animations/table-expand-animation';
import { SbpTableExpander } from './expander/expander';
import { SbpTableFilterComponent } from './filter/filter';
import { SbpTableHeader } from './header/header';
import { SbpTablePaginator } from './paginator/paginator';
import { SbpTableSelector } from './selector/selector';
import { SbpSort } from './sort/sort';
import { SbpColumnDef, SbpDataSource, SbpHeaderCellDef, SbpTable } from './table';
import { SbpHeaderRow, SbpHeaderRowDef, SbpRow, SbpRowDef } from './table/row';
import { SbpTable as SbpTable_1 } from './table/table';

@Directive({
  selector: 'table-container-controls',
  standalone: true,
})
export class TableContainerControlsDirective {
  private readonly elementRef = inject(ElementRef);
  private readonly renderer = inject(Renderer2);

  constructor() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'grid');
    this.renderer.setStyle(this.elementRef.nativeElement, 'grid-auto-flow', 'column dense');
    this.renderer.setStyle(this.elementRef.nativeElement, 'gap', '12px');
    this.renderer.setStyle(this.elementRef.nativeElement, 'align-items', 'center');
    this.renderer.setStyle(this.elementRef.nativeElement, 'justify-content', 'flex-end');
  }
}

@Directive({
  selector: 'table-container-footer-controls',
  standalone: true,
})
export class TableContainerFooterControlsDirective {}

@Directive({
  selector: 'table-container-columns',
  standalone: true,
})
export class TableContainerColumnsDirective {}

@Directive({
  selector: 'table-container-custom-content',
  standalone: true,
})
export class TableContainerCustomContentDirective {}

@Component({
  selector: 'account-table-container',
  templateUrl: './table-container.component.html',
  styleUrl: './table-container.component.less',
  animations: [tableExpandAnimation],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    SbpTableHeader,
    SbpTable_1,
    SbpTableExpander,
    SbpTableSelector,
    SbpTableActions,
    SbpHeaderRowDef,
    SbpHeaderRow,
    SbpRowDef,
    SbpRow,
    EmptyStateComponent,
    MatTooltipModule,
    SbpTablePaginator,
    SbpHeaderCellDef,
    SbpColumnDef,
    LoadingSpinnerDirective,
  ],
})
export class TableContainerComponent<T> implements OnInit, OnDestroy, AfterContentInit {
  private readonly _sort = inject(SbpSort, { optional: true });
  private readonly breakpointObserver = inject(BreakpointObserver);
  @Input() headline: string;
  @Input() emptyStateHeading: string;
  @Input() emptyStateSubheading: string;
  @Input() emptyStateIcon: string;
  @Input() emptyStateButtonText: string | null;
  @Input() emptyStateButtonDisabled: boolean | null;
  @Input() emptyStateButtonDisabledTooltip: string | null;
  @Input() total: number;
  @Input() showTotal = true;
  @Input() requestMetaData: RequestMetaData;
  @Input() gridActions: TableAction[] = [];
  @Input() rowActions: TableAction[] = [];
  @Input() pagination = true;
  @Input() hasShadow = false;
  @Input() showFooter = true;
  @Input() showRefresh = true;
  @Input() multiline = false;
  @Input() loading: boolean | null = null;

  @Input()
  @HostBinding('class.embedded')
  embedded = false;

  @Input()
  @HostBinding('class.stretch-height')
  stretchHeight = false;

  @Output() readonly requestMetaDataChange = new EventEmitter<RequestMetaData>();
  @Output() readonly rowDoubleClicked = new EventEmitter<T>();
  @Output() readonly emptyStateButtonAction = new EventEmitter<void>();

  @ContentChild(SbpTableFilterComponent) tableFilter: SbpTableFilterComponent;

  selection = new SelectionModel<T>(true, []);
  expandedRowElement: T = null;
  displayedExpansionColumns: string[] = [];
  isMobileViewport: boolean;

  @ViewChild(SbpTablePaginator, { static: true }) private readonly paginator: SbpTablePaginator;
  @ViewChild(SbpTable, { static: true }) private readonly table: SbpTable<T>;
  @ContentChildren(SbpColumnDef) private readonly sbpColumDefs: QueryList<SbpColumnDef>;

  private _dataSource: SbpDataSource<T>;
  private _displayedColumns: string[] = [];
  private _loading = false;
  private changeSubscription: Subscription;
  private originalRequestMetaData: RequestMetaData = null;

  @Input() set dataSource(dataSource: SbpDataSource<T>) {
    dataSource.connect().subscribe(
      (data) => (this._loading = dataSource.isInitialState && data.length === 0)
    );
    this._dataSource = dataSource;
  }

  get dataSource(): SbpDataSource<T> {
    return this._dataSource;
  }

  @Input() set displayedColumns(displayedColumns: string[]) {
    this._displayedColumns = displayedColumns;
  }

  get displayedColumns(): string[] {
    let array = [...this._displayedColumns];
    if (this.gridActions && this.gridActions.length > 0) {
      array = ['selector', ...array, 'actions'];
    }
    if (!array.includes('actions') && this.rowActions && this.rowActions.length > 0) {
      array = [...array, 'actions'];
    }
    const expansion = this.sbpColumDefs.find((columnDef: SbpColumnDef) => columnDef.name === 'expansion');
    if (expansion) {
      this.displayedExpansionColumns = ['expansion'];
      array = ['expander', ...array];
    }
    return array;
  }

  ngOnInit(): void {
    this.originalRequestMetaData = Object.assign({}, this.requestMetaData);

    const mobileObserver = this.breakpointObserver.observe(['(max-width: 1024px)']);

    mobileObserver.subscribe((viewport) => {
      this.isMobileViewport = viewport.matches;
    });
  }

  ngAfterContentInit(): void {
    const updateOffset = (): void => {
      if (this.paginator) {
        this.paginator.offset = 0;
      }
      this.requestMetaData.offset = 0;
    };

    if (this._sort) {
      this._sort.sortChange.subscribe(updateOffset);
    }

    this.sbpColumDefs.forEach((sbpColumnDef: SbpColumnDef) => {
      this.table.addColumnDef(sbpColumnDef);
    });

    let observables$: any[] = [];
    if (this._sort) {
      observables$ = [this._sort.sortChange];
    }
    if (this.pagination) {
      observables$ = [...observables$, this.paginator.onChange, this.paginator.onReload];
    }
    if (this.tableFilter) {
      observables$ = [...observables$, this.tableFilter.onUpdateFilter, this.tableFilter.onTriggerSearch];
      this.tableFilter.onUpdateFilter.subscribe(updateOffset);
      this.tableFilter.onTriggerSearch.subscribe(updateOffset);
    }
    this.changeSubscription = merge(...observables$)
      .pipe(tap(() => this.selection.clear()))
      .subscribe(() => {
        this._loading = true;
        this.requestMetaDataChange.emit(this.generateRequestMetaData());
      });
  }

  ngOnDestroy(): void {
    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
    }
  }

  toggleExpansion(row: T): void {
    if (this.expandedRowElement === row) {
      this.expandedRowElement = null;
      return;
    }

    this.expandedRowElement = row;
  }

  isExpansionRow(index: number, row: any): boolean {
    return Object.prototype.hasOwnProperty.call(row, 'sbpExpansionRow');
  }

  onRowDoubleClick($event: Event, element: T): void {
    if (($event.target as HTMLElement).closest('account-dropdown')) {
      return;
    }
    this.rowDoubleClicked.emit(element);
  }

  applyFilter(openFilter = true): void {
    if (this.tableFilter) {
      this.tableFilter.applyFilter();
      this.tableFilter.visible = openFilter;
    }
  }

  emitEmptyStateButtonAction(): void {
    this.emptyStateButtonAction.emit();
  }

  get isLoading(): boolean {
    return this.loading === true || (this.loading !== false && this._loading === true);
  }

  private generateRequestMetaData(): RequestMetaData {
    const requestMetaData: RequestMetaData = {
      limit: this.requestMetaData.limit,
      offset: this.requestMetaData.offset,
      orderBy: this.requestMetaData.orderBy,
      orderSequence: this.requestMetaData.orderSequence,
      search: this.requestMetaData.search,
    };

    if (this.paginator) {
      if (this.paginator.limit) {
        requestMetaData.limit = this.paginator.limit;
      }
      if (this.paginator.offset || this.paginator.offset === 0) {
        requestMetaData.offset = this.paginator.offset;
      }
    }

    if (this._sort && this._sort.active && this._sort.direction) {
      requestMetaData.orderBy = this._sort.active;
      requestMetaData.orderSequence = this._sort.direction;
    } else {
      requestMetaData.orderBy = this.originalRequestMetaData.orderBy;
      requestMetaData.orderSequence = this.originalRequestMetaData.orderSequence;
    }

    if (this.tableFilter) {
      if (this.tableFilter.searchInput.value || this.tableFilter.searchInput.value === '') {
        requestMetaData.search = this.tableFilter.searchInput.value;
      }
    }

    if (
      this.tableFilter &&
      this.tableFilter.getActiveFilterGroups() &&
      this.tableFilter.getActiveFilterGroups().length > 0
    ) {
      requestMetaData.filter = this.tableFilter.getActiveFilterGroups();
    }

    return requestMetaData;
  }
}
