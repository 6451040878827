<div class="dropdown-container" (blur)="onBlur($event)">
  <div class="dropdown" [class.focused]="focus">
    <span class="item-badge" *ngFor="let selectedItem of selected">
      <ng-template [ngIf]="template && selectedItem">
        <img
          class="ai-generated-indicator"
          *ngIf="selectedItem.fromAiAssigned"
          src="/assets/images/shared/sparkles.svg" />
        <tags-input-item-template-loader class="item-content" [template]="template" [item]="selectedItem" />
        <account-sw-icon class="item-remove" (click)="select($event, selectedItem)" icon="times-xs" size="8px" />
      </ng-template>
    </span>
    <input
      (focus)="onFocus()"
      (focusout)="onBlur($event)"
      #inputFieldElement
      class="input-field"
      [class.invalid]="inputInvalid"
      type="text"
      placeholder="{{ placeholder || 'Add Tag' }}"
      [formControl]="inputField"
      (paste)="onPaste($event)"
      (keydown)="onInputKeyDown($event)" />
  </div>
  <div class="dropdown-content" [class.open]="inputField.value && items.length > 0">
    <div class="dropdown-list">
      <div
        class="dropdown-list-item"
        *ngFor="let item of items"
        (click)="select($event, item)"
        [class.active]="isSelected(item)">
        <ng-template [ngIf]="template">
          <tags-input-item-template-loader [template]="template" [item]="item" />
        </ng-template>
      </div>
    </div>
  </div>
</div>
