import { LanguagesFacade } from '@account/core/facades';
import {
  Category,
  CategoryDetail,
  Language,
  PluginTestingInstancesConfig,
  SoftwareVersion,
} from '@account/core/models';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Md5 } from 'ts-md5';

import {
  Binary,
  Info,
  Plugin,
  PluginForLocalization,
  PluginForStoreAvailability,
  PluginPriceModel,
  PluginVariant,
  StoreAvailability,
} from '../../core/models/plugin/plugin.model';
import { EnvironmentService } from '../../core/services';
import { versionCompare } from '../../core/utils';

@Injectable({
  providedIn: 'root',
})
export class PluginsService {
  private readonly languagesFacade = inject(LanguagesFacade);
  private readonly translateService = inject(TranslateService);
  private readonly environmentService = inject(EnvironmentService);
  testingInstanceConfig: PluginTestingInstancesConfig;
  private readonly currentLanguage$: Observable<Language>;

  constructor() {
    this.currentLanguage$ = this.languagesFacade.getSelectedLanguage();
  }

  getLocalizedName(plugin: PluginForLocalization, showGenerationLabel = false): string {
    let langKey = '';
    this.currentLanguage$.pipe(take(1)).subscribe((language: Language) => (langKey = language.key));
    let pluginName: string;
    if (plugin?.infos.length > 0) {
      const infoInActiveLanguage = plugin.infos.find((info: Info) => info.locale.name.slice(0, 2) === langKey);
      if (infoInActiveLanguage && infoInActiveLanguage.name) {
        pluginName = infoInActiveLanguage.name;
      } else {
        const alternateLangKey = langKey === 'de' ? 'en' : 'de';

        const infoInAlternativeLanguage = plugin.infos.find(
          (info: Info) => info.locale.name.slice(0, 2) === alternateLangKey
        );
        if (infoInAlternativeLanguage && infoInAlternativeLanguage.name) {
          pluginName = infoInAlternativeLanguage.name;
        }
      }
    }
    if (!pluginName) {
      if (plugin?.name.length > 0) {
        pluginName = plugin.name;
      } else {
        pluginName = langKey === 'de' ? 'Unbenannt' : 'Unlabeled';
        if (typeof plugin?.code === 'string') {
          pluginName += ` (${plugin.code})`;
        }
      }
    }

    if (showGenerationLabel && plugin?.generation.name === 'classic') {
      pluginName += ` ${this.translateService.instant('COMMON.LABEL.PLUGIN.GENERATION.CLASSIC')}`;
    }
    return pluginName;
  }

  getLocalizedCategoryName(pluginCategory: Category): string {
    let langKey = '';
    this.currentLanguage$.pipe(take(1)).subscribe((language: Language) => (langKey = language.key));

    return pluginCategory.details.find((detail: CategoryDetail) => detail.locale.name.slice(0, 2) === langKey).name;
  }

  getLowestPluginPrice(pluginList: Plugin[], type = 'buy', duration: number = null): number {
    let lowestPluginPrice: null | number = null;
    pluginList.forEach((plugin: Plugin) => {
      const pluginPrice = this.getPluginPrice(plugin, type, duration);
      if (!lowestPluginPrice || pluginPrice < lowestPluginPrice) {
        lowestPluginPrice = pluginPrice;
      }
    });

    return lowestPluginPrice;
  }

  getPluginPrice(plugin: Plugin, type = 'buy', duration: number = null): null | number {
    const validPriceTypes = ['buy', 'rent', 'support'];
    if (!validPriceTypes.includes(type)) {
      throw new Error(`Price type must be one of: ${validPriceTypes.join('; ')}`);
    }

    if (duration !== null && type !== 'rent') {
      throw new Error('Price must be of type rent when duration is set');
    }

    const variant = plugin.variants?.find(
      (variantToCheck: PluginVariant) =>
        type === variantToCheck.priceModel.type &&
        (duration === null || variantToCheck.priceModel.duration === duration)
    );
    return undefined === variant ? null : variant.priceModel.price;
  }

  getPluginDiscount(plugin: Plugin, type = 'buy'): null | number {
    const validPriceTypes = ['buy', 'rent', 'support'];
    if (!validPriceTypes.includes(type)) {
      throw new Error(`Price type must be one of: ${validPriceTypes.join('; ')}`);
    }

    const variant = plugin.variants?.find((variantToCheck: PluginVariant) => type === variantToCheck.priceModel.type);
    return undefined === variant ? null : variant.priceModel.discount;
  }

  getPluginDiscountAppliesForMonth(plugin: Plugin): null | number {
    const type = 'rent';
    const variant = plugin.variants?.find((variantToCheck: PluginVariant) => type === variantToCheck.priceModel.type);
    return undefined === variant ? null : variant.priceModel.discountAppliesForMonths;
  }

  getPluginPriceModel(plugin: Plugin, type = 'buy'): null | PluginPriceModel {
    const validPriceTypes = ['buy', 'rent'];
    if (!validPriceTypes.includes(type)) {
      throw new Error(`Price type must be one of: ${validPriceTypes.join('; ')}`);
    }

    const variant = plugin.variants?.find((variantToCheck: PluginVariant) => type === variantToCheck.priceModel.type);
    return undefined === variant ? null : variant.priceModel;
  }

  getPluginsForTooltip(pluginList: Plugin[], showGeneration = false): null | string {
    const determinator = '\n';
    let pluginsAsString = pluginList.reduce(
      (accumulator: string, plugin: Plugin) =>
        `${accumulator}• ${this.getLocalizedName(plugin, showGeneration)}${determinator}`,
      ''
    );
    // remove last determinator
    pluginsAsString = pluginsAsString.slice(0, -determinator.length);
    return pluginsAsString.length > 0 ? pluginsAsString : null;
  }

  sortPluginList(list: Plugin[], orderBy?: string): Plugin[] {
    switch (orderBy) {
      case 'name':
      default:
        this.sortPluginListByName(list);
    }

    return list;
  }

  getStoreLinkForPlugin(plugin: PluginForStoreAvailability): string {
    let langKey = '';
    this.currentLanguage$.pipe(take(1)).subscribe((language: Language) => (langKey = language.key));

    let url = `${this.environmentService.current.storeProtocol}://${this.environmentService.current.storeIp}:${this.environmentService.current.storePort}`;

    const isInternationalized =
      plugin.storeAvailabilities.find(
        (storeAvailability: StoreAvailability) => 'International' === storeAvailability.name
      ) !== undefined;

    if (isInternationalized && langKey === 'en') {
      url += '/en';
    } else {
      url += '/de';
    }

    const hash = Md5.hashStr(`plugin_${plugin.id}`);
    url += `/detail/${hash}`;
    return url;
  }

  getAllCompatibleShopwareVersions(plugin: Plugin): SoftwareVersion[] {
    let versions: SoftwareVersion[] = [];
    for (const binary of plugin.binaries) {
      versions = [...versions, ...binary.compatibleSoftwareVersions];
    }

    const unique = (obj: Record<string, any>, pos: number, arr: any[]): boolean =>
      arr.map((mapObj) => mapObj['id']).indexOf(obj['id']) === pos;

    const softwareVersionCompare = (a: SoftwareVersion, b: SoftwareVersion): number => versionCompare(a.name, b.name);

    return versions.filter(unique).sort(softwareVersionCompare);
  }

  pluginHasValidStatusForTestingInstance(plugin: Plugin): boolean {
    return ['created', 'approvalrejected', 'readyforapproval'].includes(plugin.lifecycleStatus.name);
  }

  pluginHasValidStatusForTestingInstanceAfterRelease(plugin: Plugin): boolean {
    return ['approvalrejected', 'fullyrejected', 'readyforstore'].includes(plugin.lifecycleStatus.name);
  }

  pluginRequiresTestingInstance(plugin: Plugin, ignoreMaintenanceMode = false): boolean {
    if (
      plugin.pluginTestingInstanceDisabled ||
      (!ignoreMaintenanceMode && this.testingInstanceConfig?.maintenanceModeEnabled)
    ) {
      return false;
    }

    if (!this.pluginHasValidStatusForTestingInstance(plugin)) {
      return false;
    }

    const latestApprovedBinaryByVersion = this.getLatestApprovedBinaryByVersion(plugin.binaries);

    const latestShopwareVersion = latestApprovedBinaryByVersion?.compatibleSoftwareVersions
      .filter((version: SoftwareVersion) => version.status === 'public')
      .sort((a: SoftwareVersion, b: SoftwareVersion) => versionCompare(b.name, a.name))[0];

    if (!latestShopwareVersion || versionCompare(latestShopwareVersion.name, '6.4.20.0') < 0) {
      return false;
    }

    return true;
  }

  pluginFulfillsPublishedRequirements(plugin: Plugin, ignoreMaintenanceMode = false): boolean {
    if (
      plugin.pluginTestingInstanceDisabled ||
      (!ignoreMaintenanceMode && this.testingInstanceConfig?.maintenanceModeEnabled)
    ) {
      return false;
    }

    if (!this.pluginHasValidStatusForTestingInstanceAfterRelease(plugin)) {
      return false;
    }

    const latestApprovedBinaryByVersion = this.getLatestApprovedBinaryByVersion(plugin.binaries);

    const latestShopwareVersion = latestApprovedBinaryByVersion?.compatibleSoftwareVersions
      .filter((version: SoftwareVersion) => version.status === 'public')
      .sort((a: SoftwareVersion, b: SoftwareVersion) => versionCompare(b.name, a.name))[0];

    if (!latestShopwareVersion || versionCompare(latestShopwareVersion.name, '6.4.20.0') < 0) {
      return false;
    }

    return true;
  }

  getLatestApprovedBinaryByVersion(binaries: Binary[]): Binary | undefined {
    return binaries
      .filter((binary: Binary) => binary.status.name === 'codereviewsucceeded')
      .sort((a: Binary, b: Binary) => versionCompare(b.version, a.version))[0];
  }

  pluginIsOlderThanSW5(plugin: Plugin): boolean {
    if (plugin.generation.name !== 'classic') {
      return false;
    }
    const foundBinaries = plugin.binaries.find((binary: Binary) => {
      if (binary.status.name !== 'codereviewsucceeded') {
        return false;
      }
      const foundSoftware5Versions = binary.compatibleSoftwareVersions.find((softwareVersion: SoftwareVersion) =>
        softwareVersion.name.startsWith('5')
      );
      return foundSoftware5Versions !== undefined;
    });

    return foundBinaries === undefined;
  }

  private sortPluginListByName(list: Plugin[]): void {
    list.sort((a: Plugin, b: Plugin) => {
      const nameA = this.getLocalizedName(a);
      const nameB = this.getLocalizedName(b);

      if (nameA <= nameB) {
        return -1;
      }
      return 1;
    });
  }
}
