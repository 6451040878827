import { NgIf } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { SwIconComponent } from '../../sw-icon/sw-icon.component';
import { SbpColumnDef, SbpTable } from '../table';
import { SbpCell, SbpCellDef, SbpColumnDef as SbpColumnDef_1, SbpHeaderCell, SbpHeaderCellDef } from '../table/cell';

@Component({
  selector: 'sbp-table-expander',
  templateUrl: './expander.html',
  styleUrl: './expander.less',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'expander-column cdk-visually-hidden',
    '[attr.ariaHidden]': 'true',
  },
  standalone: true,
  imports: [SbpColumnDef_1, SbpHeaderCellDef, SbpHeaderCell, SbpCellDef, SbpCell, NgIf, SwIconComponent],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SbpTableExpander<T> implements OnInit, OnDestroy {
  table = inject<SbpTable<T>>(SbpTable<T>, { optional: true });
  @Input() expandedRowElement: T;
  @Output() readonly toggleRowExpansion = new EventEmitter<T>();

  @ViewChild(SbpColumnDef, { static: true }) columnDef: SbpColumnDef;

  private readonly _name = 'expander';

  ngOnInit(): void {
    this.columnDef.name = this._name;
    if (this.table) {
      this.table.addColumnDef(this.columnDef);
    }
  }

  ngOnDestroy(): void {
    if (this.table) {
      this.table.removeColumnDef(this.columnDef);
    }
  }

  toggle(row: T): void {
    this.toggleRowExpansion.emit(row);
  }
}
