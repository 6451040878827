import { NgFor, NgIf, NgStyle } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';

import { BreadcrumbsService } from '../../../core/components/breadcrumbs/breadcrumbs.service';
import { HelptextComponent } from '../helptext/helptext.component';
import { TabComponent } from '../tabs/tab/tab.component';

/**
 * The content-tabs component displays multiple tab inside a wrapper element.
 */
@Component({
  selector: 'account-content-tabs',
  templateUrl: './content-tabs.component.html',
  styleUrl: './content-tabs.component.less',
  standalone: true,
  imports: [NgStyle, NgFor, NgIf, HelptextComponent],
})
export class ContentTabsComponent implements AfterContentInit {
  private readonly breadcrumbsService = inject(BreadcrumbsService);
  /**
   * Defines the width of the tabs wrapper element.
   */
  @Input() headerWidth = 'max-content';

  /**
   * Activates if the breadcrumbService should dynamically label the route based on the active tab.
   */
  @Input() applyBreadcrumb = true;

  /**
   * Defines if the tabs wrapper element has side padding.
   */
  @Input() noSidePadding = false;

  /**
   * Defines if the tabs have a margin on the bottom.
   */
  @Input() hasBottomMargin = true;

  /**
   * Defines if the tabs are centered horizontally.
   */
  @Input() tabsCenter = false;

  /**
   * Defines if the tab wrapper has the default background color.
   */
  @Input() hasBackground = true;

  /**
   * @internal
   */
  @ViewChild('tabHeader') tabsHeader: ElementRef;

  /**
   * The <ng-content> slot can be filled with a list of `<account-tab>` components.
   */
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  /**
   * Emits an event with the content of type `TabComponent` when the active tab changes.
   */
  @Output() readonly onTabChanged = new EventEmitter<TabComponent>();

  ngAfterContentInit(): void {
    this.setDefaultActivatedTab();
  }

  /**
   * @internal
   */
  setDefaultActivatedTab(): void {
    const activeTabs = this.tabs.filter((tab) => tab.active);
    let activeLabel: string;
    if (activeTabs.length === 0) {
      this.tabs.first.active = true;
      activeLabel = this.tabs.first.heading;
    } else {
      activeLabel = activeTabs[0].heading;
    }
    if (this.applyBreadcrumb) {
      this.breadcrumbsService.setDynamicLabel(activeLabel);
    }
  }

  /**
   * @internal
   */
  activateTab(tab: TabComponent): void {
    this.tabs.forEach((currentTab) => {
      currentTab.active = false;
    });
    tab.active = true;
    if (this.applyBreadcrumb) {
      this.breadcrumbsService.setDynamicLabel(tab.heading);
    }
    this.onTabChanged.emit(tab);
  }

  /**
   * @internal
   */
  activateTabById(id: string): void {
    const foundTab = this.tabs.find((tab: TabComponent) => tab.id === id);
    if (!foundTab) {
      return;
    }
    this.activateTab(foundTab);
  }
}
