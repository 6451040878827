import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { PluginWithdrawalGateway } from '../../gateways/licenses/plugin-withdrawal.gateway';
import { ListResult, PluginLicense, PluginWithdrawalRequest } from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { CompaniesFacade } from '../company';
import { ProducersFacade } from '../plugins';

@Injectable({
  providedIn: 'root',
})
export class PluginWithdrawalFacade {
  private readonly companiesFacade = inject(CompaniesFacade);
  private readonly producersFacade = inject(ProducersFacade);
  private readonly pluginWithdrawalGateway = inject(PluginWithdrawalGateway);

  getPluginWithdrawalRequestsForLicenseCompany(
    filter: Record<string, any> = {}
  ): Observable<ListResult<PluginWithdrawalRequest[]>> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.pluginWithdrawalGateway.getPluginWithdrawalRequestsForCompany(companyId, filter)
        )
      );
  }

  getPluginWithdrawalRequestsForProducerCompany(
    metaData: RequestMetaData,
    filter: Record<string, any> = {}
  ): Observable<ListResult<PluginWithdrawalRequest[]>> {
    return this.producersFacade
      .getProducerIdOnce()
      .pipe(
        switchMap((producerId: number) =>
          this.pluginWithdrawalGateway.getPluginWithdrawalRequestsForProducer(producerId, metaData, filter)
        )
      );
  }

  sendPluginWithdrawalRequest(pluginWithdrawalRequest: PluginWithdrawalRequest): Observable<PluginWithdrawalRequest> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.pluginWithdrawalGateway.sendPluginWithdrawalRequest(companyId, pluginWithdrawalRequest)
        )
      );
  }

  createPluginWithdrawalRequest(license: PluginLicense, description: string): Observable<PluginWithdrawalRequest> {
    return this.companiesFacade
      .getCompanyId()
      .pipe(
        switchMap((companyId: number) =>
          this.pluginWithdrawalGateway.createPluginWithdrawalRequest(companyId, license, description)
        )
      );
  }

  declineOrAcceptPluginWithdrawalRequest(
    pluginWithdrawalRequest: PluginWithdrawalRequest
  ): Observable<PluginWithdrawalRequest> {
    return this.producersFacade
      .getProducerIdOnce()
      .pipe(
        switchMap((producerId: number) =>
          this.pluginWithdrawalGateway.declineOrAcceptPluginWithdrawalRequest(producerId, pluginWithdrawalRequest)
        )
      );
  }
}
