<div
  class="card-header"
  [class.is--reversed]="showToggleLeft"
  data-padding="s"
  [class.clickable]="headerFullyLinked"
  (click)="toggleOnFullHeaderClick()">
  <ng-content select="card-header" />
  <div class="header-toggle" *ngIf="collapsible" (click)="!headerFullyLinked && toggle()">
    <account-sw-icon [class.is-reversed]="open" icon="chevron-down-xs" size="10px" />
  </div>
</div>
<div class="card-body" [class.is-open]="open">
  <ng-content select="card-body" />
</div>
<ng-content select="card-additional" />
