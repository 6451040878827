import { NgClass } from '@angular/common';
import { Component, ElementRef, inject, Input } from '@angular/core';

@Component({
  selector: 'account-flag',
  templateUrl: './flag.component.html',
  styleUrl: './flag.component.less',
  standalone: true,
  imports: [NgClass],
})
export class FlagComponent {
  private readonly elementRef = inject(ElementRef);
  @Input() code: string;
  @Input() size = 'medium';

  private _supported = true;

  @Input()
  set supported(supported: boolean) {
    this._supported = supported;
    const elementHasClassNotSupported = this.elementRef.nativeElement.classList.contains('not-supported');
    if (this.supported) {
      if (elementHasClassNotSupported) {
        this.elementRef.nativeElement.classList.remove('not-supported');
      }
    } else {
      if (!elementHasClassNotSupported) {
        this.elementRef.nativeElement.classList.add('not-supported');
      }
    }
  }

  get supported(): boolean {
    return this._supported;
  }
}
