import { DomainVerificationTokenData } from '@account/core/models';
import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { SwIconComponent } from '../../../../../../shared/components/sw-icon/sw-icon.component';
import { DomainVerificationModalService } from '../domain-verification-modal.service';

@Component({
  selector: 'domain-verification-verification-element',
  templateUrl: './verification-element.component.html',
  styleUrl: './verification-element.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgIf, NgClass, MatTooltipModule, TranslateModule, SwIconComponent],
})
export class VerificationElementComponent {
  private readonly domainVerificationModalService = inject(DomainVerificationModalService);
  private readonly translateService = inject(TranslateService);
  @Input() domainName: string;
  @Input() domainVerified = false;
  @Input() verificationRequired = true;
  @Input() verificationResult: boolean | null = null;
  @Input() domainVerificationTokenData: DomainVerificationTokenData;
  @Input() showButtonOnly = false;

  @Output() readonly onVerifyDomain = new EventEmitter<void>();

  getTooltipForValidationIcon(): string {
    if (true === this.verificationResult) {
      return this.translateService.instant('COMMON.SHOP.VERIFICATION.TOOLTIP.VERIFIED');
    } else {
      if (null === this.verificationResult) {
        return this.translateService.instant('COMMON.SHOP.VERIFICATION.TOOLTIP.VERIFICATION_REQUIRED');
      } else {
        return this.translateService.instant('COMMON.SHOP.VERIFICATION.TOOLTIP.VERIFICATION_FAILED');
      }
    }
  }
}
