<ng-container [formGroup]="form">
  <div *ngIf="domainWithLicensesToTerminate" class="termination">
    <strong class="title">{{ 'COMMON.TERMINATION_OF_LICENSE.INFO.TITLE' | translate }}:</strong>
    <p
      [innerHTML]="'COMMON.TERMINATION_OF_LICENSE.INFO.TEXT' | translate: { domain: domainWithLicensesToTerminate }"
      data-margin-bottom></p>
    <account-checkbox
      [label]="'COMMON.FORM.FIELD.CHECKBOX.LABEL.TERMINATION_OF_LICENSE' | translate"
      [labelAsHTML]="true"
      formControlName="terminationOfLicense"
      data-margin-bottom />
  </div>
  <div class="gtc">
    <strong class="title">{{ 'COMMON.GTC_SHORT' | translate }}:</strong>
    <account-checkbox
      [label]="'COMMON.FORM.FIELD.CHECKBOX.LABEL.GTC' | translate"
      [labelAsHTML]="true"
      formControlName="gtcSigned" />
  </div>
</ng-container>
