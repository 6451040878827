import { inject, Injectable } from '@angular/core';

import { DateTimezoneService } from '../../../shared/services/date-timezone.service';
import { SupportTicket, SupportTicketAnswer, SupportTicketWithBasicInformation } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class SupportTicketLocalizer {
  private readonly dateTimezoneService = inject(DateTimezoneService);

  localizeSupportTicketWithBasicInformation(
    ticket: SupportTicketWithBasicInformation
  ): SupportTicket | SupportTicketWithBasicInformation {
    return {
      ...ticket,
      deadline: ticket.deadline
        ? this.dateTimezoneService.toZonedTime(ticket.deadline, ticket.timeZone).toISOString()
        : null,
      lastContactDate: this.dateTimezoneService.toZonedTime(ticket.lastContactDate, ticket.timeZone).toISOString(),
      licenseInformation: {
        ...ticket.licenseInformation,
        expirationDate: ticket.licenseInformation?.expirationDate
          ? this.dateTimezoneService
              .toZonedTime(ticket.licenseInformation.expirationDate, ticket.timeZone)
              .toISOString()
          : null,
        actualExpirationDate: ticket.licenseInformation?.actualExpirationDate
          ? this.dateTimezoneService
              .toZonedTime(ticket.licenseInformation.actualExpirationDate, ticket.timeZone)
              .toISOString()
          : null,
      },
    };
  }

  localizeSupportTicket(ticket: SupportTicket): SupportTicket {
    return {
      ...ticket,
      ...this.localizeSupportTicketWithBasicInformation(ticket),
      creationDate: this.dateTimezoneService.toZonedTime(ticket.creationDate, ticket.timeZone).toISOString(),
      answers: ticket.answers.map((answer: SupportTicketAnswer) => ({
        ...answer,
        sentDate: this.dateTimezoneService.toZonedTime(answer.sentDate, ticket.timeZone).toISOString(),
      })),
    };
  }
}
