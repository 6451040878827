<p-calendar
  [minDate]="minDate"
  [maxDate]="maxDate"
  [placeholder]="placeholder"
  [readonlyInput]="readonlyInput"
  [disabled]="disabled"
  [class.is_disabled]="disabled"
  [locale]="LOCALIZATION[(currentLanguage$ | async).key]"
  [monthNavigator]="true"
  [yearNavigator]="true"
  [yearRange]="yearRange"
  [showTime]="showTime"
  hourFormat="24"
  [timeOnly]="timeOnly"
  [view]="view"
  (ngModelChange)="onChange($event)"
  [(ngModel)]="internalDate" />
