import { DomainVerificationTokenData, SoftwareVersion } from '@account/core/models';
import { NgIf } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'domain-verification-instruction-element',
  templateUrl: './instruction-element.component.html',
  styleUrl: './instruction-element.component.less',
  standalone: true,
  imports: [NgIf, TranslateModule],
})
export class InstructionElementComponent implements OnChanges {
  @Input() domainVerificationTokenData: DomainVerificationTokenData;
  @Input() newDomainName: string;
  @Input() shopwareVersion: SoftwareVersion;

  isPlatform = false;

  ngOnChanges(): void {
    if (this.shopwareVersion) {
      this.isPlatform = this.shopwareVersion.name.startsWith('6');
    }
  }
}
