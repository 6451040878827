import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ShopLicensesGateway } from '../../gateways/shop';
import {
  Partner,
  PluginLicense,
  PluginLicenseSubscription,
  PluginLicenseSubscriptionRenewalOption,
  PluginLicenseSubscriptionRenewalRequest,
  PluginLicenseUpgradeRequest,
  ProductAcceleratorLicense,
  RentLicenseUpgradeOption,
  RentLicenseUpgradeOptionRequest,
  SelfHostedShop,
  Shop,
  SubshopLicense,
} from '../../models';
import { CompaniesFacade } from '../company';

@Injectable({
  providedIn: 'root',
})
export class ShopLicensesFacade {
  private readonly companiesFacade = inject(CompaniesFacade);
  private readonly shopLicensesGateway = inject(ShopLicensesGateway);

  getPluginLicenses(shop: Pick<Shop, 'id'>, filter: Record<string, any> = {}): Observable<PluginLicense[]> {
    return this.shopLicensesGateway.getPluginLicenses(shop, filter);
  }

  getPluginLicensePublishedBinaryVersions(shopId: number, licenseId: number, pluginId: number): Observable<string[]> {
    return this.shopLicensesGateway.getPluginLicensePublishedBinaryVersions(shopId, licenseId, pluginId);
  }

  getSubshopLicenses(shop: SelfHostedShop): Observable<SubshopLicense[]> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.shopLicensesGateway.getSubshopLicenses(companyId, shop)));
  }

  getProductAcceleratorLicenses(shop: SelfHostedShop): Observable<ProductAcceleratorLicense[]> {
    return this.shopLicensesGateway.getProductAcceleratorLicenses(shop);
  }

  getSubscriptionRenewalOptions(license: PluginLicense): Observable<PluginLicenseSubscriptionRenewalOption[]> {
    return this.shopLicensesGateway.getSubscriptionRenewalOptions(license);
  }

  renewSubscription(
    license: PluginLicense,
    request: PluginLicenseSubscriptionRenewalRequest
  ): Observable<PluginLicenseSubscription> {
    return this.shopLicensesGateway.renewSubscription(license, request);
  }

  upgradeLicense(license: PluginLicense, request: PluginLicenseUpgradeRequest): Observable<PluginLicense> {
    return this.shopLicensesGateway.upgradeLicense(license, request);
  }

  upgradeLicenseAsPartner(
    partner: Partner,
    license: PluginLicense,
    request: PluginLicenseUpgradeRequest
  ): Observable<PluginLicense> {
    return this.shopLicensesGateway.upgradeLicenseAsPartner(partner, license, request);
  }

  deleteLicense(shopId: number, licenseId: number): Observable<PluginLicense> {
    return this.shopLicensesGateway.deleteLicense(shopId, licenseId);
  }

  reactivateLicense(shopId: number, licenseId: number): Observable<PluginLicense> {
    return this.shopLicensesGateway.reactivateLicense(shopId, licenseId);
  }

  archiveLicense(shopId: number, licenseId: number): Observable<PluginLicense> {
    return this.shopLicensesGateway.archiveLicense(shopId, licenseId);
  }

  restoreLicenseFromArchive(shopId: number, licenseId: number): Observable<PluginLicense> {
    return this.shopLicensesGateway.restoreLicenseFromArchive(shopId, licenseId);
  }

  setSubshopLicenseDomain(license: SubshopLicense, newDomain: string): Observable<SubshopLicense> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.shopLicensesGateway.setSubshopLicenseDomain(companyId, license, newDomain)
        )
      );
  }

  getPluginLicenseRentUpgradeOptions(shopId: number, licenseId: number): Observable<RentLicenseUpgradeOption[]> {
    return this.shopLicensesGateway.getPluginLicenseRentUpgradeOptions(shopId, licenseId);
  }

  upgradeBuyLicenseToRent(
    shopId: number,
    licenseId: number,
    request: RentLicenseUpgradeOptionRequest
  ): Observable<void> {
    return this.shopLicensesGateway.upgradeBuyLicenseToRent(shopId, licenseId, request);
  }
}
