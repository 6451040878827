import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  DiscountCampaign,
  DiscountCampaignCreateRequestData,
  DiscountCampaignGetPluginsDuplicatesRequestData,
  DiscountCampaignUpdateRequestData,
  ListResult,
  Producer,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { Plugin } from '../../models/plugin/plugin.model';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class DiscountCampaignsGateway {
  private readonly apiService = inject(ApiService);

  addDiscountCampaign(
    discountCampaignCreateRequestData: DiscountCampaignCreateRequestData
  ): Observable<DiscountCampaign> {
    return this.apiService.post('/plugindiscountcampaigns', discountCampaignCreateRequestData);
  }

  updateDiscountCampaign(discountCampaign: DiscountCampaignUpdateRequestData): Observable<DiscountCampaign> {
    return this.apiService.put(`/plugindiscountcampaigns/${discountCampaign.discountCampaignId}`, discountCampaign);
  }

  removeDiscountCampaign(discountCampaign: DiscountCampaign): Observable<any> {
    return this.apiService.delete(`/plugindiscountcampaigns/${discountCampaign.id}`);
  }

  getDiscountCampaignList(producer: Producer, metaData: RequestMetaData): Observable<ListResult<DiscountCampaign[]>> {
    const params = new HttpParams()
      .set('limit', metaData.limit.toString())
      .set('offset', metaData.offset.toString())
      .set('orderBy', metaData.orderBy)
      .set('orderSequence', metaData.orderSequence)
      .set('search', metaData.search)
      .set('producerId', producer.id.toString());
    return this.apiService.getList('/plugindiscountcampaigns', params);
  }

  getDiscountCampaign(id: number): Observable<DiscountCampaign> {
    return this.apiService.get(`/plugindiscountcampaigns/${id}`);
  }

  getDiscountDuplicates(
    getPluginsDuplicatesRequestData: DiscountCampaignGetPluginsDuplicatesRequestData
  ): Observable<Plugin[]> {
    return this.apiService.post('/plugindiscountcampaigns/duplicates', getPluginsDuplicatesRequestData);
  }

  abortDiscountCampaign(discountCampaign: DiscountCampaign): Observable<DiscountCampaign> {
    return this.apiService.put(`/plugindiscountcampaigns/${discountCampaign.id}/abort`, {});
  }
}
