import { SoftwareVersion } from '@account/core/models';
import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ShopEnvironment } from '../../../../../../core/models/shop/shop.model';
import { DomainNamingElementComponent } from '../../../shared-elements/domain-verification/domain-naming-element/domain-naming-element.component';
import { ShopSettingsElementComponent } from '../../../shared-elements/domain-verification/shop-settings-element/shop-settings-element.component';

@Component({
  selector: 'shop-creation-modal-domain-naming-step',
  templateUrl: './domain-naming-step.component.html',
  styleUrl: './domain-naming-step.component.less',
  standalone: true,
  imports: [DomainNamingElementComponent, ShopSettingsElementComponent, TranslateModule],
})
export class ShopCreationModalDomainNamingStepComponent {
  @Input() form: UntypedFormGroup;
  @Input() active = false;
  @Input() shopEnvironments: ShopEnvironment[];
  @Input() softwareVersions: SoftwareVersion[];
  @Input() shopwareMajorVersion: number;
}
