import { userAccountActions } from '@account/core/store/actions';
import { userAccountSelectors } from '@account/core/store/selectors';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { defaultIfEmpty, EMPTY, Observable, of, throwError } from 'rxjs';
import { filter, mapTo, switchMap, switchMapTo, take, takeUntil, tap } from 'rxjs/operators';

import { CompanyMembership, CompanyMembershipInvitation, SbpException, UserAccount } from '../../models';
import { RootState } from '../../store/root.state';
import { membershipsSelectors } from '../../store/useraccount/useraccount-meta.selectors';
import { AuthFacade } from '../auth';
import { CompaniesFacade } from '../company';
import { UserAccountsFacade } from './user-accounts.facade';

@Injectable({
  providedIn: 'root',
})
export class UserAccountMembershipsFacade {
  private readonly store = inject<Store<RootState>>(Store<RootState>);
  private readonly authFacade = inject(AuthFacade);
  private readonly userAccountsFacade = inject(UserAccountsFacade);
  private readonly companiesFacade = inject(CompaniesFacade);

  changeSelectedMembership(membership: CompanyMembership): Observable<UserAccount> {
    return this.userAccountsFacade.getUserAccount().pipe(
      take(1),
      filter(
        (userAccount: UserAccount) =>
          !userAccount.selectedMembership || userAccount.selectedMembership.id !== membership.id,
      ),
      switchMap(() => {
        this.store.dispatch(userAccountActions.selectMembership({ payload: membership.id }));
        return this.store.select(userAccountSelectors.isLoading).pipe(
          filter((isLoading: boolean) => !isLoading),
          switchMapTo(this.store.select(userAccountSelectors.getError)),
          switchMap((error: SbpException | undefined) => {
            if (error) {
              return throwError(error);
            }
            return this.store.select(membershipsSelectors.isSelectionInProgress).pipe(
              filter((changeInProgress: boolean) => !changeInProgress),
              switchMapTo(this.userAccountsFacade.getUserAccount()),
            );
          }),
          take(1),
        );
      }),
      // remember to unsubscribe so this will not trigger if the store is cleared
      takeUntil(this.authFacade.waitUntilLoggingOut()),
    );
  }

  getMemberships(): Observable<CompanyMembership[]> {
    return this.store.select(userAccountSelectors.memberships.isLoaded).pipe(
      switchMap((loaded: boolean) => {
        if (loaded) {
          return this.store.select(userAccountSelectors.memberships.getError).pipe(
            switchMap((error: SbpException | undefined) => {
              if (error) {
                return throwError(error);
              }
              // if is loaded and no error has been caught return current memberships state
              return this.store.select(userAccountSelectors.memberships.getMemberships);
            }),
          );
        }
        // memberships are not loaded or should be reloaded therefore dispatch loading action, afterwards wait for and select result
        return this.userAccountsFacade.getUserAccountId().pipe(
          tap((userAccountId: number) =>
            this.store.dispatch(userAccountActions.getMemberships({ payload: userAccountId })),
          ),
          switchMap(() => this.store.select(userAccountSelectors.memberships.isLoaded)),
          filter((loaded: boolean) => loaded),
          switchMap(() => this.getMemberships()),
        );
      }),
      takeUntil(this.authFacade.waitUntilLoggingOut()),
    );
  }

  getMembershipInvitations(): Observable<CompanyMembershipInvitation[]> {
    return this.store.select(userAccountSelectors.invitations.isLoaded).pipe(
      filter((loaded: boolean) => loaded),
      switchMapTo(
        this.store.select(userAccountSelectors.invitations.getError).pipe(
          switchMap((error: SbpException | undefined) => {
            if (error) {
              return throwError(error);
            }
            return this.store.select(userAccountSelectors.invitations.getInvitations);
          }),
        ),
      ),
    );
  }

  acceptMembershipInvitation(invitation: CompanyMembershipInvitation): Observable<boolean> {
    this.store.dispatch(userAccountActions.acceptInvitation({ payload: invitation }));

    return this.store.select(userAccountSelectors.invitations.isLoading).pipe(
      filter((isLoading: boolean) => !isLoading),
      switchMapTo(this.store.select(userAccountSelectors.invitations.getError)),
      switchMap((error: SbpException | undefined) => {
        if (error) {
          return throwError(error);
        }
        return of(true);
      }),
      take(1),
    );
  }

  declineMembershipInvitation(invitation: CompanyMembershipInvitation): Observable<boolean> {
    this.store.dispatch(userAccountActions.declineInvitation({ payload: invitation }));

    return this.store.select(userAccountSelectors.invitations.isLoading).pipe(
      filter((isLoading: boolean) => !isLoading),
      switchMapTo(this.store.select(userAccountSelectors.invitations.getError)),
      switchMap((error: SbpException | undefined) => {
        if (error) {
          return throwError(error);
        }
        return of(true);
      }),
      take(1),
    );
  }

  decideLaterMembershipInvitation(invitation: CompanyMembershipInvitation): void {
    this.store.dispatch(userAccountActions.decideLater({ payload: invitation }));
  }

  terminateMembership(membershipId: number): Observable<boolean> {
    this.store.dispatch(userAccountActions.terminateMembership({ payload: membershipId }));

    return this.store.select(userAccountSelectors.memberships.isLoading).pipe(
      filter((isLoading: boolean) => !isLoading),
      switchMapTo(this.store.select(userAccountSelectors.memberships.getWriteError)),
      switchMap((error: SbpException | undefined) => {
        if (error) {
          return throwError(error);
        }
        return this.userAccountsFacade.getUserAccount().pipe(mapTo(true));
      }),
      take(1),
    );
  }

  changeSelectedMembershipBasedOnCompanyId(givenCompanyId?: number): Observable<null | UserAccount> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) => {
        if (!givenCompanyId || givenCompanyId === companyId) {
          return EMPTY;
        }
        return this.getMemberships();
      }),
      switchMap((memberships: CompanyMembership[]) => {
        if (memberships === null) {
          return of(null);
        }
        const companyMembershipFromUrl = memberships.find(
          (membershipOfCompanyFromUrl: CompanyMembership) => membershipOfCompanyFromUrl.company.id === givenCompanyId,
        );
        if (!companyMembershipFromUrl) {
          return of(null);
        }

        return of(companyMembershipFromUrl);
      }),
      switchMap((companyMembership: CompanyMembership | null) => {
        if (!companyMembership) {
          return of(null);
        }
        return this.changeSelectedMembership(companyMembership);
      }),
      defaultIfEmpty(null),
    );
  }

  isMembershipSwitchInProgress(): Observable<boolean> {
    return this.store.select(membershipsSelectors.isSelectionInProgress);
  }

  // TODO: remove... invitation accepting or declining invitations should be handled on useraccounts portal page
  completeInvitationCheck(): void {
    this.store.dispatch(userAccountActions.invitationCheckCompleted());
  }

  // TODO: remove... invitation accepting or declining invitations should be handled on useraccounts portal page
  isInvitationCheckCompleted(): Observable<boolean> {
    return this.store.select(userAccountSelectors.invitations.isInvitationCheckCompleted);
  }
}
