import { NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { SelfServiceContractPriceInfos } from '../../../../../core/models/shop';
import { SwIconComponent } from '../../../../../shared/components/sw-icon/sw-icon.component';
import { LocaleCurrencyPipe } from '../../../../../shared/pipes/locale-number.pipe';

@Component({
  selector: 'account-plan-tile-content',
  templateUrl: './plan-tile-content.component.html',
  styleUrl: './plan-tile-content.component.less',
  standalone: true,
  imports: [NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet, TranslateModule, LocaleCurrencyPipe, SwIconComponent],
  preserveWhitespaces: false,
})
export class PlanTileContentComponent {
  @Input() plan: string | null = null;
  @Input() subscriptionRefund: number;
  @Input() calculatedPrice: SelfServiceContractPriceInfos;
  @Input() showAndMoreLink = false;
  @Input() allowedForTrialPhase = false;
  @Input() canSeeSpecialOffer: boolean = false;
  @Input() shopCreationDate: string;
  @Input() isCustomPrice: boolean;

  currentDate: Date = new Date();
  specialOfferEndDate: Date = new Date('2024-06-18T00:00:00');
  shopCreatedBefore: Date = new Date('2024-01-01T00:00:00');

  get isSpecialOfferPhase(): boolean {
    return (
      new Date(this.shopCreationDate) <= this.shopCreatedBefore &&
      this.currentDate <= this.specialOfferEndDate &&
      this.canSeeSpecialOffer
    );
  }
}
