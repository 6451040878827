import { ShopsFacade } from '@account/core/facades';
import { SbpException } from '@account/core/models';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const validateDomainRemote =
  (shopsFacade: ShopsFacade): AsyncValidatorFn =>
  (control: AbstractControl): Observable<null | ValidationErrors> =>
    shopsFacade.precheckDomain(control.value).pipe(
      map(() => null),
      catchError((error: SbpException) => {
        switch (error.code) {
          case 'DomainVerificationException-4':
            return of({ domainInUse: true });
          case 'DomainVerificationException-5':
            return of({ domainIsWildcardEnvironment: true });
          case 'UserShopsException-24':
            return of({ containsPath: true });
          default:
            return of({ domainValidationError: true });
        }
      }),
    );
