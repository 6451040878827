import { NgFor, NgIf } from '@angular/common';
import { Component, forwardRef, inject, Input, TemplateRef } from '@angular/core';

import { SwIconComponent } from '../../../sw-icon/sw-icon.component';
import { TreeViewComponent } from '../tree-view.component';
import { TreeValueTemplateLoaderComponent } from '../value/value-template-loader.component';

@Component({
  selector: 'tree-view-node',
  templateUrl: './node.component.html',
  styleUrl: './node.component.less',
  standalone: true,
  imports: [NgIf, SwIconComponent, TreeValueTemplateLoaderComponent, NgFor],
})
export class NodeComponent {
  tree = inject<TreeViewComponent>(forwardRef(() => TreeViewComponent));
  @Input() depth: number;

  // must be an object
  @Input() nodeValue: Record<string, any>;
  // name of the element attribute which should be rendered
  @Input() nodeValueAttributeName: string;
  // name of the element attribute which contains the children
  @Input() childNodesAttributeName: string;

  @Input() template: TemplateRef<any>;

  @Input() backgroundColor: string;

  collapsed = true;

  resolveFieldData(data: any, field: string): any {
    if (data && field) {
      if (!field.includes('.')) {
        return data[field];
      } else {
        const fields: string[] = field.split('.');
        let value = data;
        for (const extractedField of fields) {
          if (value == null) {
            return null;
          }
          value = value[extractedField];
        }
        return value;
      }
    }
    return null;
  }

  onNodeClick(event: MouseEvent): void {
    if (this.isSelectable()) {
      this.tree.onNodeClick(event, this.nodeValue);
    } else {
      this.toggle();
    }
  }

  toggle(): void {
    this.collapsed = !this.collapsed;
  }

  isLeaf(): boolean {
    return !(this.nodeValue[this.childNodesAttributeName] && this.nodeValue[this.childNodesAttributeName].length > 0);
  }

  isSelected(): boolean {
    return this.tree.isSelected(this.nodeValue);
  }

  isPartialSelected(): boolean {
    return this.tree.isPartialSelected(this.nodeValue);
  }

  isSelectable(): boolean {
    switch (this.tree.selectableOption) {
      case 'all':
        return true;
      case 'leaf':
        return this.isLeaf();
      default:
        return false;
    }
  }
}
