import { inject, Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, skipUntil, takeUntil } from 'rxjs/operators';

import { StringUtils } from '../../utils';
import { AuthFacade } from '../auth';

@Injectable({
  providedIn: 'root',
})
export class RouterFacade {
  private readonly router = inject(Router);
  private readonly authFacade = inject(AuthFacade);

  registerRoutingChanges(): Observable<string> {
    return this.router.events.pipe(
      // only listen to route path changes
      filter((event: Event) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => StringUtils.trimSlashes(event.urlAfterRedirects))
    );
  }

  registerRoutingChangesIfLoggedIn(): Observable<string> {
    return this.registerRoutingChanges().pipe(
      skipUntil(this.authFacade.isUILoginCompleted()),
      takeUntil(this.authFacade.waitUntilLoggingOut())
    );
  }
}
