import { ContractCancellation } from '@account/core/models';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class ContractCancellationGateway {
  private readonly apiService = inject(ApiService);

  cancelContract(commercialContractId: number): Observable<ContractCancellation> {
    return this.apiService.post(`/commercialcontracts/${commercialContractId}/cancellations`);
  }
}
