import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostBinding, inject, Input, ViewEncapsulation } from '@angular/core';

/**
 * The well component is a wrapper element used for visual separation inside a card.
 */
@Component({
  selector: 'account-well',
  templateUrl: './well.component.html',
  styleUrl: './well.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class WellComponent {
  private readonly breakpointObserver = inject(BreakpointObserver);
  /**
   * Defines the background color of the well.
   */
  @HostBinding('style.background')
  @Input()
  background = 'var(--sw-gray-50)';

  /**
   * Defines the padding of the well.
   */
  @Input() padding = '35px 40px';
  @HostBinding('style.padding')
  get hasMobilePadding(): string {
    return this.mobileViewport ? '25px' : this.padding;
  }

  /**
   * Defines the direction of the flexbox styling (`row`|`column`).
   */
  @HostBinding('style.flex-direction')
  @Input()
  flexDirection = 'row';

  /**
   * @internal
   */
  mobileViewport: boolean;

  constructor() {
    const mobileObserver = this.breakpointObserver.observe(['(max-width: 480px)']);
    mobileObserver.subscribe((result) => {
      this.mobileViewport = result.matches;
    });
  }
}
