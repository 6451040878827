<form>
  <account-card
    [icon]="icon"
    [heading]="heading"
    [contentHasPadding]="contentHasPadding"
    [contentIsScrollable]="contentIsScrollable">
    <card-controls>
      <span [matTooltip]="disabled ? disabledTitle : null">
        <button
          class="a_button is_small"
          *ngIf="!initialConfiguration && !started"
          [disabled]="disabled"
          type="button"
          (click)="started = true">
          <span *ngIf="showButtonLabels">{{ labelStartButton ? labelStartButton : ('WIZARD.START' | translate) }}</span>
        </button>
      </span>
      <!-- Comment required for hacky CSS reasons, don't remove!
      -->
      <button class="a_button is_small" *ngIf="started" type="button" (click)="abort()">
        {{ labelCancelButton ? labelCancelButton : ('WIZARD.ABORT' | translate) }}
      </button>
    </card-controls>
    <card-body>
      <ng-content />
    </card-body>
    <card-footer [hidden]="!started">
      <wizard-pagination
        *ngIf="steps"
        [steps]="steps"
        [showProgressBar]="showProgressBar"
        [showButtonLabels]="showButtonLabels"
        [labelCompleteButton]="labelCompleteButton"
        (onNext)="next()"
        (onPrev)="previous()"
        (onComplete)="complete()" />
    </card-footer>
  </account-card>
</form>
