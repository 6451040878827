import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'arrayControlsAsFormGroups',
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class ArrayControlsAsFormGroups implements PipeTransform {
  transform(value: AbstractControl): UntypedFormGroup[] {
    const formArray = value as UntypedFormArray;
    return formArray.controls as UntypedFormGroup[];
  }
}
@Pipe({
  name: 'abstractControlAsFormControl',
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class AbstractControlAsFormControl implements PipeTransform {
  transform(value: AbstractControl): UntypedFormControl {
    return value as UntypedFormControl;
  }
}

@Pipe({
  name: 'abstractControlAsFormGroup',
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class AbstractControlAsFormGroup implements PipeTransform {
  transform(value: AbstractControl): UntypedFormGroup {
    return value as UntypedFormGroup;
  }
}
