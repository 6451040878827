import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { SwIconComponent } from '../../../shared/components/sw-icon/sw-icon.component';
import { Breadcrumb } from './breadcrumb.model';
import { BreadcrumbsService } from './breadcrumbs.service';

@Component({
  selector: 'account-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrl: './breadcrumbs.component.less',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, RouterLink, TranslateModule, SwIconComponent],
})
export class BreadcrumbsComponent {
  private readonly breadcrumbsService = inject(BreadcrumbsService);
  private readonly router = inject(Router);
  private readonly breakpointObserver = inject(BreakpointObserver);
  breadcrumbs: Breadcrumb[];
  isMobileViewport: boolean;

  constructor() {
    this.breadcrumbsService.breadcrumbs$.subscribe((breadcrumbs: Breadcrumb[]) => {
      this.breadcrumbs = breadcrumbs;
    });

    const mobileObserver = this.breakpointObserver.observe(['(max-width: 768px)']);

    mobileObserver.subscribe((viewport) => {
      this.isMobileViewport = viewport.matches;
    });
  }

  goToPortal(): void {
    this.router.navigate(['/portal']);
  }
}
