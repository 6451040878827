import { CloudShopsFacade, PartnerShopsFacade, ShopsFacade } from '@account/core/facades';
import { inject, Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';

import { SbpException } from '../../../../../core/models/api/sbp-exception.model';
import { ToastService } from '../../../../../shared/components/toast/toast.service';
import { ShopMigrationModalConfig } from '../../../../partner/shared/shop/shop-migration-modal/shop-migration-modal.model';
import { DomainRenameModalConfig } from '../../domain-rename-modal/domain-rename-modal.model';
import { DomainVerificationModalFormFactory, FormType } from './domain-verification-modal-form.factory';

@Injectable({
  providedIn: 'root',
})
export class DomainVerificationModalService {
  private readonly formBuilder = inject(UntypedFormBuilder);
  private readonly shopsFacade = inject(ShopsFacade);
  private readonly partnerShopsFacade = inject(PartnerShopsFacade);
  private readonly toastService = inject(ToastService);
  private readonly translateService = inject(TranslateService);
  private readonly cloudShopsFacade = inject(CloudShopsFacade);
  private readonly domainVerificationModalFormFactory = inject(DomainVerificationModalFormFactory);

  createForm(formType: FormType, modalConfig?: ShopMigrationModalConfig | DomainRenameModalConfig): UntypedFormGroup {
    return this.domainVerificationModalFormFactory.createForm(this.formBuilder, formType, modalConfig);
  }

  patchDomainName(form: UntypedFormGroup, newName: string): void {
    form.get('domain.domainName').patchValue(newName, { onlySelf: true, emitEvent: false });
  }

  patchDomainVerificationStatus(form: UntypedFormGroup, validated: boolean): void {
    const domainVerifiedFormControl = form.get('verification.domainVerified');
    domainVerifiedFormControl.patchValue(validated, { onlySelf: true, emitEvent: false });
    domainVerifiedFormControl.updateValueAndValidity();
  }

  isDomainVerified(form: UntypedFormGroup): boolean {
    return form.get('verification.domainVerified').value;
  }

  verifyDomain(domainToVerify: string, showToastMessage = true): Observable<boolean> {
    return this.shopsFacade.verifyDomainVerificationHash(domainToVerify).pipe(
      mapTo(true),
      catchError((error: SbpException) => {
        if (showToastMessage) {
          switch (error.code) {
            case 'UserShopsException-6':
              this.toastService.error(
                this.translateService.instant('COMMON.SHOP.VERIFICATION.TOAST.ERROR.TITLE.DOMAIN_VERIFICATION_FAILED'),
                this.translateService.instant('COMMON.SHOP.VERIFICATION.TOAST.ERROR.MESSAGE.DOMAIN_ALREADY_IN_USE', {
                  domain: domainToVerify,
                })
              );
              break;
            case 'UserShopsException-24':
              this.toastService.error(
                this.translateService.instant('COMMON.SHOP.VERIFICATION.TOAST.ERROR.TITLE.DOMAIN_VERIFICATION_FAILED'),
                this.translateService.instant('COMMON.SHOP.VERIFICATION.TOAST.ERROR.MESSAGE.PATH_IN_DOMAIN')
              );
              break;
            case 'DomainVerificationException-5':
              this.toastService.error(
                this.translateService.instant('COMMON.SHOP.VERIFICATION.TOOLTIP.VERIFICATION_FAILED'),
                this.translateService.instant(
                  'COMMON.SHOP.VERIFICATION.TOAST.ERROR.MESSAGE.DOMAIN_IS_WILDCARD_DOMAIN',
                  { domain: domainToVerify }
                )
              );
              break;
            default:
              this.toastService.error(
                this.translateService.instant('COMMON.SHOP.VERIFICATION.TOAST.ERROR.TITLE.DOMAIN_VERIFICATION_FAILED'),
                this.translateService.instant('COMMON.SHOP.VERIFICATION.TOAST.ERROR.MESSAGE.DOMAIN_VERIFICATION_FAILED')
              );
          }
        }
        return of(false);
      })
    );
  }
}
