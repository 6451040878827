import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { LicenseKeysFacade } from '../../core/facades';
import { LicenseKeyResponse, SbpException } from '../../core/models';
import { Shop } from '../../core/models/shop/shop.model';
import { LicenseKeyModalComponent } from '../../views/shared/license/license-keys/license-key-modal/license-key-modal.component';
import { ModalService } from '../components/modal/modal.service';
import { ToastService } from '../components/toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class LicenseKeysService {
  private readonly licenseKeysFacade = inject(LicenseKeysFacade);
  private readonly modalService = inject(ModalService);
  private readonly toastService = inject(ToastService);
  private readonly translateService = inject(TranslateService);

  showCommercialLicenseKeyForShop(shop: Pick<Shop, 'id'>): void {
    this.licenseKeysFacade.getCommercialLicenseKeyForShop(shop).subscribe({
      next: (licenseKeyResponse: LicenseKeyResponse) => {
        this.modalService.open(LicenseKeyModalComponent, {
          data: licenseKeyResponse,
        });
      },
      error: (errorResponse: SbpException) => {
        switch (errorResponse.code) {
          case 'CommercialLicenseKeyException-1':
            this.toastService.error(
              this.translateService.instant('COMMON.ERROR'),
              this.translateService.instant('COMMON.SHOP.LICENSE_KEY.ERRORS.NO_KEY_SET_FOR_SW_VERSION')
            );
            break;
          case 'CommercialLicenseKeyException-2':
            this.toastService.error(
              this.translateService.instant('COMMON.ERROR'),
              this.translateService.instant('COMMON.SHOP.LICENSE_KEY.ERRORS.SHOP_NOT_FOUND')
            );
            break;
          case 'CommercialLicenseKeyException-3':
            this.toastService.error(
              this.translateService.instant('COMMON.ERROR'),
              this.translateService.instant('COMMON.SHOP.LICENSE_KEY.ERRORS.SHOP_NOT_VERIFIED')
            );
            break;
          case 'CommercialLicenseKeyException-4':
            this.toastService.error(
              this.translateService.instant('COMMON.ERROR'),
              this.translateService.instant('COMMON.SHOP.LICENSE_KEY.ERRORS.SHOP_HAS_NO_VALID_LICENSE')
            );
            break;
          case 'CommercialLicenseKeyException-5':
            this.toastService.error(
              this.translateService.instant('COMMON.ERROR'),
              this.translateService.instant('COMMON.SHOP.LICENSE_KEY.ERRORS.WILDCARD_NOT_APPROVED')
            );
            break;
          default:
            this.toastService.error(
              this.translateService.instant('COMMON.ERROR'),
              this.translateService.instant('COMMON.SHOP.LICENSE_KEY.ERRORS.GENERAL')
            );
            break;
        }
      },
    });
  }
}
