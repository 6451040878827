import { BreakpointObserver } from '@angular/cdk/layout';
import { NgIf } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  inject,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { CardComponent } from '../card';
import { FigureComponent } from '../figure';
import { FrameComponent } from '../frame';
import { SwIconComponent } from '../sw-icon';
import { WellComponent } from '../well';

@Directive({
  selector: 'detail-panel-controls',
  standalone: true,
})
export class DetailPanelControlsDirective {}

@Directive({
  selector: 'detail-panel-info',
  standalone: true,
})
export class DetailPanelInfoDirective {}

@Directive({
  selector: 'detail-panel-content',
  standalone: true,
})
export class DetailPanelContentDirective {}

@Component({
  selector: 'account-detail-panel',
  templateUrl: './detail-panel.component.html',
  styleUrl: './detail-panel.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CardComponent, WellComponent, NgIf, FigureComponent, SwIconComponent, FrameComponent],
})
export class DetailPanelComponent implements AfterContentInit, OnDestroy {
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  @Input() heading: string;
  @Input() swIconName: string;
  @Input() imageFrameUrl: string;

  showContent = false;
  mobileViewport: boolean;
  private readonly mobileSubscription: Subscription;

  @ContentChild(DetailPanelContentDirective, { static: true }) private readonly content: DetailPanelContentDirective;

  constructor() {
    const mobileObserver = this.breakpointObserver.observe(['(max-width: 768px)']);
    this.mobileSubscription = mobileObserver.subscribe((result) => {
      this.mobileViewport = result.matches;
      this.changeDetectorRef.markForCheck();
    });
  }

  ngAfterContentInit(): void {
    this.showContent = undefined !== this.content;
    if (!this.heading) {
      throw new Error('A heading must be provided');
    }
    if (!this.imageFrameUrl && !this.swIconName) {
      throw new Error('Either the name of the shopware icon or an image-url must be provided');
    }

    if (this.imageFrameUrl && this.swIconName) {
      throw new Error('Unable to differ. Use either "imageFrameUrl" or "swIconName"');
    }
  }

  ngOnDestroy(): void {
    if (this.mobileSubscription) {
      this.mobileSubscription.unsubscribe();
    }
  }
}
