import { CompaniesFacade } from '@account/core/facades';
import { Basket, BasketShop } from '@account/core/models';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { BasketGateway } from '../../gateways/basket/basket.gateway';
import { actions, selectors } from '../../store';
import { RootState } from '../../store/root.state';

@Injectable({
  providedIn: 'root',
})
export class BasketFacade {
  private readonly store = inject<Store<RootState>>(Store<RootState>);
  private readonly basketGateway = inject(BasketGateway);
  private readonly companiesFacade = inject(CompaniesFacade);

  getStoredBasket(): Observable<Basket | null> {
    return this.store.select(selectors.basketSelectors.getBasket);
  }

  getBasket(): Observable<Basket | null> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) => this.basketGateway.getBasket(companyId)),
      catchError(() => of(null))
    );
  }

  addToBasket(orderNumber: string, positionData?: Record<string, any>): Observable<Basket> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) => this.basketGateway.addToBasket(companyId, orderNumber, positionData)),
      tap((basket: Basket | null) => {
        this.store.dispatch(actions.basketActions.setBasketForCompanyUserAccountSuccess({ payload: { basket } }));
      })
    );
  }

  updateBasketPosition(orderNumber: string, positionData?: Record<string, any>): Observable<Basket> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) => this.basketGateway.updateBasketPosition(companyId, orderNumber, positionData)),
      tap((basket: Basket | null) => {
        this.store.dispatch(actions.basketActions.setBasketForCompanyUserAccountSuccess({ payload: { basket } }));
      })
    );
  }

  removeFromBasket(orderNumber: string): Observable<Basket> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) => this.basketGateway.removeFromBasket(companyId, orderNumber)),
      tap((basket: Basket | null) => {
        this.store.dispatch(actions.basketActions.setBasketForCompanyUserAccountSuccess({ payload: { basket } }));
      })
    );
  }

  checkoutBasket(shopId: number): Observable<Basket> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) => this.basketGateway.checkoutBasket(companyId, shopId)),
      tap(() => {
        this.store.dispatch(actions.basketActions.resetBasket());
      })
    );
  }

  getShops(): Observable<BasketShop[]> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.basketGateway.getShops(companyId)));
  }

  validateVoucher(voucherCode: string): Observable<Basket | null> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) => this.basketGateway.validateVoucher(companyId, voucherCode)),
      tap((basket: Basket | null) => {
        this.store.dispatch(actions.basketActions.setBasketForCompanyUserAccountSuccess({ payload: { basket } }));
      })
    );
  }
}
