import { inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PluginForLocalization } from '../../../core/models/plugin/plugin.model';
import { PluginsService } from '../../services/plugins.service';

@Pipe({
  name: 'pluginName',
  pure: false,
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class PluginNamePipe implements PipeTransform {
  private readonly pluginsService = inject(PluginsService);
  private readonly translateService = inject(TranslateService);
  private value = '';
  private lastPlugin: null | PluginForLocalization = null;
  private lastLangKey: null | string = null;

  transform(plugin: PluginForLocalization, showGenerationLabel = false): any {
    if (plugin === this.lastPlugin && this.lastLangKey === this.translateService.currentLang) {
      return this.value;
    }
    this.lastPlugin = plugin;
    this.lastLangKey = this.translateService.currentLang;
    this.value = this.pluginsService.getLocalizedName(plugin, showGenerationLabel);

    return this.value;
  }
}
