import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { PartnerReferencesGateway } from '../../gateways/partner/partner-references.gateway';
import {
  ListResult,
  Partner,
  PartnerReference,
  PartnerReferenceFacet,
  PartnerReferenceFacetPicture,
  PartnerReferenceFacetStatics,
  PartnerReferenceHistoryEntry,
  PartnerReferenceSkeleton,
  PartnerReferencesStatics,
  PartnerReferenceStatusName,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { PartnersFacade } from './partners.facade';

@Injectable({
  providedIn: 'root',
})
export class PartnerReferencesFacade {
  private readonly partnersFacade = inject(PartnersFacade);
  private readonly partnerReferencesGateway = inject(PartnerReferencesGateway);

  getReferenceList(metaData: RequestMetaData): Observable<ListResult<PartnerReference[]>> {
    return this.getPartner().pipe(
      switchMap((partner: Partner) => this.partnerReferencesGateway.getReferenceList(partner, metaData)),
    );
  }

  getReference(id: number): Observable<PartnerReference> {
    return this.getPartner().pipe(
      switchMap((partner: Partner) => this.partnerReferencesGateway.getReference(partner, id)),
    );
  }

  addReference(newReference: PartnerReferenceSkeleton): Observable<PartnerReference> {
    return this.getPartner().pipe(
      switchMap((partner: Partner) => this.partnerReferencesGateway.addReference(partner, newReference)),
    );
  }

  updateReference(updatedReference: PartnerReference): Observable<PartnerReference> {
    return this.getPartner().pipe(
      switchMap((partner: Partner) => this.partnerReferencesGateway.updateReference(partner, updatedReference)),
    );
  }

  deleteReference(deletableReference: PartnerReference): Observable<null> {
    return this.getPartner().pipe(
      switchMap((partner: Partner) => this.partnerReferencesGateway.deleteReference(partner, deletableReference)),
    );
  }

  submitReferenceForNextWorkflowStep(
    referenceId: number,
    newStatus: PartnerReferenceStatusName,
  ): Observable<PartnerReferenceHistoryEntry> {
    return this.getPartner().pipe(
      switchMap((partner: Partner) =>
        this.partnerReferencesGateway.submitReferenceForNextWorkflowStep(partner, referenceId, newStatus),
      ),
    );
  }
  getPartnerReferencesStatics(): Observable<PartnerReferencesStatics> {
    return this.partnerReferencesGateway.getPartnerReferencesStatics();
  }

  getPartnerReferenceFacetList(
    referenceId: number,
    metaData: RequestMetaData,
    filter?: object,
  ): Observable<ListResult<PartnerReferenceFacet[]>> {
    return this.getPartner().pipe(
      switchMap((partner: Partner) =>
        this.partnerReferencesGateway.getPartnerReferenceFacetList(partner, referenceId, metaData, filter),
      ),
    );
  }

  addPartnerReferenceFacet(referenceId: number, facet: PartnerReferenceFacet): Observable<PartnerReferenceFacet> {
    return this.getPartner().pipe(
      switchMap((partner: Partner) =>
        this.partnerReferencesGateway.addPartnerReferenceFacet(partner, referenceId, facet),
      ),
    );
  }

  updatePartnerReferenceFacet(referenceId: number, facet: PartnerReferenceFacet): Observable<PartnerReferenceFacet> {
    return this.getPartner().pipe(
      switchMap((partner: Partner) =>
        this.partnerReferencesGateway.updatePartnerReferenceFacet(partner, referenceId, facet),
      ),
    );
  }

  deletePartnerReferenceFacet(referenceId: number, facet: PartnerReferenceFacet): Observable<void> {
    return this.getPartner().pipe(
      switchMap((partner: Partner) =>
        this.partnerReferencesGateway.deletePartnerReferenceFacet(partner, referenceId, facet),
      ),
    );
  }

  addPartnerReferenceFacetPicture(
    referenceId: number,
    facetId: number,
    picture: File,
  ): Observable<PartnerReferenceFacetPicture> {
    return this.getPartner().pipe(
      switchMap((partner: Partner) =>
        this.partnerReferencesGateway.addPartnerReferenceFacetPicture(partner, referenceId, facetId, picture),
      ),
    );
  }

  setPartnerReferenceFacetPreviewPicture(
    referenceId: number,
    facetId: number,
    pictureId: number,
  ): Observable<PartnerReferenceFacetPicture> {
    return this.getPartner().pipe(
      switchMap((partner: Partner) =>
        this.partnerReferencesGateway.setPartnerReferenceFacetPreviewPicture(partner, referenceId, facetId, pictureId),
      ),
    );
  }

  deletePartnerReferenceFacetPicture(referenceId: number, facetId: number, pictureId: number): Observable<any> {
    return this.getPartner().pipe(
      switchMap((partner: Partner) =>
        this.partnerReferencesGateway.deletePartnerReferenceFacetPicture(partner, referenceId, facetId, pictureId),
      ),
    );
  }

  getPartnerReferenceFacetStatics(): Observable<PartnerReferenceFacetStatics> {
    return this.partnerReferencesGateway.getPartnerReferenceFacetStatics();
  }

  updateReferenceOrder(updatedOrder: { id: number; priority: number }[]): Observable<void> {
    return this.getPartner().pipe(
      switchMap((partner: Partner) => this.partnerReferencesGateway.updateReferenceOrder(partner, updatedOrder)),
    );
  }

  private getPartner(): Observable<Partner> {
    return this.partnersFacade.getPartner().pipe(take(1));
  }
}
