import { sessionActions } from '@account/core/store/actions';
import { companySelectors, rootSelectors, sessionSelectors } from '@account/core/store/selectors';
import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, throwError } from 'rxjs';
import { filter, map, mapTo, share, switchMap, switchMapTo, take, tap } from 'rxjs/operators';

import { LoginCredentials, SbpException } from '../../models';
import { RootState } from '../../store/root.state';

@Injectable({
  providedIn: 'root',
})
export class AuthFacade {
  private readonly location = inject(Location);
  private readonly store = inject<Store<RootState>>(Store<RootState>);
  private readonly showLoggedInUI = new BehaviorSubject(false);
  private redirectUrl = '/';

  login(credentials: LoginCredentials): Observable<boolean | never> {
    this.store.dispatch(sessionActions.loginWithCredentials({ payload: credentials }));

    return this.store.select(sessionSelectors.isLoading).pipe(
      filter((loading: boolean) => !loading),
      switchMap(() => this.store.select(sessionSelectors.getError)),
      switchMap((error: SbpException | undefined) => {
        if (undefined !== error) {
          return throwError(error);
        }
        return this.store.select(sessionSelectors.isLoggedIn);
      }),
      take(1)
    );
  }

  loginWithToken(token: string): Observable<boolean | never> {
    this.store.dispatch(sessionActions.loginWithToken({ payload: token }));

    return this.store.select(sessionSelectors.isLoading).pipe(
      filter((loading: boolean) => !loading),
      switchMapTo(this.store.select(sessionSelectors.getError)),
      switchMap((error: SbpException | undefined) => {
        if (undefined !== error) {
          return throwError(error);
        }
        return this.waitUntilIsLoggedIn().pipe(tap(() => this.completeUILogin()));
      })
    );
  }

  loginWithOryJwtSessionToken(oryJwtSessionToken: string): Observable<boolean> {
    this.store.dispatch(sessionActions.loginWithOryJwtSessionToken({ payload: oryJwtSessionToken }));

    return this.store.select(sessionSelectors.isLoading).pipe(
      filter((loading: boolean) => !loading),
      switchMapTo(this.store.select(sessionSelectors.getError)),
      switchMap((error: SbpException | undefined) => {
        if (undefined !== error) {
          return throwError(error);
        }
        return this.waitUntilIsLoggedIn().pipe(tap(() => this.completeUILogin()));
      })
    );
  }

  resetUILogin(): void {
    this.showLoggedInUI.next(false);
  }

  completeUILogin(): void {
    this.showLoggedInUI.next(true);
  }

  isUILoginCompleted(): Observable<boolean> {
    return this.showLoggedInUI.asObservable().pipe(share());
  }

  logout(): Observable<boolean> {
    return this.store.select(sessionSelectors.isLoggingOut).pipe(
      filter((isLoggingOut: boolean) => !isLoggingOut),
      take(1),
      tap(() => this.store.dispatch(sessionActions.logout())),
      switchMapTo(this.store.select(sessionSelectors.isLoggingOut)),
      filter((isLoggingOut: boolean) => !isLoggingOut),
      take(1),
      tap(() => this.showLoggedInUI.next(false)),
      mapTo(true)
    );
  }

  waitUntilIsLoggedIn(): Observable<boolean> {
    return this.store.select(rootSelectors.isDataLoaded).pipe(
      filter((loaded: boolean) => loaded),
      switchMapTo(this.isLoggedIn())
    );
  }

  isLoggedIn(): Observable<boolean> {
    return this.store.select(rootSelectors.isLoggedIn);
  }

  waitUntilLoggingOut(): Observable<boolean> {
    return this.isLoggingOut().pipe(filter((isLoggingOut: boolean) => isLoggingOut));
  }

  isLoggingOut(): Observable<boolean> {
    return this.store.select(rootSelectors.isLoggingOut);
  }

  isLatestGTCSigned(): Observable<boolean> {
    return this.store.select(companySelectors.isLoaded).pipe(
      filter((loaded: boolean) => loaded),
      switchMapTo(this.store.select(companySelectors.signedGTC.getError)),
      switchMap((error: SbpException | undefined) => {
        if (undefined !== error) {
          return throwError(error);
        }
        return combineLatest([
          this.store.select(companySelectors.signedGTC.hasSignedLatestGTC),
          this.store.select(sessionSelectors.isAllowedToBypassGTCConfirmation),
        ]).pipe(
          map(
            ([hasSignedLatestGTC, isAllowedToBypassGTC]: [boolean, boolean]) =>
              hasSignedLatestGTC || isAllowedToBypassGTC
          )
        );
      })
    );
  }

  isBanned(): Observable<boolean> {
    return this.store.select(rootSelectors.isBanned);
  }

  isSoftBanned(): Observable<boolean> {
    return this.store.select(rootSelectors.isSoftBanned);
  }

  setRedirectUrl(redirectUrl = '/'): void {
    this.redirectUrl = redirectUrl;
  }

  getRedirectUrl(): string {
    return this.redirectUrl;
  }
}
