import { ProducersFacade } from '@account/core/facades';
import { Producer } from '@account/core/models';
import { ModalComponent } from '@account/shared/components';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';

import { MODAL_DATA } from '../../../../shared/components/modal/modal-injector';
import { ModalRef } from '../../../../shared/components/modal/modal-ref';
import { ToastService } from '../../../../shared/components/toast/toast.service';
import { cloneObject, objectIsEqual } from '../../../../shared/util';
import { StringValidators } from '../../../../shared/validators/string.validators';

@Component({
  selector: 'account-producer-mail-modal',
  templateUrl: './producer-mail-modal.component.html',
  styleUrl: './producer-mail-modal.component.less',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, ModalComponent],
})
export class ProducerMailModalComponent implements OnInit {
  private readonly modalRef = inject(ModalRef);
  private readonly modalData = inject<any>(MODAL_DATA);
  private readonly formBuilder = inject(UntypedFormBuilder);
  private readonly producersFacade = inject(ProducersFacade);
  private readonly toastService = inject(ToastService);
  private readonly translateService = inject(TranslateService);
  formHasPendingChanges = false;

  form: UntypedFormGroup;

  private producer: Producer;
  private originalFormValues: object;

  ngOnInit(): void {
    if (this.modalData.producer) {
      this.producer = this.modalData.producer;
      this.createForm(this.producer);
      this.originalFormValues = cloneObject(this.form.getRawValue());
    } else {
      this.producersFacade
        .getProducer()
        .pipe(take(1))
        .subscribe((producer: Producer) => {
          this.producer = Object.assign({}, producer);
          this.createForm(this.producer);
        });
    }
  }

  createForm(producer: Producer): void {
    const validatorsArray = [Validators.required, StringValidators.email];
    this.form = this.formBuilder.group({
      supportMail: [producer.supportMail, validatorsArray],
      ratingMail: [producer.ratingMail, validatorsArray],
      saleMail: [producer.saleMail, validatorsArray],
    });

    this.originalFormValues = cloneObject(this.form.getRawValue());

    this.form.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe(() => {
      if (objectIsEqual(this.originalFormValues, cloneObject(this.form.getRawValue()))) {
        this.formHasPendingChanges = false;
        this.form.markAsPristine();
      } else {
        this.formHasPendingChanges = true;
      }
    });
  }

  hasPendingChanges(): boolean {
    return !objectIsEqual(this.originalFormValues, this.form.getRawValue());
  }

  save(): void {
    this.producer = Object.assign(this.producer, this.form.getRawValue());
    this.producersFacade.updateProducer(this.producer).subscribe(
      () => {
        this.toastService.success(
          this.translateService.instant('COMMON.TOAST.SUCCESS.TITLE.UPDATE'),
          this.translateService.instant('PRODUCER_MAIL.MODAL.TOAST.SUCCESS.UPDATE'),
        );
      },
      () => {
        this.toastService.error(
          this.translateService.instant('COMMON.TOAST.ERROR.TITLE.REQUEST_FAILED'),
          this.translateService.instant('COMMON.TOAST.ERROR.MESSAGE.REQUEST_FAILED.PUT'),
        );
      },
    );

    this.modalRef.close();
  }

  closeModal(): void {
    this.modalRef.close();
  }
}
