import { NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  inject,
  Input,
  NgZone,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { HelptextComponent } from '../../../../../../shared/components/helptext/helptext.component';
import { KeysPipe } from '../../../../../../shared/pipes';

@Component({
  selector: 'domain-verification-domain-naming-element',
  templateUrl: './domain-naming-element.component.html',
  styleUrl: './domain-naming-element.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf, TranslateModule, KeysPipe, HelptextComponent],
})
export class DomainNamingElementComponent implements OnChanges {
  private readonly ngZone = inject(NgZone);
  @Input() active: boolean;
  @Input() form: UntypedFormGroup;
  @Input() introText: string;
  @Input() labelInputDomainName: string;
  @Input() selectInputOnInit = true;

  @ViewChild('inputDomainName', { static: true }) inputDomainName: ElementRef;

  ngOnChanges(changes: SimpleChanges): void {
    if (true === changes?.active?.currentValue) {
      this.selectAndFocusDomainInput();
    }
  }

  get isRequired(): boolean {
    const validator = this.form.get('domainName').validator({} as AbstractControl);
    return validator && validator.required;
  }

  private selectAndFocusDomainInput(): void {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        // need to wait one tick in order to initialize the view
        if (this.selectInputOnInit) {
          this.inputDomainName.nativeElement.select();
        }
        this.inputDomainName.nativeElement.focus();
      });
    });
    this.form.get('domainName').updateValueAndValidity();
  }
}
