import { NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SwIconComponent } from '../sw-icon/sw-icon.component';
import { StatusStyle } from './status.model';

/**
 * The status component shows a status icon in combination with a descriptive text.
 */
@Component({
  selector: 'account-status',
  templateUrl: './status.component.html',
  styleUrl: './status.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, SwIconComponent, MatTooltipModule],
})
export class StatusComponent {
  /**
   * Defines the type of the status icon.
   */
  @Input() type: StatusStyle;

  /**
   * Allows you to overwrite the default icon displayed for each status.
   */
  @Input() icon: string;

  /**
   * Defines the content of the tooltip displayed when hovering the element.
   */
  @Input() toolTip: string = null;

  @Input() preLineTooltip = false;

  /**
   * Defines the `font-size` of the description text.
   */
  @Input() fontSize = '14px';

  @Input() iconPositionTop = false;

  /**
   * Defines if the status icon should be displayed in large.
   */
  @HostBinding('class.large') @Input() large: boolean;
}
