import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  CreateBinaryCommand,
  ListResult,
  PluginCertification,
  Producer,
  ProductType,
  UpdateExistingBinaryCommand,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import {
  Binary,
  BinaryCheckResult,
  BinaryReview,
  LicenseCheckSnippet,
  Plugin,
  PluginGeneration,
  PluginPicture,
  PluginPreview,
} from '../../models/plugin/plugin.model';
import { ApiService } from '../../services';
import { HttpParamsBuilder } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class PluginsGateway {
  private readonly apiService = inject(ApiService);

  getPlugin(pluginId: number): Observable<Plugin> {
    return this.apiService.get(`/plugins/${pluginId}`);
  }

  getPluginList(
    producerId: number,
    metaData: RequestMetaData,
    filter: Record<string, any>
  ): Observable<ListResult<Plugin[]>> {
    const params = this.getPluginListParams(producerId, metaData, filter);

    return this.apiService.getList('/plugins', params);
  }

  getPluginListWithSimpleData(
    producerId: number,
    metaData: RequestMetaData,
    filter: Record<string, any>
  ): Observable<ListResult<Plugin[]>> {
    const params = this.getPluginListParams(producerId, metaData, filter).set('simpleData', 'true');

    return this.apiService.getList('/plugins', params);
  }

  getPluginListForVersionCompatibilityAssistant(producerId: number): Observable<ListResult<Plugin[]>> {
    const metaData = HttpParamsBuilder.createMetaData(0, 0, 'id', 'asc');
    const filter = [{ property: 'status', value: 'instore' }];
    const params = this.getPluginListParams(producerId, metaData, filter).set('withLatestBinary', 'true');

    return this.apiService.getList('/plugins', params);
  }

  createNewPlugin(generation: PluginGeneration, producer: Producer, productType?: ProductType): Observable<Plugin> {
    const data = {
      generation: generation,
      producerId: producer.id.toString(),
      productType: productType ?? null,
    };

    return this.apiService.post('/plugins', data);
  }

  deletePlugin(pluginId: number): Observable<''> {
    return this.apiService.delete(`/plugins/${pluginId}`);
  }

  updatePlugin(plugin: Plugin): Observable<Plugin> {
    return this.apiService.put(`/plugins/${plugin.id}`, plugin);
  }

  getPluginPictures(pluginId: number): Observable<PluginPicture[]> {
    return this.apiService.get(`/plugins/${pluginId}/pictures`);
  }

  uploadPluginPicture(pluginId: number, file: any): Observable<PluginPicture[]> {
    return this.apiService.upload(`/plugins/${pluginId}/pictures`, file);
  }

  updatePluginPicture(pluginId: number, picture: PluginPicture): Observable<PluginPicture> {
    return this.apiService.put(`/plugins/${pluginId}/pictures/${picture.id}`, picture);
  }

  deletePluginPicture(pluginId: number, pictureId: number): Observable<any> {
    return this.apiService.delete(`/plugins/${pluginId}/pictures/${pictureId}`);
  }

  uploadIcon(pluginId: number, file: any): Observable<{ icon: string }> {
    return this.apiService.upload(`/plugins/${pluginId}/icon`, file);
  }

  getPluginBinaries(producerId: number, pluginId: number): Observable<Binary[]> {
    return this.apiService.get(`/producers/${producerId}/plugins/${pluginId}/binaries`);
  }

  createBinary(producerId: number, pluginId: number, binary: CreateBinaryCommand): Observable<Binary> {
    return this.apiService.post(`/producers/${producerId}/plugins/${pluginId}/binaries`, binary);
  }

  validateBinaryFile(producerId: number, pluginId: number, file: File): Observable<Binary> {
    return this.apiService.upload(`/producers/${producerId}/plugins/${pluginId}/binaries/validate`, file);
  }

  uploadBinaryFile(producerId: number, pluginId: number, binaryId: number, file: File): Observable<Binary> {
    return this.apiService.upload(`/producers/${producerId}/plugins/${pluginId}/binaries/${binaryId}/file`, file);
  }

  updateBinary(
    producerId: number,
    pluginId: number,
    binaryId: number,
    binary: UpdateExistingBinaryCommand
  ): Observable<Binary> {
    return this.apiService.put(`/producers/${producerId}/plugins/${pluginId}/binaries/${binaryId}`, binary);
  }

  revokeBinary(producerId: number, pluginId: number, binaryId: number): Observable<null | Binary> {
    return this.apiService.delete(`/producers/${producerId}/plugins/${pluginId}/binaries/${binaryId}`);
  }

  getBinaryFileUrl(
    pluginId: number,
    binaryId: number,
    shopwareMajorVersion: number | null,
    shopId: number | null
  ): Observable<{ url: string }> {
    let params = new HttpParams();
    if (shopwareMajorVersion) {
      params.set('shopwareMajorVersion', shopwareMajorVersion.toString());
    }
    if (!shopwareMajorVersion) {
      params = params.set('unencrypted', 'true');
    }
    params = params.set('json', 'true');
    if (shopId !== null) {
      params = params.set('shopId', shopId.toString());
    }
    return this.apiService.get(`/plugins/${pluginId}/binaries/${binaryId}/file`, params);
  }

  downloadBinaryFile(url: string): Observable<Blob> {
    return this.apiService.downloadFromExternal(url);
  }

  requestBinaryReview(plugin: Plugin): Observable<BinaryReview[]> {
    return this.apiService.post(`/plugins/${plugin.id}/reviews`);
  }

  getBinaryReviews(pluginId: number): Observable<BinaryReview[]> {
    return this.apiService.get(`/plugins/${pluginId}/reviews`);
  }

  getPluginBinaryCheckResults(pluginId: number, binaryId: number): Observable<BinaryCheckResult[]> {
    return this.apiService.get(`/plugins/${pluginId}/binaries/${binaryId}/checkresults`);
  }

  getLicenseCheckSnippet(plugin: Plugin): Observable<LicenseCheckSnippet> {
    return this.apiService.get(`/plugins/${plugin.id}/checklicensesnippets/${plugin.name}`);
  }

  validateCertification(plugin: Plugin): Observable<PluginCertification> {
    return this.apiService.post(`/plugins/${plugin.id}/validations`);
  }

  getPluginPreview(plugin: Plugin): Observable<PluginPreview> {
    return this.apiService.get(`/plugins/${plugin.id}/preview`);
  }

  updatePluginPreviewStatus(plugin: Plugin, pluginPreview: PluginPreview): Observable<PluginPreview> {
    return this.apiService.put(`/plugins/${plugin.id}/preview`, pluginPreview);
  }

  updatePluginAutomaticBugfixVersionCompatibility(plugin: Plugin): Observable<Plugin> {
    const data = {
      automaticBugfixVersionCompatibility: plugin.automaticBugfixVersionCompatibility,
    };
    return this.apiService.put(`/plugins/${plugin.id}/automaticbugfixversioncompatibility`, data);
  }

  private getPluginListParams(producerId: number, metaData: RequestMetaData, filter: Record<string, any>): HttpParams {
    let params = new HttpParams()
      .set('limit', metaData.limit.toString())
      .set('offset', metaData.offset.toString())
      .set('orderBy', metaData.orderBy)
      .set('orderSequence', metaData.orderSequence)
      .set('search', metaData.search)
      .set('producerId', producerId.toString());

    if (filter) {
      params = HttpParamsBuilder.applyFilters(params, filter, true);
    }
    return params;
  }
}
