import { CompaniesFacade, NavigationFacade, ShopsFacade, UserAccountsFacade } from '@account/core/facades';
import { Company, CompanyRegistrationData, Language, ListResult, SelfHostedShop } from '@account/core/models';
import { EnvironmentService } from '@account/core/services';
import { HttpParamsBuilder } from '@account/core/utils';
import { ModalConfig, ModalService, ToastService } from '@account/shared/components';
import { StringValidators } from '@account/shared/validators';
import { inject, Injectable } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, NEVER, Observable, of } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';

import { CompanyOnboardingFacade } from '../../core/facades/onboarding/company-onboarding.facade';
import { CompanyOnboardingData, CompanyOnboardingStatus } from '../../core/models/onboarding/onboarding.model';
import { CompanyOnboardingState } from '../../core/store/onboarding';
import * as onboardingActions from '../../core/store/onboarding/onboarding.actions';
import {
  ShopCreationModalComponent,
  ShopCreationModalConfig,
} from '../../views/shared/shop/shop-creation-modal/shop-creation-modal.component';
import { ShopMigrationAssistantComponent } from '../../views/shared/shop/shop-successor-instance/shop-migration-assistant/shop-migration-assistant.component';
import { ShopUpgradeToCommercialPlanModalComponent } from '../../views/shared/shop/shop-upgrade-to-commercial-plan/shop-upgrade-to-commercial-plan-modal/shop-upgrade-to-commercial-plan-modal.component';

export interface CompanyOnboardingForm {
  companyName: FormControl<string>;
  countryId: FormControl<number | null>;
  locale: FormControl<string>;
  gtc: FormControl<boolean>;
  smallTradesman: FormControl<boolean>;
  email: FormControl<string>;
  salutation: FormControl<string>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  phone: FormControl<string>;
  street: FormControl<string>;
  city: FormControl<string>;
  zipCode: FormControl<string>;
  isHavingVatId: FormControl<boolean>;
  vatId: FormControl<string | null>;
}

export interface OnboardingStepContent {
  heading: string;
  description: string;
  imagePath: string;
  buttonText: string;
  actionIsLink: boolean;
  isButtonDisabled: boolean;
}

export interface OnboardingStep {
  title: string;
  isCompleted: boolean;
  content: OnboardingStepContent;
  navigationTitle: string;
  action: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class CompanyOnboardingService {
  private readonly formBuilder = inject(FormBuilder);
  private readonly userAccountFacade = inject(UserAccountsFacade);
  private readonly toastService = inject(ToastService);
  private readonly translateService = inject(TranslateService);
  private readonly navigationFacade = inject(NavigationFacade);
  private readonly router = inject(Router);
  private readonly modalService = inject(ModalService);
  private readonly companyOnboardingFacade = inject(CompanyOnboardingFacade);
  private readonly store = inject(Store);
  private readonly environmentService = inject(EnvironmentService);
  private readonly companiesFacade = inject(CompaniesFacade);
  private readonly shopsFacade = inject(ShopsFacade);

  getCompanyOnboardingState(): Observable<CompanyOnboardingState> {
    return combineLatest([
      this.companyOnboardingFacade.getStoredOnboardingStatus(),
      this.companyOnboardingFacade.getStoredOnboardingData(),
    ]).pipe(map(([status, data]) => ({ status, data })));
  }

  getCompanyOnboardingSteps(
    onboardingState: CompanyOnboardingState,
    isUSCustomer: boolean,
    selectedLanguage: Language
  ): OnboardingStep[] {
    let steps = [
      {
        title: 'COMPANY.ONBOARDING.GUIDE.START.TITLE',
        isCompleted: onboardingState.data.isFirstStoreRegistered,
        content: {
          heading: 'COMPANY.ONBOARDING.GUIDE.START.HEADING',
          description: 'COMPANY.ONBOARDING.GUIDE.START.DESCRIPTION',
          imagePath: 'assets/images/onboarding/onboarding-registration.png',
          buttonText: 'COMPANY.ONBOARDING.GUIDE.START.BUTTON_TEXT',
          actionIsLink: false,
          isButtonDisabled: false,
        },
        navigationTitle: 'COMPANY.ONBOARDING.NAVIGATION.REGISTER_SHOP',
        action: () => this.openShopCreationModal(onboardingState.data),
      },
      {
        title: 'COMPANY.ONBOARDING.GUIDE.DEFAULT_PAYMENT.TITLE',
        isCompleted: onboardingState.data.isDefaultPaymentMeanRegistered,
        content: {
          heading: 'COMPANY.ONBOARDING.GUIDE.DEFAULT_PAYMENT.HEADING',
          description: 'COMPANY.ONBOARDING.GUIDE.DEFAULT_PAYMENT.DESCRIPTION',
          imagePath: 'assets/images/onboarding/onboarding-payment.png',
          buttonText: 'COMPANY.ONBOARDING.GUIDE.DEFAULT_PAYMENT.BUTTON_TEXT',
          actionIsLink: false,
          isButtonDisabled: false,
        },
        navigationTitle: 'COMPANY.ONBOARDING.NAVIGATION.DEFAULT_PAYMENT',
        action: () => this.redirectToPaymentRegistration(),
      },
      {
        title: 'COMPANY.ONBOARDING.GUIDE.VISIT_STORE.TITLE',
        isCompleted: onboardingState.data.hasVisitedExtensionStore,
        content: {
          heading: 'COMPANY.ONBOARDING.GUIDE.VISIT_STORE.HEADING',
          description: 'COMPANY.ONBOARDING.GUIDE.VISIT_STORE.DESCRIPTION',
          imagePath: 'assets/images/onboarding/onboarding-extensions.png',
          buttonText: 'COMPANY.ONBOARDING.GUIDE.VISIT_STORE.BUTTON_TEXT',
          actionIsLink: true,
          isButtonDisabled: false,
        },
        navigationTitle: 'COMPANY.ONBOARDING.NAVIGATION.VISIT_STORE',
        action: () => this.redirectToStore(onboardingState.status, onboardingState.data, selectedLanguage),
      },
      {
        title: 'COMPANY.ONBOARDING.GUIDE.EXPLORE_PLANS.TITLE',
        isCompleted: onboardingState.data.hasExploredShopwarePlans,
        content: {
          heading: 'COMPANY.ONBOARDING.GUIDE.EXPLORE_PLANS.HEADING',
          description: 'COMPANY.ONBOARDING.GUIDE.EXPLORE_PLANS.DESCRIPTION',
          imagePath: 'assets/images/onboarding/onboarding-plans.png',
          buttonText: 'COMPANY.ONBOARDING.GUIDE.EXPLORE_PLANS.BUTTON_TEXT',
          actionIsLink: false,
          isButtonDisabled: true === onboardingState.data.hasExploredShopwarePlans,
        },
        navigationTitle: 'COMPANY.ONBOARDING.NAVIGATION.EXPLORE_PLANS',
        action: () => this.openPlanBookingModal(onboardingState.status, onboardingState.data),
      },
    ];

    if (isUSCustomer === true) {
      steps = steps.filter((step) => step.title !== 'COMPANY.ONBOARDING.GUIDE.DEFAULT_PAYMENT.TITLE');
    }

    return steps;
  }

  openShopCreationModal(onboardingData: CompanyOnboardingData): void {
    this.companiesFacade
      .getCompany()
      .pipe(
        take(1),
        switchMap((company: Company) => {
          const config: ShopCreationModalConfig = {
            assignee: company,
            context: 'shop',
          };
          const modalConfig: ModalConfig = { data: config };
          return this.modalService
            .open(ShopCreationModalComponent, modalConfig)
            .afterClosed()
            .pipe(
              switchMap((shopCreated: SelfHostedShop | null) => {
                if (!shopCreated) {
                  return NEVER;
                }

                return this.companyOnboardingFacade
                  .updateCompanyOnboardingData({
                    status: CompanyOnboardingStatus.IN_PROGRESS,
                    hasVisitedExtensionStore: onboardingData.hasVisitedExtensionStore,
                    hasExploredShopwarePlans: shopCreated.commercialContract !== null,
                  })
                  .pipe(map(() => shopCreated));
              })
            );
        })
      )
      .subscribe((shopCreated: SelfHostedShop | null) => {
        if (shopCreated !== null) {
          this.router.navigate(['shops']);
        }
      });
  }

  openPlanBookingModal(onboardingStatus: CompanyOnboardingStatus, onboardingData: CompanyOnboardingData): void {
    this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.shopsFacade
            .getSelfHostedShopsForCompany(companyId, HttpParamsBuilder.createMetaData(0, 0, 'creationDate', 'asc'))
            .pipe(map((listResultOfShops: ListResult<SelfHostedShop[]>) => listResultOfShops.list))
        ),
        switchMap((shops: SelfHostedShop[]) => {
          const shopsWithoutPlan = shops.filter((shop) => shop.commercialContract === null);
          const shopsWithPlan = shops.filter((shop) => shop.commercialContract !== null);

          if (shopsWithoutPlan.length === 0) {
            const shopToRedirectTo = shopsWithPlan[0];
            this.router.navigate(['shops', 'shops', shopToRedirectTo.id]);

            return this.companyOnboardingFacade.updateCompanyOnboardingData({
              status: onboardingStatus,
              hasVisitedExtensionStore: onboardingData.hasVisitedExtensionStore,
              hasExploredShopwarePlans: true,
            });
          }

          const latestShop = shopsWithoutPlan[0];

          if (latestShop.commercialContract !== null) {
            return NEVER;
          }

          switch (latestShop.shopwareVersion.major) {
            case 'Shopware 5':
              return this.modalService
                .open(ShopMigrationAssistantComponent, {
                  data: {
                    preselectedShop: latestShop,
                    intent: 'migration',
                  },
                })
                .afterClosed();

            case 'Shopware 6':
              return this.modalService
                .open(ShopUpgradeToCommercialPlanModalComponent, {
                  data: {
                    preselectedShop: latestShop,
                    intent: 'upgrade',
                  },
                })
                .afterClosed();
          }

          return NEVER;
        }),
        switchMap(() =>
          this.companyOnboardingFacade.updateCompanyOnboardingData({
            status: onboardingStatus,
            hasVisitedExtensionStore: onboardingData.hasVisitedExtensionStore,
            hasExploredShopwarePlans: true,
          })
        )
      )
      .subscribe();
  }

  redirectToStore(
    onboardingStatus: CompanyOnboardingStatus,
    onboardingData: CompanyOnboardingData,
    selectedLanguage: Language
  ): void {
    this.companyOnboardingFacade
      .updateCompanyOnboardingData({
        status: onboardingStatus,
        hasVisitedExtensionStore: true,
        hasExploredShopwarePlans: onboardingData.hasExploredShopwarePlans,
      })
      .subscribe(() =>
        window.open(
          `${this.environmentService.current.storeProtocol}://${this.environmentService.current.storeIp}/${selectedLanguage.key}`,
          '_blank'
        )
      );
  }

  redirectToPaymentRegistration(): void {
    this.router.navigate(['settings', 'billing', 'registerpaymentmean']);
  }

  createNewCompanyDuringOnboarding(companyRegistrationData: CompanyRegistrationData): void {
    this.userAccountFacade.createCompany(companyRegistrationData).subscribe({
      next: () => {
        this.toastService.success(
          this.translateService.instant('COMPANY.ONBOARDING.TOAST.SUCCESS_TITLE'),
          this.translateService.instant('COMPANY.ONBOARDING.TOAST.SUCCESS_MESSAGE')
        );

        this.navigationFacade.selectWorkspace(null);
        this.router.navigate(['company', 'registration', 'success']);
      },
      error: () => {
        this.toastService.error(
          this.translateService.instant('COMPANY.ONBOARDING.TOAST.ERROR_TITLE'),
          this.translateService.instant('COMPANY.ONBOARDING.TOAST.ERROR_MESSAGE')
        );
      },
    });
  }

  createCompanyOnboardingForm(): FormGroup<CompanyOnboardingForm> {
    const form = this.formBuilder.group<CompanyOnboardingForm>(
      {
        companyName: this.formBuilder.control<string>('', [Validators.required]),
        countryId: this.formBuilder.control<number | null>(null, [Validators.required]),
        locale: this.formBuilder.control<string>('', [Validators.required]),
        gtc: this.formBuilder.control<boolean>(false, [Validators.requiredTrue]),
        smallTradesman: this.formBuilder.control<boolean>(false),
        email: this.formBuilder.control<string>('', [Validators.required, StringValidators.email]),
        salutation: this.formBuilder.control<string>('', [Validators.required]),
        firstName: this.formBuilder.control<string>('', [Validators.required]),
        lastName: this.formBuilder.control<string>('', [Validators.required]),
        phone: this.formBuilder.control<string>('', [Validators.required, StringValidators.phone]),
        street: this.formBuilder.control<string>('', [Validators.required, Validators.minLength(3)]),
        city: this.formBuilder.control<string>('', [Validators.required, Validators.minLength(3)]),
        zipCode: this.formBuilder.control<string>('', [Validators.required]),
        isHavingVatId: this.formBuilder.control<boolean>(false),
        vatId: this.formBuilder.control<string | null>({ value: null, disabled: true }),
      },
      { validators: this.validateBusinessType() }
    );

    const isSmallBusinessOwnerControl = form.get('smallTradesman');
    const isHavingVatIdControl = form.get('isHavingVatId');
    const vatIdControl = form.get('vatId');

    combineLatest([
      isSmallBusinessOwnerControl!.valueChanges.pipe(startWith(isSmallBusinessOwnerControl!.value)),
      isHavingVatIdControl!.valueChanges.pipe(startWith(isHavingVatIdControl!.value)),
    ]).subscribe(([isSmallBusinessOwner, isHavingVatId]) => {
      if (isSmallBusinessOwner) {
        isHavingVatIdControl!.disable({ emitEvent: false });
        vatIdControl!.disable({ emitEvent: false });
        vatIdControl!.reset();
      } else {
        isHavingVatIdControl!.enable({ emitEvent: false });
        if (isHavingVatId) {
          vatIdControl!.enable({ emitEvent: false });
        } else {
          vatIdControl!.disable({ emitEvent: false });
          vatIdControl!.reset();
        }
      }

      if (isHavingVatId) {
        isSmallBusinessOwnerControl!.disable({ emitEvent: false });
      } else {
        isSmallBusinessOwnerControl!.enable({ emitEvent: false });
      }
    });

    return form;
  }

  skipOnboarding(): void {
    this.modalService
      .confirm(
        this.translateService.instant('COMPANY.ONBOARDING.GUIDE.SKIP_CONFIRM.TITLE'),
        this.translateService.instant('COMPANY.ONBOARDING.GUIDE.SKIP_CONFIRM.TEXT'),
        this.translateService.instant('COMPANY.ONBOARDING.GUIDE.SKIP_CONFIRM.CANCEL_BUTTON'),
        this.translateService.instant('COMPANY.ONBOARDING.GUIDE.SKIP_CONFIRM.CONFIRM_BUTTON'),
        false,
        '500px'
      )
      .afterClosed()
      .pipe(
        switchMap((closeResult: boolean) => {
          if (closeResult) {
            return this.companyOnboardingFacade.updateCompanyOnboardingStatus(CompanyOnboardingStatus.SKIPPED);
          }

          return of(null);
        })
      )
      .subscribe();
  }

  completeOnboarding(onboardingData: CompanyOnboardingData): void {
    this.companyOnboardingFacade
      .updateCompanyOnboardingData({
        status: CompanyOnboardingStatus.COMPLETED,
        hasVisitedExtensionStore: onboardingData.hasVisitedExtensionStore,
        hasExploredShopwarePlans: onboardingData.hasExploredShopwarePlans,
      })
      .subscribe();
  }

  refreshCompanyOnboardingData(): void {
    this.store.dispatch(onboardingActions.setOnboardingDataForCompany());
  }

  private validateBusinessType(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isSmallBusinessOwner = control.get('smallTradesman')?.value;
      const isHavingVatId = control.get('isHavingVatId')?.value;
      const vatId = control.get('vatId')?.value;

      if (isSmallBusinessOwner && isHavingVatId) {
        return { vatIdError: 'You cannot be a small business owner and have a VAT ID at the same time.' };
      }

      if (isHavingVatId && !vatId) {
        return { vatIdError: 'The VAT ID must be filled if the company is no small business owner.' };
      }

      return null;
    };
  }
}
