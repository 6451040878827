<form [formGroup]="form" class="contents">
  <div class="flex flex-col gap-4 p-6">
    <div class="flex flex-row gap-3">
      <div class="bg-gray-200 size-12 rounded-full flex flex-shrink-0 items-center justify-center typo-body-inter-16px-bold text-default">
        {{ user().personalData.firstName.charAt(0) }}{{ user().personalData.lastName.charAt(0) }}
      </div>
      <div class="grid">
        <p class="text-ellipsis typo-body-brandon-16px-medium">
          {{ user().personalData.firstName + ' ' + user().personalData.lastName }}
        </p>
        <p class="text-ellipsis typo-body-inter-15px-medium text-faded mt-[-5px]">{{ user().email }}</p>
      </div>
    </div>

    <div class="flex flex-col gap-2">
      @if (activeUserContext?.company) {
        <ao-select-field placeholder="Select an option" formControlName="company">
          <ng-template ao-field-label>
            {{ 'COMMON.COMPANY_NAME' | translate }}
          </ng-template>
          <ao-select-trigger>
            <ng-template aoSelectValue let-value="value" let-label="label">
              {{ label }}
            </ng-template>
          </ao-select-trigger>
          @for (membership of activeUserContext.memberships; track membership.company.id) {
            <ao-select-option [value]="membership" [label]="membership.company.name">
              <div class="flex flex-col">
                <p class="typo-body-inter-16px-regular text-default">{{ membership.company.name }}</p>
                <p class="typo-body-inter-14px-regular text-faded">
                  {{ 'PROFILE.MASTER_DATA.ACCOUNT_INFORMATION.CUSTOMER_ID' | translate }}: {{ membership.company.customerNumber }}
                </p>
              </div>
            </ao-select-option>
          }
          @if (activeUserContext?.company?.masterData?.customerNumber) {
            <ng-template ao-field-message>
              <ao-field-message>
                {{ 'PROFILE.MASTER_DATA.ACCOUNT_INFORMATION.CUSTOMER_ID' | translate }}:
                {{ activeUserContext?.company?.masterData?.customerNumber }}
              </ao-field-message>
            </ng-template>
          }
        </ao-select-field>
      }

      <ao-select-field placeholder="Select an option" formControlName="language">
        <ng-template ao-field-label>
          {{ 'COMMON.LABEL.LANGUAGE' | translate }}
        </ng-template>
        <ao-select-trigger>
          <ng-template aoSelectValue let-value="value" let-label="label">
            {{ 'LANGUAGE.' + value.key | translate }}
          </ng-template>
        </ao-select-trigger>
        @for (language of languages; track language.key) {
          <ao-select-option [value]="language" [label]="language.key">
            {{ 'LANGUAGE.' + language.key | translate }}
          </ao-select-option>
        }
      </ao-select-field>
    </div>
  </div>

  <hr class="menu-separator" />

  <div class="flex flex-col p-1">
    <button ao-button variant="link" class="justify-start" (click)="logout()">
      <ao-icon name="sign-out" variant="regular" class="size-4 text-faded" />
      {{ 'NAVIGATION.LOGOUT' | translate }}
    </button>
  </div>
</form>
