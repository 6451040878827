<account-modal
  overflowBody="hidden"
  paddingBody="padding_none"
  [maxWidth]="maxWidth"
  [minHeight]="minHeight"
  [showHeader]="false"
  [focusInput]="focusInput"
  [closeableOnEscape]="closeableOnEscape"
  [loading]="loading"
  [loadingText]="loadingText"
  (modalClose)="abort()">
  <modal-body>
    <form>
      <account-card
        [icon]="icon"
        [heading]="heading"
        [contentHasPadding]="contentHasPadding"
        [contentIsScrollable]="contentIsScrollable">
        <card-controls>
          <button
            class="a_button is_small"
            *ngIf="!initialConfiguration && !started"
            type="button"
            (click)="started = true">
            <span *ngIf="showButtonLabels">{{ 'WIZARD.START' | translate }}</span>
          </button>
          <!-- Comment required for hacky CSS reasons, don't remove!
      -->
          <button class="a_button is_small" *ngIf="closable" type="button" (click)="abort()">
            <span *ngIf="showButtonLabels">
              {{ labelCancelButton ? labelCancelButton : ('WIZARD.ABORT' | translate) }}
            </span>
          </button>
        </card-controls>
        <card-body>
          <ng-content />
        </card-body>
        <card-footer [hidden]="!started">
          <ng-content select="modal-wizard-footer" />

          <wizard-pagination
            *ngIf="steps"
            [hidden]="hidePagination"
            [steps]="steps"
            [showProgressBar]="showProgressBar"
            [showButtonLabels]="showButtonLabels"
            [labelCompleteButton]="labelCompleteButton"
            (onNext)="next()"
            (onPrev)="previous()"
            (onComplete)="complete()" />
        </card-footer>
      </account-card>
    </form>
  </modal-body>
</account-modal>
