import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { SafePipe } from '../../../pipes/safe.pipe';
import { AbstractModalComponent } from '../abstract-modal.component';
import { ModalBodyDirective, ModalComponent } from '../modal.component';

@Component({
  selector: 'account-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrl: './../modal.component.less',
  standalone: true,
  imports: [ModalComponent, NgIf, ModalBodyDirective, SafePipe],
})
export class InfoModalComponent extends AbstractModalComponent implements OnInit {
  close(): void {
    this.modalRef.close(false);
  }
}
