import { CloudShopSandboxGateway } from '@account/core/gateways';
import { CloudShopSandbox } from '@account/core/models';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CloudShopSandboxFacade {
  private readonly cloudShopSandboxGateway = inject(CloudShopSandboxGateway);

  getCloudShopSandbox(): Observable<CloudShopSandbox | null> {
    return this.cloudShopSandboxGateway.getCloudShopSandbox();
  }
  createCloudShopSandbox(): Observable<CloudShopSandbox> {
    return this.cloudShopSandboxGateway.createCloudShopSandbox();
  }
}
