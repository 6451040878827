@if (!isWizardStep) {
  <account-modal
    paddingBody="padding_none"
    maxWidth="860px"
    [heading]="heading"
    [closeableOnEscape]="false"
    [loading]="planBookingIsLoading"
    (modalClose)="close()">
    <modal-body>
      <ng-template [ngTemplateOutlet]="body" />
    </modal-body>

    <modal-footer>
      <ng-template [ngTemplateOutlet]="footer" />
    </modal-footer>
  </account-modal>
} @else {
  <div class="wizard-step-body">
    <ng-template [ngTemplateOutlet]="body" />
  </div>

  <div class="wizard-step-footer">
    <ng-template [ngTemplateOutlet]="footer" />
  </div>
}

<ng-template #body [formGroup]="form">
  <h1 class="typo-headline-poppins-28px-bold mb-1">{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.BOOK_PLAN' | translate }}</h1>

  <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ $implicit: form }" />

  <div class="plan-booking" formGroupName="planBooking">
    <h2>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.STORE_REVENUE' | translate }}</h2>
    <p>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.STORE_REVENUE_DESCRIPTION' | translate }}</p>

    <account-incremental-select formControlName="gmv" [options]="gmvSteps">
      <ng-template let-option>
        {{ getGmvStepLabel(option) }}
      </ng-template>
    </account-incremental-select>

    @if (bookablePlans$ | async; as bookablePlans) {
      <div
        class="bookable-plans-slider"
        [class.is--slider]="(canSkipBookingStep$ | async) && bookablePlans.length > 1"
      >
        <input
          type="radio"
          name="slider"
          class="back"
          checked />
        <div class="nav-button">
          <account-sw-icon size="14px" icon="chevron-left-s" />
        </div>
        <input
          type="radio"
          name="slider"
          class="forward" />
        <div class="nav-button">
          <account-sw-icon size="14px" icon="chevron-right-s" />
        </div>

        <div class="bookable-plans">
          <account-tile-select
            *ngIf="canSkipBookingStep$ | async"
            formControlName="skipBooking"
            class="ce-tile"
            [options]="[true]"
            [buttonLabel]="'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.CHOOSE_PLAN' | translate">
            <ng-template let-option>
              <account-plan-tile-content />
            </ng-template>
          </account-tile-select>

          <account-tile-select
            formControlName="plan"
            [options]="bookablePlans"
            [buttonLabel]="'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.CHOOSE_PLAN' | translate">
            <ng-template let-option>
              <account-plan-tile-content
                [plan]="option.name"
                [subscriptionRefund]="subscriptionRefund"
                [calculatedPrice]="calculatePlanPrices[option.name] | async"
                [showAndMoreLink]="shopLicense === 'PE'"
                [allowedForTrialPhase]="isRiseTrialPhaseAllowed && shopLicense === 'CE'"
                [canSeeSpecialOffer]="shopLicense === 'CE' && this.preselectedShop?.shopwareVersion.major === 'Shopware 5'"
                [shopCreationDate]="this.preselectedShop?.creationDate"
                [isCustomPrice]="showCustomPriceForBeyond(option) || !isBookable"
                accountLoadingSpinner
                [showLoadingSpinner]="pricingIsLoading" />
            </ng-template>
          </account-tile-select>
        </div>
      </div>
    } @else {
      <div accountLoadingSpinner class="h-10 my-20 after:!border-0"></div>
    }

    @if (isBookable) {
      @if (isRiseTrialPhaseAllowed && shopLicense === 'CE') {
        @if (!selectedPlan || selectedPlan.name === 'rise') {
          <p class="mt-10">
            {{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.FREE_TRIAL_DISCLAIMER' | translate }}
            {{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.VAT_DISCLAIMER' | translate: { minDuration: 12 } }}
          </p>
        }
      } @else {
        <p class="mt-10">{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.VAT_DISCLAIMER' | translate: { minDuration: 24 } }}</p>
      }
      <account-checkbox
        *ngIf="!(isRiseTrialPhaseAllowed && shopLicense === 'CE') || !selectedPlan || selectedPlan.name === 'rise'"
        class="license-agreement-checkbox"
        formControlName="contract"
        [label]="
          isGtcAcceptanceRequired
            ? ('SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.GTC_LABEL'
              | translate: { url: ('FOOTER.LINK_GTC' | translate) })
            : ('SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.LICENSE_LABEL' | translate)
        "
        [labelAsHTML]="true"
        (click)="downloadLicenseAgreement($event)" />
    }
  </div>

  <hr />

  <div class="plan-booking">
    <div class="book-meeting">
      <h2>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.HERE_FOR_YOU' | translate }}</h2>
      <p>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.HERE_FOR_YOU_DESCRIPTION' | translate }}</p>
      <div class="book-meeting__flex">
        <a
          class="a_button is_default padding_small"
          [href]="'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.FAQ_URL' | translate"
          target="_blank"
          (click)="onAddTrackingEvent('bookingAssistantFaqClick')">
          {{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.FAQ' | translate }}
        </a>
        <a
          class="a_button is_default padding_small"
          href="https://ecommerce.shopware.com/meetings/sales1366/account-self-service"
          target="_blank"
          (click)="onAddTrackingEvent('bookingAssistantBookMeeting')">
          {{ 'SHOPS.SUCCESSOR.CREATION_MODAL.BOOK_MEETING.BUTTON' | translate }}
        </a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #footer [formGroup]="form">
  <button
    *ngIf="modalData.partnerCustomer && preselectedShop"
    [disabled]="isPriceIndicationDisabled"
    [class.is_disabled]="isPriceIndicationDisabled"
    class="a_button is_small"
    (click)="downloadPriceIndication()">
    {{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.DOWNLOAD_PRICE_INDICATION' | translate }}
  </button>

  <button
    [disabled]="form.invalid"
    [class.is_disabled]="form.invalid"
    class="a_button is_small is_primary"
    (click)="handleBooking()">
    {{ finishButtonLabel }}
  </button>
</ng-template>
