<div (click)="toggleFilterGroup()" class="filter-group-heading">
  <account-checkbox [checked]="active" (change)="groupIsActiveCheckboxChange($event)" />

  <div class="headline flex-fill">
    <ng-template #plainText>
      <strong>{{ heading }}</strong>
    </ng-template>
    <ng-template [ngTemplateOutlet]="headingAsReference ? heading : plainText" />
  </div>

  <account-sw-icon [icon]="active ? 'chevron-up-s' : 'chevron-down-s'" size="16px" />
</div>
<div class="filter-group-content" [class.is--active]="active">
  <ng-container *ngFor="let template of templateList">
    <ng-template [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ filterItemForm: formGroup }" />
  </ng-container>
</div>
