<div class="menu-button" (click)="toggleNavigation()">
  <account-sw-icon icon="bars" size="24px" />
</div>

<div class="action-bar-content" [class.has-actions]="!!actionTemplateRef">
  <account-breadcrumbs />

  <div *ngIf="!!actionTemplateRef" class="action-bar-content--actions">
    <ng-container [ngTemplateOutlet]="actionTemplateRef" />
  </div>
</div>
