import { LanguagesFacade } from '@account/core/facades';
import { FeatureFlagPipe } from '@account/shared/pipes';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DOCUMENT, NgIf, UpperCasePipe } from '@angular/common';
import { Component, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { Language } from '../../../core/models/common/language.model';
import { LocalStorageService } from '../../../core/services';
import { LoadingSpinnerDirective } from '../../../shared/directives/loading-spinner.directive';
import { SwIconComponent } from '../sw-icon/sw-icon.component';
import { AuthBadgeComponent } from './badge/badge.component';

@Component({
  selector: 'account-auth-card',
  templateUrl: './auth-card.component.html',
  styleUrl: './auth-card.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [AuthBadgeComponent, NgIf, SwIconComponent, UpperCasePipe, TranslateModule, FeatureFlagPipe, LoadingSpinnerDirective],
})
export class AuthCardComponent implements OnInit {
  private readonly document = inject<any>(DOCUMENT);
  private readonly route = inject(ActivatedRoute);
  private readonly languagesFacade = inject(LanguagesFacade);
  private readonly localStorageService = inject(LocalStorageService);
  createDemoShop = false;
  notSelectedLanguage: Language;

  @Input() loading = false;
  @Input() isWideLayout = false;

  ngOnInit(): void {
    if (coerceBooleanProperty(this.route.snapshot.queryParamMap.get('createdemoshop'))) {
      this.createDemoShop = true;
      this.languagesFacade.getSelectedLanguage().subscribe((language: Language) => {
        if (language.key && language.key === 'de') {
          this.document.location.href = 'https://www.shopware.com/de/demo-testen/';
        } else {
          this.document.location.href = 'https://www.shopware.com/en/test-demo/';
        }
      });
    }
    let lang = this.localStorageService.getItem('lang');
    if (!lang || lang === '') {
      this.languagesFacade.getSelectedLanguage().subscribe((language: Language) => {
        lang = language.key;
      });
    }
    if (lang === 'de') {
      this.notSelectedLanguage = { key: 'en' };
    } else {
      this.notSelectedLanguage = { key: 'de' };
    }
  }

  changeLanguage(language: Language): void {
    this.languagesFacade.selectLanguage(language);
    if (language.key === 'de') {
      this.notSelectedLanguage = { key: 'en' };
    } else {
      this.notSelectedLanguage = { key: 'de' };
    }
  }
}
