import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { StatusComponent } from '../../../shared/components/status/status.component';
import { SwIconComponent } from '../../../shared/components/sw-icon/sw-icon.component';
import { SafePipe } from '../../../shared/pipes';
import { LocalStorageService } from '../../services/app';
import { TopbarComponent } from '../topbar';

@Component({
  selector: 'account-browser-compatibility-notice',
  templateUrl: './browser-compatibility-notice.component.html',
  styleUrl: './browser-compatibility-notice.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, TopbarComponent, TranslateModule, SwIconComponent, StatusComponent, SafePipe],
})
export class BrowserCompatibilityNoticeComponent implements OnInit {
  private readonly deviceDetectorService = inject(DeviceDetectorService);
  private readonly localStorageService = inject(LocalStorageService);
  localStorageEntryKey = 'hideBrowserCompatibilityNotice';
  showMessage = false;

  ngOnInit(): void {
    if (this.localStorageService.getItem(this.localStorageEntryKey)) {
      return;
    }
    if (
      this.deviceDetectorService.browser.toLowerCase() !== 'chrome' &&
      this.deviceDetectorService.browser.toLowerCase() !== 'firefox' &&
      this.deviceDetectorService.browser.toLowerCase() !== 'ms-edge-chromium'
    ) {
      this.showMessage = true;
    }
  }

  hideNotice(): void {
    this.localStorageService.setItem(this.localStorageEntryKey, 'true');
    this.showMessage = false;
  }
}
