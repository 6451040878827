<div class="collapsible-heading" [class.is-collapsed]="!open" (click)="collapsible && toggle()">
  <ng-content select="collapsible-headline" />
  <account-sw-icon
    [attr.data-hidden]="!collapsible ? '' : null"
    class="text-default"
    icon="chevron-up-s"
    variant="solid"
    size="10px"
    [class.is-reversed]="!open" />
</div>
<div class="collapsible-body" [class.is-closed]="!open">
  <ng-content />
</div>
