import { WikiGateway } from '@account/core/gateways';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Language } from '../../models';
import { WikiArticle } from '../../models/wiki/wiki.model';

@Injectable({
  providedIn: 'root',
})
export class WikiFacade {
  private readonly wikiGateway = inject(WikiGateway);

  getArticle(url: string, language: Language): Observable<WikiArticle> {
    return this.wikiGateway.getArticle(url, language);
  }
}
