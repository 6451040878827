import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LicenseTransfer, LicenseTransferCollisions, ListResult } from '../../models';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class LicenseTransferGateway {
  private readonly apiService = inject(ApiService);
  // creator calls

  checkIntegrity(companyId: number, licenseTransferId: number): Observable<void> {
    return this.apiService.get(`/companies/${companyId}/licensetransfers/${licenseTransferId}/checkintegrity`);
  }

  getUnprocessedLicenseTransferForShop(
    companyId: number,
    shopId: number,
    licenseTransferId: number
  ): Observable<null | LicenseTransfer> {
    return this.apiService.get(`/companies/${companyId}/shops/${shopId}/licensetransfers/${licenseTransferId}`);
  }

  getUnprocessedLicenseTransferListForShop(companyId: number, shopId: number): Observable<LicenseTransfer[]> {
    return this.apiService.get(`/companies/${companyId}/shops/${shopId}/licensetransfers`);
  }

  // receiver calls

  addLicenseTransfer(licenseTransfer: LicenseTransfer): Observable<LicenseTransfer> {
    return this.apiService.post(
      `/companies/${licenseTransfer.sourceShop.company.id}/shops/${licenseTransfer.sourceShop.id}/licensetransfers`,
      licenseTransfer
    );
  }

  checkForCollisions(
    licenseTransfer: LicenseTransfer,
    shopId: number,
    companyId: number
  ): Observable<LicenseTransferCollisions> {
    return this.apiService.get(
      `/companies/${companyId}/licensetransfers/${licenseTransfer.id}/checkforcollisions/${shopId}`
    );
  }

  declineLicenseTransfer(companyId: number, licenseTransferId: number): Observable<void> {
    return this.apiService.post(`/companies/${companyId}/licensetransfers/${licenseTransferId}/declinebytargetcompany`);
  }

  processLicenseTransfer(companyId: number, licenseTransfer: LicenseTransfer): Observable<void> {
    return this.apiService.post(
      `/companies/${companyId}/licensetransfers/${licenseTransfer.id}/process`,
      licenseTransfer
    );
  }

  getProcessableLicenseTransferListForCompany(companyId: number): Observable<ListResult<LicenseTransfer[]>> {
    return this.apiService.getList(`/companies/${companyId}/licensetransfers`);
  }

  getLicenseTransferForCompany(companyId: number, licenseTransferId: number): Observable<LicenseTransfer> {
    return this.apiService.get(`/companies/${companyId}/licensetransfers/${licenseTransferId}`);
  }
}
