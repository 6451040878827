import { inject, Injectable, Pipe, PipeTransform } from '@angular/core';

import { FeatureFlagService } from '../../core/services';

@Pipe({
  name: 'featureFlag',
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagPipe implements PipeTransform {
  private readonly featureFlagService = inject(FeatureFlagService);

  transform(flag: string): boolean {
    return this.featureFlagService.flagActivated(flag);
  }
}
