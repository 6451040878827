<account-card
  class="min-w-[300px] pt-4 pr-4 pl-4 pb-4 !mb-0 !mr-0 !ml-0"
  [showHeader]="false"
  [contentHasPadding]="false"
  [hasBorder]="false">
  <card-body class="flex flex-col gap-2 border-l-4 border-blue-400 bg-gray-900 pt-4 pb-4 pr-6 pl-6 rounded">
    <div class="flex flex-row gap-2 items-center">
      <account-sw-icon icon="guide" variant="solid" size="16px" />
      <h4 class="typo-body-inter-18px-bold text-inverted">{{ 'COMPANY.ONBOARDING.NAVIGATION.TITLE' | translate }}</h4>
    </div>

    @if (onboardingSteps$ | async; as onboardingSteps) {
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <span class="typo-body-inter-14px-regular text-inverted">
            {{
              'COMPANY.ONBOARDING.NAVIGATION.PROGRESS'
                | translate
                  : { percentage: ((getProgressBarValue(onboardingSteps) / onboardingSteps.length) * 100).toFixed() }
            }}
          </span>
          <account-progress-bar
            [maxValue]="onboardingSteps.length"
            [value]="getProgressBarValue(onboardingSteps)"
            [showValue]="false"
            [height]="8" />
        </div>

        <div class="flex flex-col gap-2">
          @for (onboardingStep of onboardingSteps; track onboardingStep) {
            <account-navigation-onboarding-step
              [index]="$index + 1"
              [heading]="onboardingStep.navigationTitle | translate"
              [completed]="onboardingStep.isCompleted"
              (action)="onboardingStep.action()" />
          }
        </div>
      </div>
    }
  </card-body>
</account-card>
