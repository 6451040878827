import { CompaniesFacade, LanguagesFacade } from '@account/core/facades';
import { Company, Language } from '@account/core/models';
import { CurrencyPipe } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  private readonly translateFacade = inject(TranslateService);
  private readonly companiesFacade = inject(CompaniesFacade);
  private readonly languagesFacade = inject(LanguagesFacade);

  getCurrencySymbol(): string {
    let symbol = '';

    this.companiesFacade
      .getCompany()
      .pipe(take(1))
      .subscribe({
        next: (company: Company) => {
          symbol = company.currency.isoCode === 'EUR' ? '€' : '$';
        },
      });

    return symbol;
  }

  getCurrencyLabel(): string {
    let label = '';

    this.companiesFacade
      .getCompany()
      .pipe(take(1))
      .subscribe({
        next: (company: Company) => {
          label = this.translateFacade.instant(`COMMON.CURRENCY.${company.currency.isoCode}`);
        },
      });

    return label;
  }

  /**
   * get localized name of day in week (day zero is monday)
   * @param day
   */
  getDayOfWeek(day: number): string {
    switch (day) {
      case 0:
        return this.translateFacade.instant('COMMON.LABEL.WEEKDAY.MONDAY');

      case 1:
        return this.translateFacade.instant('COMMON.LABEL.WEEKDAY.TUESDAY');
      case 2:
        return this.translateFacade.instant('COMMON.LABEL.WEEKDAY.WEDNESDAY');
      case 3:
        return this.translateFacade.instant('COMMON.LABEL.WEEKDAY.THURSDAY');
      case 4:
        return this.translateFacade.instant('COMMON.LABEL.WEEKDAY.FRIDAY');
      case 5:
        return this.translateFacade.instant('COMMON.LABEL.WEEKDAY.SATURDAY');
      case 6:
        return this.translateFacade.instant('COMMON.LABEL.WEEKDAY.SUNDAY');
      default:
        throw new Error('Only 0 - 6 are valid days of week');
    }
  }

  getLocaleCurrency(
    value: any,
    currency = '',
    symbolDisplay: boolean | 'symbol' | 'code' | 'symbol-narrow' = 'symbol',
    digits: string = null
  ): string {
    let langKey = '';

    this.languagesFacade
      .getSelectedLanguage()
      .pipe(take(1))
      .subscribe((language: Language) => (langKey = language.key));

    if (currency === '') {
      this.companiesFacade
        .getCompany()
        .pipe(take(1))
        .subscribe((company: Company) => (currency = company.currency.isoCode));
    }

    const localeCurrency: CurrencyPipe = new CurrencyPipe(langKey);
    return localeCurrency.transform(value, currency, symbolDisplay, digits);
  }
}
