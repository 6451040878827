import { companyActions } from '@account/core/store/actions';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

import { LicenseTransferGateway } from '../../gateways/licenses/license-transfer.gateway';
import { CompanyAllocations, LicenseTransfer, LicenseTransferCollisions, ListResult } from '../../models';
import { RootState } from '../../store/root.state';
import { CompaniesFacade } from '../company';

@Injectable({
  providedIn: 'root',
})
export class LicenseTransferFacade {
  private readonly store = inject<Store<RootState>>(Store<RootState>);
  private readonly companiesFacade = inject(CompaniesFacade);
  private readonly licenseTransferGateway = inject(LicenseTransferGateway);
  // creator calls

  addLicenseTransfer(licenseTransfer: LicenseTransfer): Observable<LicenseTransfer> {
    return this.licenseTransferGateway.addLicenseTransfer(licenseTransfer);
  }

  getUnprocessedLicenseTransferForShop(shopId: number, licenseTransferId: number): Observable<null | LicenseTransfer> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.licenseTransferGateway.getUnprocessedLicenseTransferForShop(companyId, shopId, licenseTransferId)
        )
      );
  }

  getUnprocessedLicenseTransferListForShop(shopId: number): Observable<LicenseTransfer[]> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.licenseTransferGateway.getUnprocessedLicenseTransferListForShop(companyId, shopId)
        )
      );
  }

  // receiver calls

  checkForCollisions(
    licenseTransfer: LicenseTransfer,
    shopId: number,
    companyId: number
  ): Observable<LicenseTransferCollisions> {
    return this.licenseTransferGateway.checkForCollisions(licenseTransfer, shopId, companyId);
  }

  checkIntegrity(companyId: number, licenseTransferId: number): Observable<void> {
    return this.licenseTransferGateway.checkIntegrity(companyId, licenseTransferId);
  }

  declineLicenseTransfer(licenseTransferId: number): Observable<void> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.licenseTransferGateway.declineLicenseTransfer(companyId, licenseTransferId)
        )
      );
  }

  processLicenseTransfer(licenseTransfer: LicenseTransfer): Observable<void> {
    return this.companiesFacade.getAllocations().pipe(
      take(1),
      switchMap((allocations: CompanyAllocations) => {
        const processTransfer$ = this.companiesFacade
          .getCompanyIdOnce()
          .pipe(
            switchMap((companyId: number) =>
              this.licenseTransferGateway.processLicenseTransfer(companyId, licenseTransfer)
            )
          );
        if (allocations.hasShops) {
          return processTransfer$;
        }
        return processTransfer$.pipe(
          tap(() => this.store.dispatch(companyActions.allocations.refreshCompanyAllocations()))
        );
      })
    );
  }

  getProcessableLicenseTransferListForCompany(): Observable<ListResult<LicenseTransfer[]>> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.licenseTransferGateway.getProcessableLicenseTransferListForCompany(companyId)
        )
      );
  }

  getLicenseTransferForCompany(licenseTransferId: number): Observable<LicenseTransfer> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.licenseTransferGateway.getLicenseTransferForCompany(companyId, licenseTransferId)
        )
      );
  }
}
