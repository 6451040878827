import { createSelector } from '@ngrx/store';

import { RootState } from '../root.state';
import { CompanyOnboardingState } from './onboarding.state';

export const onboardingState = (state: RootState): CompanyOnboardingState => state.onboarding;

export const getOnboardingStatus = createSelector(onboardingState, (state: CompanyOnboardingState) => state.status);

export const getOnboardingData = createSelector(onboardingState, (state: CompanyOnboardingState) => state.data);
