<account-plan-booking-modal-base
  [modalData]="modalData"
  [commercialPlansFn]="commercialPlansFn"
  [heading]="'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.HEADLINE' | translate"
  [skipLabel]="'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.SKIP_BOOKING_LABEL' | translate"
  (onClose)="close()"
  (onCreate)="create($event)"
  (onSalesContact)="close()">
  <ng-template let-form>
    <div class="plan-booking" [formGroup]="form.get('shopSelection')">
      <div [class.loading]="shopsLoading" class="a_control">
        <div class="control_description">
          <strong>{{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.WHICH_STORE' | translate }}</strong>
          <account-helptext [content]="'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.SHOP_SELECT_TOOLTIP' | translate" />
        </div>
        <div class="control_content a_select">
          <select class="a_select" formControlName="preselectedShop" [compareWith]="compareByShopId">
            <option [ngValue]="null" disabled>
              {{ 'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.MIGRATION_SHOP_SELECT' | translate }}
            </option>
            <option *ngFor="let selectableShop of selectableShops" [ngValue]="selectableShop">
              {{ selectableShop.domain }}
            </option>
          </select>
        </div>
        <p class="normal-nums"
          *ngIf="form.get('shopSelection.preselectedShop').value"
          [innerHTML]="
            'SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.DOMAIN_NAME' | translate: { domain: getMigrationDomain(form) }
          "></p>
      </div>
    </div>
  </ng-template>
</account-plan-booking-modal-base>
