import { AuthFacade, ProducersFacade } from '@account/core/facades';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Producer } from '../models';
import { AuthGuard } from './auth.guard';

@Injectable({
  providedIn: 'root',
})
export class ProducerGuard {
  private readonly authGuard = inject(AuthGuard);
  private readonly authFacade = inject(AuthFacade);
  private readonly producersFacade = inject(ProducersFacade);
  private readonly router = inject(Router);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authGuard.canActivate(route, state).pipe(
      switchMap((result: boolean | UrlTree) => {
        if (!result || result instanceof UrlTree) {
          return of(result);
        }
        return this.producersFacade.getProducer().pipe(
          map((producer: Producer) => {
            if (
              producer.cancelledContract?.status.name === 'completed' &&
              state.url !== '/producer/producercontractcancelled'
            ) {
              return this.router.parseUrl('producer/producercontractcancelled');
            }
            return true;
          })
        );
      })
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
}
