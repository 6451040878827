import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { CardBodyDirective, CardComponent, CardFooterDirective } from '../card';
import { IntroComponent } from './intro/intro.component';
import { PaginationComponent } from './pagination/pagination.component';
import { StepComponent } from './step/step.component';
import { StepsComponent } from './steps/steps.component';
import { SummaryComponent } from './summary/summary.component';

@Component({
  selector: 'account-wizard',
  templateUrl: './wizard.component.html',
  styleUrl: './wizard.component.less',
  imports: [CommonModule, MatTooltipModule, TranslateModule, PaginationComponent, CardComponent],
  standalone: true,
})
export class WizardComponent implements AfterContentInit, AfterViewChecked {
  private readonly elementRef = inject(ElementRef);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  @Input() formGroup: UntypedFormGroup;
  @Input() icon: string;
  @Input() heading: string;
  @Input() disabled = false;
  @Input() disabledTitle: string;
  @Input() contentHasPadding = true;
  @Input() contentIsScrollable = false;
  @Input() labelStartButton: string;
  @Input() labelCancelButton: string;
  @Input() labelCompleteButton: string;
  @Input() showProgressBar = true;
  @Input() preserveStateAfterCompletion = false;

  @Output() readonly onStepChanged: EventEmitter<StepComponent> = new EventEmitter<StepComponent>();
  @Output() readonly onStart = new EventEmitter<void>();
  @Output() readonly onAbort = new EventEmitter<void>();
  @Output() readonly onCompleted = new EventEmitter<any>();

  @ContentChild(IntroComponent, { static: true }) wizardIntro: IntroComponent;
  @ContentChild(SummaryComponent, { static: true }) wizardSummary: SummaryComponent;
  @ContentChild(StepsComponent, { static: true }) stepWrapper: StepsComponent;
  @ContentChildren(StepComponent, { descendants: true }) wizardSteps: QueryList<StepComponent>;

  @ViewChild(CardBodyDirective, { read: ElementRef, static: true }) cardBody: ElementRef;
  @ViewChild(CardFooterDirective, { read: ElementRef, static: true }) cardFooter: ElementRef;
  @ViewChild(PaginationComponent) pagination: PaginationComponent;

  showButtonLabels = true;

  private readonly breakpointWidthButtons = 560;
  private containerWidth: number;

  private _initialized = false;
  private _initialConfiguration = false;
  private _started = false;
  private _wizardSteps: StepComponent[] = [];

  private introOnStartSubscription: Subscription;

  ngAfterContentInit(): void {
    this._initialized = true;
    this._wizardSteps = this.wizardSteps.toArray();
    if (this._wizardSteps.length === 0) {
      throw new Error('Wizard has zero steps, at least one step is required');
    }
    this.initialActiveStep.active = true;
    if (!this.wizardSummary && (!this.wizardIntro || !this.initialConfiguration)) {
      this.started = true;
    }
    if (this.wizardIntro && this.initialConfiguration) {
      this.introOnStartSubscription = this.wizardIntro.onStarted.subscribe(() => {
        this.started = true;
      });
      this.wizardIntro.active = true;
      if (this.wizardSummary) {
        this.wizardSummary.active = false;
      }
    }
    if (this.wizardSummary && !this.initialConfiguration) {
      this.wizardSummary.active = true;
      this.wizardIntro.active = false;
    }

    this.stepWrapper.showSteps = this.started;
  }

  ngAfterViewChecked(): void {
    if (!this.containerWidth) {
      this.containerWidth = this.elementRef.nativeElement.clientWidth;
      if (this.containerWidth < this.breakpointWidthButtons) {
        this.showButtonLabels = false;
      }
    }
  }

  @Input() set initialConfiguration(initialConfiguration: boolean) {
    this._initialConfiguration = initialConfiguration;
    if (this.initialConfiguration) {
      if (!this.introOnStartSubscription) {
        // create subscription for intro to start the wizard if it does not already exists
        this.introOnStartSubscription = this.wizardIntro.onStarted.subscribe(() => {
          this.started = true;
        });
      }

      this.started = false;
    }
  }

  get initialConfiguration(): boolean {
    return this._initialConfiguration;
  }

  @Input()
  set started(started: boolean) {
    if (this.disabled) {
      return;
    }
    if (this._started !== started) {
      this._started = started;
      if (this._initialized) {
        this.changeDetectorRef.detectChanges();
      }
      if (this._started) {
        this.startWizard();
        this.onStart.emit();
      } else {
        this.stopWizard();
      }
    }
  }

  get started(): boolean {
    return this._started;
  }

  get steps(): StepComponent[] {
    return this._wizardSteps.filter((step) => !step.hidden);
  }

  submit(): void {
    this.initialConfiguration = false;
    this.pagination.activeStep.formGroup.markAsTouched();
    const formResult: Record<string, any> = {};
    if (this.formGroup) {
      for (const key of Object.keys(this.formGroup.controls)) {
        if (this.formGroup.controls[key].touched) {
          formResult[key] = this.formGroup.get(key).value;
        }
      }
    }
    this.onCompleted.emit(formResult);
    this.pagination.activeStep.onComplete.emit();
    this.pagination.activeStep.active = false;
    this.started = false;
  }

  next(): void {
    if (this.pagination.hasNextStep) {
      if (undefined === this.pagination.activeStep.nextStepAsyncConfirm$) {
        this.goToNextStep();
      } else {
        this.pagination.activeStep.nextStepAsyncConfirm$.pipe(take(1)).subscribe((confirmed: boolean) => {
          if (confirmed) {
            this.goToNextStep();
          }
        });
      }
    }
  }

  previous(): void {
    if (this.pagination.hasPreviousStep) {
      if (undefined === this.pagination.activeStep.previousStepAsyncConfirm$) {
        this.goToPreviousStep();
      } else {
        this.pagination.activeStep.previousStepAsyncConfirm$.pipe(take(1)).subscribe((confirmed: boolean) => {
          if (confirmed) {
            this.goToPreviousStep();
          }
        });
      }
    }
  }

  abort(): void {
    this.started = false;
    this.onAbort.emit();
  }

  complete(): void {
    if (undefined === this.pagination.activeStep.nextStepAsyncConfirm$) {
      this.completeWizard();
    } else {
      this.pagination.activeStep.nextStepAsyncConfirm$.pipe(take(1)).subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.completeWizard();
        }
      });
    }
  }

  protected goToNextStep(): void {
    const nextStep: StepComponent = this.steps[this.pagination.activeStepIndex + 1];
    if (this.pagination.activeStep.formGroup) {
      this.pagination.activeStep.formGroup.markAsTouched();
    }
    this.pagination.activeStep.onNext.emit();
    this.pagination.activeStep = nextStep;
    this.pagination.activeStep.onInit.emit();
  }

  protected goToPreviousStep(): void {
    const prevStep: StepComponent = this.steps[this.pagination.activeStepIndex - 1];
    this.pagination.activeStep.onPrev.emit();
    if (this.pagination.activeStep.formGroup) {
      this.pagination.activeStep.formGroup.markAsUntouched();
    }
    this.pagination.activeStep = prevStep;
    this.pagination.activeStep.onInit.emit();
  }

  protected completeWizard(): void {
    this.initialConfiguration = false;
    if (this.pagination.activeStep.formGroup) {
      this.pagination.activeStep.formGroup.markAsTouched();
    }
    const formResult: Record<string, any> = {};
    if (this.formGroup) {
      for (const key of Object.keys(this.formGroup.controls)) {
        if (this.formGroup.controls[key].touched) {
          formResult[key] = this.formGroup.get(key).value;
        }
      }
    }

    this.onCompleted.emit(formResult);
    this.pagination.activeStep.onNext.emit();
    this.pagination.activeStep.onComplete.emit();
    if (!this.preserveStateAfterCompletion) {
      this.started = false;
    }
  }

  private startWizard(): void {
    if (this.wizardIntro) {
      this.wizardIntro.active = false;
    }
    if (this.wizardSummary) {
      this.wizardSummary.active = false;
    }
    this.stepWrapper.showSteps = true;
    if (this._wizardSteps.length > 0) {
      this.initialActiveStep.onInit.emit();
    }
  }

  private stopWizard(): void {
    if (this.wizardIntro && this.initialConfiguration) {
      this.wizardIntro.active = true;
      if (this.wizardSummary) {
        this.wizardSummary.active = false;
      }
    } else if (this.wizardSummary) {
      this.wizardSummary.active = true;
      this.wizardIntro.active = false;
    }
    if (this.pagination) {
      // this.pagination.activeStep.active = false;
      this.pagination.activeStep = this.initialActiveStep;
    }
    if (this._wizardSteps[0]) {
      this._wizardSteps[0].active = true;
    }

    this.stepWrapper.showSteps = false;
  }

  private get initialActiveStep(): StepComponent {
    return this._wizardSteps.find((step: StepComponent) => !step.hidden);
  }
}
