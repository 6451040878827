import { inject, Injectable } from '@angular/core';
import { Configuration, FrontendApi, Session } from '@ory/client';
import { from, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { EnvironmentService } from '../environment';

@Injectable({
  providedIn: 'root',
})
export class OrySessionService {
  private readonly environmentService = inject(EnvironmentService);
  private session: Session | null = null;
  private oryJwtSessionToken: string | null = null;

  isLoggedIn(): Observable<string | null> {
    const basePath = this.environmentService.current.auth_api_url;
    const ory = new FrontendApi(
      new Configuration({
        basePath,
        baseOptions: {
          withCredentials: true,
        },
      })
    );

    return from(
      ory
        .toSession({ tokenizeAs: 'shopware_account' })
        .then((response): Session => response.data)
        .catch(() => null)
    ).pipe(
      switchMap((session: Session | null) => {
        if (null === session) {
          return of(null);
        }
        this.session = session;
        this.oryJwtSessionToken = session.tokenized ?? null;

        return of(this.oryJwtSessionToken);
      }),
      catchError(() => of(null))
    );
  }

  getToken(): string | null {
    return this.oryJwtSessionToken;
  }

  destroyToken(): void {
    this.oryJwtSessionToken = null;
    this.session = null;
  }

  isVerified(): boolean {
    if (!this.session) {
      return false;
    }

    return this.session.identity.verifiable_addresses[0].status === 'completed';
  }

  getEmail(): string | null {
    if (!this.session) {
      return null;
    }

    return this.session.identity.traits.email;
  }
}
