import { createSelector } from '@ngrx/store';

import { SbpException, UserAccount } from '../../../models';
import * as fromMeta from '../useraccount-meta.reducer';
import { UserAccountState } from './useraccount.reducer';

const userAccountState = createSelector(fromMeta.state, (state: fromMeta.State): UserAccountState => state.userAccount);

export const isLoading = createSelector(userAccountState, (state: UserAccountState) => state.loading);

export const isLoaded = createSelector(userAccountState, (state: UserAccountState) => state.loaded);

export const getError = createSelector(userAccountState, (state: UserAccountState) => state.error);

export const hasError = createSelector(getError, (error: SbpException | undefined) => !!error);

export const getUserAccount = createSelector(userAccountState, (state: UserAccountState) => state.userAccount);

export const isBanned = createSelector(getUserAccount, (userAccount: UserAccount) =>
  userAccount ? userAccount.banned : false
);
