import { BankAccount } from '@account/core/models';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ModalService } from '../../../../shared/components/modal/modal.service';
import { ModalConfig } from '../../../../shared/components/modal/modal-config.model';
import { LocaleCurrencyPipe, SafePipe } from '../../../../shared/pipes';
import { BankAccountModalComponent } from './bank-account-modal';

@Component({
  selector: 'account-company-bank-account',
  templateUrl: './company-bank-account.component.html',
  styleUrl: './company-bank-account.component.less',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, TranslateModule, LocaleCurrencyPipe, SafePipe],
})
export class CompanyBankAccountComponent {
  private readonly modalService = inject(ModalService);
  private readonly translateService = inject(TranslateService);
  @Input() bankAccount: BankAccount;
  @Input() undisbursedBalance: number;
  @Input() hasPermission = true;
  @Output() readonly bankAccountChanged = new EventEmitter<BankAccount>();

  openBankDataModal(): void {
    const modalConfig: ModalConfig = {
      data: {
        bankData: this.bankAccount,
        heading: this.translateService.instant('ACCOUNTING.BANK_ACCOUNT.MODAL.HEADLINE'),
        infoText: this.translateService.instant('ACCOUNTING.BANK_ACCOUNT.MODAL.INFO.TEXT'),
      },
    };
    const modalRef = this.modalService.open(BankAccountModalComponent, modalConfig);
    modalRef.afterClosed().subscribe((bankData: BankAccount) => {
      if (bankData) {
        this.bankAccount = bankData;
        this.bankAccountChanged.emit(bankData);
      }
    });
  }

  get amountTypeClass(): string {
    return this.undisbursedBalance > 0 ? 'positive' : this.undisbursedBalance === 0 ? 'neutral' : 'negative';
  }
}
