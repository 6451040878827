import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CheckboxComponent } from '../../../../../../shared/components/checkbox/checkbox.component';

@Component({
  selector: 'domain-verification-license-gtc-block',
  templateUrl: './license-gtc-element.component.html',
  styleUrl: './license-gtc-element.component.less',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf, TranslateModule, CheckboxComponent],
})
export class LicenseGtcElementComponent {
  @Input() form: UntypedFormGroup;
  @Input() domainWithLicensesToTerminate: string;
}
