<div
  class="avatar-group"
  [class.large-group]="total > 5"
  [matTooltip]="tooltip"
  [matTooltipDisabled]="!showTooltip"
  matTooltipShowDelay="300"
  matTooltipClass="list width-max-content">
  <div *ngFor="let avatarInfo of displayedAvatars; let index = index">
    <account-avatar
      *ngIf="!showUnknownAvatar(avatarInfo); else showUnknown"
      [initials]="avatarInfo.initials"
      [realName]="avatarInfo.realName"
      [height]="height"
      [style.left.px]="-8 * index"
      [style.z-index]="displayedAvatars.length - index" />
    <ng-template #showUnknown>
      <account-avatar
        class="unknown-avatar"
        [initials]="'?'"
        [height]="height"
        [style.left.px]="-8 * index"
        [style.z-index]="displayedAvatars.length - index" />
    </ng-template>
  </div>
</div>
