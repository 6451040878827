import { NgIf } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { LocalStorageService } from '../../../core/services';
import { EmptyStateComponent } from '../empty-state/empty-state.component';

@Component({
  selector: 'account-no-access',
  templateUrl: './no-access.component.html',
  styleUrl: './no-access.component.less',
  standalone: true,
  imports: [EmptyStateComponent, NgIf, RouterLink, TranslateModule],
})
export class NoAccessComponent implements OnInit, OnDestroy {
  private readonly route = inject(ActivatedRoute);
  private readonly localStorageService = inject(LocalStorageService);
  heading: string;
  subheading: string;
  returnUrl: string;
  goToPortal = false;

  constructor() {
    this.subheading =
      this.localStorageService.getItem('no-access-message') ?? 'CORE.NO_ACCESS.SUBHEADING.PERMISSION.DEFAULT';
  }

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;
    if ('returnUrl' in queryParams) {
      this.returnUrl = queryParams['returnUrl'];
    } else if ('toPortal' in queryParams) {
      this.goToPortal = true;
    }
  }

  ngOnDestroy(): void {
    this.localStorageService.removeItem('no-access-message');
  }
}
