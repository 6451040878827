import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StatisticsGateway } from '../../gateways/common/statistics.gateway';
import { ListResult } from '../../models';
import { GraphModel } from '../../models/common/graph.model';

@Injectable({
  providedIn: 'root',
})
export class StatisticsFacade {
  private readonly statisticsGateway = inject(StatisticsGateway);

  getShopwareVersionDistributionForPlugin(pluginId: number): Observable<ListResult<GraphModel[]>> {
    return this.statisticsGateway.getShopwareVersionDistributionForPlugin(pluginId);
  }

  getBinaryDownloadCount(pluginId: number, binaryId: number): Observable<number> {
    return this.statisticsGateway.getBinaryDownloadCount(pluginId, binaryId);
  }
}
