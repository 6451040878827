<ng-container *ngFor="let item of breadcrumbs; let first = first; let last = last">
  <span *ngIf="first && breadcrumbs.length > 1">
    <a (click)="goToPortal()">
      <account-sw-icon icon="home" size="16px" />
    </a>
    <div *ngIf="!item.url.startsWith('/portal')" class="divider">/</div>
  </span>

  <span *ngIf="item.label" [class.is--last]="last" [matTooltip]="item.label | translate" [matTooltipShowDelay]="600">
    <span *ngIf="!first && item.label.length > 0" class="divider">/</span>
    <a [class.is--last]="last" [routerLink]="item.url">{{ item.label | translate }}</a>
  </span>
</ng-container>
