import { createAction, props } from '@ngrx/store';

import { CompanyOnboardingDTO } from '../../models/onboarding/onboarding.model';
import { CompanyOnboardingState } from './onboarding.state';

export const setOnboardingDataForCompany = createAction('[Onboarding] set onboarding data');
export const setOnboardingDataForCompanySuccess = createAction(
  '[Onboarding] set company onboarding data success',
  props<{ payload: { companyOnboardingState: CompanyOnboardingState | null } }>()
);
export const updateOnboardingDataForCompanyByDTO = createAction(
  '[Onboarding] update company onboarding data by dto',
  props<{ payload: { companyOnboardingDTO: CompanyOnboardingDTO } }>()
);

export const resetOnboardingData = createAction('[Onboarding] reset onboarding data');
