import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SubscriptionBasketItem } from '../../models/shop/subscription-assistant.model';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionAssistantGateway {
  private readonly apiService = inject(ApiService);

  getSubscriptionBasket(shopId: number, partnerId: number = null): Observable<SubscriptionBasketItem[]> {
    let params = new HttpParams();
    if (partnerId) {
      params = params.set('partnerId', partnerId.toString());
    }
    return this.apiService.get(`/shops/${shopId}/subscriptionBasket`, params);
  }

  proceedSubscriptionBasket(
    shopId: number,
    payload: {
      totalAmount: number;
      subscriptionBasket: SubscriptionBasketItem[];
      bookingDomain: string;
      domain: string;
      partnerId: number;
    }
  ): Observable<void> {
    return this.apiService.post(`/shops/${shopId}/subscriptionBasket`, payload);
  }
}
