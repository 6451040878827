import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NotificationItem } from '../../models';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class NotificationsGateway {
  private readonly apiService = inject(ApiService);

  getNotifications(companyId: number): Observable<NotificationItem[]> {
    return this.apiService.get(`/companies/${companyId}/notifications`);
  }

  deleteNotifications(companyId: number, id: number = null): Observable<void> {
    let params = new HttpParams();
    if (id !== null) {
      params = params.append('id', id.toString());
    }
    return this.apiService.delete(`/companies/${companyId}/notifications`, params);
  }
}
