import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ListResult,
  PluginReply,
  PluginReview,
  PluginReviewHistory,
  PluginReviewInformationSet,
  Producer,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { ApiService } from '../../services';
import { HttpParamsBuilder } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class PluginReviewsGateway {
  private readonly apiService = inject(ApiService);

  getPluginReviewsList(
    id: number,
    asProducer: boolean,
    metaData: RequestMetaData,
    filter: object = {},
  ): Observable<ListResult<PluginReview[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);

    if (asProducer) {
      params = params.set('producerId', id.toString());
    } else {
      params = params.set('userId', id.toString());
    }

    params = HttpParamsBuilder.applyFilters(params, filter);

    return this.apiService.getList('/plugincomments', params);
  }

  getPluginReview(id: number): Observable<PluginReview> {
    return this.apiService.get(`/plugincomments/${id}`);
  }

  addPluginReview(pluginId: number, review: PluginReview, pluginLicenseOwnerId?: number): Observable<PluginReview> {
    let url = `/plugins/${pluginId.toString()}/comments`;
    if (pluginLicenseOwnerId) {
      url += `?licenseOwnerId=${pluginLicenseOwnerId.toString()}`;
    }
    return this.apiService.post(url, review);
  }

  updatePluginReview(companyId: number, review: PluginReview): Observable<PluginReview> {
    return this.apiService.put(`/companies/${companyId}/plugincomments/${review.id}`, review);
  }

  sendPrivatePluginReviewMessageToProducer(
    companyId: number,
    review: PluginReview,
    message: string,
  ): Observable<PluginReviewHistory> {
    return this.apiService.post(`/companies/${companyId}/plugincomments/${review.id}/message`, { message: message });
  }

  sendPrivatePluginReviewMessageToEvaluator(
    producer: Producer,
    review: PluginReview,
    message: string,
  ): Observable<PluginReviewHistory> {
    return this.apiService.post(`/producer/${producer.id}/plugincomments/${review.id}/message`, { message: message });
  }

  addPluginReviewReply(producer: Producer, review: PluginReview, text: string): Observable<PluginReply> {
    return this.apiService.post(`/producer/${producer.id}/plugincomments/${review.id}/reply`, { text: text });
  }

  updatePluginReviewReply(
    producer: Producer,
    review: PluginReview,
    reply: PluginReply,
    text: string,
  ): Observable<PluginReply> {
    return this.apiService.put(`/producer/${producer.id}/plugincomments/${review.id}/replies/${reply.id}`, {
      text: text,
    });
  }

  getPluginReviewInformationSet(companyId: number, technicalName: string): Observable<PluginReviewInformationSet> {
    return this.apiService.get(`/companies/${companyId}/commentinformationset/${technicalName}`);
  }
}
