<div class="item-image" [style.background-image]="'url(' + iconUrl + ')'"></div>
<div class="item-info">
  <ng-content *ngIf="cartItemContent !== undefined; else useProps" select="cart-item-content" />
  <ng-template #useProps>
    <account-overflow-tooltip class="title" [content]="title" [useMaxWidth]="true" />
    <br />
    <account-overflow-tooltip class="subtitle" [content]="subtitle" [useMaxWidth]="true" />
  </ng-template>
</div>
<div class="item-config">
  <ng-content select="cart-item-config" />
</div>
<div class="item-removal" (click)="onRemove.emit()">
  <account-sw-icon icon="times-s" size="10px" />
</div>
