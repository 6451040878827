import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { combineLatest, EMPTY, forkJoin, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, shareReplay, startWith, takeUntil } from 'rxjs/operators';

import {
  BEYOND,
  CommercialPlan,
  CompaniesFacade,
  EVOLVE,
  RISE,
  SelfHostedShop,
  SelfServiceContractPriceInfos,
  Shop,
  ShopsFacade,
  ShopUpgradeCommercialPlanBookingInformation,
  ShopUpgradeSalesInformation,
  UpgradeToCommercialPlanFacade,
  UserAccount,
  UserAccountsFacade,
} from '../../../../core';
import { CheckboxComponent } from '../../../../shared/components/checkbox/checkbox.component';
import { IncrementalSelectComponent } from '../../../../shared/components/incremental-select/incremental-select.component';
import { ModalComponent } from '../../../../shared/components/modal';
import { SwIconComponent } from '../../../../shared/components/sw-icon';
import { TileSelectComponent } from '../../../../shared/components/tile-select/tile-select.component';
import { ToastService } from '../../../../shared/components/toast/toast.service';
import { LoadingSpinnerDirective } from '../../../../shared/directives/loading-spinner.directive';
import { AbstractControlAsFormGroup } from '../../../../shared/pipes/form.pipe';
import { FileSaveService } from '../../../../shared/services/file-save.service';
import { TrackingDataService } from '../../../../shared/services/tracking-data.service';
import { PlanTileContentComponent } from './plan-tile-content/plan-tile-content.component';

export interface PlanBookingConfig {
  preselectedShop?: SelfHostedShop;
  selectableShops?: SelfHostedShop[];
  partnerCustomer?: boolean;
  campaign?: string;
  intent?: 'migration' | 'upgrade' | 'registration';
  shopCompanyId?: number;
  domain?: string;
}

interface ShopSelectionGroup {
  preselectedShop: FormControl<SelfHostedShop | null>;
  domain: FormControl<string>;
}

interface PlanBookingGroup {
  plan: FormControl<CommercialPlan>;
  interval: FormControl<1 | 12>;
  gmv: FormControl<number>;
  contract: FormControl<boolean>;
  additionalInformation: FormControl<string>;
  skipBooking: FormControl<boolean>;
}

export interface ShopPlanBookingForm {
  shopSelection: FormGroup<ShopSelectionGroup>;
  planBooking: FormGroup<PlanBookingGroup>;
}

@Component({
  selector: 'account-plan-booking-modal-base',
  templateUrl: './plan-booking-modal-base.component.html',
  styleUrl: './plan-booking-modal-base.component.less',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    FormsModule,
    ReactiveFormsModule,
    AsyncPipe,
    TranslateModule,
    AbstractControlAsFormGroup,
    ModalComponent,
    IncrementalSelectComponent,
    CheckboxComponent,
    NgTemplateOutlet,
    TileSelectComponent,
    PlanTileContentComponent,
    SwIconComponent,
    LoadingSpinnerDirective,
  ],
  preserveWhitespaces: false,
})
export class PlanBookingModalBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly companiesFacade = inject(CompaniesFacade);
  private readonly formBuilder = inject(FormBuilder);
  private readonly translateService = inject(TranslateService);
  private readonly toastService = inject(ToastService);
  private readonly upgradeToCommercialPlanFacade = inject(UpgradeToCommercialPlanFacade);
  private readonly trackingDataService = inject(TrackingDataService);
  private readonly shopsFacade = inject(ShopsFacade);
  private readonly fileSaveService = inject(FileSaveService);
  private readonly userAccountFacade = inject(UserAccountsFacade);
  protected form: FormGroup<ShopPlanBookingForm>;
  protected pricingIsLoading = true;

  protected planBookingIsLoading = false;

  protected selectedShop$ = new Subject<SelfHostedShop>();
  protected canSkipBookingStep$: Observable<boolean>;
  protected subscriptionRefund$ = new Subject<Observable<{ refund: number }>>();
  protected subscriptionRefund = 0;
  protected bookablePlans$: Observable<CommercialPlan[]>;
  protected isRiseTrialPhaseAllowed: boolean;
  protected calculatePlanPrices$ = new Subject<Observable<SelfServiceContractPriceInfos>[]>();
  protected calculatePlanPrices = {
    rise: new Subject<SelfServiceContractPriceInfos>(),
    evolve: new Subject<SelfServiceContractPriceInfos>(),
    beyond: new Subject<SelfServiceContractPriceInfos>(),
  };
  private shopCompanyId: number | null = null;
  private isClosedExplicitly: boolean = false;
  private isBookingInitialized: boolean = false;

  protected gmvSteps = [100000, 250000, 500000, 1000000, 2000000, 3000000, 4000000, 5000000];

  @Input({ required: true }) modalData: PlanBookingConfig;
  @Input({ required: true }) commercialPlansFn: (shop?: SelfHostedShop) => Observable<CommercialPlan[]>;
  @Input({ required: true }) heading: string;
  @Input() skipLabel: string | null = null;
  @Input() isWizardStep: boolean = false;
  @Input() isShopSelectionRequired: boolean = true;
  @Input() isGtcAcceptanceRequired: boolean = true;
  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;
  @Output() readonly onClose = new EventEmitter<void>();
  @Output() readonly onCreate = new EventEmitter<FormGroup<ShopPlanBookingForm>>();
  @Output() readonly onSalesContact = new EventEmitter<void>();

  ngOnInit(): void {
    const selectedShop = this.getPreselectedShop();
    this.createForm(selectedShop);

    const shopSelectionChange$ = this.form.get('shopSelection.preselectedShop').valueChanges.pipe(
      tap((shop: SelfHostedShop) => {
        this.subscriptionRefund = 0;
        this.selectedShop$.next(shop);
        this.form.get('shopSelection.domain').enable();
        this.addTrackingEvent('bookingAssistantSelectShop');
      }),
      shareReplay()
    );

    this.canSkipBookingStep$ = shopSelectionChange$.pipe(
      startWith(this.form.get('shopSelection.preselectedShop').value),
      map((shop: SelfHostedShop) => (shop ? !shop.isCommercial && this.skipLabel !== null : true))
    );

    // if the id of the company is provided, no shop must be given to display bookable plans and prices
    // e.g. in the shop registration, when the shop has not yet been created, but available plans and prices are already foreseeable
    if (!this.isShopSelectionRequired && this.modalData.shopCompanyId) {
      this.shopCompanyId = this.modalData.shopCompanyId;
      this.bookablePlans$ = this.commercialPlansFn().pipe(shareReplay());
    } else {
      this.bookablePlans$ = this.selectedShop$.pipe(
        tap((shop: SelfHostedShop) => (this.shopCompanyId = shop.companyId)),
        tap((shop: SelfHostedShop) =>
          this.subscriptionRefund$.next(this.calculateShopMigrationSubscriptionRefund(shop))
        ),
        switchMap((shop: SelfHostedShop) => this.commercialPlansFn(shop)),
        shareReplay()
      );
    }

    this.bookablePlans$
      .pipe(
        switchMap((plans: CommercialPlan[]) =>
          combineLatest([of(plans), this.getTrialPhaseOfferedResult(), this.canSkipBookingStep$])
        ),
        shareReplay()
      )
      .subscribe(
        ([plans, isTrialPhaseOffered, canSkipBookingStep]: [CommercialPlan[], { result: boolean }, boolean]) => {
          this.isRiseTrialPhaseAllowed = isTrialPhaseOffered.result && this.shopLicense === 'CE';

          this.calculatePlanPrices$.next(
            plans
              .filter((plan: CommercialPlan) => (!this.isRiseTrialPhaseAllowed || plan.name === 'rise') && !this.showCustomPriceForBeyond(plan))
              .map((plan: CommercialPlan) =>
                this.getCurrentPricing(plan).pipe(
                  tap((price: SelfServiceContractPriceInfos) => this.calculatePlanPrices[plan.name].next(price))
                )
              )
          );

          if (!this.isBookingInitialized) {
            this.addTrackingEvent('bookingAssistantStart', {
              location:
                this.modalData.campaign === 'sw5_migration'
                  ? 'portal page'
                  : this.modalData.preselectedShop
                    ? 'shop detail page'
                    : 'shop overview page',
              trial: this.isRiseTrialPhaseAllowed ? 'active' : 'inactive',
            });
            this.isBookingInitialized = true;
          }

          const selectedPlan = plans.find(
            (plan: CommercialPlan) => plan.name === this.form.get('planBooking.plan').value?.name
          );
          this.form.get('planBooking.plan').setValue(selectedPlan, { emitEvent: false });

          this.form.get('planBooking.skipBooking').setValue(!selectedPlan && canSkipBookingStep, { emitEvent: false });
          this.onSkipBookingChange(this.isSkipBooking);

          if (selectedPlan || this.isSkipBooking) {
            return;
          }

          const preSelectedPlan =
            plans.find(
              (plan: CommercialPlan) =>
                plan.name === BEYOND && this.preselectedShop?.shopwareVersion.major !== 'Shopware 6'
            ) ??
            plans.find((plan: CommercialPlan) => plan.name === RISE && this.isRiseTrialPhaseAllowed) ??
            plans.find((plan: CommercialPlan) => plan.name === EVOLVE);
          this.form.get('planBooking.plan').setValue(preSelectedPlan, { emitEvent: false });
        }
      );

    this.subscriptionRefund$
      .pipe(switchMap((subscriptionRefund: Observable<{ refund: number }>) => subscriptionRefund))
      .subscribe((subscriptionRefund: { refund: number }) => (this.subscriptionRefund = subscriptionRefund.refund));

    this.calculatePlanPrices$
      .pipe(switchMap((planPrices$: Observable<SelfServiceContractPriceInfos>[]) => forkJoin(planPrices$)))
      .subscribe(() => (this.pricingIsLoading = false));

    this.form
      .get('planBooking.gmv')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((gmv: number) => {
        this.onGmvChange(gmv);
        this.addTrackingEvent('bookingAssistantChangeGmv');
      });

    this.form
      .get('planBooking.plan')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((plan: CommercialPlan | null) => {
        if (plan) {
          this.form.get('planBooking.skipBooking').setValue(false, { emitEvent: false });
          this.onSkipBookingChange(false);
          this.addTrackingEvent('bookingAssistantSelectPlan');
        }

        if (!this.isBookable) {
          return;
        }

        if ((this.isRiseTrialPhaseAllowed && plan?.name === 'evolve') || this.isSw6PEAndBeyondSelected) {
          this.form.get('planBooking.contract').disable({ emitEvent: false });
        } else {
          this.form.get('planBooking.contract').enable({ emitEvent: false });
        }
      });

    this.form.get('planBooking.contract').valueChanges.subscribe((checked: boolean) => {
      if (checked) {
        this.addTrackingEvent('bookingAssistantAgreeGtc');
      }
    });

    this.form.get('planBooking.skipBooking').valueChanges.subscribe((skipBooking: boolean) => {
      this.onSkipBookingChange(skipBooking);
      this.addTrackingEvent('bookingAssistantStayCE', {
        checkboxStatus: skipBooking ? 'active' : 'inactive',
      });
    });
  }

  ngAfterViewInit(): void {
    if (this.preselectedShop) {
      this.selectedShop$.next(this.preselectedShop);
    }
  }

  ngOnDestroy(): void {
    if (this.isClosedExplicitly === true) {
      return;
    }

    this.addTrackingEvent('bookingAssistantCancel');
  }

  handleBooking(): void {
    this.planBookingIsLoading = true;
    this.isClosedExplicitly = true;

    if (!this.isSkipBooking && (!this.isBookable || this.requiresSalesInformation(this.selectedPlan))) {
      this.sendInformationToSales();
      return;
    }

    this.onCreate.emit(this.form);

    this.addTrackingEvent(this.isSkipBooking ? 'bookingAssistantCEFinish' : 'bookingAssistantOrder');
  }

  sendInformationToSales(): void {
    const payload: ShopUpgradeSalesInformation = {
      domain: this.modalData.domain ?? this.preselectedShop.domain,
      planId: this.form.get('planBooking.plan').value.id,
      gmv: this.form.get('planBooking.gmv').value,
      interval: this.form.get('planBooking.interval').value,
      additionalInformation: this.form.get('planBooking.additionalInformation').value,
      shopwareMajorVersion: this.preselectedShop?.shopwareVersion.major.replace('Shopware ', 'SW'),
      shopLicense: this.shopLicense,
    };

    this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        map((companyId: number) => this.shopCompanyId === companyId),
        switchMap((isShopOwner: boolean) => {
          if (isShopOwner) {
            return this.upgradeToCommercialPlanFacade.contactSalesWithPlanInformation(this.shopCompanyId, payload);
          }

          return this.upgradeToCommercialPlanFacade.contactSalesWithPlanInformationForCustomer(
            this.shopCompanyId,
            payload
          );
        })
      )
      .subscribe({
        next: () => {
          this.planBookingIsLoading = false;
          this.onSalesContact.emit();
          this.toastService.success(
            this.translateService.instant('SHOPS.SHOP_DETAILS.UPGRADE_TO_COMMERCIAL_PLAN.TOAST.CONTACT_SALES.TITLE'),
            this.translateService.instant('SHOPS.SHOP_DETAILS.UPGRADE_TO_COMMERCIAL_PLAN.TOAST.CONTACT_SALES.MESSAGE')
          );
        },
        error: () => {
          this.planBookingIsLoading = false;
          this.toastService.error(
            this.translateService.instant(
              'SHOPS.SHOP_DETAILS.UPGRADE_TO_COMMERCIAL_PLAN.TOAST.CONTACT_SALES.ERROR.TITLE'
            ),
            this.translateService.instant(
              'SHOPS.SHOP_DETAILS.UPGRADE_TO_COMMERCIAL_PLAN.TOAST.CONTACT_SALES.ERROR.MESSAGE'
            )
          );
        },
      });
  }

  close(): void {
    this.isClosedExplicitly = true;

    this.addTrackingEvent('bookingAssistantCancel');

    this.onClose.emit();
  }

  onAddTrackingEvent(event: string): void {
    this.addTrackingEvent(event);
  }

  getGmvStepLabel(gmv: number): string {
    if (gmv >= 5000000) {
      return `${gmv / 1000000}${this.translateService.instant('SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.GMV.GTE_FIVE_MIL')}`;
    }

    if (gmv >= 1000000) {
      return `${gmv / 1000000}${this.translateService.instant('SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.GMV.GTE_ONE_MIL')}`;
    }

    return `${gmv / 1000}${this.translateService.instant('SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.GMV.LT_ONE_MIL')}`;
  }

  onGmvChange(gmv: number): void {
    if (gmv >= 5000000 || this.requiresSalesInformation(this.selectedPlan)) {
      this.form.get('planBooking.contract').disable({ emitEvent: false });

      return;
    }

    if (!this.isSkipBooking) {
      this.form.get('planBooking.contract').enable({ emitEvent: false });
    }
  }

  onSkipBookingChange(skipBooking: boolean): void {
    if (skipBooking) {
      this.form.get('planBooking.plan').setValue(null);
      this.form.get('planBooking.plan').clearValidators();
      this.form.get('planBooking.plan').updateValueAndValidity();
      this.form.get('planBooking.contract').disable({ emitEvent: false });
      this.form.get('planBooking.additionalInformation').disable();

      return;
    }

    this.form.get('planBooking.plan').addValidators([Validators.required]);
    this.form.get('planBooking.plan').updateValueAndValidity({ emitEvent: false });
    this.form.get('planBooking.additionalInformation').enable();
    this.onGmvChange(this.form.get('planBooking.gmv').value);
  }

  downloadLicenseAgreement(event: Event): void {
    if (!(event.target as HTMLElement).classList.contains('license-agreement-download')) {
      return;
    }

    event.preventDefault();

    const locale = this.translateService.currentLang === 'de' ? 'de_DE' : 'en_GB';
    const domain = this.modalData.domain || this.preselectedShop.domain;

    this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) => {
          const isShopOwner = this.shopCompanyId === companyId;
          if (isShopOwner) {
            return this.shopsFacade.getShopLicenseAgreement(this.shopCompanyId, locale);
          }

          return this.shopsFacade.getShopLicenseAgreementForCustomer(this.shopCompanyId, locale);
        })
      )
      .subscribe({
        next: (response: Blob) => {
          const filename =
            `${this.translateService.instant('SHOPS.SHOP_DETAILS.UPGRADE_TO_COMMERCIAL_PLAN.LICENSE_AGREEMENT_FILE')}${domain}.pdf`.replace(
              new RegExp(/\s/),
              '_'
            );
          this.fileSaveService.saveAs(response, filename);
          this.toastService.success(
            this.translateService.instant(
              'SHOPS.SHOP_DETAILS.UPGRADE_TO_COMMERCIAL_PLAN.DOWNLOAD_LICENSE_AGREEMENT.HEADLINE'
            ),
            this.translateService.instant(
              'SHOPS.SHOP_DETAILS.UPGRADE_TO_COMMERCIAL_PLAN.DOWNLOAD_LICENSE_AGREEMENT.DESCRIPTION'
            )
          );
        },
        error: () => {
          this.toastService.error(
            this.translateService.instant(
              'SHOPS.SHOP_DETAILS.UPGRADE_TO_COMMERCIAL_PLAN.DOWNLOAD_LICENSE_AGREEMENT.HEADLINE_ERROR'
            ),
            this.translateService.instant(
              'SHOPS.SHOP_DETAILS.UPGRADE_TO_COMMERCIAL_PLAN.DOWNLOAD_LICENSE_AGREEMENT.DESCRIPTION_ERROR'
            )
          );
        },
      });
  }

  downloadPriceIndication(): void {
    const data: ShopUpgradeCommercialPlanBookingInformation = {
      planId: this.selectedPlan.id,
      plannedGmv: this.form.get('planBooking.gmv').value,
      chargingInterval: this.form.get('planBooking.interval').value,
      licenseAgreement: true,
    };

    this.shopsFacade.getPriceIndicationForCustomer(this.shopCompanyId, this.preselectedShop.id, data).subscribe({
      next: (response: Blob) => {
        const planName = this.selectedPlan.name;
        const domain = this.preselectedShop.domain;
        const date = format(new Date(), 'yyyy_mm_dd');
        const filename = `price_indication_${planName}_${domain}_${date}.pdf`.replace(new RegExp(/\s/), '_');
        this.fileSaveService.saveAs(response, filename);

        this.addTrackingEvent('bookingAssistantDownloadPriceIndication');
      },
    });
  }

  showCustomPriceForBeyond(plan: CommercialPlan): boolean {
    if (plan.name !== 'beyond') {
      return false;
    }

    return this.shopLicense === 'PE' && this.preselectedShop?.shopwareVersion.major === 'Shopware 6';
  }

  get finishButtonLabel(): string {
    if (this.form.get('planBooking.skipBooking').value === true) {
      return this.translateService.instant('SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.CONTINUE_WITHOUT_PLAN');
    }

    if (
      this.form.get('planBooking.gmv').value >= 5000000 ||
      (this.isRiseTrialPhaseAllowed && this.selectedPlan?.name === 'evolve') ||
      this.isSw6PEAndBeyondSelected
    ) {
      return this.translateService.instant('SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.SEND_REQUEST');
    }

    return this.translateService.instant('SHOPS.SUCCESSOR.SHOP_MIGRATION_ASSISTANT.ORDER');
  }

  get selectedPlan(): CommercialPlan {
    return this.form.get('planBooking.plan').value;
  }

  get preselectedShop(): null | SelfHostedShop {
    return this.form.get('shopSelection.preselectedShop').value;
  }

  get isBookable(): boolean {
    return this.form.get('planBooking.gmv').value < 5000000;
  }

  get isSkipBooking(): boolean {
    return this.form.get('planBooking.skipBooking').value === true;
  }

  get isSw6PEAndBeyondSelected(): boolean {
    return (
      this.selectedPlan?.name === 'beyond' &&
      this.shopLicense === 'PE' &&
      this.preselectedShop?.shopwareVersion.major === 'Shopware 6'
    );
  }

  get shopLicense(): string {
    switch (this.preselectedShop?.shopwareEdition) {
      case 'Shopware Core':
      case 'Shopware Professional Plus':
        return 'PE';
      case 'Shopware Enterprise':
      case 'Shopware Enterprise Basic':
      case 'Shopware Enterprise Premium':
        return 'EE';
      default:
        return 'CE';
    }
  }

  get isPriceIndicationDisabled(): boolean {
    return (
      this.isSkipBooking ||
      !this.selectedPlan ||
      !this.isBookable ||
      (this.isRiseTrialPhaseAllowed && this.selectedPlan.name === 'evolve')
    );
  }

  private getCurrentPricing(plan: CommercialPlan): Observable<SelfServiceContractPriceInfos> {
    const planBookingForm = this.form.get('planBooking');
    const formValues = planBookingForm.getRawValue();

    const interval$ = planBookingForm.get('interval').valueChanges.pipe(startWith(formValues.interval));
    const gmv$ = planBookingForm.get('gmv').valueChanges.pipe(
      distinctUntilChanged(),
      startWith(formValues.gmv),
      filter((gmv: number) => gmv > 0 && gmv < 5000000),
      takeUntil(this.form.get('shopSelection.preselectedShop').valueChanges)
    );

    return combineLatest([interval$, gmv$]).pipe(
      tap(() => (this.pricingIsLoading = true)),
      switchMap(([interval, gmv]: [number, number]) =>
        this.companiesFacade.getCompanyIdOnce().pipe(
          switchMap((companyId: number) => {
            const isShopOwner = this.shopCompanyId === companyId;
            if (isShopOwner) {
              return this.upgradeToCommercialPlanFacade.calculateShopPriceAndDiscount(
                this.shopCompanyId,
                plan.id,
                interval,
                gmv
              );
            }

            return this.upgradeToCommercialPlanFacade.calculateShopPriceAndDiscountForCustomer(
              this.shopCompanyId,
              plan.id,
              interval,
              gmv
            );
          })
        )
      ),
      tap(() => (this.pricingIsLoading = false)),
      catchError(() => {
        this.toastService.error(
          this.translateService.instant('COMMON.TOAST.ERROR.TITLE.REQUEST_FAILED'),
          this.translateService.instant('COMMON.TOAST.ERROR.MESSAGE.REQUEST_FAILED.UNKNOWN')
        );
        this.close();

        return EMPTY;
      })
    );
  }

  protected getPreselectedShop(): null | SelfHostedShop {
    if (this.modalData.preselectedShop) {
      return this.modalData.preselectedShop;
    }
    if (this.modalData.selectableShops?.length === 1) {
      return this.modalData.selectableShops[0];
    }
    return null;
  }

  private createForm(preSelectedShop?: SelfHostedShop): void {
    this.form = this.formBuilder.group<ShopPlanBookingForm>({
      shopSelection: this.formBuilder.group<ShopSelectionGroup>({
        preselectedShop: this.formBuilder.control<SelfHostedShop>(
          { value: preSelectedShop, disabled: !this.isShopSelectionRequired },
          [Validators.required]
        ),
        domain: this.formBuilder.control<string>({ value: 'shopware-6-target', disabled: !preSelectedShop }, [
          Validators.required,
          Validators.pattern(/^[\dA-Za-zÄÖÜäöü-]+$/),
        ]),
      }),
      planBooking: this.formBuilder.group<PlanBookingGroup>({
        plan: this.formBuilder.control<CommercialPlan>(null),
        interval: this.formBuilder.control<1 | 12>(12, [Validators.required]),
        gmv: this.formBuilder.control<number>(100000, [
          Validators.required,
          Validators.min(1),
          Validators.pattern('^[0-9]*$'),
        ]),
        contract: this.formBuilder.control<boolean>(false, [Validators.requiredTrue]),
        additionalInformation: this.formBuilder.control<string>(''),
        skipBooking: this.formBuilder.control<boolean>(false),
      }),
    });
  }

  private calculateShopMigrationSubscriptionRefund(shop: Shop): Observable<{ refund: number }> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) => {
        const isShopOwner = shop.companyId === companyId;
        if (isShopOwner) {
          return this.upgradeToCommercialPlanFacade.calculateSubscriptionRefund(shop.id);
        }

        return this.upgradeToCommercialPlanFacade.calculateSubscriptionRefundForCustomer(shop.companyId, shop.id);
      }),
      catchError(() => of({ refund: 0 }))
    );
  }

  private getTrialPhaseOfferedResult(): Observable<{ result: boolean }> {
    if (!this.preselectedShop) {
      return this.upgradeToCommercialPlanFacade.isTrialPhaseOfferedInPlanBookingForNewShop();
    }

    if (this.modalData.partnerCustomer) {
      return this.upgradeToCommercialPlanFacade.isTrialPhaseOfferedInPlanBookingForCustomer(
        this.preselectedShop.companyId,
        this.preselectedShop.id
      );
    }

    return this.upgradeToCommercialPlanFacade.isTrialPhaseOfferedInPlanBooking(this.preselectedShop.id);
  }

  private addTrackingEvent(event: string, payload: Record<string, any> = {}): void {
    const bookingContext = this.modalData.intent || 'migration';
    const area = this.modalData.partnerCustomer === true ? 'partner' : 'merchant';
    const shopLicense = this.shopLicense;
    const plan = this.form.get('planBooking.plan').value?.name;
    const interval = this.form.get('planBooking.interval').value === 12 ? 'annually' : 'monthly';
    const gmv = this.form.get('planBooking.gmv').value;

    const preselectedShop = this.form.get('shopSelection.preselectedShop').value;
    const shopDomain = preselectedShop ? preselectedShop.domain : this.modalData.domain;

    this.userAccountFacade.getUserAccount().subscribe(({ email }: UserAccount) => {
      this.trackingDataService.trackData({
        event,
        payload: { area, shopLicense, plan, interval, gmv, bookingContext, shopDomain, email, ...payload },
      });
    });
  }

  private requiresSalesInformation(plan: CommercialPlan | null): boolean {
    return (this.isRiseTrialPhaseAllowed && plan?.name === 'evolve') || this.isSw6PEAndBeyondSelected;
  }
}
