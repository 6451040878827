import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { PluginReviewsGateway } from '../../gateways/plugins';
import { ListResult, PluginReply, PluginReview, PluginReviewHistory, Producer } from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { ProducersFacade } from './producers.facade';

@Injectable({
  providedIn: 'root',
})
export class PluginProducerReviewsFacade {
  private readonly producersFacade = inject(ProducersFacade);
  private readonly pluginReviewsGateway = inject(PluginReviewsGateway);

  getPluginReviewsList(metaData: RequestMetaData, filter: object = {}): Observable<ListResult<PluginReview[]>> {
    return this.getProducer().pipe(
      switchMap((producer: Producer) =>
        this.pluginReviewsGateway.getPluginReviewsList(producer.id, true, metaData, filter),
      ),
    );
  }

  getPluginReview(id: number): Observable<PluginReview> {
    return this.pluginReviewsGateway.getPluginReview(id);
  }

  sendPrivatePluginReviewMessageToEvaluator(review: PluginReview, message: string): Observable<PluginReviewHistory> {
    return this.getProducer().pipe(
      switchMap((producer: Producer) =>
        this.pluginReviewsGateway.sendPrivatePluginReviewMessageToEvaluator(producer, review, message),
      ),
    );
  }

  addPluginReviewReply(review: PluginReview, message: string): Observable<PluginReply> {
    return this.getProducer().pipe(
      switchMap((producer: Producer) => this.pluginReviewsGateway.addPluginReviewReply(producer, review, message)),
    );
  }

  updatePluginReviewReply(review: PluginReview, reply: PluginReply, text: string): Observable<PluginReply> {
    return this.getProducer().pipe(
      switchMap((producer: Producer) =>
        this.pluginReviewsGateway.updatePluginReviewReply(producer, review, reply, text),
      ),
    );
  }

  private getProducer(): Observable<Producer> {
    return this.producersFacade.getProducer().pipe(take(1));
  }
}
