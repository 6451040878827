<div *ngIf="workspaces" class="navigation--workspaces" [class.is--collapsed]="!isExpanded">
  <div class="select--wrapper">
    @if (selected) {
      <navigation-entry
        class="bg-gray-900"
        (click)="toggle()"
        [color]="selected.color"
        [isDropdown]="true"
        [item]="{
          label: selected.label | translate,
          icon: selected.icon,
        }" />
    }
    <div class="content" [class.is--active]="open">
      <ng-template ngFor [ngForOf]="workspaces" let-workspace>
        <a class="content--entry" [ngClass]="workspace.name" (click)="selectWorkspace(workspace)" tabindex="0">
          <account-sw-icon [icon]="workspace.icon" [color]="workspace.color" />
          {{ workspace.label | translate }}
        </a>
      </ng-template>
    </div>
  </div>
</div>
