<div class="toggle-button" [class.disabled]="disabled">
  <div class="wrapper" [ngClass]="{ checked: checked, focus: focused }" (click)="onClick($event, cb)">
    <input
      #cb
      type="checkbox"
      role="switch"
      class="background"
      [checked]="checked"
      [disabled]="disabled"
      [attr.tabindex]="tabindex"
      [attr.aria-checked]="checked"
      (change)="onInputChange($event)"
      (focus)="onFocus($event)"
      (blur)="onBlur($event)" />
  </div>
  <label class="label" [class.focused]="focused" (click)="onClick($event, cb)">
    <span class="content" [class.multi-line]="labelMultiline">
      <ng-content />
    </span>
  </label>
</div>
