@if (isMobileViewport) {
  <div class="flex flex-col p-4 items-start">
    <button ao-button class="close-button" variant="link" (click)="toggleNavigation()">
      <ao-icon name="times" class="size-4 text-white"></ao-icon>
    </button>
  </div>
  <navigation-separator />
}

<navigation-header [user]="activeUser$ | async" (onGoToPortal)="goToPortal()" (onToggleOverlay)="toggleOverlayMenu()" />

@if (isOverlayMenuOpen) {
  <navigation-menu-overlay
    [user]="activeUser$ | async"
    [activeWorkspace]="activeWorkspace$ | async"
    [languages]="languages"
    [selectedLanguage]="selectedLanguage$ | async"
    (onLanguageChange)="changeLanguage($event)"
    (onChangeSelectedMembership)="changeSelectedMembership($event)"
    (onLogout)="logout()"
    (onCloseOverlay)="toggleOverlayMenu()"
  />
}

@if (activeWorkspace$ | async) {
  <navigation-workspaces-selector
    [workspaces]="workspaces$ | async"
    [selected]="activeWorkspace$ | async"
    [disabled]="!!(activeWorkspace$ | async)"
    (workspaceSelected)="selectWorkspace($event)"
  />
}

<navigation-separator />

<ng-container *ngIf="activeWorkspace$ | async; else showWorkspaces">
  <navigation-workspace
    [workspace]="activeWorkspace$ | async"
    [workspaceItems]="activeWorkspaceItems$ | async"
    [activeWorkspaceItem]="activeWorkspaceItem$ | async"
  />
</ng-container>

<ng-template #showWorkspaces>
  <div class="flex-1 overflow-y-auto scrollbar-hide p-4">
    <ng-container *ngFor="let workspace of workspaces$ | async; trackBy: trackByWorkspace">
      @if (!workspace.featureFlag || (workspace.featureFlag | featureFlag)) {
        <navigation-entry [item]="workspace" [isWorkspace]="true" />
      }
    </ng-container>
  </div>
</ng-template>

@if (onboardingStatus$ | async; as onboardingStatus) {
  @if (onboardingStatus === CompanyOnboardingStatus.IN_PROGRESS) {
    <account-navigation-onboarding />
  }
}

<!-- @TODO: Relevant for CRM-1636
Examples of the navigation teasers + a badge example that can be deleted -->

@if (showTeasers && (showProducerTeaser || showPartnerTeaser)) {
  <navigation-separator />

  <div class="p-4">
    @if (showProducerTeaser) {
      <navigation-entry [item]="{
        path: 'teaser/producer', label: ('PORTAL.TEASER.PRODUCER.HEADLINE' | translate), icon: 'plug' }" color="#0CBDED"
      />
    }
    @if (showPartnerTeaser) {
      <navigation-entry [item]="{
          label: ('PORTAL.TEASER.PARTNER.HEADLINE' | translate), icon: 'briefcase', externalLink: ('PROFILE.PORTAL.ACTIONS.PARTNER_LINK' | translate) }" color="#24CC6A"
      />
    }
  </div>
}
