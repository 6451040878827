<ng-container *ngIf="collapsible; else content">
  <list-item-shared-collapsible-container [collapsed]="collapsed" (onToggleCollapsible)="toggleCollapsible()">
    <ng-content select="simple-list-item-visible" />
  </list-item-shared-collapsible-container>
  <div>
    <hr class="collapsible-divider" [class.collapsed]="collapsed" />
    <list-item-shared-collapsible-container [collapsible]="true" [collapsed]="collapsed">
      <ng-content select="simple-list-item-collapsible" />
    </list-item-shared-collapsible-container>
  </div>
</ng-container>

<ng-template #content>
  <ng-content />
</ng-template>
