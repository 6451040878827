import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'account-figure',
  template: '<ng-content  />',
  styleUrl: './figure.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class FigureComponent {
  @HostBinding('style.background')
  @Input()
  background = '#FF8867';

  @HostBinding('style.background-origin')
  @Input()
  backgroundOrigin = 'content-box';

  @HostBinding('style.background-clip')
  @Input()
  backgroundClip = 'padding-box';

  @HostBinding('style.background-position')
  @Input()
  backgroundPosition = '0 0';

  @HostBinding('style.background-repeat')
  @Input()
  backgroundRepeat = 'no-repeat';

  @HostBinding('style.background-size')
  @Input()
  backgroundSize = 'cover';

  @HostBinding('style.height')
  @HostBinding('style.width')
  @Input()
  size = '90px';

  @HostBinding('class.has-border')
  @Input()
  hasBorder = false;

  @HostBinding('class.is-round')
  @Input()
  isRound = true;
}
