import { CompaniesFacade, SessionFacade, UserAccountMembershipsFacade } from '@account/core/facades';
import { LocalStorageService } from '@account/core/services';
import { convertSbpDate } from '@account/core/utils';
import { ComponentOverlayService } from '@account/shared/components';
import { Overlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  Renderer2,
  viewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AoButtonDirective, AoIconComponent } from '@shopware/aorta';
import { combineLatestWith, Observable, of, Subject, timer } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { NotificationCenterComponent, NotificationsFacade } from 'src/app/core';

import { SwIconComponent } from '../../../../shared/components/sw-icon/sw-icon.component';
import { Company, CompanyMembership, NotificationItem, UserAccount } from '../../../models';

@Component({
  selector: 'navigation-header',
  templateUrl: './navigation-header.component.html',
  styleUrl: './navigation-header.component.less',
  standalone: true,
  imports: [CommonModule, TranslateModule, SwIconComponent, AoButtonDirective, AoIconComponent],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'flex flex-row p-4 gap-1 relative z-10',
  },
})
export class NavigationHeaderComponent implements OnInit {
  private readonly renderer = inject(Renderer2);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly sessionFacade = inject(SessionFacade);
  private readonly companiesFacade = inject(CompaniesFacade);
  private readonly userAccountMembershipsFacade = inject(UserAccountMembershipsFacade);
  private readonly overlayService = inject(ComponentOverlayService);
  private readonly overlay = inject(Overlay);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly notificationsFacade = inject(NotificationsFacade);

  @Input() user: UserAccount;
  @Output() readonly onGoToPortal = new EventEmitter<unknown>();
  @Output() readonly onToggleNavigation = new EventEmitter<unknown>();
  @Output() readonly onChangeSelectedMembership = new EventEmitter<CompanyMembership>();
  @Output() readonly onToggleOverlay = new EventEmitter<undefined>();

  open = false;

  protected notifications$: Observable<NotificationItem[]>;
  protected notifications: NotificationItem[];
  protected showNotificationBubble = false;
  protected notificationsContainer = viewChild<ElementRef>('notificationWrap');

  activeUserContext$: Observable<{
    company: null | Company;
    memberships: CompanyMembership[];
    hasMultipleCompanies: boolean;
  }>;

  private selfClick = false;
  private itemClick = false;
  private documentClickListener: () => void;

  ngOnInit(): void {
    const company$ = this.companiesFacade
      .hasCompany()
      .pipe(switchMap((hasCompany: boolean) => (hasCompany ? this.companiesFacade.getCompany() : of(null))));

    const memberships$ = this.userAccountMembershipsFacade
      .getMemberships()
      .pipe(
        map((memberships: CompanyMembership[]) =>
          memberships
            .filter((membership: CompanyMembership) => membership.active)
            .sort((a: CompanyMembership, b: CompanyMembership) =>
              a.company.name.toLowerCase() < b.company.name.toLowerCase() ? -1 : 1,
            ),
        ),
      );

    this.activeUserContext$ = this.sessionFacade.waitUntilDataLoaded().pipe(
      switchMap(() => company$),
      combineLatestWith(memberships$),
      map(([company, memberships]: [Company, CompanyMembership[]]) => ({
        company: company,
        memberships: memberships,
        hasMultipleCompanies: memberships.length > 1,
      })),
    );

    this.notifications$ = timer(0, 600000).pipe(
      switchMap(() =>
        this.notificationsFacade.getNotifications().pipe(
          tap((notifications: NotificationItem[]) => {
            this.notifications = notifications;
            this.checkForNewNotifications();
          }),
        ),
      ),
    );
  }

  goToPortal(): void {
    this.onGoToPortal.emit();
  }

  changeSelectedMembership(membership: CompanyMembership): void {
    this.onChangeSelectedMembership.emit(membership);
  }

  toggle(): void {
    this.bindDocumentClickListener();
    this.open = !this.open;
    this.selfClick = true;
  }

  toggleOverlay(): void {
    this.onToggleOverlay.emit();
  }

  openNotificationCenter(): void {
    const remainingNotificationsAfterDeletion$ = new Subject<NotificationItem[]>();

    const notificationCenterRef = this.overlayService.create(
      NotificationCenterComponent,
      {
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-transparent-backdrop',
        positionStrategy: this.overlay
          .position()
          .flexibleConnectedTo(this.notificationsContainer())
          .withPositions([
            {
              originX: 'end',
              originY: 'bottom',
              overlayX: 'end',
              overlayY: 'top',
            },
          ]),
      },
      {
        notifications: this.notifications,
        onDelete: remainingNotificationsAfterDeletion$,
      },
    );

    remainingNotificationsAfterDeletion$.subscribe((items: NotificationItem[]) => {
      this.notifications = items;
      if (this.notifications.length === 0) {
        this.showNotificationBubble = false;
      }
    });

    notificationCenterRef.afterClosed().subscribe(() => {
      let lastOpened = new Date(+this.localStorageService.getItem('notification.last-opened'));

      this.notifications.forEach((notification: NotificationItem) => {
        const creationDate = convertSbpDate(notification.creationDate);
        if (creationDate > lastOpened) {
          lastOpened = creationDate;
        }
      });

      this.localStorageService.setItem('notification.last-opened', lastOpened.getTime().toString());
      this.checkForNewNotifications();
    });
  }

  private checkForNewNotifications(): void {
    const lastOpened = new Date(+this.localStorageService.getItem('notification.last-opened'));

    this.showNotificationBubble =
      this.notifications.filter((item: NotificationItem) => convertSbpDate(item.creationDate) > lastOpened).length > 0;
  }

  private bindDocumentClickListener(): void {
    if (!this.documentClickListener) {
      this.documentClickListener = this.renderer.listen('document', 'click', () => {
        if (!this.selfClick && !this.itemClick) {
          this.open = false;
          this.unbindDocumentClickListener();
        }
        this.selfClick = false;
        this.itemClick = false;
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  private unbindDocumentClickListener(): void {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }
}
