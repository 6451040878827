import { AuthFacade } from '@account/core/facades';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TermsGuard {
  private readonly authFacade = inject(AuthFacade);
  private readonly router = inject(Router);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authFacade.isLoggedIn().pipe(
      take(1),
      switchMap((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.authFacade.setRedirectUrl(state.url);
          this.router.navigate(['front']);
          return of(false);
        }
        return this.authFacade.isLatestGTCSigned().pipe(
          map((result: boolean) => {
            if (result) {
              return this.router.parseUrl('portal');
            }
            return true;
          })
        );
      })
    );
  }
}
