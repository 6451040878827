import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

import { ToastService } from '../../../shared/components/toast/toast.service';
import { PartnerTechnologyProfileGateway } from '../../gateways/partner/partner-technology-profile.gateway';
import { ListResult, Partner, SbpException, Technology, TechnologyCategory } from '../../models';
import { Plugin } from '../../models/plugin/plugin.model';
import { ExceptionFormatterService } from '../../services';
import { PartnersFacade } from './partners.facade';

@Injectable({
  providedIn: 'root',
})
export class PartnerTechnologyProfileFacade {
  private readonly technologyProfileGateway = inject(PartnerTechnologyProfileGateway);
  private readonly partnerFacade = inject(PartnersFacade);
  private readonly toastService = inject(ToastService);
  private readonly translateService = inject(TranslateService);
  private readonly exceptionFormatterService = inject(ExceptionFormatterService);

  createTechnology(technology: Technology): Observable<Technology> {
    return this.getPartner().pipe(
      switchMap((partner: Partner) => {
        technology.partner = {
          partnerId: partner.partnerId,
        } as Partner;
        return this.technologyProfileGateway.createTechnology(technology);
      })
    );
  }

  getTechnology(): Observable<ListResult<Technology[]>> {
    return this.getPartner().pipe(
      switchMap((partner: Partner) => this.technologyProfileGateway.getTechnology(partner.partnerId))
    );
  }

  updateTechnology(technology: Technology): Observable<Technology> {
    return this.technologyProfileGateway.updateTechnology(technology);
  }

  getTechnologyCategories(): Observable<TechnologyCategory[]> {
    return this.technologyProfileGateway.getTechnologyCategories();
  }

  uploadImage(file: File): Observable<string> {
    return this.getPartner().pipe(
      switchMap((partner: Partner) =>
        this.technologyProfileGateway.uploadImage(partner.partnerId, file).pipe(
          catchError((exception: SbpException) => {
            switch (exception.code) {
              case 'ValidatorException-5':
                this.toastService.error(
                  this.translateService.instant('COMMON.TOAST.ERROR.TITLE.UPLOAD_FAILED'),
                  this.exceptionFormatterService.getMessageByContext(exception, null),
                  true
                );
                break;
              // skip default
            }
            return throwError(() => exception);
          })
        )
      )
    );
  }

  getTechnologyPlugins(searchTerm: string, filer = {}): Observable<ListResult<Plugin[]>> {
    return this.technologyProfileGateway.getTechnologyPlugins(searchTerm, filer);
  }

  private getPartner(): Observable<Partner> {
    return this.partnerFacade.getPartner().pipe(take(1));
  }
}
