<div class="shop-creation-modal-teaser-step__image-wrapper">
  <img
    class="shop-creation-modal-teaser-step__image"
    src="/assets/images/pricing-teaser/pricing-visual.svg"
    [alt]="'TEASER_MODAL.GENERAL.MODAL_HEADLINE' | translate" />
</div>
<div class="shop-creation-modal-teaser-step__content">
  <h1 class="shop-creation-modal-teaser-step__headline">
    {{ 'TEASER_MODAL.GENERAL.HEADLINE' | translate }}
  </h1>
  <p class="shop-creation-modal-teaser-step__text">
    {{ 'TEASER_MODAL.GENERAL.DESCRIPTION' | translate }}
    <span [innerHTML]="'TEASER_MODAL.GENERAL.BLOG_LINK' | translate"></span>
  </p>
</div>
<account-contact />
