import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  Contract,
  Locale,
  Plugin,
  Producer,
  ProducerCustomerValidationResult,
  ProducerGivenExtensionLicenseRequestData,
  SbpException,
  SupportLanguage,
} from '../../models';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class ProducersGateway {
  private readonly apiService = inject(ApiService);

  getProducerByCompany(companyId: number): Observable<Producer> {
    let params = new HttpParams();
    params = params.set('companyId', companyId.toString());
    return this.apiService.get('/producers', params).pipe(map((data) => data[0]));
  }

  createProducerForCompany(companyId: number): Observable<Producer> {
    const data = { companyId: companyId };
    return this.apiService.post('/producers', data);
  }

  updateProducer(producer: Producer): Observable<Producer> {
    return this.apiService.put(`/producers/${producer.id}`, producer);
  }

  updateContract(producerId: number, contract: Contract): Observable<Contract> {
    return this.apiService.put(`/producers/${producerId}/contracts/${contract.id}`, contract);
  }

  getContract(producer: Producer): Observable<Contract> {
    return this.apiService.get(`/producers/${producer.id}/contracts/${producer.contract.id}`);
  }

  downloadContract(locale: Locale): Observable<Blob | SbpException> {
    return this.apiService.download(`/documents/producerContract/current/${locale.name}.pdf`);
  }

  uploadIcon(producerId: number, file: File): Observable<{ icon: string }> {
    return this.apiService.upload(`/producers/${producerId}/icon`, file);
  }

  getProducerSupportLanguages(): Observable<SupportLanguage[]> {
    return this.apiService.get('/producersupportlanguages');
  }

  isCustomerAndShopValid(
    producerId: number,
    customerNumber: string,
    shopDomain: string
  ): Observable<ProducerCustomerValidationResult> {
    return this.apiService.get(
      `/producers/${producerId}/pluginlicenses/validatecustomer/${customerNumber}/${shopDomain}`
    );
  }

  getProvisionPreviewForPlugin(producerId: number, plugin: Plugin): Observable<{ provisionAmount: number }> {
    return this.apiService.get(`/producers/${producerId}/pluginprovisionpreview/${plugin.id}`);
  }

  createProducerGivenLicense(
    producerId: number,
    requestData: ProducerGivenExtensionLicenseRequestData
  ): Observable<void> {
    return this.apiService.post(`/producers/${producerId}/pluginlicenses`, requestData);
  }
}
