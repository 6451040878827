import {
  Component,
  EmbeddedViewRef,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'multi-select-item-template-loader',
  template: '',
  standalone: true,
})
export class MultiSelectItemTemplateLoaderComponent implements OnChanges, OnDestroy {
  viewContainer = inject(ViewContainerRef);
  @Input() template: any;
  @Input() item: any;

  view: EmbeddedViewRef<any>;

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.item) {
      return;
    }
    if (this.view) {
      this.view.destroy();
    }
    this.view = this.viewContainer.createEmbeddedView(this.template, {
      $implicit: this.item,
    });
  }

  ngOnDestroy(): void {
    this.view.destroy();
  }
}
