<div
  class="wrapper"
  [routerLink]="routerLink"
  [matTooltip]="tooltipContent || content"
  [matTooltipShowDelay]="300"
  [matTooltipClass]="useMaxWidth ? 'width-max-content ' : '' + tooltipClass"
  [tabindex]="routerLink ? tabIndex : -1"
  [class.has-overflow]="!content"
  [style.max-width]="wrapperWidth">
  <ng-container *ngIf="tooltipType === 'link'; then linkTemplate; else textTemplate" />

  <ng-template #linkTemplate>
    <a *ngIf="routerLink; else noRouterLink" class="overflow" [target]="linkTarget" rel="noreferrer">
      <span *ngIf="content; else contentProjection">{{ content }}</span>
    </a>

    <ng-template #noRouterLink>
      <a *ngIf="link; else javascriptVoid" class="overflow" [href]="link" [target]="linkTarget" rel="noreferrer">
        <span *ngIf="content; else contentProjection" [tabindex]="tabIndex">{{ content }}</span>
      </a>

      <ng-template #javascriptVoid>
        <a
          class="overflow"
          [routerLink]="['./']"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          [target]="linkTarget"
          rel="noreferrer">
          <span *ngIf="content; else contentProjection">{{ content }}</span>
        </a>
      </ng-template>
    </ng-template>
  </ng-template>

  <ng-template #textTemplate>
    <span *ngIf="content; else contentProjection" class="overflow">{{ content }}</span>
  </ng-template>

  <ng-template #contentProjection>
    <ng-content />
  </ng-template>
</div>
