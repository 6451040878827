import { NgIf, NgStyle } from '@angular/common';
import { Component, HostBinding, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { ImagePlaceholderComponent } from '../image-placeholder/image-placeholder.component';
import { ModalService } from '../modal/modal.service';
import { FramePreviewComponent } from './frame-preview/frame-preview.component';

@Component({
  selector: 'account-frame',
  templateUrl: './frame.component.html',
  styleUrl: './frame.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgStyle, NgIf, ImagePlaceholderComponent],
})
export class FrameComponent implements OnInit {
  private readonly modalService = inject(ModalService);
  @Input() link: string;
  @Input() name: string;
  @Input() background: string;
  @Input() backgroundSize = 'contain';
  @Input() backgroundPosition = 'center';
  @Input() clickable = false;

  @HostBinding('style.height')
  @Input()
  height = '90px';

  @HostBinding('style.width')
  @Input()
  width = '90px';

  @HostBinding('style.maxHeight')
  @Input()
  maxHeight = '90px';

  @HostBinding('style.maxWidth')
  @Input()
  maxWidth = '90px';

  @HostBinding('style.minHeight')
  @Input()
  minHeight = 'initial';

  @HostBinding('style.minWidth')
  @Input()
  minWidth = 'initial';

  content: string;

  ngOnInit(): void {
    this.content = this.link ? `url('${this.link}')` : this.background;
  }

  onOpenPreview(event: any): void {
    event.preventDefault();
    this.modalService.open(FramePreviewComponent, {
      data: {
        name: this.name,
        link: this.link,
      },
    });
  }
}
