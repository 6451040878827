import { AuthFacade } from '@account/core/facades';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ModalService } from '../../shared/components/modal/modal.service';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class PendingChangesGuard {
  private readonly authFacade = inject(AuthFacade);
  private readonly translateService = inject(TranslateService);
  private readonly modalService = inject(ModalService);

  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> {
    return this.authFacade.isLoggingOut().pipe(
      switchMap((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          return of(true);
        }
        const componentCanDeactivate = component.canDeactivate();
        if (typeof componentCanDeactivate === 'boolean') {
          return of(componentCanDeactivate);
        }
        return componentCanDeactivate.pipe(
          switchMap((canDeactivate: boolean) => {
            // if there are no pending changes, just allow deactivation; else confirm first
            if (canDeactivate) {
              return of(true);
            }
            // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
            // when navigating away from your angular app, the browser will show a generic warning message
            // see http://stackoverflow.com/a/42207299/7307355
            const modalRef = this.modalService.confirm(
              this.translateService.instant('COMMON.UNSAVED_CHANGES'),
              this.translateService.instant('COMMON.UNSAVED_CHANGES_QUESTION')
            );
            return modalRef.afterClosed().pipe(map((leavePageConfirmed: boolean) => leavePageConfirmed));
          })
        );
      })
    );
  }
}
