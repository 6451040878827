<div class="control_content">
  <input
    name="password"
    autocomplete="new-password"
    [formControl]="control"
    [type]="inputType"
    (focus)="emitShowHelp()"
    (blur)="emitBlur()"
    (capsLockToggled)="isCapsLockOn = $event" />
  <account-sw-icon
    class="show-password"
    [icon]="eyeState"
    [size]="'16px'"
    [color]="'#758CA3'"
    (click)="togglePasswordShown()" />
</div>
<div class="caps_lock_warning" *ngIf="isCapsLockOn">
  {{ 'FRONT.LOGIN.CAPS_LOCK_WARNING' | translate }}
</div>
