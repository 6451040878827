import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListResult, PluginLicense, SbpException } from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { ApiService } from '../../services';
import { HttpParamsBuilder } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class ProducerSalesGateway {
  private readonly apiService = inject(ApiService);

  getSalesList(producerId: number, metaData: RequestMetaData): Observable<ListResult<any>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    if (metaData.filter) {
      params = HttpParamsBuilder.applyFilters(params, metaData.filter);
    }

    return this.apiService.getList(`/producers/${producerId}/sales`, params);
  }

  downloadPluginList(producerId: number, locale: string, metaData: RequestMetaData): Observable<Blob | SbpException> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    if (metaData.filter) {
      params = HttpParamsBuilder.applyFilters(params, metaData.filter);
    }

    return this.apiService.download(`/producers/${producerId}/salesexport/${locale}`, params);
  }

  extendTrialLicenseExpirationDate(producerId: number, licenseId: number): Observable<PluginLicense> {
    return this.apiService.post(`/producers/${producerId}/sales/${licenseId}/extendexpirationdate`);
  }
}
