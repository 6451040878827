import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { NotificationsGateway } from '../../gateways/company/notifications.gateway';
import { NotificationItem } from '../../models';
import { AuthFacade } from '../auth';
import { CompaniesFacade } from './companies.facade';

@Injectable({
  providedIn: 'root',
})
export class NotificationsFacade {
  private readonly notificationsGateway = inject(NotificationsGateway);
  private readonly authFacade = inject(AuthFacade);
  private readonly companiesFacade = inject(CompaniesFacade);

  getNotifications(): Observable<NotificationItem[]> {
    return this.companiesFacade.hasCompany().pipe(
      switchMap((hasCompany: boolean) => {
        if (!hasCompany) {
          return of([]);
        }
        return this.companiesFacade
          .getCompanyIdOnce()
          .pipe(switchMap((companyId: number) => this.notificationsGateway.getNotifications(companyId)));
      }),
      takeUntil(this.authFacade.waitUntilLoggingOut())
    );
  }

  deleteNotifications(id: number = null): Observable<void> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.notificationsGateway.deleteNotifications(companyId, id)));
  }
}
