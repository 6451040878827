<ng-container *ngIf="partnerSearchResults$ | async as partnerSearchResults">
  <div class="search-results" *ngIf="partnerSearchResults?.length > 0; else showEmptyState">
    <div *ngFor="let partner of partnerSearchResults" class="search-results__item" (click)="select(partner)">
      {{ partner.companyName }}
    </div>
  </div>
  <ng-template #showEmptyState>
    <account-empty-state
      class="empty-state"
      inline="true"
      icon="briefcase"
      heading="{{ 'PROFILE.MY_PARTNER.PARTNER_SEARCH_EMPTY_STATE' | translate }}" />
  </ng-template>
</ng-container>
