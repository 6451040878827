<ng-container [formGroup]="form">
  <p class="step-intro-text" [innerHTML]="introText"></p>
  <div
    class="a_control"
    [class.is_required]="isRequired"
    [class.is_error]="
      form.get('domainName').value.length > 3 &&
      form.get('domainName').dirty &&
      (form.get('domainName').errors | keys)?.length > 0
    ">
    <div class="control_description">
      <strong>{{ labelInputDomainName }}:</strong>
      <account-helptext
        [content]="'COMMON.SHOP.SHOP_CREATION_MODAL.STEP.DOMAIN.DOMAIN_RESTRICTION_HELPTEXT' | translate" />
    </div>
    <div class="control_content">
      <input type="text" placeholder="domain.de" formControlName="domainName" #inputDomainName />
    </div>
    <div
      class="control_help"
      *ngIf="
        form.get('domainName').value.length > 3 &&
        form.get('domainName').dirty &&
        (form.get('domainName').errors | keys)?.length > 0
      ">
      <ng-container *ngIf="form.get('domainName').errors?.registered">
        {{ 'COMMON.SHOP.VERIFICATION.STEP.DOMAIN.DOMAIN_NAME.HELP_TEXT.REGISTERED_URL' | translate }}
      </ng-container>
      <ng-container *ngIf="form.get('domainName').errors?.domainInUse">
        {{ 'COMMON.SHOP.VERIFICATION.STEP.DOMAIN.DOMAIN_NAME.HELP_TEXT.REGISTERED_URL' | translate }}
      </ng-container>
      <ng-container *ngIf="form.get('domainName').errors?.domainIsWildcardEnvironment">
        {{ 'COMMON.SHOP.VERIFICATION.STEP.DOMAIN.DOMAIN_NAME.HELP_TEXT.REGISTERED_URL' | translate }}
      </ng-container>
      <ng-container
        *ngIf="
          form.get('domainName').errors?.url ||
          form.get('domainName').errors?.path ||
          form.get('domainName').errors?.containsPath
        ">
        {{ 'COMMON.SHOP.VERIFICATION.STEP.DOMAIN.DOMAIN_NAME.HELP_TEXT.INVALID_URL' | translate }}
      </ng-container>
    </div>
  </div>
</ng-container>
