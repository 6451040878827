import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SanitizeGateway } from '../../gateways/common/sanitize.gateway';
import { SanitizedContent } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class SanitizeFacade {
  private readonly sanitizeGateway = inject(SanitizeGateway);

  sanitizeHTML(content: string): Observable<SanitizedContent> {
    return this.sanitizeGateway.sanitizeHTML(content);
  }
}
