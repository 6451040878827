import { Component, Directive, HostBinding, Input, ViewEncapsulation } from '@angular/core';

import { SwIconComponent } from '../sw-icon';

@Directive({
  selector: 'collapsible-headline',
  standalone: true,
})
export class CollapsibleHeadlineDirective {}

@Component({
  selector: 'account-collapsible',
  templateUrl: './collapsible.component.html',
  styleUrl: './collapsible.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [SwIconComponent],
})
export class CollapsibleComponent {
  @HostBinding('class.is-collapsible')
  @Input()
  collapsible = true;
  @Input() open = true;

  toggle(): void {
    this.open = !this.open;
  }
}
