import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CommercialPlan } from '../../models/shop/commercial-plan.model';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class CommercialPlanGateway {
  private readonly apiService = inject(ApiService);

  getCommercialMigrationPlans(): Observable<CommercialPlan[]> {
    return this.apiService.get('/commercialmigrationplans');
  }

  getCommercialUpgradePlans(): Observable<CommercialPlan[]> {
    return this.apiService.get('/commercialupgradeplans');
  }

  getCommercialRegularPlans(): Observable<CommercialPlan[]> {
    return this.apiService.get('/commercialregularplans');
  }
}
