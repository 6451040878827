import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { IntegrationPartnerCommissionsGateway } from '../../gateways/partner/integration-partner-commissions.gateway';
import { ListResult, PartnerPayoutListItem, QueryFilterGroup, SbpException } from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { CompaniesFacade, FinancialFacade } from '../company';

@Injectable({
  providedIn: 'root',
})
export class IntegrationPartnerCommissionsFacade {
  private readonly companiesFacade = inject(CompaniesFacade);
  private readonly financialFacade = inject(FinancialFacade);
  private readonly integrationPartnerCommissionsGateway = inject(IntegrationPartnerCommissionsGateway);

  getIntegrationPartnerCommissionBalance(): Observable<number> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.integrationPartnerCommissionsGateway.getIntegrationPartnerCommissionBalance(companyId)
        )
      );
  }

  getIntegrationPartnerCommissionPayouts(metaData: RequestMetaData): Observable<ListResult<PartnerPayoutListItem[]>> {
    if (metaData.filter && metaData.filter.length > 0) {
      const filterGroup = {
        active: true,
        items: [{ property: 'context', value: 'integrationPartnerCommission' }],
      } as QueryFilterGroup;
      metaData.filter.push(filterGroup);
    } else {
      metaData.filter = { context: 'integrationPartnerCommission' };
    }

    return this.financialFacade.getPartnerPayoutList(metaData);
  }

  getIntegrationPartnerCommissionList(metaData: RequestMetaData): Observable<any> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.integrationPartnerCommissionsGateway.getIntegrationPartnerCommissionList(companyId, metaData)
        )
      );
  }

  downloadIntegrationPartnerCommissionPayoutExport(locale: string, metaData: RequestMetaData): Observable<Blob> {
    if (metaData.filter && metaData.filter.length > 0) {
      const filterGroup = {
        active: true,
        items: [{ property: 'context', value: 'integrationPartnerCommission' }],
      } as QueryFilterGroup;
      metaData.filter.push(filterGroup);
    } else {
      metaData.filter = { context: 'integrationPartnerCommission' };
    }
    return this.financialFacade.downloadPartnerPayoutList(locale, metaData);
  }

  downloadPayoutDocument(invoiceId: number): Observable<SbpException | Blob> {
    return this.financialFacade.downloadInvoiceDocument(invoiceId);
  }

  downloadIntegrationPartnerCommissionExport(
    locale: string,
    metaData: RequestMetaData
  ): Observable<Blob | SbpException> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.integrationPartnerCommissionsGateway.downloadIntegrationPartnerCommissionExport(
            companyId,
            locale,
            metaData
          )
        )
      );
  }
}
