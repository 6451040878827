import { NgFor } from '@angular/common';
import { Component, HostBinding, inject, Input, OnInit } from '@angular/core';

import { ToastItemComponent } from '../item/toast-item.component';
import { Toast, ToastEvent } from '../toast.model';
import { ToastService } from '../toast.service';

@Component({
  selector: 'account-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrl: './toast-container.component.less',
  standalone: true,
  imports: [NgFor, ToastItemComponent],
})
export class ToastContainerComponent implements OnInit {
  private readonly toastService = inject(ToastService);
  toasts: Toast[] = [];
  @HostBinding('class.logged-out') @Input() loggedOut = false;

  ngOnInit(): void {
    this.toastService.events$.subscribe((event: ToastEvent) => {
      switch (event.type) {
        case 'add': {
          this.add(event.value);
          break;
        }
        case 'clear': {
          this.clear(event.value);
          break;
        }
        case 'clear_permanent': {
          this.clearPermanent();
          break;
        }
        default: {
          throw new Error(`Could not find event.value in defined events${event.value}`);
        }
      }
    });
  }

  closeToast(toast: Toast): void {
    this.clear(toast.id);
  }

  add(toast: Toast): void {
    this.toasts.push(toast);
    if (toast.timeout) {
      this.setTimeOut(toast);
    }
  }

  clear(id: number): void {
    if (id) {
      this.toasts.forEach((value: Toast, key: number) => {
        if (value.id === id) {
          this.toasts.splice(key, 1);
        }
      });
    } else {
      throw new Error('could not find Toast to close');
    }
  }

  clearPermanent(): void {
    this.toasts = this.toasts.filter((toast: Toast) => undefined !== toast.timeout);
  }

  private setTimeOut(toast: Toast): void {
    setTimeout(() => {
      this.clear(toast.id);
    }, toast.timeout);
  }
}
