import { DropdownComponent, SwIconComponent } from '@account/shared/components';
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  input,
  OnInit,
  Output,
  Signal,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  AoButtonDirective,
  AoFieldLabelDirective,
  AoFieldMessageComponent,
  AoFieldMessageDirective,
  AoIconComponent,
  AoSelectFieldComponent,
  AoSelectOptionComponent,
  AoSelectTriggerComponent,
  AoSelectValueDirective,
} from '@shopware/aorta';
import { combineLatestWith, map, Observable, of, switchMap } from 'rxjs';
import { CompaniesFacade, SessionFacade, UserAccountMembershipsFacade } from 'src/app/core';

import { Company, CompanyMembership, Language, UserAccount, Workspace } from '../../../models';

@Component({
  selector: 'navigation-menu-overlay',
  templateUrl: './menu-overlay.component.html',
  styleUrl: './menu-overlay.component.less',
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    SwIconComponent,
    DropdownComponent,
    AoSelectFieldComponent,
    AoSelectOptionComponent,
    AoSelectTriggerComponent,
    AoSelectValueDirective,
    AoFieldMessageComponent,
    AoFieldMessageDirective,
    AoFieldLabelDirective,
    AoButtonDirective,
    AoIconComponent,
  ],
  standalone: true,
  preserveWhitespaces: false,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class:
      'w-[292px] top-[145px] lg:top-[72px] left-[16px] bg-default shadow rounded text-default absolute z-[10] flex flex-col',
  },
})
export class MenuOverlayComponent implements OnInit {
  private readonly elementRef = inject(ElementRef);
  private readonly formBuilder = inject(FormBuilder);
  private readonly sessionFacade = inject(SessionFacade);
  private readonly companiesFacade = inject(CompaniesFacade);
  private readonly userAccountMembershipsFacade = inject(UserAccountMembershipsFacade);

  @Input() selectedLanguage: Language;
  @Input() languages: Language[];
  @Input() activeWorkspace: Workspace;

  @Output() readonly onLanguageChange = new EventEmitter<Language>();
  @Output() readonly onLogout = new EventEmitter<undefined>();
  @Output() readonly onCloseOverlay = new EventEmitter<undefined>();
  @Output() readonly onChangeSelectedMembership = new EventEmitter<CompanyMembership>();

  user: Signal<UserAccount> = input<UserAccount>();

  protected form: FormGroup;
  private _open = false;

  activeUserContext$: Observable<{
    company: null | Company;
    memberships: CompanyMembership[];
    hasMultipleCompanies: boolean;
  }>;

  activeUserContext: {
    company: null | Company;
    memberships: CompanyMembership[];
    hasMultipleCompanies: boolean;
  };

  constructor() {
    this.form = this.formBuilder.group({
      company: [null],
      language: [null],
    });
  }

  ngOnInit(): void {
    const company$ = this.companiesFacade
      .hasCompany()
      .pipe(switchMap((hasCompany: boolean) => (hasCompany ? this.companiesFacade.getCompany() : of(null))));

    const memberships$ = this.userAccountMembershipsFacade
      .getMemberships()
      .pipe(
        map((memberships: CompanyMembership[]) =>
          memberships
            .filter((membership: CompanyMembership) => membership.active)
            .sort((a: CompanyMembership, b: CompanyMembership) =>
              a.company.name.toLowerCase() < b.company.name.toLowerCase() ? -1 : 1
            )
        )
      );

    this.activeUserContext$ = this.sessionFacade.waitUntilDataLoaded().pipe(
      switchMap(() => company$),
      combineLatestWith(memberships$),
      map(([company, memberships]: [Company, CompanyMembership[]]) => ({
        company: company,
        memberships: memberships,
        hasMultipleCompanies: memberships.length > 1,
      }))
    );

    this.activeUserContext$.subscribe((context) => {
      this.activeUserContext = context;
      let currentCompany = null;
      let currentLanguage = null;

      if (this.activeUserContext.memberships.length && this.activeUserContext.company) {
        currentCompany = this.activeUserContext.memberships.find(
          (membership: CompanyMembership): boolean => membership.company.id === this.activeUserContext.company.id
        );
      }

      if (this.selectedLanguage && this.languages) {
        currentLanguage = this.languages.find((language) => language.key === this.selectedLanguage.key);
      }

      this.form.patchValue({
        company: currentCompany,
        language: currentLanguage,
      });
    });

    this.form.get('company').valueChanges.subscribe((membership: CompanyMembership) => {
      this.changeSelectedMembership(membership);
    });

    this.form.get('language').valueChanges.subscribe((language: Language) => {
      this.changeLanguage(language);
    });
  }

  changeLanguage(language: Language): void {
    this.onLanguageChange.emit(language);
  }

  changeSelectedMembership(membership: CompanyMembership): void {
    this.onChangeSelectedMembership.emit(membership);
  }

  logout(): void {
    this.onLogout.emit();
  }

  @HostListener('document:click', ['$event.target'])
  private onMenuClick(target: HTMLElement): void {
    if (this._open && !target.closest(this.elementRef.nativeElement.tagName.toLowerCase())) {
      this._open = !this._open;
      this.onCloseOverlay.emit();
      return;
    }
    if (!this._open) {
      this._open = true;
    }
  }
}
