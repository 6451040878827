<div class="tile-select" [class.is_disabled]="disabled">
  <div *ngFor="let option of options" class="tile-select__tile" [class.is_selected]="selection === option">
    <div class="tile-select__input">
      <input
        type="radio"
        [name]="inputName"
        [disabled]="disabled"
        [ngValue]="option"
        [checked]="selection === option"
        (change)="onChange($event.target.checked, option)" />
    </div>
    <div class="tile-select__content">
      <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ $implicit: option }" />
    </div>
    <div class="tile-select__button">
      <button *ngIf="selection !== option" class="a_button" (click)="onChange(true, option)" [disabled]="disabled">
        {{ buttonLabel }}
      </button>
      <div *ngIf="selection === option" class="a_button current-selection">
        <account-sw-icon icon="check-circle" variant="solid" size="16px" />
        <span>{{ 'PLUGIN_DETAILS.BINARIES.WIZARD.CHOSEN' | translate }}</span>
      </div>
    </div>
  </div>
</div>
