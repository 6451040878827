import { Partner } from '@account/core/models';
import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PartnerSearchResult } from '../../models/company/partner-search-result';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class PartnerCustomerRelationGateway {
  private readonly apiService = inject(ApiService);

  getAvailablePartners(companyId: number, searchTerm: string, limit: number): Observable<PartnerSearchResult[]> {
    const params = new HttpParams().set('search', searchTerm).set('limit', limit.toString());
    return this.apiService.get(`/companies/${companyId}/availablepartners`, params);
  }

  getAvailablePartnerInformation(companyId: number, partnerId: number): Observable<Partner> {
    return this.apiService.get(`/companies/${companyId}/availablepartners/${partnerId}`);
  }
}
