import { NavigationFacade } from '@account/core/facades';
import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'account-change-company',
  template: '',
  styleUrl: './change-company.component.less',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'is_loading',
  },
  standalone: true,
})
export class ChangeCompanyComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly navigationFacade = inject(NavigationFacade);

  ngOnInit(): void {
    of(true)
      .pipe(delay(600))
      .subscribe(() => {
        this.navigationFacade.selectWorkspace(null);
        this.router.navigate(['portal']);
      });
  }
}
