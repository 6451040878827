<div class="pagination" data-padding-left data-padding-right>
  <div class="left">
    <button
      *ngIf="canGoToPreviousStep"
      [class.invisible]="!hasPreviousStep"
      class="a_button is_small"
      type="button"
      (click)="previous()">
      <account-sw-icon class="icon-padding-right" icon="chevron-left-s" size="12px" />
      <span *ngIf="showButtonLabels" data-float-right data-hidden="s,m">
        {{ activeStep.previousStepLabel ? activeStep.previousStepLabel : ('WIZARD.PREVIOUS_STEP' | translate) }}
      </span>
    </button>
  </div>
  <span class="filler"></span>
  <account-progress-bar
    *ngIf="showProgressBar"
    showValue="false"
    [height]="5"
    class="progress-bar"
    style="width: 200px"
    [value]="(activeStepIndex / stepsImpactingProgress.length) * 100" />
  <span class="filler"></span>
  <div class="right">
    <button
      *ngIf="hasNextStep"
      class="a_button is_primary is_small"
      type="button"
      [disabled]="!activeStep.isValid"
      (click)="next()">
      <span *ngIf="showButtonLabels" data-float-left data-hidden="s,m">
        {{ activeStep.nextStepLabel ? activeStep.nextStepLabel : ('WIZARD.NEXT_STEP' | translate) }}
      </span>
      <account-sw-icon class="icon-padding-left" icon="chevron-right-s" size="12px" />
    </button>
    <button
      *ngIf="!hasNextStep"
      class="a_button is_primary is_small"
      type="button"
      [disabled]="!activeStep.isValid"
      (click)="complete()">
      <account-sw-icon class="icon-padding-right" icon="checkmark-s" size="12px" />
      <span *ngIf="showButtonLabels" data-hidden="s,m">
        {{ labelCompleteButton ? labelCompleteButton : ('WIZARD.FINISH' | translate) }}
      </span>
    </button>
  </div>
</div>
