import { inject, Injectable, Pipe, PipeTransform } from '@angular/core';

import { Category } from '../../core/models/plugin/category.model';
import { PluginsService } from '../services/plugins.service';

@Pipe({
  name: 'pluginCategory',
  pure: false,
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class PluginCategoryPipe implements PipeTransform {
  private readonly pluginsService = inject(PluginsService);

  transform(pluginCategory: Category): any {
    return this.pluginsService.getLocalizedCategoryName(pluginCategory);
  }
}
