import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject } from 'rxjs';

export class SbpDataSource<T> extends DataSource<T> {
  /** Stream that emits when a new data array is set on the data source. */
  private readonly _data: BehaviorSubject<T[]>;
  isInitialState = true;

  constructor(initialData: T[] = []) {
    super();
    this._data = new BehaviorSubject<T[]>(initialData);
  }

  /** Array of data that should be rendered by the table, where each object represents one row. */
  set data(data: T[]) {
    this.isInitialState = false;
    const rows: any[] = [];
    data.forEach((element) => rows.push(element, { sbpExpansionRow: true, element }));
    this._data.next(rows);
  }
  get data(): T[] {
    return this._data.value;
  }

  connect(): BehaviorSubject<T[]> {
    return this._data;
  }

  disconnect(_: CollectionViewer): void {
    // implemented due to parent
  }
}
