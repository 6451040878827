import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SupportPermissions } from '../../../models';
import { ApiService } from '../../../services';

@Injectable({
  providedIn: 'root',
})
export class SupportPermissionGateway {
  private readonly apiService = inject(ApiService);

  getSupportPermissions(partnerId: number): Observable<SupportPermissions> {
    return this.apiService.get(`/partners/${partnerId}/supportpermissions`);
  }

  getSupportPermissionForShop(partnerId: number, customerId: number, shopId: number): Observable<SupportPermissions> {
    return this.apiService.get(`/partners/${partnerId}/customers/${customerId}/shops/${shopId}/supportpermissions`);
  }
}
