<div class="clickable">
  <span>
    <ng-content />
  </span>

  <ng-container *ngIf="_isSorted()">
    <account-sw-icon *ngIf="_arrowDirection === 'asc'; else ascOrder" icon="chevron-down-s" size="16px" />
    <ng-template #ascOrder>
      <account-sw-icon icon="chevron-up-s" size="16px" />
    </ng-template>
  </ng-container>
</div>
