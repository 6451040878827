<div class="card_content" [class.has-border]="hasBorder" [ngStyle]="{ background: backgroundColor }">
  <div *ngIf="showHeader" class="card_header">
    <h3 *ngIf="heading" class="heading normal-nums">{{ heading }}</h3>
    <div *ngIf="showControls">
      <ng-content select="card-controls" />
    </div>
  </div>
  <div
    class="card_body"
    *ngIf="showBody"
    [class.has-padding]="contentHasPadding"
    [class.padding-small]="hasSmallPadding"
    [class.overflow-auto]="contentIsScrollable">
    <ng-content select="card-body" />
  </div>
  <div *ngIf="showFooter" class="card_footer" data-border-top>
    <ng-content select="card-footer" />
  </div>
</div>
