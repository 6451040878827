import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  Partner,
  PluginLicense,
  PluginLicenseSubscription,
  PluginLicenseSubscriptionRenewalOption,
  PluginLicenseSubscriptionRenewalRequest,
  PluginLicenseUpgradeRequest,
  ProductAcceleratorLicense,
  RentLicenseUpgradeOption,
  RentLicenseUpgradeOptionRequest,
  SelfHostedShop,
  Shop,
  SubshopLicense,
} from '../../models';
import { ApiService } from '../../services';
import { HttpParamsBuilder } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class ShopLicensesGateway {
  private readonly apiService = inject(ApiService);

  getPluginLicenses(shop: Pick<Shop, 'id'>, filter: Record<string, any> = {}): Observable<PluginLicense[]> {
    let params = new HttpParams();
    if (filter) {
      params = HttpParamsBuilder.applyFilters(params, filter);
    }

    return this.apiService.get(`/shops/${shop.id}/pluginlicenses`, params);
  }

  getPluginLicensePublishedBinaryVersions(shopId: number, licenseId: number, pluginId: number): Observable<string[]> {
    return this.apiService.get(`/shops/${shopId}/pluginlicenses/${licenseId}/plugin/${pluginId}/binaries/versions`);
  }

  getSubshopLicenses(companyId: number, shop: SelfHostedShop): Observable<SubshopLicense[]> {
    return this.apiService.get(`/companies/${companyId}/shops/${shop.id}/subshoplicenses`);
  }

  getProductAcceleratorLicenses(shop: SelfHostedShop): Observable<ProductAcceleratorLicense[]> {
    return this.apiService.get(`/shops/${shop.id}/productacceleratorlicenses`);
  }

  getSubscriptionRenewalOptions(license: PluginLicense): Observable<PluginLicenseSubscriptionRenewalOption[]> {
    return this.apiService.get(
      `/shops/${license.licenseShop.id}/pluginlicenses/${license.id}/subscriptions/${license.subscription.id}/renewalOptions`
    );
  }

  renewSubscription(
    license: PluginLicense,
    request: PluginLicenseSubscriptionRenewalRequest
  ): Observable<PluginLicenseSubscription> {
    return this.apiService.post(
      `/shops/${license.licenseShop.id}/pluginlicenses/${license.id}/subscriptions/${license.subscription.id}/renewalRequest`,
      request
    );
  }

  upgradeLicense(license: PluginLicense, request: PluginLicenseUpgradeRequest): Observable<PluginLicense> {
    return this.apiService.post(`/shops/${license.licenseShop.id}/pluginlicenses/${license.id}/upgrade`, request);
  }

  upgradeLicenseAsPartner(
    partner: Partner,
    license: PluginLicense,
    request: PluginLicenseUpgradeRequest
  ): Observable<PluginLicense> {
    return this.apiService.post(
      `/partners/${partner.partnerId}/shops/${license.licenseShop.id}/pluginlicenses/${license.id}/upgrade`,
      request
    );
  }

  deleteLicense(shopId: number, licenseId: number): Observable<PluginLicense> {
    return this.apiService.delete(`/shops/${shopId}/pluginlicenses/${licenseId}`);
  }

  reactivateLicense(shopId: number, licenseId: number): Observable<PluginLicense> {
    return this.apiService.post(`/shops/${shopId}/pluginlicenses/${licenseId}/reactivate`);
  }

  archiveLicense(shopId: number, licenseId: number): Observable<PluginLicense> {
    return this.apiService.put(`/shops/${shopId}/pluginlicenses/${licenseId}/archive`, licenseId);
  }

  restoreLicenseFromArchive(shopId: number, licenseId: number): Observable<PluginLicense> {
    return this.apiService.put(`/shops/${shopId}/pluginlicenses/${licenseId}/restore`, licenseId);
  }

  setSubshopLicenseDomain(companyId: number, license: SubshopLicense, newDomain: string): Observable<SubshopLicense> {
    const data = {
      domain: newDomain,
    };
    return this.apiService.post(
      `/companies/${companyId}/shops/${license.licenseShop.id}/subshoplicenses/${license.id}/domain`,
      data
    );
  }

  getPluginLicenseRentUpgradeOptions(shopId: number, licenseId: number): Observable<RentLicenseUpgradeOption[]> {
    return this.apiService.get(`/shops/${shopId}/pluginlicenses/${licenseId}/rentupgradeoptions`);
  }

  upgradeBuyLicenseToRent(
    shopId: number,
    licenseId: number,
    request: RentLicenseUpgradeOptionRequest
  ): Observable<void> {
    return this.apiService.post(`/shops/${shopId}/pluginlicenses/${licenseId}/tradein`, request);
  }
}
