import {
  CommercialPlanFacade,
  CompaniesFacade,
  PartnerCustomersFacade,
  ShopsFacade,
  ShopSuccessorFacade,
} from '@account/core/facades';
import {
  CommercialPlan,
  CompanyAllocations,
  EVOLVE,
  ListResult,
  MIGRATION_PLAN_EE_BEYOND,
  MIGRATION_PLAN_PE_EVOLVE,
  MIGRATION_PLAN_PE_PLUS_EE_EVOLVE,
  MIGRATION_PLAN_PE_RISE,
  RISE,
  SelfHostedShop,
  Shop,
  ShopUpgradeCommercialPlanBookingInformation,
} from '@account/core/models';
import { convertSbpDate, HttpParamsBuilder, StringUtils } from '@account/core/utils';
import { NgForOf, NgIf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PaginatorModule } from 'primeng/paginator';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { HelptextComponent } from 'src/app/shared/components/helptext';
import { MODAL_DATA, ModalRef } from 'src/app/shared/components/modal';
import { ToastService } from 'src/app/shared/components/toast/toast.service';

import { ObjectComparators } from '../../../../../shared/object.comparators';
import {
  PlanBookingConfig,
  PlanBookingModalBaseComponent,
  ShopPlanBookingForm,
} from '../../plan-booking-modal-base/plan-booking-modal-base.component';

@Component({
  selector: 'account-shop-migration-assistant',
  templateUrl: './shop-migration-assistant.component.html',
  styleUrl: './shop-migration-assistant.component.less',
  standalone: true,
  imports: [
    PlanBookingModalBaseComponent,
    HelptextComponent,
    NgForOf,
    NgIf,
    PaginatorModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class ShopMigrationAssistantComponent implements OnInit {
  protected readonly modalData = inject<PlanBookingConfig>(MODAL_DATA);
  private readonly modalRef = inject(ModalRef);
  private readonly shopsFacade = inject(ShopsFacade);
  private readonly partnerCustomersFacade = inject(PartnerCustomersFacade);
  private readonly companiesFacade = inject(CompaniesFacade);
  private readonly translateService = inject(TranslateService);
  private readonly shopSuccessorFacade = inject(ShopSuccessorFacade);
  private readonly toastService = inject(ToastService);
  private readonly commercialPlanFacade = inject(CommercialPlanFacade);
  protected selectableShops: SelfHostedShop[] = [];
  protected shopsLoading = false;
  protected commercialPlansFn: (shop: SelfHostedShop) => Observable<CommercialPlan[]>;
  private migrationPlans$: Observable<CommercialPlan[]>;
  private regularPlans$: Observable<CommercialPlan[]>;

  ngOnInit(): void {
    const selectedShop = this.modalData.preselectedShop;

    const migratableFilter: Record<string, any> = {};
    migratableFilter.migratableSubscription = true;
    migratableFilter.onlyMigratableShops = 'all';

    if (!this.modalData.selectableShops || this.modalData.selectableShops.length === 0) {
      const shops$ = this.modalData.partnerCustomer
        ? this.partnerCustomersFacade.getPartnerCustomerSelfHostedShops(
            selectedShop.companyId,
            HttpParamsBuilder.createMetaData(0, 0, 'creationDate', 'asc'),
            migratableFilter
          )
        : this.shopsFacade.getSelfHostedShopsForCompany(
            selectedShop.companyId,
            HttpParamsBuilder.createMetaData(0, 0, 'creationDate', 'asc'),
            migratableFilter
          );

      this.shopsLoading = true;
      shops$
        .pipe(
          map((listResult: ListResult<SelfHostedShop[]>) =>
            listResult.list
              .filter((shop: SelfHostedShop) => 'www.shopware-ag.de' !== shop.domain && !shop.mandant)
              .sort(ObjectComparators.compareByProperty.bind(this, 'domain'))
          )
        )
        .subscribe((shops: SelfHostedShop[]) => {
          this.selectableShops = shops;
          this.shopsLoading = false;
        });
    } else {
      this.selectableShops = selectedShop
        ? [selectedShop]
        : this.modalData.selectableShops.filter((shop) => !shop.mandant);
    }

    this.companiesFacade
      .getAllocations()
      .pipe(
        filter(() => !selectedShop),
        map((allocations: CompanyAllocations) => allocations.pricingTeaser[0].shop),
        map(
          (potentialShop: SelfHostedShop | null) =>
            this.selectableShops.find((shop: SelfHostedShop) => shop.id === potentialShop?.id) ?? this.selectableShops[0]
        )
      )
      .subscribe((preSelectedShop: SelfHostedShop | undefined) => {
        this.modalData.preselectedShop = preSelectedShop;
      });

    this.migrationPlans$ = this.commercialPlanFacade.getCommercialMigrationPlans().pipe(shareReplay());
    this.regularPlans$ = this.commercialPlanFacade.getCommercialRegularPlans().pipe(shareReplay());

    this.commercialPlansFn = this.commercialPlansByShop.bind(this);
  }

  commercialPlansByShop(shop: SelfHostedShop): Observable<CommercialPlan[]> {
    const subscriptionModules = shop.subscriptionModules[0]?.module.name;
    return forkJoin([this.migrationPlans$, this.regularPlans$]).pipe(
      map(([migrationPlans, regularPlans]: [CommercialPlan[], CommercialPlan[]]) => [
        ...migrationPlans,
        ...regularPlans,
      ]),
      map((plans: CommercialPlan[]) => {
        switch (shop.shopwareEdition) {
          case 'Shopware Core':
            return plans.filter((plan) => [MIGRATION_PLAN_PE_RISE, MIGRATION_PLAN_PE_EVOLVE].includes(plan.label));
          case 'Shopware Professional Plus':
            if (subscriptionModules === 'SILVER_SUPPORT' || subscriptionModules === 'GOLD_SUPPORT') {
              return plans.filter((plan) => [MIGRATION_PLAN_PE_RISE, MIGRATION_PLAN_PE_EVOLVE].includes(plan.label));
            }
            return plans.filter((plan) =>
              [MIGRATION_PLAN_PE_PLUS_EE_EVOLVE, MIGRATION_PLAN_EE_BEYOND].includes(plan.label)
            );
          case 'Shopware Enterprise':
          case 'Shopware Enterprise Basic':
          case 'Shopware Enterprise Premium':
            return plans.filter((plan) =>
              [MIGRATION_PLAN_PE_PLUS_EE_EVOLVE, MIGRATION_PLAN_EE_BEYOND].includes(plan.label)
            );
          default: {
            const regularPlans = plans.filter((plan) => [RISE, EVOLVE].includes(plan.label));

            if (convertSbpDate(shop.creationDate) >= convertSbpDate('2024-01-01 00:00:00')) {
              return regularPlans;
            }

            const migrationPlans = plans
              .filter((plan) => [MIGRATION_PLAN_PE_RISE, MIGRATION_PLAN_PE_EVOLVE].includes(plan.label))
              .filter((plan) => plan.isMigrationIncludingCE);

            return migrationPlans.length > 0 ? migrationPlans : regularPlans;
          }
        }
      })
    );
  }

  compareByShopId(a: SelfHostedShop, b: SelfHostedShop): boolean {
    return ObjectComparators.compareById(a, b);
  }

  getMigrationDomain(form: FormGroup<ShopPlanBookingForm>): string {
    return (
      form.get('shopSelection.domain').value +
      this.getSuffixForMigrationDomain(form.get('shopSelection').get('preselectedShop').value)
    );
  }

  close(shop?: Shop): void {
    this.modalRef.close(shop);
  }

  create(form: FormGroup<ShopPlanBookingForm>): void {
    const targetShopDomainSuffix = this.getSuffixForMigrationDomain(form.get('shopSelection.preselectedShop').value);
    const targetShopDomain = form.get('shopSelection.domain').value + targetShopDomainSuffix;
    const migrationSourceShop = form.get('shopSelection.preselectedShop').value;
    const isSkipBooking = form.get('planBooking.skipBooking').value === true;

    const headlinePlanBookingSuccess = !isSkipBooking
      ? this.translateService.instant(
          'SHOPS.SUCCESSOR.SHOP_MIGRATION_WIZARD.TOASTS.COMMERCIAL_PLAN_BOOKING_HEADLINE_SUCCESS'
        )
      : this.translateService.instant('SHOPS.SUCCESSOR.CREATION_MODAL.TOAST.SUCCESS.TITLE.CREATED');
    const planBookingSuccess = !isSkipBooking
      ? this.translateService.instant('SHOPS.SUCCESSOR.SHOP_MIGRATION_WIZARD.TOASTS.COMMERCIAL_PLAN_BOOKING_SUCCESS')
      : this.translateService.instant('SHOPS.SUCCESSOR.CREATION_MODAL.TOAST.SUCCESS.MESSAGE.CREATED', {
          shopDomain: migrationSourceShop.domain,
          migrationDomain: targetShopDomain,
        });

    this.shopSuccessorFacade
      .createShopForSwPlatform(migrationSourceShop, targetShopDomain)
      .pipe(
        switchMap((createdShop: SelfHostedShop) => {
          if (isSkipBooking) {
            return of(createdShop);
          }

          return this.handleCommercialPlanBooking(migrationSourceShop, form).pipe(map(() => createdShop));
        })
      )
      .subscribe({
        next: (createdShop: SelfHostedShop) => {
          this.toastService.success(headlinePlanBookingSuccess, planBookingSuccess);
          this.close(createdShop);
        },
        error: () => {
          this.toastService.error(
            this.translateService.instant(
              'SHOPS.SUCCESSOR.SHOP_MIGRATION_WIZARD.TOASTS.COMMERCIAL_PLAN_BOOKING_HEADLINE_ERROR'
            ),
            this.translateService.instant('SHOPS.SUCCESSOR.SHOP_MIGRATION_WIZARD.TOASTS.COMMERCIAL_PLAN_BOOKING_ERROR')
          );
          this.close();
        },
      });
  }

  private getSuffixForMigrationDomain(shop: null | SelfHostedShop): string {
    return StringUtils.getDomainSuffix(shop?.domain);
  }

  private handleCommercialPlanBooking(
    migrationSourceShop: SelfHostedShop,
    form: FormGroup<ShopPlanBookingForm>
  ): Observable<CompanyAllocations> {
    const planId = form.get('planBooking.plan').value.id;
    const plannedGmv = form.get('planBooking.gmv').value;
    const chargingInterval = form.get('planBooking.interval').value;
    const licenseAgreement = form.get('planBooking.contract').value;

    const data: ShopUpgradeCommercialPlanBookingInformation = {
      planId: planId,
      plannedGmv: +plannedGmv,
      chargingInterval: chargingInterval,
      licenseAgreement: licenseAgreement,
    };

    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) => {
        const isShopOwner = migrationSourceShop.companyId === companyId;
        if (isShopOwner) {
          return this.shopsFacade.handleCommercialPlanBooking(
            migrationSourceShop.companyId,
            migrationSourceShop.id,
            data
          );
        }

        return this.shopsFacade.handleCommercialPlanBookingForCustomer(
          migrationSourceShop.companyId,
          migrationSourceShop.id,
          data
        );
      })
    );
  }
}
