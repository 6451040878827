import { CompaniesFacade, LanguagesFacade } from '@account/core/facades';
import { Company, CompanyContractor, Language } from '@account/core/models';
import { CardComponent, ProgressBarComponent, SwIconComponent } from '@account/shared/components';
import { CompanyOnboardingService, OnboardingStep } from '@account/shared/services';
import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CompanyOnboardingData, CompanyOnboardingStatus } from '../../../models/onboarding/onboarding.model';
import { CompanyOnboardingState } from '../../../store/onboarding';
import { NavigationOnboardingStepComponent } from './navigation-onboarding-step/navigation-onboarding-step.component';

@Component({
  selector: 'account-navigation-onboarding',
  standalone: true,
  imports: [
    CardComponent,
    SwIconComponent,
    ProgressBarComponent,
    NavigationOnboardingStepComponent,
    TranslateModule,
    AsyncPipe,
  ],
  templateUrl: './navigation-onboarding.component.html',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class]': '["overflow-hidden"]',
  },
  preserveWhitespaces: false,
})
export class NavigationOnboardingComponent implements OnInit {
  onboardingStatus: CompanyOnboardingStatus;
  onboardingData: CompanyOnboardingData;

  onboardingSteps$: Observable<OnboardingStep[]>;

  private readonly companyOnboardingService = inject(CompanyOnboardingService);
  private readonly languagesFacade = inject(LanguagesFacade);
  private readonly companiesFacade = inject(CompaniesFacade);

  ngOnInit(): void {
    this.onboardingSteps$ = combineLatest([
      this.companyOnboardingService.getCompanyOnboardingState(),
      this.languagesFacade.getSelectedLanguage(),
      this.companiesFacade.getCompany(),
    ]).pipe(
      filter(([onboardingState]: [CompanyOnboardingState, Language, Company]) => onboardingState.data !== null),
      map(([onboardingState, selectedLanguage, company]: [CompanyOnboardingState, Language, Company]) => {
        this.onboardingStatus = onboardingState.status;
        this.onboardingData = onboardingState.data;

        return this.companyOnboardingService.getCompanyOnboardingSteps(
          onboardingState,
          company.contractor === CompanyContractor.SHOPWARE_INC,
          selectedLanguage
        );
      })
    );
  }

  getProgressBarValue(onboardingSteps: OnboardingStep[]): number {
    return Object.values(onboardingSteps).filter((step) => step.isCompleted === true).length;
  }
}
