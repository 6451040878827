import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, OperatorFunction, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { SbpException } from '../../models';
import { EnvironmentService } from '../environment';
import { CustomHttpParameterEncoder } from './custom-http-parameter.encoder';
import { FeatureFlagService } from './feature-flag.service';
import { OrySessionService } from './ory-session.service';
import { TokenService } from './token.service';

/**
 * pipe operator to create proper error for Global-ErrorHandler if request fails
 * TODO: function should be removed after `formatBlobError` has been refactored so itself will throw the error
 */
const handleError =
  (): OperatorFunction<any, any> =>
  <T>(source$: Observable<T>): Observable<T> =>
    source$.pipe(catchError((errorResponse: HttpErrorResponse) => throwError(errorResponse.error)));

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly tokenService = inject(TokenService);
  private readonly orySessionService = inject(OrySessionService);
  private readonly http = inject(HttpClient);
  private readonly featureFlagService = inject(FeatureFlagService);
  private readonly environmentService = inject(EnvironmentService);

  getList(
    path: string,
    params: HttpParams = new HttpParams(),
    headers: HttpHeaders = new HttpHeaders()
  ): Observable<any | SbpException> {
    params = this.encodeHttpParams(params);
    return this.http
      .get(`${this.environmentService.current.apiUrl}${path}`, {
        headers: this.setHeaders(headers),
        params: params,
        observe: 'response',
      })
      .pipe(
        take(1),
        map((res: HttpResponse<any>) => {
          const total = +res.headers.get('SW-Meta-Total');
          return { list: res.body, total: total };
        }),
        handleError()
      );
  }

  getResponse(
    path: string,
    params: HttpParams = new HttpParams(),
    headers: HttpHeaders = new HttpHeaders()
  ): Observable<HttpResponse<any> | SbpException> {
    params = this.encodeHttpParams(params);
    return this.http
      .get(`${this.environmentService.current.apiUrl}${path}`, {
        headers: this.setHeaders(headers),
        params: params,
        observe: 'response',
      })
      .pipe(take(1), handleError());
  }

  get(
    path: string,
    params: HttpParams = new HttpParams(),
    headers: HttpHeaders = new HttpHeaders()
  ): Observable<any | SbpException> {
    params = this.encodeHttpParams(params);
    return this.http
      .get<any>(`${this.environmentService.current.apiUrl}${path}`, {
        headers: this.setHeaders(headers),
        params: params,
      })
      .pipe(take(1), handleError());
  }

  put(path: string, body: any = {}): Observable<any | SbpException> {
    return this.http
      .put<any>(`${this.environmentService.current.apiUrl}${path}`, JSON.stringify(body), {
        headers: this.setHeaders(),
      })
      .pipe(take(1), handleError());
  }

  post(path: string, body: any = {}): Observable<any | SbpException> {
    return this.http
      .post<any>(`${this.environmentService.current.apiUrl}${path}`, JSON.stringify(body), {
        headers: this.setHeaders(),
      })
      .pipe(take(1), handleError());
  }

  delete(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    params = this.encodeHttpParams(params);
    return this.http
      .delete(`${this.environmentService.current.apiUrl}${path}`, { headers: this.setHeaders(), params: params })
      .pipe(take(1), handleError());
  }

  upload(path: string, file: File, filename?: string, params: HttpParams = new HttpParams()): Observable<any> {
    params = this.encodeHttpParams(params);
    const formData = new FormData();
    if (filename) {
      formData.append('file', file, filename);
    } else {
      formData.append('file', file);
    }
    return this.http
      .post<any>(`${this.environmentService.current.apiUrl}${path}`, formData, {
        headers: this.setHeaders().delete('Content-Type'),
        params: params,
      })
      .pipe(take(1), handleError());
  }

  uploadMultiple(
    path: string,
    files: File[],
    filenames?: string[],
    params: HttpParams = new HttpParams()
  ): Observable<any> {
    params = this.encodeHttpParams(params);
    const formData = new FormData();
    for (const [i, file] of files.entries()) {
      if (filenames && i in filenames) {
        formData.append(`file${i}`, file, filenames[i]);
      } else {
        formData.append(`file${i}`, file);
      }
    }

    return this.http
      .post<any>(`${this.environmentService.current.apiUrl}${path}`, formData, {
        headers: this.setHeaders().delete('Content-Type'),
        params: params,
      })
      .pipe(take(1), handleError());
  }

  download(
    path: string,
    params: HttpParams = new HttpParams(),
    headers: HttpHeaders = new HttpHeaders()
  ): Observable<Blob> {
    params = this.encodeHttpParams(params);
    return this.http
      .get(`${this.environmentService.current.apiUrl}${path}`, {
        headers: this.setHeaders(headers).delete('Accept'),
        params: params,
        observe: 'body',
        responseType: 'blob',
      })
      .pipe(
        take(1),
        catchError((error) => this.formatBlobError(error))
      );
  }

  downloadFromExternal(url: string): Observable<Blob> {
    return this.http
      .get(url, {
        observe: 'body',
        responseType: 'blob',
      })
      .pipe(
        take(1),

        catchError((error) => this.formatBlobError(error))
      );
  }

  private setHeaders(headers: HttpHeaders = new HttpHeaders()): HttpHeaders {
    headers = headers.set('Content-Type', 'application/json').set('Accept', 'application/json');

    const token = this.tokenService.getToken();
    let oryToken = '';
    if (this.featureFlagService.flagActivated('ory')) {
      oryToken = this.orySessionService.getToken();
    }
    if (token) {
      headers = headers.set('X-Shopware-Token', token);
    } else if (oryToken) {
      headers = headers.set('X-Shopware-Token', oryToken);
    }
    return headers;
  }

  private formatBlobError(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();

    const obs = new Observable((observer: any) => {
      reader.onloadend = (): void => {
        const rawErrorMessage = reader.result as string;
        let error: SbpException;
        if (rawErrorMessage.startsWith('<')) {
          // parse XML error response from AWS
          const parser = new DOMParser();
          const dom = parser.parseFromString(reader.result as string, 'application/xml');
          const errorCode = dom.documentElement.querySelector('Code').firstChild.nodeValue;
          const errorMessage = dom.documentElement.querySelector('Message').firstChild.nodeValue;
          error = {
            success: false,
            code: 'DownloadException-1',
            context: [
              {
                code: errorCode,
                message: errorMessage,
              },
            ],
            originalError: { name: errorCode, message: errorMessage, stack: rawErrorMessage },
          };
        } else {
          error = JSON.parse(rawErrorMessage) as SbpException;
        }
        observer.error(error);
        observer.complete();
      };
    });
    reader.readAsText(err.error);
    return obs;
  }

  private encodeHttpParams(params: HttpParams): HttpParams {
    return new HttpParams({
      fromString: params.toString(),
      encoder: new CustomHttpParameterEncoder(),
    });
  }
}
