import { inject, Injectable } from '@angular/core';

import { LocalStorageService } from '../app';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private readonly localStorageService = inject(LocalStorageService);

  getQueryFlagString(): string {
    let name = 'featureflags';
    const url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  resolveQueryString(): void {
    const queryFlagString: string = this.getQueryFlagString();

    if (queryFlagString === null) {
      return;
    }

    const flags: string[] = queryFlagString.split(',');
    if (flags.length > 0) {
      this.storeFlags(flags);
    }
  }

  flagActivated(flag: string): boolean {
    const flags: string[] = this.getFlags();
    if (flags === null) {
      return false;
    }

    if (flags.find((x: string) => x === 'none') !== undefined) {
      return false;
    }

    if (flags.find((x: string) => x === 'all') !== undefined) {
      return true;
    }

    return flags.find((x: string) => x === flag) !== undefined;
  }

  getFlags(): string[] {
    const flags: string = this.localStorageService.getItem('featureflags');

    if (flags === undefined || flags === '') {
      return [];
    }

    if (flags === null) {
      return [];
    }

    return flags.split(',');
  }

  private storeFlags(flags: string[]): void {
    const noneKey: string = flags.find((x: string) => x === 'none');
    if (noneKey !== undefined) {
      this.localStorageService.setItem('featureflags', 'none');
    }
    const allKey: string = flags.find((x: string) => x === 'all');
    if (allKey !== undefined) {
      this.localStorageService.setItem('featureflags', 'all');
      return;
    }
    this.localStorageService.setItem('featureflags', flags.join());
  }
}
