import {
  CloudShopCancellationReasonInfo,
  CloudShopCancellationReasonOption,
  CloudShopCancellationReasonOptionInfo,
  CloudShopReductionReason,
} from '@account/core/models';
import { inject, Injectable, Pipe, PipeTransform, Provider } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'cloudShopReductionReasonLabel',
  pure: false,
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class CloudShopReductionReasonLabelPipe implements PipeTransform {
  private readonly translateService = inject(TranslateService);
  private value = '';
  private lastReason: null | CloudShopReductionReason = null;
  private lastLangKey: null | string = null;

  transform(reason: CloudShopReductionReason): string {
    if (reason === this.lastReason && this.lastLangKey === this.translateService.currentLang) {
      return this.value;
    }
    this.lastReason = reason;
    this.lastLangKey = this.translateService.currentLang;
    const reasonInfo = reason.infos.find(
      (info: CloudShopCancellationReasonInfo) => info.locale.slice(0, 2) === this.translateService.currentLang
    );
    this.value = reasonInfo.label;
    return this.value;
  }
}

@Pipe({
  name: 'cloudShopReductionReasonDescription',
  pure: false,
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class CloudShopReductionReasonDescriptionPipe implements PipeTransform {
  private readonly translateService = inject(TranslateService);
  private value = '';
  private lastReason: null | CloudShopReductionReason = null;
  private lastLangKey: null | string = null;

  transform(reason: CloudShopReductionReason): string {
    if (reason === this.lastReason && this.lastLangKey === this.translateService.currentLang) {
      return this.value;
    }
    this.lastReason = reason;
    this.lastLangKey = this.translateService.currentLang;
    const reasonInfo = reason.infos.find(
      (info: CloudShopCancellationReasonInfo) => info.locale.slice(0, 2) === this.translateService.currentLang
    );
    this.value = reasonInfo.description;
    return this.value;
  }
}

@Pipe({
  name: 'cloudShopReductionReasonPlaceholder',
  pure: false,
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class CloudShopReductionReasonPlaceholderPipe implements PipeTransform {
  private readonly translateService = inject(TranslateService);
  private value = '';
  private lastReason: null | CloudShopReductionReason = null;
  private lastLangKey: null | string = null;

  transform(reason: CloudShopReductionReason): string {
    if (reason === this.lastReason && this.lastLangKey === this.translateService.currentLang) {
      return this.value;
    }
    this.lastReason = reason;
    this.lastLangKey = this.translateService.currentLang;
    const reasonInfo = reason.infos.find(
      (info: CloudShopCancellationReasonInfo) => info.locale.slice(0, 2) === this.translateService.currentLang
    );
    this.value = reasonInfo.placeholder;
    return this.value;
  }
}

@Pipe({
  name: 'cloudShopReductionReasonOption',
  pure: false,
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class CloudShopReductionReasonOptionPipe implements PipeTransform {
  private readonly translateService = inject(TranslateService);
  private value = '';
  private lastOption: null | CloudShopCancellationReasonOption = null;
  private lastLangKey: null | string = null;

  transform(option: CloudShopCancellationReasonOption): string {
    if (option === this.lastOption && this.lastLangKey === this.translateService.currentLang) {
      return this.value;
    }
    this.lastOption = option;
    this.lastLangKey = this.translateService.currentLang;
    const reasonInfo = option.infos.find(
      (info: CloudShopCancellationReasonOptionInfo) => info.locale.slice(0, 2) === this.translateService.currentLang
    );
    this.value = reasonInfo.label;
    return this.value;
  }
}

export const CloudShopReductionReasonPipes: Provider[] = [
  CloudShopReductionReasonOptionPipe,
  CloudShopReductionReasonPlaceholderPipe,
  CloudShopReductionReasonDescriptionPipe,
  CloudShopReductionReasonLabelPipe,
];
