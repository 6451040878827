import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ExtensionPartnerRevenueListItem, ListResult, QueryFilter, SbpException } from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { ApiService } from '../../services';
import { HttpParamsBuilder } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class ExtensionPartnerRevenuesGateway {
  private readonly apiService = inject(ApiService);

  getRevenueBalance(producerId: number, filters: object | QueryFilter[]): Observable<number> {
    const params = HttpParamsBuilder.applyFilters(new HttpParams(), filters);
    return this.apiService
      .get(`/producers/${producerId}/extensionpartnerrevenuebalance`, params)
      .pipe(map((response: { value: number }) => response.value));
  }

  getRevenueList(
    producerId: number,
    metaData: RequestMetaData,
  ): Observable<ListResult<ExtensionPartnerRevenueListItem[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    params = HttpParamsBuilder.applyFilters(params, metaData.filter);
    return this.apiService.getList(`/producers/${producerId}/extensionpartnerrevenues`, params);
  }

  downloadRevenueListAsExport(
    producerId: number,
    locale: string,
    metaData: RequestMetaData,
  ): Observable<Blob | SbpException> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData).set('limit', '0');
    params = HttpParamsBuilder.applyFilters(params, metaData.filter);
    return this.apiService.download(`/producers/${producerId}/extensionpartnerrevenuesexport/${locale}`, params);
  }
}
