import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DocumentsGateway } from '../../gateways/common';
import { Document } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class DocumentsFacade {
  private readonly documentsGateway = inject(DocumentsGateway);

  getDocuments(type: string): Observable<Document[]> {
    return this.documentsGateway.getDocuments(type);
  }

  getDocumentByVersion(type: string, version: string): Observable<Document> {
    return this.documentsGateway.getDocumentByVersion(type, version);
  }
}
