import {
  CommercialPlanFacade,
  PluginStaticsFacade,
  ShopsFacade,
  UpgradeToCommercialPlanFacade,
} from '@account/core/facades';
import {
  CommercialContract,
  CommercialPlan,
  Company,
  DomainVerificationTokenData,
  EVOLVE,
  QueryFilter,
  RISE,
  SbpException,
  SelfHostedShop,
  ShopCreationSkeleton,
  ShopEnvironment,
  ShopUpgradeCommercialPlanBookingInformation,
  SoftwareVersion,
} from '@account/core/models';
import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, switchMap } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';

import { MODAL_DATA } from '../../../../shared/components/modal/modal-injector';
import { ModalRef } from '../../../../shared/components/modal/modal-ref';
import { ToastService } from '../../../../shared/components/toast/toast.service';
import { ModalWizardComponent } from '../../../../shared/components/wizard/modal-wizard/modal-wizard.component';
import { StepComponent } from '../../../../shared/components/wizard/step/step.component';
import { StepsComponent } from '../../../../shared/components/wizard/steps/steps.component';
import {
  PlanBookingConfig,
  PlanBookingModalBaseComponent,
  ShopPlanBookingForm,
} from '../plan-booking-modal-base/plan-booking-modal-base.component';
import { DomainVerificationModalService } from '../shared-elements/domain-verification/domain-verification-modal.service';
import { InstructionElementComponent } from '../shared-elements/domain-verification/instruction-element/instruction-element.component';
import { ShopCreationModalDomainNamingStepComponent } from './steps/domain-naming-step/domain-naming-step.component';
import { ShopCreationModalDomainVerificationStepComponent } from './steps/domain-verification-step/domain-verification-step.component';
import { ShopCreationModalTeaserStepComponent } from './steps/teaser-step/teaser-step.component';

export interface ShopCreationModalConfig {
  assignee: Company;
  context: 'partner' | 'partnerCustomer' | 'shop';
  shopwareMajorVersion?: number;
}

@Component({
  selector: 'account-shop-creation-modal',
  templateUrl: './shop-creation-modal.component.html',
  styleUrl: './shop-creation-modal.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalWizardComponent,
    FormsModule,
    ReactiveFormsModule,
    StepsComponent,
    StepComponent,
    NgIf,
    ShopCreationModalDomainNamingStepComponent,
    InstructionElementComponent,
    ShopCreationModalDomainVerificationStepComponent,
    ShopCreationModalTeaserStepComponent,
    AsyncPipe,
    TranslateModule,
    PlanBookingModalBaseComponent,
  ],
})
export class ShopCreationModalComponent implements OnInit {
  private readonly modalRef = inject(ModalRef);
  private readonly formBuilder = inject(UntypedFormBuilder);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly shopsFacade = inject(ShopsFacade);
  private readonly pluginStaticsFacade = inject(PluginStaticsFacade);
  private readonly domainVerificationModalService = inject(DomainVerificationModalService);
  private readonly translateService = inject(TranslateService);
  private readonly toastService = inject(ToastService);
  private readonly commercialPlanFacade = inject(CommercialPlanFacade);
  private readonly upgradeToCommercialPlanFacade = inject(UpgradeToCommercialPlanFacade);
  config = inject<ShopCreationModalConfig>(MODAL_DATA);
  loading = false;
  loadingText: string;

  form: UntypedFormGroup;

  activeStep: 'domainName' | 'instruction' | 'booking' | 'verification' = 'domainName';

  verificationResult: boolean = null;
  domainVerificationTokenData: DomainVerificationTokenData = null;

  softwareVersions$: Observable<SoftwareVersion[]>;
  shopEnvironments$: Observable<ShopEnvironment[]>;

  private _domainName: string;
  protected planBookingConfig: PlanBookingConfig;
  protected commercialPlansFn: (shop: SelfHostedShop) => Observable<CommercialPlan[]>;

  ngOnInit(): void {
    this.loadingText = this.translateService.instant('COMMON.SHOP.VERIFICATION.LOADING_TEXT');
    this.form = this.domainVerificationModalService.createForm('shopCreation');
    this.shopType = 'self-hosted';

    if (this.config.shopwareMajorVersion) {
      let versionFilter: QueryFilter[];
      if (this.config.shopwareMajorVersion === 5) {
        versionFilter = [{ property: 'pluginGeneration', value: 'classic' }];
      } else if (this.config.shopwareMajorVersion === 6) {
        versionFilter = [{ property: 'pluginGeneration', value: 'platform' }];
      }
      this.softwareVersions$ = this.pluginStaticsFacade.getPluginStatics(
        'softwareVersions',
        versionFilter
      ) as Observable<SoftwareVersion[]>;
    } else {
      this.softwareVersions$ = this.pluginStaticsFacade.getPluginStatics('softwareVersions') as Observable<
        SoftwareVersion[]
      >;
    }

    this.shopEnvironments$ = this.shopsFacade
      .getShopEnvironments()
      .pipe(
        map((shopEnvironments: ShopEnvironment[]) =>
          shopEnvironments.filter((environment) => environment.name !== 'unknown')
        )
      );

    if (this.config.context !== 'shop') {
      this.shopType = 'self-hosted';
    }

    this.commercialPlansFn = this.getCommercialPlans.bind(this);

    this.planBookingConfig = {
      shopCompanyId: this.config.assignee.id,
      partnerCustomer: this.config.context === 'partnerCustomer',
      intent: 'registration',
    };
  }

  verifyDomain(): void {
    this.loading = true;
    this.domainVerificationModalService.verifyDomain(this.domainName).subscribe(
      (domainVerified: boolean) => {
        this.domainVerified = domainVerified;
        this.verificationResult = domainVerified;
        this.loading = false;

        if (domainVerified) {
          this.toastService.success(
            this.translateService.instant('COMMON.SHOP.VERIFICATION.TOAST.SUCCESS.TITLE.DOMAIN_VERIFICATION'),
            this.translateService.instant(
              'COMMON.SHOP.VERIFICATION.TOAST.SUCCESS.MESSAGE.DOMAIN_VERIFICATION.CONFIRM_GTC'
            )
          );
        }

        this.changeDetectorRef.detectChanges();
      },
      () => {
        this.loading = false;
      }
    );
  }

  getCommercialPlans(): Observable<CommercialPlan[]> {
    return this.commercialPlanFacade.getCommercialRegularPlans().pipe(
      map((plans: CommercialPlan[]) => plans.filter((plan: CommercialPlan) => [RISE, EVOLVE].includes(plan.label))),
      shareReplay()
    );
  }

  createSelfHostedShopAndClose(bookingForm?: FormGroup<ShopPlanBookingForm>): void {
    this.loading = true;

    const createdShop$ = bookingForm
      ? this.createSelfHostedShopWithPotentialBooking(bookingForm)
      : this.createSelfHostedShop();

    createdShop$.subscribe({
      next: (shopCreated: SelfHostedShop) => {
        switch (this.config.context) {
          case 'partnerCustomer': {
            const customerCompanyName = this.config.assignee.name;
            this.toastService.success(
              this.translateService.instant('COMMON.SHOP.SHOP_CREATION_MODAL.TOAST.SUCCESS.TITLE.CREATED'),
              this.translateService.instant(
                'COMMON.SHOP.SHOP_CREATION_MODAL.TOAST.SUCCESS.MESSAGE.CREATED_FOR_CUSTOMER',
                { domain: shopCreated.domain, customer: customerCompanyName }
              )
            );
            break;
          }
          case 'shop':
          case 'partner':
          default:
            this.toastService.success(
              this.translateService.instant('COMMON.SHOP.SHOP_CREATION_MODAL.TOAST.SUCCESS.TITLE.CREATED'),
              this.translateService.instant('COMMON.SHOP.SHOP_CREATION_MODAL.TOAST.SUCCESS.MESSAGE.CREATED', {
                domain: shopCreated.domain,
              })
            );
        }
        this.close(shopCreated);
      },
      error: (error: SbpException) => {
        switch (error.code) {
          case 'UserShopsException-6':
            this.toastService.error(
              this.translateService.instant('COMMON.SHOP.SHOP_CREATION_MODAL.TOAST.ERROR.TITLE.CREATION_FAILED'),
              this.translateService.instant('COMMON.SHOP.VERIFICATION.TOAST.ERROR.MESSAGE.DOMAIN_ALREADY_IN_USE', {
                domain: this.domainName,
              })
            );
            break;
          default:
            this.toastService.error(
              this.translateService.instant('COMMON.SHOP.SHOP_CREATION_MODAL.TOAST.ERROR.TITLE.CREATION_FAILED'),
              this.translateService.instant('COMMON.SHOP.SHOP_CREATION_MODAL.TOAST.ERROR.MESSAGE.CREATION_FAILED', {
                domain: this.domainName,
              })
            );
        }
        this.loading = false;
      },
    });
  }

  createShopAndClose(bookingForm?: FormGroup<ShopPlanBookingForm>): void {
    if (this.shopType === 'self-hosted') {
      this.createSelfHostedShopAndClose(bookingForm);
    }
  }

  close(shopCreated: SelfHostedShop | null = null): void {
    this.modalRef.close(shopCreated);
  }

  set domainVerified(verified: boolean) {
    this.domainVerificationModalService.patchDomainVerificationStatus(this.form, verified);
  }

  domainRenamingStepFinished(): void {
    // reset validation if user has already validated the domain, but navigates back and changes the domain name
    if (this._domainName !== this.domainName) {
      if (!this.domainVerificationRequired()) {
        this.verificationResult = null;
        this.domainVerificationModalService.patchDomainVerificationStatus(this.form, false);
      }
    }
    this._domainName = this.domainName;

    this.shopsFacade
      .requestVerificationHashForDomain(this.domainName)
      .pipe(take(1))
      .subscribe((result: DomainVerificationTokenData) => {
        this.domainVerificationTokenData = result;
        this.changeDetectorRef.detectChanges();
      });
  }

  returnToDomainRenamingStep(): void {
    this.activeStep = 'domainName';
  }

  initializeInstructionStep(): void {
    this.activeStep = 'instruction';
  }

  initializeDomainRenamingStep(): void {
    this.activeStep = 'domainName';
  }

  initializeVerificationStep(): void {
    this.activeStep = 'verification';
  }

  initializeBookingStep(): void {
    this.activeStep = 'booking';
    this.planBookingConfig.domain = this.domainName;
  }

  get wizardHeading(): string {
    // TODO: fix confusing wording as soon as the list of the shops owned by the partner has been removed from the partner workspace
    switch (this.config.context) {
      case 'partnerCustomer':
        return this.translateService.instant('COMMON.SHOP.SHOP_CREATION_MODAL.HEADING.REGISTER_FOR_CUSTOMER');
      case 'partner':
      default:
        return this.translateService.instant('COMMON.SHOP.SHOP_CREATION_MODAL.HEADING.REGISTER');
    }
  }

  get domainName(): string {
    return this.form.get('domain.domainName').value;
  }

  get shopEnvironment(): ShopEnvironment {
    return this.form.get('domain.shopEnvironment').value;
  }

  get shopwareVersion(): SoftwareVersion {
    return this.form.get('domain.shopwareVersion').value;
  }

  set shopType(shopType: 'self-hosted') {
    this.form.get('shopType.name').patchValue(shopType);
  }

  get shopType(): 'self-hosted' {
    return this.form.get('shopType.name').value;
  }

  domainVerificationRequired(): boolean {
    return !this.domainVerificationModalService.isDomainVerified(this.form);
  }

  isDomainVerified(): boolean {
    return this.verificationResult;
  }

  private createSelfHostedShop(): Observable<SelfHostedShop> {
    const shop: ShopCreationSkeleton = {
      domain: this.domainName,
      environment: this.shopEnvironment.name,
      shopwareVersion: this.shopwareVersion.name,
      companyId: this.config.assignee.id,
    };
    return this.shopsFacade.addShop(shop);
  }

  private createSelfHostedShopWithPotentialBooking(
    bookingForm: FormGroup<ShopPlanBookingForm>
  ): Observable<SelfHostedShop> {
    if (bookingForm.get('planBooking.skipBooking').value === true) {
      return this.createSelfHostedShop();
    }

    return this.createSelfHostedShop().pipe(
      switchMap((shop: SelfHostedShop) => {
        const isShopOwner = shop.companyId === this.planBookingConfig.shopCompanyId;
        const planId = bookingForm.get('planBooking.plan').value.id;
        const plannedGmv = bookingForm.get('planBooking.gmv').value;
        const chargingInterval = bookingForm.get('planBooking.interval').value;
        const licenseAgreement = bookingForm.get('planBooking.contract').value;

        const data: ShopUpgradeCommercialPlanBookingInformation = {
          planId,
          plannedGmv,
          chargingInterval,
          licenseAgreement,
        };

        if (isShopOwner) {
          return this.upgradeToCommercialPlanFacade.handleUpgrade(shop.id, data).pipe(
            map((commercialContract: CommercialContract) => {
              shop.commercialContract = commercialContract;
              return shop;
            })
          );
        }

        return this.upgradeToCommercialPlanFacade.handleUpgradeForCustomer(shop.companyId, shop.id, data).pipe(
          map((commercialContract: CommercialContract) => {
            shop.commercialContract = commercialContract;
            return shop;
          })
        );
      })
    );
  }
}
