import { NavigationFacade, NotificationsFacade } from '@account/core/facades';
import { Overlay } from '@angular/cdk/overlay';
import { AsyncPipe, CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ComponentOverlayService } from '../../../shared/components/component-overlay/component-overlay.service';
import { SwIconComponent } from '../../../shared/components/sw-icon/sw-icon.component';
import { LocalStorageService } from '../../services';
import { BreadcrumbsComponent } from '../breadcrumbs';
import { ActionBarService } from './action-bar.service';

@Component({
  selector: 'account-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrl: './action-bar.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, AsyncPipe, TranslateModule, BreadcrumbsComponent, SwIconComponent],
  preserveWhitespaces: false,
})
export class ActionBarComponent implements OnInit {
  private readonly navigationFacade = inject(NavigationFacade);
  private readonly notificationsFacade = inject(NotificationsFacade);
  private readonly overlayService = inject(ComponentOverlayService);
  private readonly overlay = inject(Overlay);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly actionBarService = inject(ActionBarService);
  private readonly cdr = inject(ChangeDetectorRef);
  @ViewChild('notificationWrap') container: ElementRef;

  actionTemplateRef: null | TemplateRef<Element> = null;

  ngOnInit(): void {
    this.actionBarService.getActionBar().subscribe((actionBar: TemplateRef<Element>) => {
      this.actionTemplateRef = actionBar;
      this.cdr.detectChanges();
    });
  }

  toggleNavigation(): void {
    this.navigationFacade.toggleNavigation();
  }
}
