import { HttpParams, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ListResult,
  SbpException,
  SupportTicket,
  SupportTicketAnswer,
  SupportTicketAnswerRating,
  SupportTicketInternalComment,
  SupportTicketStatus,
  SupportTicketWithBasicInformation,
  TimeZone,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { Plugin } from '../../models/plugin/plugin.model';
import { SupportMatrixEntry, SupportTicketAttachment } from '../../models/support/support.model';
import { ApiService } from '../../services';
import { HttpParamsBuilder } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class SupportTicketGateway {
  private readonly apiService = inject(ApiService);

  getSupportTicketListByCompany(
    companyId: number,
    metaData: RequestMetaData,
    filter: Record<string, any> = {}
  ): Observable<ListResult<SupportTicketWithBasicInformation[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    params = HttpParamsBuilder.applyFilters(params, filter);

    Object.keys(filter).forEach((key) => {
      params = params.set(key, filter[key]);
    });

    return this.apiService.getList(`/companies/${companyId}/supportticketlist`, params);
  }

  getSupportTicketListByProducer(
    metaData: RequestMetaData,
    producerId: number,
    filter: Record<string, any> = {}
  ): Observable<ListResult<SupportTicketWithBasicInformation[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    params = HttpParamsBuilder.applyFilters(params, filter);

    Object.keys(filter).forEach((key) => {
      params = params.set(key, filter[key]);
    });

    return this.apiService.getResponse(`/producers/${producerId}/supporttickets`, params).pipe(
      map((res: HttpResponse<any>) => {
        const total = +res.headers.get('SW-Meta-Total');

        const prefix = 'sw-meta-status-count-';
        const facets: Record<string, number> = {};
        res.headers.keys().forEach((key) => {
          if (key.toLowerCase().startsWith(prefix)) {
            facets[key.toLowerCase().replace(prefix, '')] = +res.headers.get(key);
          }
        });
        return { list: res.body, total: total, facets: { statusCount: facets } };
      })
    );
  }

  createTicket(companyId: number, data: any): Observable<SupportTicketWithBasicInformation> {
    return this.apiService.post(`/companies/${companyId}/supporttickets`, data);
  }

  getSupportTicket(companyId: number, ticketId: number): Observable<SupportTicket> {
    return this.apiService.get(`/companies/${companyId}/supporttickets/${ticketId}`);
  }

  getSupportTicketAsProducer(producerId: number, ticketId: number): Observable<SupportTicket> {
    return this.apiService.get(`/producers/${producerId}/supporttickets/${ticketId}`);
  }

  updateSupportTicketStatus(companyId: number, ticketId: number, data: any): Observable<SupportTicketStatus> {
    return this.apiService.put(`/companies/${companyId}/supporttickets/${ticketId}/status`, data);
  }

  updateSupportTicketStatusAsProducer(
    producerId: number,
    ticketId: number,
    data: any
  ): Observable<SupportTicketStatus> {
    return this.apiService.put(`/producers/${producerId}/supporttickets/${ticketId}/status`, data);
  }

  validateAttachment(companyId: number, file: File): Observable<void> {
    return this.apiService.upload(`/companies/${companyId}/supporttickets/validateattachment`, file);
  }

  uploadAttachment(companyId: number, file: File): Observable<SupportTicketAttachment> {
    return this.apiService.upload(`/companies/${companyId}/supporttickets/attachments`, file);
  }

  getDownloadUrlForAttachment(
    ticket: SupportTicket,
    attachment: SupportTicketAttachment,
    companyId: number
  ): Observable<SupportTicketAttachment | SbpException> {
    let params = new HttpParams().set('json', 'true');
    if (attachment.uniqueFilename) {
      params = params.append('uniqueFilename', attachment.uniqueFilename);
    }
    return this.apiService.get(
      `/companies/${companyId}/supporttickets/${ticket.id}/attachments/${attachment.id}`,
      params
    );
  }

  downloadAttachmentFile(url: string): Observable<Blob | SbpException> {
    return this.apiService.downloadFromExternal(url);
  }

  addAnswer(ticket: SupportTicket, companyId: number, data: SupportTicketAnswer): Observable<void> {
    return this.apiService.post(`/companies/${companyId}/supporttickets/${ticket.id}/answers`, data);
  }

  addAnswerAsProducer(ticket: SupportTicket, producerId: number, data: SupportTicketAnswer): Observable<void> {
    return this.apiService.post(`/producers/${producerId}/supporttickets/${ticket.id}/answers`, data);
  }

  addInternalComment(ticket: SupportTicket, producerId: number, data: SupportTicketInternalComment): Observable<void> {
    return this.apiService.post(`/producers/${producerId}/supporttickets/${ticket.id}/internalcomments`, data);
  }

  addTicketAnswerRating(
    companyId: number,
    ticket: SupportTicket,
    answer: SupportTicketAnswer,
    data: any
  ): Observable<SupportTicketAnswerRating> {
    return this.apiService.post(
      `/companies/${companyId}/supporttickets/${ticket.id}/answers/${answer.id}/rating`,
      data
    );
  }

  getEstimatedDeadline(
    typeName: string,
    entry?: SupportMatrixEntry,
    plugin?: Plugin,
    timeZone?: TimeZone
  ): Observable<string> {
    let params = new HttpParams();
    params = params.set('typeName', typeName);
    if (entry) {
      params = params.append('entryId', entry.id.toString());
    }
    if (plugin) {
      params = params.append('pluginId', plugin.id.toString());
    }
    if (timeZone) {
      params = params.append('timeZone', timeZone);
    }
    return this.apiService.get('/supporttickets/deadline', params);
  }
}
