<div
  class="banned-container"
  [style.background-color]="'#FF5630'"
  [style.padding]="'2px'"
  [style.color]="'white'"
  [matTooltip]="tooltip | translate"
  matTooltipShowDelay="300">
  <div>
    <account-sw-icon class="banned-icon" size="12px" icon="times-hexagon" />
    <p class="banned-attention">{{ 'CORE.BANNED.HEADING' | translate }}:&nbsp;</p>
    <p class="banned-info-text" [innerHTML]="infoText | translate | safe: 'html'"></p>
  </div>
</div>
