import { Action, createReducer, on } from '@ngrx/store';

import { UserAccount } from '../../../models';
import { AsyncState } from '../../async.state';
import * as actions from './useraccount.actions';

export interface UserAccountState extends AsyncState {
  userAccount: UserAccount;
}

export const initialUserAccountState: UserAccountState = {
  loading: false,
  loaded: false,
  error: undefined,
  userAccount: undefined,
};

const userAccountReducerInternal = createReducer(
  initialUserAccountState,

  on(
    actions.createUserAccount,
    actions.getUserAccount,
    actions.updateUserAccount,
    actions.createCompany,
    (state: UserAccountState) => ({
      ...state,
      loading: true,
    })
  ),

  on(actions.createCompany, (state: UserAccountState) => ({
    ...state,
    error: undefined as undefined,
  })),

  on(actions.changeSelectedMembership, (state: UserAccountState, { payload }) => ({
    ...state,
    userAccount: {
      ...state.userAccount,
      selectedMembership: payload,
    },
    error: undefined,
  })),

  on(actions.noUserAccountExists, (state: UserAccountState) => ({
    ...state,
    loaded: true,
    loading: false,
    error: undefined as undefined,
    userAccount: undefined as undefined,
  })),

  on(
    actions.createUserAccountSuccess,
    actions.createUserAccountFail,
    actions.getUserAccountSuccess,
    actions.getUserAccountFail,
    actions.updateUserAccountSuccess,
    actions.updateUserAccountFail,
    (state: UserAccountState) => ({
      ...state,
      loading: false,
      loaded: true,
    })
  ),

  on(actions.createCompanySuccess, actions.createCompanyFail, (state: UserAccountState) => ({
    ...state,
    loading: false,
    loaded: true,
  })),

  on(
    actions.createUserAccountSuccess,
    actions.getUserAccountSuccess,
    actions.updateUserAccountSuccess,
    (state: UserAccountState, { payload }) => ({
      ...state,
      userAccount: {
        ...state.userAccount,
        ...payload,
      },
      error: undefined,
    })
  ),

  on(
    actions.createUserAccountFail,
    actions.getUserAccountFail,
    actions.updateUserAccountFail,
    actions.createCompanyFail,
    (state: UserAccountState, { payload }) => ({
      ...state,
      error: payload,
    })
  )
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function reducer(state: UserAccountState | undefined, action: Action): UserAccountState {
  return userAccountReducerInternal(state, action);
}
