import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MarketDefinition } from '../../models';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class MarketDefinitionsGateway {
  private readonly apiService = inject(ApiService);

  getMarketDefinitions(companyId: number): Observable<MarketDefinition[]> {
    return this.apiService.get(`/companies/${companyId}/marketdefinitions`);
  }
}
