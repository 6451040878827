import { Salutation } from '@account/core/models';
import { NgIf, UpperCasePipe } from '@angular/common';
import { Component, HostBinding, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { objectIsEqual } from '../../../util';

export interface RealName {
  salutation?: Salutation | string;
  firstName: string;
  lastName: string;
}

@Component({
  selector: 'account-avatar',
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.less',
  standalone: true,
  imports: [NgIf, UpperCasePipe],
})
export class AvatarComponent implements OnInit, OnChanges {
  private readonly sanitizer = inject(DomSanitizer);
  @Input() initials: string;
  @Input() realName: RealName;
  @Input() colorInitials = '#FFFFFF';
  @Input() orientation: 'row' | 'column' = 'row';
  // TODO: make tooltip work
  @Input() showTooltip = false;
  @Input() @HostBinding('style.background-color') backgroundColor: string;
  tooltip: string;

  @HostBinding('style.background-image') private _background: SafeStyle;
  @HostBinding('style.width') private _width = '100%';
  @HostBinding('style.padding-top') private _paddingTop = '100%';
  private _srcUrl: string;

  ngOnChanges(changes: SimpleChanges): void {
    let initials = '?';
    if (Object.prototype.hasOwnProperty.call(changes, 'realName') && changes.realName.currentValue) {
      if (
        !changes.realName.firstChange &&
        objectIsEqual(changes.realName.previousValue, changes.realName.currentValue)
      ) {
        return;
      }
      const realName = changes.realName.currentValue as RealName;
      initials = realName.firstName.charAt(0) + realName.lastName.charAt(0);
      let salutation = '';
      if (realName.salutation) {
        salutation = typeof realName.salutation === 'string' ? realName.salutation : realName.salutation.name; // TODO: translate
      }
      this.tooltip = [salutation, realName.firstName, realName.lastName].join(' ').trim();
    } else if (Object.prototype.hasOwnProperty.call(changes, 'initials')) {
      if (
        !changes.initials.firstChange &&
        objectIsEqual(changes.initials.previousValue, changes.initials.currentValue)
      ) {
        return;
      }
      initials = changes.initials.currentValue;
      this.tooltip = changes.initials.currentValue;
    }

    if (initials !== this.initials && initials !== '?') {
      this.initials = initials;
      if (!Object.prototype.hasOwnProperty.call(changes, 'backgroundColor')) {
        this.backgroundColor = this.getBackgroundColorByInitials();
      }
    }
  }

  ngOnInit(): void {
    this.backgroundColor = this.getBackgroundColorByInitials();
  }

  @Input() set srcUrl(srcUrl: string) {
    if (srcUrl) {
      this._background = this.sanitizer.bypassSecurityTrustStyle(`url("${srcUrl}")`);
    }
    this._srcUrl = srcUrl;
  }

  get srcUrl(): string {
    return this._srcUrl;
  }

  @Input() set height(height: string) {
    this.updateDimensions(height);
  }

  @Input() set width(width: string) {
    this.updateDimensions(width);
  }

  private updateDimensions(value: string): void {
    if (value !== this._width) {
      this._width = value;
      // update padding top to maintain aspect ratio
      this._paddingTop = value;
    }
  }

  private getBackgroundColorByInitials(): string {
    const ordFirstLetterFirstName = this.initials.toUpperCase().charCodeAt(0);
    const ordFirstLetterLastName = this.initials.toUpperCase().charCodeAt(1);
    const valFirstName = (ordFirstLetterFirstName - 65) / 30;
    const valLastName = (ordFirstLetterLastName - 65) / 30;

    const h = Math.floor(valFirstName * 360);
    const s = `${Math.floor(valLastName * 100)}%`;
    // max value of l is 100, here it has been set to 60 in order to generate dark colors
    const l = `${Math.floor(((valFirstName + valLastName) % 2) * 60)}%`;
    return `hsl(${h},${s},${l})`;
  }
}
