<div class="a_row" [formGroup]="form">
  <div class="a_column-12 a_column-l-6 a_control is_required" data-no-margin-bottom>
    <div class="control_description">
      <strong>{{ 'COMMON.SHOP.SHOP_CREATION_MODAL.STEP.SETTINGS.SHOPWARE_VERSION.LABEL' | translate }}:</strong>
    </div>
    <div class="control_content a_select">
      <select formControlName="shopwareVersion" [compareWith]="compareByName">
        <ng-container *ngFor="let shopwareVersion of shopwareVersions">
          <option *ngIf="isSelectable(shopwareVersion)" [ngValue]="shopwareVersion">
            <ng-container>{{ shopwareVersion.name }}</ng-container>
          </option>
        </ng-container>
      </select>
    </div>
  </div>

  <div
    class="a_column-12 a_column-l-6 a_control"
    [class.is_required]="!form.get('shopEnvironment').disabled"
    [class.is_disabled]="form.get('shopEnvironment').disabled"
    data-no-margin-bottom>
    <div class="control_description">
      <strong>{{ 'COMMON.SHOP.SHOP_CREATION_MODAL.STEP.SETTINGS.SHOP_ENVIRONMENT.LABEL' | translate }}:</strong>
    </div>
    <div class="control_content a_select">
      <select formControlName="shopEnvironment" [compareWith]="compareByName">
        <option [value]="null" disabled>
          {{ 'COMMON.SHOP.SHOP_CREATION_MODAL.STEP.SETTINGS.SHOP_ENVIRONMENT.PLACEHOLDER' | translate }}
        </option>
        <option *ngFor="let shopEnvironment of shopEnvironments" [ngValue]="shopEnvironment">
          <ng-container>{{ 'COMMON.SHOP.ENVIRONMENT.' + shopEnvironment.name | uppercase | translate }}</ng-container>
        </option>
      </select>
    </div>
  </div>
  <account-alert
    *ngIf="form.get('shopEnvironment').value && form.get('shopEnvironment').value.name === 'test'"
    [type]="'info'">
    {{ 'COMMON.SHOP.SHOP_CREATION_MODAL.STEP.SETTINGS.SHOP_ENVIRONMENT.TEST_INFO' | translate }}
  </account-alert>
</div>
