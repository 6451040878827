import { MODAL_DATA, ModalComponent, ModalRef } from '@account/shared/components';
import { NgClass } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

interface ModalData {
  key: string;
}

@Component({
  selector: 'account-license-key-modal',
  templateUrl: './license-key-modal.component.html',
  styleUrl: './license-key-modal.component.less',
  standalone: true,
  imports: [NgClass, TranslateModule, ModalComponent],
})
export class LicenseKeyModalComponent implements OnInit {
  private readonly modalRef = inject(ModalRef);
  private readonly modalData = inject<ModalData>(MODAL_DATA);
  key: string;

  highlighted = false;

  ngOnInit(): void {
    this.key = this.modalData.key;
  }

  copyToClipboard(): void {
    window.navigator.clipboard.writeText(this.key);

    this.highlighted = true;

    setTimeout(() => {
      this.highlighted = false;
    }, 500);
  }

  closeModal(): void {
    this.modalRef.close();
  }
}
