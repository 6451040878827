import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { inject, Injectable, Injector, Type } from '@angular/core';

import { ComponentOverlayInjector } from './component-overlay-injector';
import { ComponentOverlayRef } from './component-overlay-ref';

@Injectable({
  providedIn: 'root',
})
export class ComponentOverlayService {
  private readonly injector = inject(Injector);
  private readonly overlay = inject(Overlay);

  create(component: Type<any>, config?: OverlayConfig, data?: any): ComponentOverlayRef {
    const injector = this.injector;
    const componentOverlayRef = new ComponentOverlayRef();
    const tabInjector = new ComponentOverlayInjector(injector, componentOverlayRef, data || null);

    const overlayRef = this.overlay.create(config);
    const componentPortal = new ComponentPortal(component, null, tabInjector);

    overlayRef.attach(componentPortal);
    overlayRef.backdropClick().subscribe(() => componentOverlayRef.close());

    componentOverlayRef.afterClosed().subscribe(() => {
      overlayRef.detach();
    });

    return componentOverlayRef;
  }
}
