import { companySelectors } from '@account/core/store/selectors';
import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { PartnersGateway } from '../../gateways/partner';
import {
  ListResult,
  Locale,
  Partner,
  PartnerAvatar,
  PartnerBranch,
  PartnerCompanySizeRange,
  PartnerOperatingCountry,
  PartnerProjectType,
  PartnerResource,
  SbpException,
  SupportPermissions,
} from '../../models';
import { PartnerFeatureState, partnerStore } from '../../store/partner';
import { RootState } from '../../store/root.state';
import { SessionFacade } from '../app';
import { AuthFacade } from '../auth';

@Injectable({
  providedIn: 'root',
})
export class PartnersFacade {
  private readonly store = inject<Store<RootState & PartnerFeatureState>>(Store<RootState & PartnerFeatureState>);
  private readonly authFacade = inject(AuthFacade);
  private readonly sessionFacade = inject(SessionFacade);
  private readonly partnerGateway = inject(PartnersGateway);

  getPartner(): Observable<Partner> {
    return this.findPartner().pipe(filter((partner: Partner) => !!partner));
  }

  getPartnerById(companyId: number): Observable<Partner> {
    return this.partnerGateway.getPartner(companyId);
  }

  getPartnerOnce(): Observable<Partner> {
    return this.getPartner().pipe(take(1));
  }

  getPartnerIdOnce(): Observable<number> {
    return this.getPartnerId().pipe(take(1));
  }

  refreshPartner(): void {
    this.store.dispatch(partnerStore.actions.partner.refreshPartner());
  }

  updatePartner(partner: Partner): Observable<Partner> {
    return this.getPartnerOnce().pipe(
      switchMap((originalPartner: Partner) => {
        const countries = partner.operatingCountries; // partner.operatingCountries.map((country) => country.country);
        const originalCountries = originalPartner.operatingCountries; // originalPartner.operatingCountries.map((country) => country.country);
        const languages = partner.languages;
        const originalLanguages = originalPartner.languages;
        const branches = partner.branches;
        const originalBranches = originalPartner.branches;

        const countriesToDelete: Observable<void | PartnerOperatingCountry>[] = originalCountries
          .filter((country) => !countries.find((oCountry) => country.id === oCountry.id))
          .map((country) => this.partnerGateway.deletePartnerOperatingCountry(partner, country));
        const countriesToAdd = countries
          .filter((country) => !originalCountries.find((oCountry) => country.id === oCountry.id))
          .map((country) => this.partnerGateway.addPartnerOperatingCountry(partner, country));

        const languagesToDelete: Observable<void | Locale>[] = originalLanguages
          .filter((language) => !languages.find((oLanguage) => language.id === oLanguage.id)) // filters every removed language
          .map((language) => this.partnerGateway.deletePartnerLanguage(partner, language));
        const languagesToAdd = languages
          .filter((language) => !originalLanguages.find((oLanguage) => language.id === oLanguage.id))
          .map((language) => this.partnerGateway.addPartnerLanguage(partner, language));

        const branchesToDelete: Observable<void | PartnerBranch>[] = branches
          .filter((branch) => !originalBranches.find((oBranch) => branch.id === oBranch.id))
          .map((branch) => this.partnerGateway.deletePartnerBranch(partner, branch));
        const branchesToAdd = branches
          .filter((branch) => !originalBranches.find((oBranch) => branch.id === oBranch.id))
          .map((branch) => this.partnerGateway.addPartnerBranch(partner, branch));

        const updateRequest$ = forkJoin([
          this.partnerGateway.updatePartnerContactChannels(partner, partner.contactChannels),
          this.partnerGateway.updatePartnerResources(partner, partner.freeResources),
          ...countriesToDelete,
          ...countriesToAdd,
          ...languagesToDelete,
          ...languagesToAdd,
          ...branchesToDelete,
          ...branchesToAdd,
        ]);

        return updateRequest$.pipe(
          switchMap(() => this.partnerGateway.updatePartner(partner)),
          tap((partner: Partner) => {
            this.store.dispatch(partnerStore.actions.partner.updatePartnerSuccess({ payload: partner }));
          })
        );
      })
    );
  }

  getPartnerLanguages(): Observable<ListResult<Locale[]>> {
    return this.partnerGateway.getPartnerLanguages();
  }

  getPartnerBranches(): Observable<PartnerBranch[]> {
    return this.partnerGateway.getPartnerBranches();
  }

  getPartnerCompanySizeRanges(): Observable<ListResult<PartnerCompanySizeRange[]>> {
    const list: PartnerCompanySizeRange[] = [
      { id: 1, name: 'oneToFive', min: 1, max: 5, description: '' },
      { id: 2, name: 'sixToFifteen', min: 6, max: 15, description: '' },
      { id: 3, name: 'sixteenToThirty', min: 16, max: 30, description: '' },
      { id: 4, name: 'thirtyoneToOnehundret', min: 31, max: 100, description: '' },
      { id: 5, name: 'overOnehundret', min: 101, max: null, description: '' },
    ];
    return of({ list, total: list.length });
  }

  getPartnerResources(): Observable<ListResult<PartnerResource[]>> {
    return this.getPartnerOnce().pipe(
      switchMap((partner: Partner) => this.partnerGateway.getPartnerResources(partner))
    );
  }

  uploadPartnerAvatar(avatar: File): Observable<PartnerAvatar> {
    return this.getPartnerOnce().pipe(
      switchMap((partner: Partner) => this.partnerGateway.uploadPartnerAvatar(partner, avatar)),
      tap((picture: PartnerAvatar) => {
        this.store.dispatch(partnerStore.actions.partner.updatePartnerAvatarSuccess({ payload: picture.icon }));
      })
    );
  }

  getPossiblePartnerProjectTypes(): Observable<PartnerProjectType[]> {
    return this.partnerGateway.getPossiblePartnerProjectTypes();
  }

  getSupportPermissions(): Observable<SupportPermissions> {
    return this.store.pipe(
      select(partnerStore.selectors.supportPermissions.selectLoaded),
      switchMap((isLoaded: boolean) => {
        if (isLoaded) {
          return this.store.pipe(
            select(partnerStore.selectors.supportPermissions.selectError),
            switchMap((error: SbpException | undefined) => {
              if (error) {
                return throwError(() => error);
              }
              return this.store.pipe(select(partnerStore.selectors.supportPermissions.selectSupportPermissions));
            })
          );
        }
        return this.getPartnerIdOnce().pipe(
          tap((partnerId: number) =>
            this.store.dispatch(partnerStore.actions.supportPermissions.getPermissions({ payload: partnerId }))
          ),
          switchMap(() => this.store.select(partnerStore.selectors.supportPermissions.selectLoaded)),
          filter((loaded: boolean) => loaded),
          switchMap(() => this.getSupportPermissions())
        );
      }),
      take(1)
    );
  }

  private findPartner(): Observable<Partner | undefined> {
    return this.store.pipe(
      select(partnerStore.selectors.partner.selectLoaded),
      switchMap((loaded: boolean) => {
        if (loaded) {
          return this.store.pipe(select(partnerStore.selectors.partner.selectPartner));
        }
        return this.sessionFacade.getCompanyId().pipe(
          tap((companyId: number) =>
            this.store.dispatch(partnerStore.actions.partner.getPartner({ payload: companyId }))
          ),
          switchMap(() => this.waitUntilPartnerIsLoaded()),
          switchMap(() => this.findPartner())
        );
      }),
      catchError((error: SbpException) => throwError(() => error)),
      takeUntil(this.authFacade.waitUntilLoggingOut())
    );
  }

  private waitUntilPartnerIsLoaded(): Observable<boolean> {
    return this.store.pipe(
      select(partnerStore.selectors.partner.selectLoaded),
      filter((loaded: boolean) => loaded)
    );
  }

  private getPartnerId(): Observable<number> {
    return this.store.select(companySelectors.allocations.isLoaded).pipe(
      filter((loaded: boolean) => loaded),
      switchMap(() => this.store),
      select(companySelectors.getPartnerId)
    );
  }
}
