import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { MarketDefinitionsGateway } from '../../gateways/common';
import { MarketDefinition } from '../../models';
import { CompaniesFacade } from '../company';

@Injectable({
  providedIn: 'root',
})
export class MarketDefinitionsFacade {
  private readonly marketDefinitionsGateway = inject(MarketDefinitionsGateway);
  private readonly companiesFacade = inject(CompaniesFacade);

  getMarketDefinitions(): Observable<MarketDefinition[]> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => this.marketDefinitionsGateway.getMarketDefinitions(companyId)));
  }
}
