import { PluginLocale, Tag } from '@account/core/models';
import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToastService } from '../../../shared/components/toast/toast.service';
import { ListResult } from '../../models';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class PluginTagsGateway {
  private readonly apiService = inject(ApiService);
  private readonly toastService = inject(ToastService);
  private readonly translateService = inject(TranslateService);

  getPluginTags(locale: PluginLocale, searchString: string): Observable<ListResult<Tag[]>> {
    const params = new HttpParams().set('search', searchString).set('locale', locale.name);

    return this.apiService.getList('/plugintags', params);
  }

  getTagSuggestions(
    pluginId: number,
    producerId: number,
    locale: string,
    pluginDescription: string,
    pluginTags: string
  ): Observable<string> {
    const params = new HttpParams()
      .set('language', locale)
      .set('pluginDescription', pluginDescription)
      .set('pluginTags', pluginTags);

    return this.apiService.get(`/producers/${producerId}/plugins/${pluginId}/gettagsuggestions`, params).pipe(
      catchError((error: any) => {
        this.toastService.error(
          this.translateService.instant('COMMON.ERROR'),
          this.translateService.instant('PLUGIN_DETAILS.LOCALIZATION.KEYWORD_GENERATOR_MODAL.TOAST.ERROR_TEXT')
        );
        return throwError(error);
      })
    );
  }
}
