import { Country, CurrencyModel } from '../common';
import { MasterData } from './master-data.model';

export type PartnerAssociationStatusName = 'none' | 'requestSent' | 'withdrawn' | 'rejected' | 'connected';

export enum CompanyContractor {
  SHOPWARE_AG = 'shopware AG',
  SHOPWARE_INC = 'Shopware US, Inc.',
}

export interface Company {
  id: number;
  customerNumber: string;
  contractor: CompanyContractor;
  name: string;
  creationDate: string;
  lastChangeDate: string | null;
  website: string;
  isBanned: boolean;
  isSoftBanned: boolean;
  isTest: boolean;
  isAllowedToBypassGTCSignature: boolean;
  masterData: MasterData;
  assignedPartner: AssignedPartner;
  currency: CurrencyModel;
}

export interface CompanyToCreate extends Pick<Company, 'name'> {
  masterData?: MasterData;
}

export interface CreateCompanyWithBillingCountry extends Pick<Company, 'name'> {
  billingCountry: Country;
}

export interface CompanyRegistrationData {
  companyName: string;
  countryId: number | null;
  locale: string;
  gtc: boolean;
  smallTradesman: boolean;
  email: string;
  salutation: string;
  firstName: string;
  lastName: string;
  phone: string;
  street: string;
  city: string;
  zipCode: string;
  isHavingVatId: boolean;
  vatId: string | null;
}

export interface AssignedPartner {
  partnerId: number;
  companyId: number;
  companyName: string;
}
