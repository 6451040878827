import { CompaniesFacade } from '@account/core/facades';
import {
  Basket,
  BasketPosition,
  BasketPositionAppointment,
  BasketPositionExamination,
  BasketPositionVoucher,
  Company,
  TrackingDataModel,
} from '@account/core/models';
import { inject, Injectable } from '@angular/core';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { take } from 'rxjs/operators';

interface Item {
  orderNumber?: string;
  price?: number;
  locale: string;
}

@Injectable({
  providedIn: 'root',
})
export class TrackingDataService {
  private readonly companiesFacade = inject(CompaniesFacade);
  private readonly angulartics2GoogleTagManager = inject(Angulartics2GoogleTagManager);

  trackData(trackingData: TrackingDataModel): void {
    this.angulartics2GoogleTagManager.pushLayer({
      event: trackingData.event,
      ...trackingData.payload,
    });
  }

  viewItems(items: Item[], name: string, category: string): void {
    this.clearEcommerce();

    this.companiesFacade
      .getCompany()
      .pipe(take(1))
      .subscribe((company: Company) => {
        this.trackData({
          event: 'view_item',
          payload: {
            ecommerce: {
              currency: company.currency.isoCode,
              items: items.map((item: Item) => ({
                item_id: item.orderNumber,
                affiliation: 'Shopware Account',
                item_brand: 'Shopware',
                item_list_name: 'Available Trainings',
                quantity: 1,
                price: item.price,
                item_name: name,
                item_category: category,
                item_variant: item.locale === 'de_DE' ? 'German' : 'English',
              })),
            },
          },
        });
      });
  }

  viewCart(basket: Basket): void {
    this.clearEcommerce();

    this.companiesFacade
      .getCompany()
      .pipe(take(1))
      .subscribe((company: Company) => {
        this.trackData({
          event: 'view_cart',
          payload: {
            ecommerce: this.getCartPayload(basket, company, basket.totalPrice, basket.positions),
          },
        });
      });
  }

  addToCart(orderNumber: string, basket: Basket): void {
    const position = this.getPositionFromCart(orderNumber, basket);

    if (this.instanceOfBasketPositionVoucher(position)) {
      return;
    }

    const voucher = this.getVoucherPosition(basket.positions);
    const positionPrice = this.getItemPriceProperties(position, basket, voucher);
    const tax = position.taxValue / position.quantity;
    const value = +(basket.totalPrice - (positionPrice.price + tax)).toFixed(2);

    this.clearEcommerce();

    this.companiesFacade
      .getCompany()
      .pipe(take(1))
      .subscribe((company: Company) => {
        this.trackData({
          event: 'add_to_cart',
          payload: {
            ecommerce: this.getCartPayload(basket, company, value, [position], 1),
          },
        });
      });
  }

  removeFromCart(orderNumber: string, basket: Basket, quantity?: number): void {
    const position = this.getPositionFromCart(orderNumber, basket);

    if (this.instanceOfBasketPositionVoucher(position)) {
      return;
    }

    this.clearEcommerce();

    this.companiesFacade
      .getCompany()
      .pipe(take(1))
      .subscribe((company: Company) => {
        this.trackData({
          event: 'remove_from_cart',
          payload: {
            ecommerce: this.getCartPayload(basket, company, basket.totalPrice, [position], quantity),
          },
        });
      });
  }

  purchase(basket: Basket): void {
    this.clearEcommerce();

    this.companiesFacade
      .getCompany()
      .pipe(take(1))
      .subscribe((company: Company) => {
        this.trackData({
          event: 'purchase',
          payload: {
            ecommerce: {
              ...this.getCartPayload(basket, company, basket.totalPrice, basket.positions),
              tax: basket.totalTax,
            },
          },
        });
      });
  }

  private getPositionFromCart(orderNumber: string, basket: Basket): BasketPosition {
    return basket.positions.find((position: BasketPosition) => position.orderNumber === orderNumber);
  }

  private getCartPayload(
    basket: Basket,
    company: Company,
    value: number,
    positions: BasketPosition[],
    quantity?: number,
  ): object {
    const voucher = this.getVoucherPosition(basket.positions);
    positions = positions.filter((position: BasketPosition) => !this.instanceOfBasketPositionVoucher(position));

    return {
      coupon: voucher ? voucher.voucherCode.code : null,
      currency: company.currency.isoCode,
      value,
      items: positions.map((position: BasketPosition) => ({
        item_id: position.orderNumber,
        affiliation: 'Shopware Account',
        item_brand: 'Shopware',
        item_list_name: 'Available Trainings',
        quantity: quantity ?? position.quantity,
        ...this.getPartialItemPayload(position),
        ...this.getItemPriceProperties(position, basket, voucher),
      })),
    };
  }

  private getPartialItemPayload(position: BasketPosition): object {
    if (this.instanceOfBasketPositionExamination(position)) {
      return {
        item_name: position.examination.name,
        item_category: 'Certification',
        item_variant: position.examination.locale.name === 'de_DE' ? 'German' : 'English',
      };
    }

    if (this.instanceOfBasketPositionAppointment(position)) {
      return {
        item_name: position.appointment.training.name,
        item_category: 'On-Site Training',
        item_variant: position.appointment.training.locale.name === 'de_DE' ? 'German' : 'English',
      };
    }

    return {};
  }

  private getItemPriceProperties(
    position: BasketPosition,
    basket: Basket,
    voucher?: BasketPositionVoucher,
  ): { price: number; discount?: number } {
    let price = +(position.netPrice / position.quantity).toFixed(2);

    if (!voucher) {
      return { price };
    }

    let discount;

    if (voucher.voucher.calculationType.name === 'percentage') {
      discount = (price / 100) * voucher.voucher.value;
    } else {
      const itemsCount = basket.positions
        .filter((position: BasketPosition) => !this.instanceOfBasketPositionVoucher(position))
        .map((position: BasketPosition) => position.quantity)
        .reduce((a, b) => a + b);

      discount = Math.abs(voucher.netPrice) / itemsCount;
    }

    discount = +discount.toFixed(2);
    price = +(price - discount).toFixed(2);

    return { price, discount };
  }

  private getVoucherPosition(positions: BasketPosition[]): BasketPositionVoucher | undefined {
    return positions.find((position: BasketPosition) => this.instanceOfBasketPositionVoucher(position)) as
      | BasketPositionVoucher
      | undefined;
  }

  private instanceOfBasketPositionExamination(position: BasketPosition): position is BasketPositionExamination {
    return 'examination' in position;
  }

  private instanceOfBasketPositionAppointment(position: BasketPosition): position is BasketPositionAppointment {
    return 'appointment' in position;
  }

  private instanceOfBasketPositionVoucher(position: BasketPosition): position is BasketPositionVoucher {
    return 'voucher' in position;
  }

  private clearEcommerce(): void {
    this.angulartics2GoogleTagManager.pushLayer({ ecommerce: null });
  }
}
