import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ListResult, Technology, TechnologyCategory } from '../../models';
import { Plugin } from '../../models/plugin/plugin.model';
import { ApiService } from '../../services';
import { HttpParamsBuilder } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class PartnerTechnologyProfileGateway {
  private readonly apiService = inject(ApiService);

  createTechnology(technology: Technology): Observable<Technology> {
    return this.apiService.post('/technologies', technology);
  }

  getTechnology(partnerId: number): Observable<ListResult<Technology[]>> {
    return this.apiService.getList(`/technologies?partnerId=${partnerId}`);
  }

  updateTechnology(technology: Technology): Observable<Technology> {
    return this.apiService.put(`/technologies/${technology.id}`, technology);
  }

  getTechnologyCategories(): Observable<TechnologyCategory[]> {
    return this.apiService.get('/technologies/categories');
  }

  uploadImage(partnerId: number, file: File): Observable<string> {
    return this.apiService.upload(`/technologies/${partnerId}/icon`, file);
  }

  getTechnologyPlugins(searchTerm: string, filter = {}): Observable<ListResult<Plugin[]>> {
    let params = new HttpParams().set('search', searchTerm);
    params = HttpParamsBuilder.applyFilters(params, filter);
    return this.apiService.getList('/technologies/plugins', params);
  }
}
