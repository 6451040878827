import { Component } from '@angular/core';

@Component({
  selector: 'navigation-separator',
  template: '',
  standalone: true,
  styles: `
    :host {
      background-image: repeating-linear-gradient(90deg, #33445b, #33445b 4px, transparent 4px, transparent 8px);
      background-position: left bottom;
      background-repeat: repeat-x;
      background-size: 100% 1px;
    }
  `,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'separator block w-full h-[1px]',
  },
})
export class NavigationSeparatorComponent {}
