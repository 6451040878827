import { AuthFacade } from '@account/core/facades';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LogoutGuard {
  private readonly router = inject(Router);
  private readonly authFacade = inject(AuthFacade);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authFacade.isLoggedIn().pipe(
      switchMap((loggedIn: boolean) => {
        if (loggedIn) {
          this.authFacade.setRedirectUrl(state.url);
          return this.authFacade.logout();
        }
        return of(true);
      })
    );
  }
}
