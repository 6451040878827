import { NgIf, NgStyle } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ImagePlaceholderComponent } from '../../image-placeholder/image-placeholder.component';
import { SwIconComponent } from '../../sw-icon/sw-icon.component';
import { ToastService } from '../../toast/toast.service';

/**
 * @TODO:
 *  - Implement upload function in avatar component
 *  - replace all usages of this component
 *  - remove this component
 */
@Component({
  selector: 'account-avatar-upload',
  templateUrl: './avatar-upload.component.html',
  styleUrl: './avatar-upload.component.less',
  standalone: true,
  imports: [NgStyle, NgIf, ImagePlaceholderComponent, SwIconComponent],
})
export class AvatarUploadComponent {
  private readonly toastService = inject(ToastService);
  private readonly translateService = inject(TranslateService);
  loaded = false;

  @Input() src = '';
  @Input() updateText = "please set property 'updateText'";
  @Input() width = 128;
  @Input() height = 128;
  @Input() displayWidth = 128;
  @Input() displayHeight = 128;
  borderSize = 12;

  @Output() readonly onImageUpdate: EventEmitter<any> = new EventEmitter();

  updateImage(): void {
    const upload = document.createElement('input');
    upload.type = 'file';
    upload.addEventListener('change', (event: any) => {
      if (event.target.files) {
        const file = event.target.files[0];
        this.loaded = false;
        const reader = new FileReader();
        reader.addEventListener('load', (event: any) => {
          if (this.src !== event.target.result) {
            const img = new Image();

            img.addEventListener('load', () => {
              if (this.checkImage(img)) {
                const imgWidth = img.width;
                const imgHeight = img.height;
                this.src = this.cropImage(
                  this.width / 2 - imgWidth / 2,
                  this.height / 2 - imgHeight / 2,
                  this.width,
                  this.height,
                  img
                );
                this.onImageUpdate.emit(file);
              } else {
                this.toastService.error(
                  this.translateService.instant('COMMON.TOAST.ERROR.TITLE.UPLOAD_FAILED'),
                  this.translateService.instant('AVATAR.TOAST.ERROR.MESSAGE.INVALID_DIMENSIONS', {
                    height: this.height,
                    width: this.width,
                  })
                );
                this.ready();
              }
            });

            img.addEventListener('error', () => {
              this.toastService.error(
                this.translateService.instant('COMMON.TOAST.ERROR.TITLE.UPLOAD_FAILED'),
                this.translateService.instant('AVATAR.TOAST.ERROR.MESSAGE.INVALID_FILETYPE')
              );
              this.ready();
            });

            img.src = event.target.result;
          } else {
            this.ready();
          }
        });
        reader.readAsDataURL(file);
      }
    });
    upload.click();
  }

  reset(): void {
    this.src = '';
    this.ready();
  }

  ready(): void {
    this.loaded = true;
  }

  private checkImage(img: HTMLImageElement): boolean {
    return img.width === this.width && img.height === this.height;
  }

  private cropImage(x: number, y: number, width: number, height: number, image: HTMLImageElement): string {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, x, y);
    const result = canvas.toDataURL('image/png');
    canvas.remove();
    return result;
  }
}
