import { NgIf } from '@angular/common';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

import { QueryFilterGroup } from '../../../../core/models/api/query-filter-group.model';
import { SwIconComponent } from '../../sw-icon/sw-icon.component';
import { SbpTableContainerIntl } from '../table-container-intl.service';
import { SbpTableFilterService } from './filter.service';
import { SbpTableFilterGroupComponent } from './filter-group/filter-group.component';

@Component({
  selector: 'sbp-table-filter',
  templateUrl: './filter.html',
  styleUrl: './filter.less',
  standalone: true,
  imports: [SwIconComponent, FormsModule, ReactiveFormsModule, NgIf],
})
export class SbpTableFilterComponent implements OnInit, AfterContentInit, AfterViewInit {
  intl = inject(SbpTableContainerIntl);
  private readonly renderer = inject(Renderer2);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly formBuilder = inject(UntypedFormBuilder);
  private readonly filterService = inject(SbpTableFilterService);
  @Input() search = '';
  @Input() disabled = false;
  @Input() searchEnabled = true;
  @Input() formGroup: UntypedFormGroup;

  @Output() readonly onUpdateFilter = new EventEmitter<any>();
  @Output() readonly onResetFilter = new EventEmitter<UntypedFormGroup>();
  @Output() readonly onTriggerSearch = new EventEmitter<string>();

  @ContentChildren(SbpTableFilterGroupComponent) filterGroupContentChildren: QueryList<SbpTableFilterGroupComponent>;

  // visibility toggled by button in template
  visible: boolean;
  searchInput = new UntypedFormControl();
  hasActiveFilters = false;

  private initialFormValue: Record<string, any>;
  private selfClick = false;
  private itemClick = false;
  private documentClickListener: () => void;

  @ViewChild('searchInputChild', { static: true }) private readonly searchInputChild: ElementRef;

  ngOnInit(): void {
    this.searchInput.patchValue('');
    this.visible = false;
  }

  ngAfterContentInit(): void {
    if (!this.searchEnabled && this.filterGroupContentChildren.length === 0) {
      this.disabled = true;
    } else {
      this.hasActiveFilters = this.checkActiveFilters();
    }
  }

  ngAfterViewInit(): void {
    this.initialFormValue = this.formGroup ? this.formGroup.value : {};
  }

  onPanelClick(): void {
    this.itemClick = true;
  }

  toggleFilterVisibility(): void {
    if (!this.disabled) {
      this.visible = !this.visible;
      if (this.visible) {
        this.bindDocumentClickListener();
        this.selfClick = true;
        // emit focus on search field one tick later, so that the filter panel has been opened
        setTimeout(() => {
          this.searchInputChild.nativeElement.focus();
        });
      }
    }
  }

  onSearch(searchTerm: string): void {
    this.onTriggerSearch.emit(searchTerm);
  }

  resetFilter(): void {
    if (this.formGroup) {
      this.formGroup.reset(this.initialFormValue);
    }
    for (const child of this.filterGroupContentChildren.toArray()) {
      if (child.formGroup) {
        child.active = child.formGroup.get('active').value;
      } else {
        child.active = false;
      }
    }
    this.searchInput.patchValue('');
    this.search = this.searchInput.value;
    this.onResetFilter.emit(this.formGroup);
    this.onUpdateFilter.emit();
    this.hasActiveFilters = this.checkActiveFilters();
  }

  applyFilter(): void {
    this.hasActiveFilters = this.checkActiveFilters();
    this.search = this.searchInput.value;
    this.onUpdateFilter.emit(this.getActiveFilterGroups());
  }

  getActiveFilterGroups(): QueryFilterGroup[] {
    return this.filterService.getActiveFilterGroups(this.formGroup);
  }

  checkActiveFilters(): boolean {
    return (
      this.filterGroupContentChildren
        .toArray()
        .find((filterGroup: SbpTableFilterGroupComponent) => filterGroup.active) !== undefined || this.search !== ''
    );
  }

  private bindDocumentClickListener(): void {
    if (!this.documentClickListener) {
      this.documentClickListener = this.renderer.listen('document', 'click', () => {
        if (!this.selfClick && !this.itemClick) {
          this.visible = false;
          this.unbindDocumentClickListener();
        }

        this.selfClick = false;
        this.itemClick = false;
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  private unbindDocumentClickListener(): void {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }
}
