<account-auth-badge class="auth-badge" />
<div *ngIf="!createDemoShop; else demoShopAuth" class="auth-image">
  <div class="teaser">
    <div class="teaser-content">
      {{ 'FRONT.GREETING' | translate }}
    </div>

    <div class="credit">
      <a class="credit-author" rel="noreferrer" target="_blank" href="https://unsplash.com/@tokeller">
        Photo by Tobias Keller on Unsplash
      </a>
      <div class="credit-location">
        <account-sw-icon icon="map-marker" />
        Walt Disney Concert Hall, Los Angeles, United States
      </div>
    </div>
  </div>
</div>

<ng-template #demoShopAuth>
  <div class="auth-image-demoshop">
    <div class="teaser">
      <div class="teaser-content">
        <h5>{{ 'FRONT.REGISTER.DEMOSHOP_WELCOME' | translate }}</h5>
        <h3>Shopware 6 Demoshop</h3>
      </div>
    </div>
  </div>
</ng-template>

<div class="auth-content-wrapper" accountLoadingSpinner [showLoadingSpinner]="loading">
  <div class="auth-content" [class.wide-layout]="isWideLayout" [class.ory-auth-content]="'ory' | featureFlag">
    <ng-content />
  </div>
</div>

<div class="auth-footer">
  <a class="footer-language" (click)="changeLanguage(notSelectedLanguage)">
    <img
      [alt]="notSelectedLanguage.key"
      [src]="'/assets/images/flags/' + (notSelectedLanguage.key === 'de' ? 'de_DE.svg' : 'en_GB.svg')" />
    {{ 'LANGUAGE.SWITCH_TO.' + notSelectedLanguage.key | uppercase | translate }}
  </a>
</div>
