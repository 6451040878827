<div class="sidebar-header">
  <strong class="header-headline">
    {{ heading }}
  </strong>
  <div class="header-close" (click)="close()">
    <account-sw-icon icon="times-s" />
  </div>
</div>
<div class="sidebar-content" [class.has-padding]="contentHasPadding">
  <ng-content />
</div>
