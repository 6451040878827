<div
  class="sbp-table-container"
  [ngClass]="{ 'has-shadow': hasShadow, 'has-multiline': multiline, 'table-cards': isMobileViewport }">
  <sbp-table-header
    *ngIf="headline"
    [headline]="headline"
    [total]="total"
    [showTotal]="showTotal"
    [isMobile]="isMobileViewport">
    <ng-content select="table-container-controls" />
  </sbp-table-header>
  <ng-content select="table-container-custom-content" />
  <sbp-table
    [dataSource]="dataSource"
    [class.has--footer]="showFooter"
    [class.has--data]="dataSource.data.length > 0"
    accountLoadingSpinner
    [showLoadingSpinner]="dataSource.data.length > 0 && isLoading">
    <sbp-table-expander [expandedRowElement]="expandedRowElement" (toggleRowExpansion)="toggleExpansion($event)" />

    <sbp-table-selector [selection]="selection" />

    <ng-content />

    <sbp-table-actions [selection]="selection" [gridActions]="gridActions" [rowActions]="rowActions" />

    <sbp-header-row *sbpHeaderRowDef="displayedColumns" data-hidden="s,m,l" />
    <sbp-row
      [ngClass]="{ uneven: index % 4 === 0, 'table-card': isMobileViewport }"
      (dblclick)="onRowDoubleClick($event, row)"
      *sbpRowDef="let row; columns: displayedColumns; let index = index" />
    <sbp-row
      *sbpRowDef="let row; columns: displayedExpansionColumns; when: isExpansionRow"
      class="sbp-expansion-row"
      [@detailExpand]="expandedRowElement === row.element ? 'expanded' : 'collapsed'" />
  </sbp-table>

  <ng-container *ngIf="dataSource.data.length === 0">
    <account-empty-state
      *ngIf="tableFilter?.search; else noFilteredEmptyState"
      accountLoadingSpinner
      [showLoadingSpinner]="isLoading"
      [inline]="true"
      [heading]="'COMMON.TABLE.EMPTY_STATE.HEADING'"
      [icon]="'database'" />

    <ng-template #noFilteredEmptyState>
      <account-empty-state
        accountLoadingSpinner
        [showLoadingSpinner]="isLoading"
        [class.empty-state-show-border]="showFooter"
        [inline]="true"
        [heading]="emptyStateHeading"
        [subheading]="emptyStateSubheading"
        [icon]="emptyStateIcon">
        <div [matTooltip]="emptyStateButtonDisabledTooltip">
          <button
            *ngIf="emptyStateButtonText"
            class="a_button is_primary is_small"
            (click)="emitEmptyStateButtonAction()"
            [disabled]="emptyStateButtonDisabled === true"
            [class.is_disabled]="emptyStateButtonDisabled === true">
            {{ emptyStateButtonText }}
          </button>
        </div>
      </account-empty-state>
    </ng-template>
  </ng-container>

  <div class="footer" [class.is-mobile]="isMobileViewport" [attr.data-hidden]="showFooter ? null : ''">
    <sbp-table-paginator
      [hidden]="!pagination"
      [total]="total"
      [limit]="requestMetaData.limit"
      [offset]="requestMetaData.offset"
      [showRefresh]="showRefresh" />
    <ng-content select="table-container-footer-controls" />
  </div>
</div>
