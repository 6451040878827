import { Component, EmbeddedViewRef, inject, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'tree-value-template-loader',
  template: '',
  standalone: true,
})
export class TreeValueTemplateLoaderComponent implements OnInit, OnDestroy {
  viewContainer = inject(ViewContainerRef);
  @Input() node: any;
  @Input() template: any;

  view: EmbeddedViewRef<any>;

  ngOnInit(): void {
    this.view = this.viewContainer.createEmbeddedView(this.template, {
      $implicit: this.node,
    });
  }

  ngOnDestroy(): void {
    this.view.destroy();
  }
}
