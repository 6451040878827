import { ShopSuccessorGateway } from '@account/core/gateways';
import { SelfHostedShop, SwPlatformShopPluginMigration } from '@account/core/models';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShopSuccessorFacade {
  private readonly shopSuccessorGateway = inject(ShopSuccessorGateway);

  createShopForSwPlatform(shop: SelfHostedShop, migrationShopDomain: string): Observable<SelfHostedShop> {
    return this.shopSuccessorGateway.createShopForSwPlatform(shop.companyId, shop.id, migrationShopDomain);
  }

  getSwPlatformSuccessorShopByPredecessor(predecessorShop: SelfHostedShop): Observable<SelfHostedShop | null> {
    return this.shopSuccessorGateway.getSwPlatformSuccessorShopByPredecessor(
      predecessorShop.companyId,
      predecessorShop.id
    );
  }

  getPluginMigrationListForSWPlatformShop(
    successorShopId: number,
    mode: 'free' | 'commercial'
  ): Observable<SwPlatformShopPluginMigration[]> {
    return this.shopSuccessorGateway.getPluginMigrationListForSWPlatformShop(successorShopId, mode);
  }

  bookPluginSuccessorsForSWPlatformShop(
    successorShopId: number,
    mode: 'free' | 'commercial',
    migrations: SwPlatformShopPluginMigration[]
  ): Observable<SwPlatformShopPluginMigration[]> {
    return this.shopSuccessorGateway.bookPluginSuccessorsForSWPlatformShop(successorShopId, mode, migrations);
  }

  releaseSwPlatformShop(shop: SelfHostedShop): Observable<SelfHostedShop> {
    return this.shopSuccessorGateway.releaseSwPlatformShop(shop.companyId, shop.id);
  }
}
