import { Basket, BasketShop } from '@account/core/models';
import { ApiService } from '@account/core/services';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BasketGateway {
  private readonly apiService = inject(ApiService);

  getBasket(companyId: number): Observable<Basket | null> {
    return this.apiService.get(`/companies/${companyId}/basket`);
  }

  addToBasket(companyId: number, orderNumber: string, positionData?: Record<string, any>): Observable<Basket> {
    return this.apiService.post(`/companies/${companyId}/basket/add/${orderNumber}`, positionData);
  }

  updateBasketPosition(companyId: number, orderNumber: string, positionData?: Record<string, any>): Observable<Basket> {
    return this.apiService.put(`/companies/${companyId}/basket/update/${orderNumber}`, positionData);
  }

  removeFromBasket(companyId: number, orderNumber: string): Observable<Basket | null> {
    return this.apiService.put(`/companies/${companyId}/basket/remove/${orderNumber}`);
  }

  checkoutBasket(companyId: number, shopId: number): Observable<Basket> {
    return this.apiService.post(`/companies/${companyId}/shops/${shopId}/basket/checkout`);
  }

  getShops(companyId: number): Observable<BasketShop[]> {
    return this.apiService.get(`/companies/${companyId}/basket/shops`);
  }

  validateVoucher(companyId: number, voucherCode: string): Observable<Basket> {
    return this.apiService.post(`/companies/${companyId}/basket/voucher/redeem`, { code: voucherCode });
  }
}
