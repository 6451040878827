import { Overlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, forwardRef, inject, Input, OnInit, Output, Type } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ComponentOverlayService } from '../component-overlay/component-overlay.service';
import { StarComponent } from '../star/star.component';

export interface RatingOverlayData {
  rating: number;
  [key: string]: any;
}

export const RATING_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => StarRatingComponent),
  multi: true,
};

/**
 * The star rating component displays five stars next to each other for rating purposes.
 */
@Component({
  selector: 'account-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrl: './star-rating.component.less',
  providers: [RATING_VALUE_ACCESSOR, ComponentOverlayService],
  imports: [CommonModule, StarComponent],
  standalone: true,
})
export class StarRatingComponent implements OnInit, ControlValueAccessor {
  private readonly elementRef = inject(ElementRef);
  private readonly overlay = inject(Overlay);
  private readonly componentOverlayService = inject(ComponentOverlayService);
  /**
   * Defines if the stars are displayed as disabled.
   */
  @Input() disabled: boolean;

  /**
   * Defines if the stars are readonly.
   */
  @Input() readonly: boolean;

  /**
   * Defines the amount of stars as type `number`.
   */
  @Input() stars = 5;

  /**
   * No purpose.
   */
  @Input() iconOnStyle: any;

  /**
   * No purpose.
   */
  @Input() iconOffStyle: any;

  /**
   * Defines the size of the star icons in `px`.
   */
  @Input() size = '16px';

  @Input() gap = '4px';

  /**
   * If applied overlay component can be attachment
   */
  @Input() ratingOverlayComponent?: Type<any>;

  /**
   * Used to apply custom data to the overlay component
   */
  @Input() overlayData: RatingOverlayData;

  /**
   * Whether the overlay should be open or not
   */
  @Input() openOverlay = false;

  @Input() bufferInternalValue = true;

  /**
   * Emits an event of type `any` when the user inputs a rating.
   */
  @Output() readonly onRate = new EventEmitter<number>();
  @Output() readonly onOverlayClosed = new EventEmitter<unknown>();

  /**
   * @internal
   */
  starsArray: number[];

  /**
   * @internal
   */
  value: number;

  /**
   * @internal
   */
  hovered: number | null = null;

  /**
   * @internal
   * @param _ updated rating
   */
  onModelChange: (_: number) => void = (_: number) => void 0;

  /**
   * @internal
   */
  onModelTouched: () => void = () => void 0;

  ngOnInit(): void {
    this.starsArray = [];
    for (let i = 0; i < this.stars; i++) {
      this.starsArray[i] = i;
    }
  }

  /**
   * @internal
   */
  rate(event: any, i: number): void {
    if (!this.disabled) {
      if (!this.readonly && this.bufferInternalValue) {
        this.value = i + 1;
        this.writeValue(this.value);
      }
      const ratingValue = !this.readonly ? i + 1 : -1;
      this.onRate.emit(ratingValue);
      if (this.openOverlay && this.ratingOverlayComponent) {
        this.openRatingOverlay(ratingValue);
      }
    }
    event.preventDefault();
  }

  /**
   * @internal
   */
  clear(event: any): void {
    if (!this.readonly && !this.disabled) {
      this.value = null;
      this.writeValue(this.value);
    }
    event.preventDefault();
  }

  /**
   * @internal
   */
  setHovered(hovered: number): void {
    if (!this.readonly && !this.disabled) {
      this.hovered = hovered;
    }
  }

  /**
   * @internal
   */
  resetHovered(): void {
    this.hovered = null;
  }

  /**
   * @internal
   */
  writeValue(value: any): void {
    this.value = value;
    this.onModelChange(this.value);
  }

  /**
   * @internal
   */
  registerOnChange(fn: (_: number) => void): void {
    this.onModelChange = fn;
  }

  /**
   * @internal
   */
  registerOnTouched(fn: () => void): void {
    this.onModelTouched = fn;
  }

  /**
   * @internal
   */
  setDisabledState(val: boolean): void {
    this.disabled = val;
  }

  /**
   * @internal
   */
  openRatingOverlay(rating: number): void {
    const ratingRef = this.componentOverlayService.create(
      this.ratingOverlayComponent,
      {
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-transparent-backdrop',
        positionStrategy: this.overlay
          .position()
          .flexibleConnectedTo(this.elementRef.nativeElement)
          .withPush(true)
          .withDefaultOffsetX(-90)
          .withPositions([
            {
              originX: 'center',
              originY: 'bottom',
              overlayX: 'center',
              overlayY: 'top',
            },
            {
              originX: 'center',
              originY: 'top',
              overlayX: 'start',
              overlayY: 'bottom',
            },
          ]),
      },
      {
        ...(this.overlayData || {}),
        rating: rating,
      },
    );

    ratingRef.afterClosed().subscribe((overlayResult?: unknown) => {
      this.onOverlayClosed.emit(overlayResult);
    });
  }
}
