import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  CompanyMembership,
  CompanyMembershipInvitation,
  CompanyMembershipRole,
  CompanyMembershipRolePermission,
  CompanyMembershipRoleWithMembers,
  ListResult,
  PartialCompanyMembershipInvitation,
  QueryFilter,
  QueryFilterGroup,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { ApiService } from '../../services';
import { HttpParamsBuilder } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class CompanyMembershipGateway {
  private readonly apiService = inject(ApiService);
  // TODO: save permissions in store
  getAclPermissions(): Observable<CompanyMembershipRolePermission[]> {
    return this.apiService.get('/membershiproles/permissions');
  }

  getRoles(
    companyId: number,
    metaData: RequestMetaData,
    filter?: object | QueryFilterGroup | QueryFilter[],
  ): Observable<ListResult<CompanyMembershipRole[] | CompanyMembershipRoleWithMembers[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    if (filter) {
      params = HttpParamsBuilder.applyFilters(params, filter);
    }
    return this.apiService.getList(`/companies/${companyId}/roles`, params);
  }

  getRole(companyId: number, roleId: number): Observable<CompanyMembershipRole> {
    return this.apiService.get(`/companies/${companyId}/roles/${roleId}`);
  }

  createRole(companyId: number, role: CompanyMembershipRole): Observable<CompanyMembershipRole> {
    return this.apiService.post(`/companies/${companyId}/roles`, role);
  }

  updateRole(companyId: number, role: CompanyMembershipRole): Observable<CompanyMembershipRole> {
    return this.apiService.put(`/companies/${companyId}/roles/${role.id}`, role);
  }

  deleteRole(companyId: number, roleId: number): Observable<void> {
    return this.apiService.delete(`/companies/${companyId}/roles/${roleId}`);
  }

  getMembershipList(
    companyId: number,
    metaData: RequestMetaData,
    filter?: object | QueryFilterGroup | QueryFilter[],
  ): Observable<ListResult<CompanyMembership[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    if (filter) {
      params = HttpParamsBuilder.applyFilters(params, metaData);
    }
    return this.apiService.getList(`/companies/${companyId}/members`, params);
  }

  getMembership(companyId: number, membershipId: number): Observable<CompanyMembership> {
    return this.apiService.get(`/companies/${companyId}/members/${membershipId}`);
  }

  updateMembership(companyId: number, member: any): Observable<CompanyMembership> {
    return this.apiService.put(`/companies/${companyId}/members/${member.id}`, member);
  }

  removeMembership(companyId: number, membershipId: number): Observable<void> {
    return this.apiService.delete(`/companies/${companyId}/members/${membershipId}`);
  }

  inviteMemberIntoCompany(
    companyId: number,
    invitation: PartialCompanyMembershipInvitation,
  ): Observable<CompanyMembershipInvitation> {
    return this.apiService.post(`/companies/${companyId}/members/invitations`, invitation);
  }

  deleteInvitation(companyId: number, invitationId: number): Observable<void> {
    return this.apiService.delete(`/companies/${companyId}/members/invitations/${invitationId}`);
  }

  getPendingInvitations(
    companyId: number,
    metaData: RequestMetaData,
    filter?: object | QueryFilterGroup | QueryFilter[],
  ): Observable<ListResult<CompanyMembershipInvitation[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    if (filter) {
      params = HttpParamsBuilder.applyFilters(params, metaData);
    }
    return this.apiService.getList(`/companies/${companyId}/members/invitations`, params);
  }

  acceptMembershipInvitation(invitation: CompanyMembershipInvitation): Observable<CompanyMembership> {
    return this.apiService.post(`/companies/${invitation.company.id}/members/invitations/${invitation.id}`);
  }

  declineMembershipInvitation(invitation: CompanyMembershipInvitation): Observable<CompanyMembershipInvitation> {
    return this.apiService.put(`/companies/${invitation.company.id}/members/invitations/${invitation.id}`);
  }
}
