<account-status [type]="toast.type" size="16px" />

<div class="toast-content">
  <h4>{{ toast.title }}</h4>
  <div class="toast-message" *ngIf="toast.htmlContent; else noHtml" [innerHtml]="toast.message"></div>
  <ng-template #noHtml>
    <div class="toast-message">
      {{ toast.message }}
    </div>
  </ng-template>
</div>

<div class="toast-close" (click)="close()">
  <account-sw-icon icon="times-s" size="10px" />
</div>
