import {
  ComponentRef,
  Directive,
  ElementRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import { AoLoadingComponent } from '@shopware/aorta';

@Directive({
  selector: '[accountLoadingSpinner]',
  standalone: true
})
export class LoadingSpinnerDirective implements OnInit, OnChanges {
  private readonly elementRef = inject(ElementRef);
  private readonly renderer = inject(Renderer2);
  private readonly viewContainerRef = inject(ViewContainerRef);
  private readonly className = 'is_loading';
  private spinnerWrapper: HTMLElement | null = null;
  private componentRef: ComponentRef<AoLoadingComponent> | null = null;
  @Input() showLoadingSpinner: boolean = true;

  ngOnInit(): void {
    this.toggleSpinner();
  }

  ngOnChanges(): void {
    this.toggleSpinner();
  }

  private toggleSpinner(): void {
    if (this.showLoadingSpinner === true) {
      if (!this.isSpinnerInitialized) {
        this.spinnerWrapper = this.renderer.createElement('div');
        this.renderer.addClass(this.spinnerWrapper, 'loading-spinner');

        this.componentRef = this.viewContainerRef.createComponent(AoLoadingComponent);
        this.componentRef.setInput('spinnerClass', 'border-default border-r-[#000000] size-10 sticky top-[calc(50%-20px)] mx-auto');

        this.elementRef.nativeElement.appendChild(this.spinnerWrapper);
        this.spinnerWrapper.appendChild(this.componentRef.location.nativeElement);

        this.renderer.addClass(this.elementRef.nativeElement, this.className);
      }

      return;
    }

    if (this.isSpinnerInitialized) {
      this.componentRef.destroy();
      this.componentRef = null;
      this.renderer.removeChild(this.elementRef.nativeElement, this.spinnerWrapper);
      this.spinnerWrapper = null;

      this.renderer.removeClass(this.elementRef.nativeElement, this.className);
    }
  }

  private get isSpinnerInitialized(): boolean {
    return this.spinnerWrapper !== null && this.componentRef !== null;
  }
}
