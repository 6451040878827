import { SoftwareVersion } from '@account/core/models';
import { NgFor, NgIf, UpperCasePipe } from '@angular/common';
import { AfterViewInit, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { ShopEnvironment } from '../../../../../../core/models/shop/shop.model';
import { AlertComponent } from '../../../../../../shared/components/alert/alert.component';
import { ObjectComparators } from '../../../../../../shared/object.comparators';

@Component({
  selector: 'domain-verification-shop-settings-element',
  templateUrl: './shop-settings-element.component.html',
  styleUrl: './shop-settings-element.component.less',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgFor, NgIf, UpperCasePipe, TranslateModule, AlertComponent],
})
export class ShopSettingsElementComponent implements AfterViewInit {
  @Input() form: UntypedFormGroup;
  @Input() shopwareVersions: SoftwareVersion[];
  @Input() shopEnvironments: ShopEnvironment[];
  @Input() shopwareMajorVersion: number;

  compareByName = ObjectComparators.compareByName;

  ngAfterViewInit(): void {
    if (!this.form.get('shopwareVersion').value) {
      this.form.get('shopwareVersion').patchValue(
        this.shopwareVersions.find((softwareVersion: SoftwareVersion) => {
          if (this.shopwareMajorVersion) {
            return softwareVersion.major === `Shopware ${this.shopwareMajorVersion}`;
          }
          return softwareVersion.major === 'Shopware 6';
        })
      );
    }
  }

  isSelectable(shopVersion: SoftwareVersion): boolean {
    return shopVersion.selectable && +shopVersion.name[0] >= 5;
  }
}
