import { NgIf } from '@angular/common';
import { Component, EventEmitter, HostBinding, inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ImageUploadService } from '../../services/image-upload.service';
import { ModalService } from '../modal/modal.service';
import { SwIconComponent } from '../sw-icon/sw-icon.component';
import { AttachmentPreviewComponent } from './attachment-preview/attachment-preview.component';

@Component({
  selector: 'account-attachment',
  templateUrl: './attachment.component.html',
  styleUrl: './attachment.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [SwIconComponent, NgIf],
})
export class AttachmentComponent implements OnInit {
  private readonly modalService = inject(ModalService);
  private readonly imageUploadService = inject(ImageUploadService);
  @Input() name: string;
  @Input() file: File;
  @Input() link: string;
  @Input() deletable = false;
  @Input() previewable = true;
  @Input() downloadable = true;
  @Input() filename: string;
  @Input() previewUrl$: Observable<string>;
  @HostBinding('class.is-primary') @Input() isUserAttachment: boolean;
  @Output() readonly onDownload = new EventEmitter<void>();
  @Output() readonly onDelete = new EventEmitter<void>();

  readonly imageTypes = ['jpg', 'png', 'jpeg'];

  ngOnInit(): void {
    if (this.link && this.file && this.onDownload) {
      throw new Error('Either provide a link to the resource, the file itself or an download action but not all');
    }
  }

  isAttachmentImage(): boolean {
    if (!this.previewable) {
      return false;
    }
    for (const type of this.imageTypes) {
      if (this.link && this.link.toLowerCase().endsWith(`.${type}`)) {
        return true;
      }
      if (this.file && this.file.name.toLowerCase().endsWith(`.${type}`)) {
        return true;
      }
      if (this.filename && this.filename.toLowerCase().endsWith(`.${type}`)) {
        return true;
      }
    }
    return false;
  }

  onOpenPreview(event: any): void {
    event.preventDefault();
    let imageSource$: Observable<string>;
    if (this.filename && this.previewUrl$) {
      imageSource$ = this.previewUrl$;
    } else {
      imageSource$ = this.link ? of(this.link) : this.imageUploadService.getBase64ImageFromFile(this.file);
    }
    imageSource$.subscribe((imageSource: string) => {
      this.modalService.open(AttachmentPreviewComponent, {
        data: {
          name: this.name,
          imageSource: imageSource,
        },
      });
    });
  }
}
