import { ProducersGateway } from '@account/core/gateways';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMapTo, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { Contract, Producer, SbpException } from '../../../models';
import { companyActions } from '../../root.actions';
import { RootState } from '../../root.state';
import { actions } from './producer.actions';
import * as producerSelectors from './producer.selectors';

@Injectable({
  providedIn: 'root',
})
export class ProducerEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject<Store<RootState>>(Store<RootState>);
  private readonly producersGateway = inject(ProducersGateway);
  createProducer = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createProducer),
      map((action) => action.payload),
      switchMap((companyId: number) =>
        this.producersGateway.createProducerForCompany(companyId).pipe(
          concatMapTo([
            actions.getProducer({ payload: companyId }),
            companyActions.allocations.refreshCompanyAllocations(),
            companyActions.allocations.getCompanyAllocations({ payload: companyId }),
            companyActions.supportTicketUpdates.getSupportTicketUpdates({ payload: companyId }),
          ]),
          catchError((error: SbpException) => of(actions.createProducerFail({ payload: error })))
        )
      )
    )
  );

  getProducer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getProducer),
      withLatestFrom(this.store.select(producerSelectors.selectLoaded)),
      filter(([_, isLoaded]) => !isLoaded),
      map(([action]) => action.payload),
      switchMap((companyId: number) =>
        this.producersGateway.getProducerByCompany(companyId).pipe(
          mergeMap((producer: Producer) =>
            // request contract after producer has been loaded
            this.producersGateway.getContract(producer).pipe(
              map((contract: Contract) => {
                // merge producer and contract
                producer.contract = contract;
                return producer;
              })
            )
          ),
          map((producer: Producer) => actions.getProducerSuccess({ payload: producer })),
          catchError((error: SbpException) => of(actions.getProducerFail({ payload: error })))
        )
      )
    )
  );

  updateProducer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateProducer),
      map((action) => action.payload),
      switchMap((producer: Producer) =>
        this.producersGateway.updateProducer(producer).pipe(
          map((producer: Producer) => actions.updateProducerSuccess({ payload: producer })),
          catchError((error: SbpException) => of(actions.updateProducerFail({ payload: error })))
        )
      )
    )
  );

  updateContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateContract),
      map((action) => action.payload),
      switchMap((payload: { producerId: number; contract: Contract }) =>
        this.producersGateway.updateContract(payload.producerId, payload.contract).pipe(
          map((contract: Contract) => actions.updateContractSuccess({ payload: contract })),
          catchError((error: SbpException) => of(actions.updateContractFail({ payload: error })))
        )
      )
    )
  );

  uploadIcon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateIconUrl),
      map((action) => action.payload),
      withLatestFrom(this.store.select(producerSelectors.selectProducer)),
      switchMap(([icon, producer]: [File, Producer]) =>
        this.producersGateway.uploadIcon(producer.id, icon).pipe(
          map((response: { icon: string }) => actions.updateIconUrlSuccess({ payload: response.icon })),
          catchError((error: SbpException) => of(actions.updateIconUrlFail({ payload: error })))
        )
      )
    )
  );
}
