<div class="full-height flex-container flex-column">
  <div class="step-intro-text">
    <p
      *ngIf="'partner' === shopAssignationType"
      [innerHTML]="
        'COMMON.SHOP.SHOP_CREATION_MODAL.STEP.VERIFICATION.INTRO_TEXT_PARTNER'
          | translate: { domain: domainName }
          | safe: 'html'
      "></p>
    <p
      *ngIf="'partnerCustomer' === shopAssignationType"
      [innerHTML]="
        'COMMON.SHOP.SHOP_CREATION_MODAL.STEP.VERIFICATION.INTRO_TEXT_CUSTOMER'
          | translate: { domain: domainName, customer: assignee.name }
          | safe: 'html'
      "></p>
  </div>

  <domain-verification-verification-element
    [domainName]="domainName"
    [domainVerified]="domainVerified"
    [verificationRequired]="verificationRequired"
    [verificationResult]="verificationResult"
    [domainVerificationTokenData]="domainVerificationTokenData"
    (onVerifyDomain)="onVerifyDomain.emit()" />

  <domain-verification-license-gtc-block [form]="form" />
</div>
