import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgClass, NgIf } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { timer } from 'rxjs';

import { StyleOverflowBehavior, StylePaddingSize } from '../../../core';
import { LoadingSpinnerDirective } from '../../../shared/directives/loading-spinner.directive';
import { SwIconComponent } from '../sw-icon/sw-icon.component';

@Directive({
  selector: 'modal-controls',
  standalone: true,
})
export class ModalControlsDirective {}

@Directive({
  selector: 'modal-body',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[style.flex]': '"1"',
    '[style.min-height]': '"0"',
    class: 'flex-container flex-column',
  },
  standalone: true,
})
export class ModalBodyDirective {}

@Directive({
  selector: 'modal-footer',
  standalone: true,
})
export class ModalFooterDirective {}

@Component({
  selector: 'account-modal',
  exportAs: 'modal',
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.less',
  animations: [
    trigger('modalBackdrop', [
      state('void', style({ opacity: 0 })),
      state('open', style({ opacity: 1 })),
      transition('* => *', [animate('400ms cubic-bezier(.68, -0.55, .26, 1.55)')]),
    ]),
    trigger('modalContent', [
      state('void', style({ transform: 'scale(0.8)', opacity: 0 })),
      state('open', style({ transform: 'scale(1)', opacity: 1 })),
      transition('* => *', [animate('400ms cubic-bezier(.68, -0.55, .26, 1.55)')]),
    ]),
  ],
  standalone: true,
  imports: [NgIf, SwIconComponent, NgClass, LoadingSpinnerDirective],
})
export class ModalComponent implements AfterViewInit, AfterViewChecked {
  readonly elementRef = inject(ElementRef);
  private readonly router = inject(Router);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  @Input() heading: string;
  @Input() fullScreen = false;
  @Input() maxWidth: string;
  @Input() minHeight = '0';
  @Input() paddingTop = '20px';
  @Input() bodyIsDisplayFlex = false;
  @Input() overflowBody: StyleOverflowBehavior = 'auto';
  @Input() paddingBody: StylePaddingSize = 'padding_medium';
  @Input() focusInput = true;
  @Input() resetInputFocus = true;
  @Input() closable = true;
  @Input() closableOnBackdropClick = false;
  @Input() closeableOnEscape = true;
  @Input() minLoadingDelay = 750;
  @Input() loadingText = '';
  @Output() readonly modalClose = new EventEmitter<any>();
  @Input() showHeader = true;
  @Input() footerContentLeft = false;

  state: 'void' | 'open' = 'void';

  @ContentChild(ModalFooterDirective) footer: ModalFooterDirective;

  private _loading = false;

  private _loadingActual = false;
  private readonly escKeyCode = 27;

  private _delayCountdownCompleted = true;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (this.closeableOnEscape && event.keyCode === this.escKeyCode) {
      this.closeModal(event);
    }
  }

  constructor() {
    this.maxWidth = '984px';
    this.state = 'open';
  }

  @Input()
  set loading(loading: boolean) {
    if (loading) {
      // if loading initialized start min loading countdown
      this.startLoadingDelayCountdown();
      this._loading = loading;
    } else {
      if (this._delayCountdownCompleted) {
        // if loading set to false and delay timer is completed set loading to false
        this._loading = loading;
      }
    }
    // preserve last input whether or not the delay countdown has completed
    this._loadingActual = loading;
  }

  get loading(): boolean {
    return this._loading;
  }

  ngAfterViewInit(): void {
    if (this.closableOnBackdropClick) {
      (this.elementRef.nativeElement as HTMLElement).parentElement.addEventListener('click', (event: any) => {
        const targetElementPath = event.path || (event.composedPath && event.composedPath());
        if (targetElementPath) {
          const elementRefInPath = targetElementPath.find(
            (e: any) => e === this.elementRef.nativeElement.querySelector('.modal-content')
          );
          if (!elementRefInPath) {
            this.closeModal(null);
          }
        }
      });
    }
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewChecked(): void {
    if (this.focusInput && this.resetInputFocus) {
      const input = this.findInput(this.elementRef.nativeElement.children);
      if (input) {
        timer(0).subscribe(() => {
          input.focus();
          this.resetInputFocus = false;
        });
      }
    }
  }

  closeModal(event: any): void {
    this.modalClose.next(event);
  }

  private startLoadingDelayCountdown(): void {
    // delay is 1 second
    let minDelay = this.minLoadingDelay;
    this._delayCountdownCompleted = false;
    const interval = setInterval(() => {
      minDelay -= 10;
      if (minDelay < 0) {
        // The code here will run when
        // the timer has reached zero.

        clearInterval(interval);
        // timer has completed
        this._delayCountdownCompleted = true;
        if (false === this._loadingActual && this._loading) {
          // if timer has completed but loading input was set to false before set loading to false now
          this._loading = false;
          this.changeDetectorRef.detectChanges();
        }
      }
    }, 10);
  }

  private findInput(collection: HTMLCollection): null | HTMLElement {
    if (collection.length > 0) {
      for (let i = 0; i < collection.length; i++) {
        const element = collection.item(i);
        if (['input', 'select', 'textarea'].includes(element.localName)) {
          return element as HTMLElement;
        }
        const input = this.findInput(element.children);
        if (input) {
          return input;
        }
      }
    }
    return null;
  }
}
