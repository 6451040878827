import { Directive, inject, OnDestroy, OnInit, TemplateRef } from '@angular/core';

import { ActionBarService } from '../action-bar.service';

@Directive({
  selector: '[accountActionBarContent]',
  standalone: true,
})
export class ActionBarContentDirective implements OnInit, OnDestroy {
  private readonly actionBarService = inject(ActionBarService);
  private readonly templateRef = inject<TemplateRef<Element>>(TemplateRef<Element>);

  ngOnInit(): void {
    this.actionBarService.setActionBar(this.templateRef);
  }

  ngOnDestroy(): void {
    this.actionBarService.setActionBar(null);
  }
}
