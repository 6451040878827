import { NgIf } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { LinkTarget } from '../../../core';

export type TooltipType = 'link' | 'text';

@Component({
  selector: 'account-overflow-tooltip',
  templateUrl: './overflow-tooltip.component.html',
  styleUrl: './overflow-tooltip.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [RouterLink, MatTooltipModule, NgIf, RouterLinkActive],
})
export class OverflowTooltipComponent implements AfterContentInit {
  private readonly elementRef = inject(ElementRef);
  @Input() content: string;
  @Input() tooltipClass: string;
  @Input() tooltipContent: string;
  @Input() tooltipType: TooltipType = 'text';

  // tooltip delay in ms
  @Input() delay = 300;

  @Input() tabIndex = -1;

  // put tooltip content in a single line
  @Input() useMaxWidth = false;

  // a tooltip of type "link" can either use link or routerLink as input but not both
  @Input() link: string;
  @Input() routerLink: string | [string, any];
  @Input() linkTarget: LinkTarget;

  @Input() wrapperWidth = '100%';

  ngAfterContentInit(): void {
    switch (this.tooltipType) {
      case 'link':
        if (undefined !== this.link && undefined !== this.routerLink) {
          throw new Error(
            'OverflowTooltipComponent using type "link" can either use link or routerLink as input attribute but not both'
          );
        }
        if (undefined === this.linkTarget) {
          this.linkTarget = '_self';
        }
        break;
      case 'text':
        if (undefined !== this.link && undefined !== this.routerLink) {
          throw new Error(
            'OverflowTooltipComponent using type "text" cannot use link or routerLink as input attribute'
          );
        }
        if (undefined !== this.linkTarget) {
          throw new Error('OverflowTooltipComponent using type "text" cannot use linkTarget as input attribute');
        }
        break;
      default:
        throw new Error(
          'No type defined or invalid type used for OverflowTooltipComponent valid types are "text" and "link"'
        );
    }

    if (undefined === this.content) {
      const componentParent = this.elementRef.nativeElement.parentElement;
      const parentStyles = window.getComputedStyle(componentParent);
      const padding = Number.parseFloat(parentStyles.paddingLeft) + Number.parseFloat(parentStyles.paddingRight);
      this.wrapperWidth = `${componentParent.clientWidth - padding}px`;
    }
  }
}
