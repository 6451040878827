import { CommercialPlanFacade, CompaniesFacade, UpgradeToCommercialPlanFacade } from '@account/core/facades';
import {
  CommercialPlan,
  EVOLVE,
  RISE,
  SelfHostedShop,
  ShopUpgradeCommercialPlanBookingInformation,
  UPGRADE_PLAN_PE_BEYOND,
  UPGRADE_PLAN_PE_EVOLVE,
  UPGRADE_PLAN_PE_RISE,
} from '@account/core/models';
import { Component, inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MODAL_DATA } from 'src/app/shared/components/modal';
import { ModalRef } from 'src/app/shared/components/modal/modal-ref';
import { ToastService } from 'src/app/shared/components/toast/toast.service';

import {
  PlanBookingConfig,
  PlanBookingModalBaseComponent,
  ShopPlanBookingForm,
} from '../../plan-booking-modal-base/plan-booking-modal-base.component';

@Component({
  selector: 'account-shop-upgrade-to-commercial-plan',
  templateUrl: './shop-upgrade-to-commercial-plan-modal.component.html',
  styleUrl: './shop-upgrade-to-commercial-plan-modal.component.less',
  standalone: true,
  imports: [TranslateModule, PlanBookingModalBaseComponent],
})
export class ShopUpgradeToCommercialPlanModalComponent implements OnInit {
  protected readonly modalData = inject<PlanBookingConfig>(MODAL_DATA);
  private readonly modalRef = inject(ModalRef);
  private readonly companiesFacade = inject(CompaniesFacade);
  private readonly commercialPlanFacade = inject(CommercialPlanFacade);
  private readonly translateService = inject(TranslateService);
  private readonly toastService = inject(ToastService);
  private readonly upgradeToCommercialPlanFacade = inject(UpgradeToCommercialPlanFacade);
  protected commercialPlansFn: (shop: SelfHostedShop) => Observable<CommercialPlan[]>;
  private upgradePlans$: Observable<CommercialPlan[]>;
  private regularPlans$: Observable<CommercialPlan[]>;

  ngOnInit(): void {
    this.upgradePlans$ = this.commercialPlanFacade.getCommercialUpgradePlans().pipe(shareReplay());
    this.regularPlans$ = this.commercialPlanFacade.getCommercialRegularPlans().pipe(shareReplay());

    this.commercialPlansFn = this.commercialPlansByShop.bind(this);
  }

  upgradeShopToCommercialPlan(form: FormGroup<ShopPlanBookingForm>): void {
    const shop = form.get('shopSelection.preselectedShop').value;
    const planId = form.get('planBooking.plan').value.id;
    const plannedGmv = form.get('planBooking.gmv').value;
    const chargingInterval = form.get('planBooking.interval').value;
    const licenseAgreement = form.get('planBooking.contract').value;

    const data: ShopUpgradeCommercialPlanBookingInformation = {
      planId,
      plannedGmv,
      chargingInterval,
      licenseAgreement,
    };

    this.isShopOwner(shop)
      .pipe(
        switchMap((isShopOwner: boolean) => {
          if (isShopOwner) {
            return this.upgradeToCommercialPlanFacade.handleUpgrade(shop.id, data);
          }

          return this.upgradeToCommercialPlanFacade.handleUpgradeForCustomer(shop.companyId, shop.id, data);
        })
      )
      .subscribe({
        next: () => {
          this.close(true);
          this.toastService.success(
            this.translateService.instant('SHOPS.SHOP_DETAILS.UPGRADE_TO_COMMERCIAL_PLAN.TOAST.FINISH_SUCCESS_TITLE'),
            this.translateService.instant('SHOPS.SHOP_DETAILS.UPGRADE_TO_COMMERCIAL_PLAN.TOAST.FINISH_SUCCESS')
          );
        },
        error: () => {
          this.close(false);
          this.toastService.error(
            this.translateService.instant('SHOPS.SHOP_DETAILS.UPGRADE_TO_COMMERCIAL_PLAN.TOAST.FINISH_ERROR_TITLE'),
            this.translateService.instant('SHOPS.SHOP_DETAILS.UPGRADE_TO_COMMERCIAL_PLAN.TOAST.FINISH_ERROR')
          );
        },
      });
  }

  close(result = false): void {
    this.modalRef.close(result);
  }

  commercialPlansByShop(shop: SelfHostedShop): Observable<CommercialPlan[]> {
    return forkJoin([this.upgradePlans$, this.regularPlans$]).pipe(
      map(([upgradePlans, regularPlans]: [CommercialPlan[], CommercialPlan[]]) => [...upgradePlans, ...regularPlans]),
      map((plans: CommercialPlan[]) => {
        const isCommunityEdition =
          shop.shopwareEdition === '-' && shop.subscriptionModules.length === 0 && !shop.commercialContract;

        if (isCommunityEdition) {
          return plans.filter((plan) => [RISE, EVOLVE].includes(plan.label));
        }

        if (shop.shopwareVersion.major === 'Shopware 6') {
          return plans.filter((plan) => [UPGRADE_PLAN_PE_EVOLVE, UPGRADE_PLAN_PE_BEYOND].includes(plan.label));
        }

        return plans.filter((plan) => [UPGRADE_PLAN_PE_RISE, UPGRADE_PLAN_PE_EVOLVE].includes(plan.label));
      })
    );
  }

  private isShopOwner(shop: SelfHostedShop): Observable<boolean> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(switchMap((companyId: number) => of(shop.companyId === companyId)));
  }
}
