import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { format as fnsFormat, getTimezoneOffset, toZonedTime } from 'date-fns-tz';

import { convertSbpDate, TimeZone } from '../../core';

export type TimeNumberFormat =
  | 'milliseconds'
  | 'seconds'
  | 'minutes'
  | 'hours'
  | 'days'
  | 'weeks'
  | 'months'
  | 'quarters'
  | 'years';

const TYPE_NUMBER_FORMAT_MAP: Record<TimeNumberFormat, number> = {
  milliseconds: 1,
  seconds: 1000,
  minutes: 1000 * 60,
  hours: 1000 * 60 * 60,
  days: 1000 * 60 * 60 * 24,
  weeks: 1000 * 60 * 60 * 24 * 7,
  months: 1000 * 60 * 60 * 24 * 30,
  quarters: 1000 * 60 * 60 * 24 * 30 * 3,
  years: 1000 * 60 * 60 * 24 * 365,
};

@Injectable({
  providedIn: 'root',
})
export class DateTimezoneService {
  private readonly translateService = inject(TranslateService);

  formatDateFns(date: string | Date, format: string): string {
    const dateToFormat = typeof date === 'string' ? new Date(date) : date;
    return fnsFormat(dateToFormat, format);
  }

  toZonedTime(date: string | Date, timeZone: string): Date {
    const convertedDate = typeof date === 'string' ? convertSbpDate(date) : date;
    return toZonedTime(convertedDate, timeZone);
  }

  getTimezoneOffset(timeZone: TimeZone, date?: Date): number {
    return getTimezoneOffset(timeZone, date);
  }

  getCurrentZonedTime(timeZone: TimeZone): Date {
    return this.toZonedTime(new Date(), timeZone);
  }

  getDiffToTime(
    date1: string | Date,
    date2: string | Date,
    absolute = true,
    precision = 0,
    timeFormat: TimeNumberFormat = 'seconds'
  ): number {
    const convertedDate1 = typeof date1 === 'string' ? convertSbpDate(date1) : date1;
    const convertedDate2 = typeof date2 === 'string' ? convertSbpDate(date2) : date2;

    let diff = (convertedDate1.valueOf() - convertedDate2.valueOf()) / TYPE_NUMBER_FORMAT_MAP[timeFormat];
    if (absolute) {
      diff = Math.abs(diff);
    }

    return Math.floor(diff * Math.pow(10, precision)) / Math.pow(10, precision);
  }

  formatTimeToString(time: number, timeFormat: TimeNumberFormat = 'seconds'): string {
    let diffAsString = ' ';

    if (time < 0) {
      diffAsString = '- ';
      time *= -1;
    }
    // calc in seconds
    let timeInMs = time * TYPE_NUMBER_FORMAT_MAP[timeFormat];

    const days = Math.floor(timeInMs / TYPE_NUMBER_FORMAT_MAP.days);
    timeInMs = timeInMs % TYPE_NUMBER_FORMAT_MAP.days;
    const hours = Math.floor(timeInMs / TYPE_NUMBER_FORMAT_MAP.hours);
    timeInMs = timeInMs % TYPE_NUMBER_FORMAT_MAP.hours;
    const minutes = Math.floor(timeInMs / TYPE_NUMBER_FORMAT_MAP.minutes);

    diffAsString += `${days}d ${hours}h ${minutes} min`;

    return diffAsString;
  }

  getDiffToCurrentDate(
    date: string | Date,
    absolute = true,
    precision = 0,
    diffFormat: TimeNumberFormat = 'seconds'
  ): number {
    const convertedDate = typeof date === 'string' ? convertSbpDate(date) : date;
    const currentDate = new Date();
    return this.getDiffToTime(convertedDate, currentDate, absolute, precision, diffFormat);
  }

  getDiffToDateAsString(date1: string | Date, date2: string | Date): string {
    const diff = this.getDiffToTime(date1, date2);
    return this.formatTimeToString(diff);
  }

  getDiffToCurrentDateAsString(date: string | Date): string {
    const diff = this.getDiffToCurrentDate(date);
    return this.formatTimeToString(diff);
  }

  getTimeZoneNameWithUTCOffset(date: string | Date, timeZone: TimeZone): string {
    const convertedDate = typeof date === 'string' ? convertSbpDate(date) : date;
    const timeZoneOffsetToUtc = (this.getTimezoneOffset(timeZone, convertedDate) / (1000 * 60 * 60)) % 24;
    const timeZoneOffsetString = timeZoneOffsetToUtc > 0 ? `+${timeZoneOffsetToUtc}` : timeZoneOffsetToUtc;

    if (timeZone === 'America/New_York') {
      return this.translateService.instant('COMMON.TIMEZONE.AMERICA_NEW_YORK', { offset: timeZoneOffsetString });
    }

    return this.translateService.instant('COMMON.TIMEZONE.EUROPE_BERLIN', { offset: timeZoneOffsetString });
  }
}
