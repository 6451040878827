<ng-container *ngIf="domainVerificationTokenData">
  <div
    *ngIf="!showButtonOnly; else verificationButton"
    class="flex-container flex-row flex-align-center a_control is_disabled">
    <div class="flex-fill is_disabled" data-margin-right>
      <input
        type="text"
        value="{{ domainName }}/{{ domainVerificationTokenData.fileName }}"
        class="control_content"
        disabled />
    </div>

    <button type="button" class="a_button is_primary" (click)="onVerifyDomain.emit()" data-margin-right>
      <span>{{ 'COMMON.SHOP.VERIFICATION.STEP.VERIFICATION.BUTTON.VERIFY' | translate }}</span>
    </button>
    <account-sw-icon
      [icon]="!verificationRequired && domainVerified ? 'checkmark-s' : 'times-s'"
      size="16px"
      [ngClass]="!verificationRequired && domainVerified ? 'text-success' : 'text-error'"
      [matTooltip]="getTooltipForValidationIcon()" />
  </div>

  <ng-template #verificationButton>
    <button type="button" class="a_button is_primary" (click)="onVerifyDomain.emit()">
      <span>{{ 'COMMON.SHOP.VERIFICATION.STEP.VERIFICATION.BUTTON.VERIFY' | translate }}</span>
    </button>
  </ng-template>
</ng-container>
