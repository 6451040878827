<div class="verification-instructions">
  <h4 class="heading-via-hash">{{ 'COMMON.SHOP.VERIFICATION.STEP.INSTRUCTION.VIA_HTML_HASH' | translate }}</h4>
  <ol class="account-list">
    <li>
      <span>
        <span *ngIf="!isPlatform; else platform">
          {{ 'COMMON.SHOP.VERIFICATION.STEP.INSTRUCTION.PART_1' | translate }}:
        </span>
        <ng-template #platform>
          <span>{{ 'COMMON.SHOP.VERIFICATION.STEP.INSTRUCTION.PART_1_PLATFORM' | translate }}:</span>
        </ng-template>
        <strong>{{ domainVerificationTokenData.fileName }}</strong>
      </span>
    </li>
    <li>
      <span>
        <span>{{ 'COMMON.SHOP.VERIFICATION.STEP.INSTRUCTION.PART_2' | translate }}:</span>
        <strong>{{ domainVerificationTokenData.content }}</strong>
      </span>
    </li>
    <li>
      <span>
        <span>{{ 'COMMON.SHOP.VERIFICATION.STEP.INSTRUCTION.PART_3' | translate }}:</span>
        <strong>{{ newDomainName }}/{{ domainVerificationTokenData.fileName }}</strong>
      </span>
    </li>
  </ol>
  <h4 class="heading-via-dns">{{ 'COMMON.SHOP.VERIFICATION.STEP.INSTRUCTION.VIA_DNS' | translate }}</h4>
  <ol class="account-list">
    <li>
      <span>
        <span>{{ 'COMMON.SHOP.VERIFICATION.STEP.INSTRUCTION.DNS_PART_1' | translate }}:</span>
        <strong>
          {{ domainVerificationTokenData.fileName.replace('.html', '') + '=' + domainVerificationTokenData.content }}
        </strong>
      </span>
    </li>
    <li>
      <span>
        <span>{{ 'COMMON.SHOP.VERIFICATION.STEP.INSTRUCTION.DNS_PART_2' | translate }}</span>
      </span>
    </li>
  </ol>
</div>
