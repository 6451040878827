<div class="community-edition-info">
  <h1>{{ 'SHOPS.SHOP_REGISTRATION_WIZARD.DOMAIN_NAMING.HEADLINE' | translate }}</h1>
  <p>{{ 'SHOPS.SHOP_REGISTRATION_WIZARD.DOMAIN_NAMING.DESCRIPTION' | translate }}</p>
  <p>{{ 'SHOPS.SHOP_REGISTRATION_WIZARD.DOMAIN_NAMING.DESCRIPTION_ADDITIONAL_INFORMATION' | translate }}</p>
</div>

<domain-verification-domain-naming-element
  [active]="active"
  [labelInputDomainName]="'COMMON.DOMAIN' | translate"
  [selectInputOnInit]="false"
  [form]="form" />

<domain-verification-shop-settings-element
  [shopwareVersions]="softwareVersions"
  [shopEnvironments]="shopEnvironments"
  [shopwareMajorVersion]="shopwareMajorVersion"
  [form]="form" />
