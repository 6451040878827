<account-card [heading]="'NOTIFICATION.HEADING' | translate" [contentHasPadding]="false">
  <card-controls>
    <div class="circle-button" (click)="deleteNotifications()">
      <account-sw-icon icon="trash" size="14px" />
    </div>
  </card-controls>

  <card-body>
    <ng-container *ngIf="data.notifications && data.notifications.length; else emptyState">
      <account-notification-item
        *ngFor="let notification of data.notifications"
        [notification]="notification"
        (onDelete)="deleteNotification(notification)"
        (onClick)="clickNotification(notification)" />
    </ng-container>

    <ng-template #emptyState>
      <account-empty-state
        icon="check-square"
        [heading]="'NOTIFICATION.EMPTY_STATE.HEADING' | translate"
        [subheading]="'NOTIFICATION.EMPTY_STATE.SUBHEADING' | translate"
        [inline]="true" />
    </ng-template>
  </card-body>
</account-card>
