import { ShopsFacade } from '@account/core/facades';
import { SelfHostedShop } from '@account/core/models';
import { inject, Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { StringValidators } from '../../../../../shared/validators/string.validators';
import { ShopMigrationModalConfig } from '../../../../partner/shared/shop/shop-migration-modal/shop-migration-modal.model';
import { DomainRenameModalConfig } from '../../domain-rename-modal/domain-rename-modal.model';
import { validateDomainRemote } from './validators/domain.validator';

export type FormType = 'migration' | 'rename' | 'shopCreation' | 'shopVerification';

@Injectable({
  providedIn: 'root',
})
export class DomainVerificationModalFormFactory {
  private readonly shopsFacade = inject(ShopsFacade);

  createForm(
    formBuilder: UntypedFormBuilder,
    formType: FormType,
    modalConfig?: ShopMigrationModalConfig | DomainRenameModalConfig
  ): UntypedFormGroup {
    let config = modalConfig as ShopMigrationModalConfig;
    switch (formType) {
      case 'migration': {
        const migrationForm = formBuilder.group({
          migration: formBuilder.group({
            shopToMigrate: formBuilder.control({ value: config.shop.selected, disabled: !config.shop.selectable }, [
              Validators.required,
            ]),
            assignedCustomer: formBuilder.control(
              { value: config.customer.selected, disabled: !config.customer.selectable },
              [Validators.required]
            ),
          }),
          domain: formBuilder.group({
            domainName: formBuilder.control('', [Validators.required, StringValidators.urlWithoutProtocol]),
          }),
          verification: this.createShopVerificationFormGroup(formBuilder, true, true),
        });
        // clear required true validator, termination of license is only required if the domainName is changed during migration
        migrationForm.get('verification.terminationOfLicense').setValidators([]);

        return migrationForm;
      }

      case 'rename': {
        config = modalConfig as DomainRenameModalConfig;
        const domainNameValidators = !config.shop.selectable
          ? [
              Validators.required,
              StringValidators.urlWithoutProtocol,
              StringValidators.notEquals(config.shop.selected.domain),
            ]
          : [];
        const renameForm = formBuilder.group({
          domainSelection: formBuilder.group({
            shopToRename: formBuilder.control({ value: config.shop.selected, disabled: !config.shop.selectable }, [
              Validators.required,
            ]),
          }),
          domain: formBuilder.group({
            domainName: formBuilder.control(
              !config.shop.selectable ? config.shop.selected.domain : '',
              domainNameValidators,
              [validateDomainRemote(this.shopsFacade)]
            ),
          }),
          verification: this.createShopVerificationFormGroup(formBuilder, true, true),
        });

        if (config.shop.selectable) {
          renameForm.get('domainSelection.shopToRename').valueChanges.subscribe((shopToRename: SelfHostedShop) => {
            renameForm.get('domain.domainName').setValidators([StringValidators.notEquals(shopToRename.domain)]);
          });
        }

        return renameForm;
      }

      case 'shopCreation':
        return formBuilder.group({
          domain: formBuilder.group({
            domainName: formBuilder.control(
              '',
              [Validators.required, StringValidators.urlWithoutProtocol, StringValidators.path],
              [validateDomainRemote(this.shopsFacade)]
            ),
            shopwareVersion: formBuilder.control(null, [Validators.required]),
            shopEnvironment: formBuilder.control(null, [Validators.required]),
          }),
          verification: this.createShopVerificationFormGroup(formBuilder),
          shopType: formBuilder.group({
            name: formBuilder.control('self-hosted', Validators.required),
          }),
          saaSDomain: formBuilder.group({
            subDomain: formBuilder.control('', [
              Validators.required,
              Validators.minLength(3),
              Validators.maxLength(63),
              Validators.pattern(/^[\da-z][\da-z-]+[\da-z]$/),
            ]),
            rawDomain: formBuilder.control(''),
            verified: formBuilder.control(false, Validators.requiredTrue),
          }),
        });

      case 'shopVerification':
        return formBuilder.group({
          verification: this.createShopVerificationFormGroup(formBuilder, false),
        });

      default:
        throw new Error('Invalid formType chosen');
    }
  }

  private createShopVerificationFormGroup(
    formBuilder: UntypedFormBuilder,
    withGTC = true,
    withTerminationOfLicense = false
  ): UntypedFormGroup {
    const form = formBuilder.group({
      domainVerified: formBuilder.control(false, [Validators.requiredTrue]),
    });
    if (withGTC) {
      form.addControl('gtcSigned', formBuilder.control(false, [Validators.requiredTrue]));
    }
    if (withTerminationOfLicense) {
      form.addControl('terminationOfLicense', formBuilder.control(false, [Validators.requiredTrue]));
    }

    return form;
  }
}
