import { PartnerSupportPermissionGateway } from '@account/core/gateways';
import { SupportPermissions } from '@account/core/models';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { SbpException } from '../../../models';
import { actions } from './support-permissions.actions';

@Injectable({
  providedIn: 'root',
})
export class SupportPermissionsEffects {
  private readonly actions$ = inject(Actions);
  private readonly partnersGateway = inject(PartnerSupportPermissionGateway);
  getSupportPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getPermissions),
      map((action) => action.payload),
      switchMap((partnerId: number) =>
        this.partnersGateway
          .getSupportPermissions(partnerId)
          .pipe(
            map((supportPermissions: SupportPermissions) =>
              actions.getPermissionsSuccess({ payload: supportPermissions })
            )
          )
      ),
      catchError((error: SbpException) => of(actions.getPermissionsFail({ payload: error })))
    )
  );
}
