import { Workspace } from '@account/core/models';
import { Action, createReducer, on } from '@ngrx/store';

import * as actions from './navigation.actions';
import { NavigationState } from './navigation.state';

export const initialNavigationState: NavigationState = {
  workspacesLoaded: false,
  activeWorkspaceSelected: null,
  activeWorkspace: null,
  activeWorkspaceItem: undefined,
  workspaces: [],
};

const navigationReducerInternal = createReducer(
  initialNavigationState,

  on(actions.setNavigationForUserAccountSuccess, (state: NavigationState, { payload }) => ({
    ...state,
    settings: payload.navigation.settings,
    companyRegistration: payload.navigation.companyRegistration,
  })),

  on(actions.setNavigationForCompanySuccess, (state: NavigationState, { payload }) => ({
    ...state,
    ...payload.navigation,
    userAccount: state.settings,
  })),

  on(actions.resetNavigation, () => ({
    ...initialNavigationState,
  })),

  on(actions.setWorkspacesSuccess, (state: NavigationState, { payload }) => ({
    ...state,
    workspaces: payload.workspaces,
    workspacesLoaded: true,
  })),

  on(actions.setActiveWorkspace, (state: NavigationState, { payload }) => ({
    ...state,
    activeWorkspaceSelected: true,
    activeWorkspace: payload ? state.workspaces.find((workspace: Workspace) => payload.name === workspace.name) : null,
  })),

  on(actions.setActiveWorkspaceItem, (state: NavigationState, { payload }) => ({
    ...state,
    activeWorkspaceSelected: null,
    activeWorkspaceItem: payload.item,
  }))
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function reducer(state: NavigationState | undefined, action: Action): NavigationState {
  return navigationReducerInternal(state, action);
}
