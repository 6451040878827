<div
  class="dropdown"
  [class.is_disabled]="disabled"
  (click)="toggle()"
  (focus)="onFocus()"
  (blur)="onInputBlur()"
  [attr.tabindex]="0">
  <span class="dropdown-selected-item">
    <ng-template [ngIf]="!alternativeSelectionLabels">
      <span class="item-badge" *ngFor="let selectedItem of selected">
        <ng-template [ngIf]="template && selectedItem">
          <multi-select-item-template-loader class="item-content" [template]="template" [item]="selectedItem" />
          <account-sw-icon
            class="item-remove"
            [class.is_disabled]="disabled"
            (click)="deselect($event, selectedItem)"
            icon="times-xs"
            size="8px" />
        </ng-template>
      </span>
    </ng-template>
    <ng-template [ngIf]="alternativeSelectionLabels">
      <span class="item-badge" *ngFor="let label of alternativeSelectionLabels">
        {{ label }}
      </span>
    </ng-template>
    <span class="preview-text" *ngIf="selected && selected.length === 0">
      {{ placeholder || 'Select' }}
    </span>
  </span>
</div>
<div class="dropdown-content" [class.open]="open" (click)="onDropdownContentClick()">
  <div [hidden]="true">
    <input
      #searchInputChild
      class="form-control input-sm"
      type="text"
      [placeholder]="'COMMON.SEARCH' | translate"
      [formControl]="searchInput" />
  </div>
  <div class="dropdown-list">
    <tree-view
      [nodes]="items"
      [disabled]="maxItems && selected && selected.length && maxItems <= selected.length"
      [selectableOption]="selectableMode"
      [selectedNodes]="selected"
      [compareWith]="_compareWith"
      [template]="template"
      (onUpdateSelectedNodes)="updateSelectedNodes($event)"
      childNodesAttributeName="children"
      [selectableAttributeName]="selectableAttributeName"
      nodeValueAttributeName="name" />
  </div>
</div>
