import { UpgradeToCommercialPlanGateway } from '@account/core/gateways';
import {
  CommercialContract,
  SelfServiceContractPriceInfos,
  ShopUpgradeCommercialPlanBookingInformation,
  ShopUpgradeSalesInformation,
} from '@account/core/models';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { CompaniesFacade } from '../company';
import { PartnersFacade } from '../partner';

@Injectable({
  providedIn: 'root',
})
export class UpgradeToCommercialPlanFacade {
  private readonly upgradeToCommercialPlanGateway = inject(UpgradeToCommercialPlanGateway);
  private readonly companiesFacade = inject(CompaniesFacade);
  private readonly partnersFacade = inject(PartnersFacade);

  calculateSubscriptionRefund(shopId: number): Observable<{ refund: number }> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.upgradeToCommercialPlanGateway.calculateSubscriptionRefund(companyId, shopId)
        )
      );
  }

  isTrialPhaseOfferedInPlanBooking(shopId: number): Observable<{ result: boolean }> {
    return this.companiesFacade
      .getCompanyIdOnce()
      .pipe(
        switchMap((companyId: number) =>
          this.upgradeToCommercialPlanGateway.isTrialPhaseOfferedInPlanBooking(companyId, shopId)
        )
      );
  }

  isTrialPhaseOfferedInPlanBookingForCustomer(customerId: number, shopId: number): Observable<{ result: boolean }> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.upgradeToCommercialPlanGateway.isTrialPhaseOfferedInPlanBookingForCustomer(partnerId, customerId, shopId)
        )
      );
  }

  isTrialPhaseOfferedInPlanBookingForNewShop(): Observable<{ result: boolean }> {
    return this.upgradeToCommercialPlanGateway.isTrialPhaseOfferedInPlanBookingForNewShop();
  }

  calculateSubscriptionRefundForCustomer(companyId: number, shopId: number): Observable<{ refund: number }> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.upgradeToCommercialPlanGateway.calculateSubscriptionRefundForCustomer(partnerId, companyId, shopId)
        )
      );
  }

  calculateShopPriceAndDiscount(
    companyId: number,
    planId: number,
    chargingInterval: number,
    plannedGmv: number
  ): Observable<SelfServiceContractPriceInfos> {
    return this.upgradeToCommercialPlanGateway.calculateShopPriceAndDiscount(
      companyId,
      planId,
      chargingInterval,
      plannedGmv
    );
  }

  calculateShopPriceAndDiscountForCustomer(
    customerId: number,
    planId: number,
    chargingInterval: number,
    plannedGmv: number
  ): Observable<SelfServiceContractPriceInfos> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.upgradeToCommercialPlanGateway.calculateShopPriceAndDiscountForCustomer(
            partnerId,
            customerId,
            planId,
            chargingInterval,
            plannedGmv
          )
        )
      );
  }

  handleUpgrade(
    shopId: number,
    planBookingInformation: ShopUpgradeCommercialPlanBookingInformation
  ): Observable<CommercialContract> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) =>
        this.upgradeToCommercialPlanGateway.handleUpgrade(companyId, shopId, planBookingInformation)
      ),
      switchMap((commercialContract: CommercialContract) =>
        this.companiesFacade.refreshAllocations().pipe(map(() => commercialContract))
      )
    );
  }

  handleUpgradeForCustomer(
    customerId: number,
    shopId: number,
    planBookingInformation: ShopUpgradeCommercialPlanBookingInformation
  ): Observable<CommercialContract> {
    return this.partnersFacade.getPartnerIdOnce().pipe(
      switchMap((partnerId: number) =>
        this.upgradeToCommercialPlanGateway.handleUpgradeForCustomer(
          partnerId,
          customerId,
          shopId,
          planBookingInformation
        )
      ),
      switchMap((commercialContract: CommercialContract) =>
        this.companiesFacade.refreshAllocations().pipe(map(() => commercialContract))
      )
    );
  }

  contactSalesWithPlanInformation(
    companyId: number,
    selectedPlanInformation: ShopUpgradeSalesInformation
  ): Observable<void> {
    return this.upgradeToCommercialPlanGateway.contactSalesWithPlanInformation(companyId, selectedPlanInformation);
  }

  contactSalesWithPlanInformationForCustomer(
    companyId: number,
    selectedPlanInformation: ShopUpgradeSalesInformation
  ): Observable<void> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(
        switchMap((partnerId: number) =>
          this.upgradeToCommercialPlanGateway.contactSalesWithPlanInformationForCustomer(
            partnerId,
            companyId,
            selectedPlanInformation
          )
        )
      );
  }
}
