import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ProductLicense } from '../../models';
import { ApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class ProductLicensesGateway {
  private readonly apiService = inject(ApiService);

  getProductLicense(shopId: number): Observable<ProductLicense> {
    return this.apiService.get(`/shops/${shopId}/productlicense`);
  }
}
