import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  inject,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const TOGGLE_BUTTON_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ToggleButtonComponent),
  multi: true,
};

/**
 * The toggle button is an input component used to represent a boolean value.
 */
@Component({
  selector: 'toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrl: './toggle-button.component.less',
  providers: [TOGGLE_BUTTON_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass],
})
export class ToggleButtonComponent implements ControlValueAccessor {
  private readonly cd = inject(ChangeDetectorRef);
  /**
   * No purpose.
   */
  @Input() @HostBinding('class.activator') isActivator = false;

  /**
   * Defines if the toggle button is disabled.
   */
  @Input() disabled: boolean;

  /**
   * No purpose.
   */
  @Input() isStateSuccess = false;

  /**
   * Defines if the label text contains multiple lines.
   */
  @Input() labelMultiline = false;

  /**
   * Defines if the toggle button is readonly.
   */
  @Input() readonly: boolean;

  /**
   * Defines the tabIndex of the toggle button.
   */
  @Input() tabindex = '0';

  /**
   * Emits an event of type `any` when the value changes.
   */
  @Output() readonly onChange = new EventEmitter<any>();

  /**
   * @internal
   */
  checked = false;

  /**
   * @internal
   */
  focused = false;

  /**
   * @internal
   */
  onModelChange: (value: boolean) => void = () => void 0;

  /**
   * @internal
   */
  onModelTouched: () => void = () => void 0;

  /**
   * @internal
   */
  onClick(event: Event, cb: HTMLInputElement): void {
    if (!this.disabled && !this.readonly) {
      event.preventDefault();
      this.toggle(event);
      cb.focus();
    }
  }

  /**
   * @internal
   */
  onInputChange(event: Event): void {
    if (!this.readonly) {
      const inputChecked = (<HTMLInputElement>event.target).checked;
      this.updateModel(event, inputChecked);
    }
  }

  /**
   * @internal
   */
  toggle($event: Event): void {
    this.updateModel($event, !this.checked);
  }

  /**
   * @internal
   */
  onFocus(_: FocusEvent): void {
    this.focused = true;
  }

  /**
   * @internal
   */
  onBlur(_: FocusEvent): void {
    this.focused = false;
    this.onModelTouched();
  }

  /**
   * @internal
   */
  writeValue(checked: boolean): void {
    this.checked = checked;
    this.cd.markForCheck();
  }

  /**
   * @internal
   */
  registerOnChange(fn: () => void): void {
    this.onModelChange = fn;
  }

  /**
   * @internal
   */
  registerOnTouched(fn: () => void): void {
    this.onModelTouched = fn;
  }

  /**
   * @internal
   */
  setDisabledState(val: boolean): void {
    this.disabled = val;
    this.cd.markForCheck();
  }

  private updateModel($event: Event, value: boolean): void {
    this.checked = value;
    this.onModelChange(this.checked);
    this.onChange.emit({
      originalEvent: $event,
      checked: this.checked,
    });
  }
}
