import { createAction, props } from '@ngrx/store';

import {
  CompanyMembership,
  CompanyRegistrationData,
  SbpException,
  UserAccount,
  UserAccountRegistration,
} from '../../../models';

export const noUserAccountExists = createAction('[UserAccount] not exists');

export const createUserAccount = createAction('[UserAccount] create', props<{ payload: UserAccountRegistration }>());
export const createUserAccountSuccess = createAction('[UserAccount] create success', props<{ payload: UserAccount }>());
export const createUserAccountFail = createAction('[UserAccount] create fail', props<{ payload: SbpException }>());

export const getUserAccount = createAction('[UserAccount] get', props<{ payload: { id: number } }>());
export const getUserAccountSuccess = createAction('[UserAccount] get success', props<{ payload: UserAccount }>());
export const getUserAccountFail = createAction('[UserAccount] get fail', props<{ payload: SbpException }>());

export const updateUserAccount = createAction('[UserAccount] update', props<{ payload: UserAccount }>());
export const updateUserAccountSuccess = createAction('[UserAccount] update success', props<{ payload: UserAccount }>());
export const updateUserAccountFail = createAction('[UserAccount] update fail', props<{ payload: SbpException }>());

export const createCompany = createAction(
  '[UserAccount] create company',
  props<{ payload: { company: CompanyRegistrationData } }>()
);
export const createCompanySuccess = createAction('[UserAccount] create company success');
export const createCompanyFail = createAction('[UserAccount] create company fail', props<{ payload: SbpException }>());

export const changeSelectedMembership = createAction(
  '[UserAccount] change selected membership',
  props<{ payload: CompanyMembership }>()
);
