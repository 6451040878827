import { ApiService } from '@account/core/services';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CompanyOnboardingDTO } from '../../models/onboarding/onboarding.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyOnboardingGateway {
  private readonly apiService = inject(ApiService);

  getCompanyOnboardingData(companyId: number): Observable<CompanyOnboardingDTO> {
    return this.apiService.get(`/companies/${companyId}/onboarding`);
  }

  updateCompanyOnboardingData(
    companyId: number,
    companyOnboardingData: CompanyOnboardingDTO
  ): Observable<CompanyOnboardingDTO> {
    return this.apiService.put(`/companies/${companyId}/onboarding`, companyOnboardingData);
  }
}
