<ng-container sbpColumnDef>
  <sbp-header-cell *sbpHeaderCellDef>
    <button
      *ngIf="gridActions && gridActions.length === 1"
      [disabled]="!selection.hasValue() || gridActions[0].disabled === true"
      [class.is_disabled]="!selection.hasValue() || gridActions[0].disabled === true"
      [matTooltip]="selection.hasValue() ? (getActionLabel$(gridActions[0].label, selection) | async)  : null"
      (click)="onGridAction(gridActions[0])"
      class="single-action--button padding_small a_button is_small">
      <account-sw-icon [icon]="gridActions[0].icon" size="10px" />
    </button>

    <account-dropdown
      *ngIf="selection && gridActions && gridActions.length > 1"
      [disabled]="!selection.hasValue()"
      [smallPadding]="true">
      <dropdown-active>
        <account-sw-icon variant="solid" icon="ellipsis-h" size="14px" />
      </dropdown-active>

      <ng-template dropdownEntry ngFor [ngForOf]="gridActions" let-action>
        <div
          dropdownEntry
          [disabled]="action.disabled === true"
          [class.is_disabled]="action.disabled === true"
          (click)="onGridAction(action)">
          <account-sw-icon [icon]="getActionIcon(action.icon, selection)" size="12px" class="context-action-icon" />
          {{ getActionLabel$(action.label, selection) | async }}
        </div>
      </ng-template>
    </account-dropdown>
  </sbp-header-cell>

  <sbp-cell *sbpCellDef="let row">
    <span
      [matTooltip]="rowActions[0] ? !isActionDisabled(rowActions[0], row) ? (getActionLabel$(rowActions[0].label, row) | async) : rowActions[0] ? (getActionLabel$(rowActions[0].disabledLabel, row) | async) : null : null"
      [matTooltipShowDelay]="250">
      <button
        *ngIf="rowActions && rowActions.length === 1"
        (click)="onRowAction(rowActions[0], row)"
        [disabled]="isActionDisabled(rowActions[0], row)"
        [class.not-visible]="isActionNotVisible(rowActions[0], row)"
        [class.is_disabled]="isActionDisabled(rowActions[0], row)"
        class="a_button is_small single-action--button padding_small">
        <account-sw-icon [icon]="getActionIcon(rowActions[0].icon, row)" size="10px" />
      </button>
    </span>

    <account-dropdown *ngIf="selection && rowActions && rowActions.length > 1" [smallPadding]="true" data-float-right>
      <dropdown-active>
        <account-sw-icon variant="solid" icon="ellipsis-h" size="14px" />
      </dropdown-active>

      <ng-template dropdownEntry ngFor [ngForOf]="rowActions" let-action [ngForTrackBy]="trackByAction.bind(this, row)">
        <div
          dropdownEntry
          [matTooltip]="isActionDisabled(action, row) ? (getActionLabel$(action.disabledLabel, row) | async) : null"
          [matTooltipShowDelay]="250"
          (click)="onRowAction(action, row)"
          [disabled]="isActionDisabled(action, row)"
          [class.not-visible]="isActionNotVisible(action, row)">
          <account-sw-icon [icon]="getActionIcon(action.icon, row)" size="12px" class="context-action-icon" />
          {{ getActionLabel$(action.label, row) | async }}
        </div>
      </ng-template>
    </account-dropdown>
  </sbp-cell>
</ng-container>
