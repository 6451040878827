import { CompaniesFacade } from '@account/core/facades';
import { onboardingSelectors } from '@account/core/store/selectors';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CompanyOnboardingGateway } from '../../gateways/onboarding/company-onboarding.gateway';
import {
  CompanyOnboardingData,
  CompanyOnboardingDTO,
  CompanyOnboardingStatus,
} from '../../models/onboarding/onboarding.model';
import * as onboardingActions from '../../store/onboarding/onboarding.actions';

@Injectable({
  providedIn: 'root',
})
export class CompanyOnboardingFacade {
  private readonly companyOnboardingGateway = inject(CompanyOnboardingGateway);
  private readonly companiesService = inject(CompaniesFacade);
  private readonly store = inject(Store);

  getCompanyOnboarding(companyId: number): Observable<CompanyOnboardingDTO> {
    return this.companyOnboardingGateway.getCompanyOnboardingData(companyId);
  }

  getStoredOnboardingStatus(): Observable<CompanyOnboardingStatus> {
    return this.store.select(onboardingSelectors.getOnboardingStatus);
  }

  getStoredOnboardingData(): Observable<CompanyOnboardingData | null> {
    return this.store.select(onboardingSelectors.getOnboardingData);
  }

  updateCompanyOnboardingData(companyOnboardingData: CompanyOnboardingDTO): Observable<CompanyOnboardingDTO> {
    return this.companiesService.getCompanyIdOnce().pipe(
      switchMap((companyId) =>
        this.companyOnboardingGateway.updateCompanyOnboardingData(companyId, companyOnboardingData)
      ),
      tap(() => this.store.dispatch(onboardingActions.setOnboardingDataForCompany()))
    );
  }

  updateCompanyOnboardingStatus(companyOnboardingStatus: CompanyOnboardingStatus): Observable<CompanyOnboardingDTO> {
    return this.companiesService.getCompanyIdOnce().pipe(
      switchMap((companyId) =>
        this.companyOnboardingGateway.updateCompanyOnboardingData(companyId, {
          status: companyOnboardingStatus,
          hasVisitedExtensionStore: null,
          hasExploredShopwarePlans: null,
        })
      ),
      tap((companyOnboardingDTO: CompanyOnboardingDTO) =>
        this.store.dispatch(
          onboardingActions.updateOnboardingDataForCompanyByDTO({ payload: { companyOnboardingDTO } })
        )
      )
    );
  }
}
