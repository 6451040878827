/* tslint:disable */
/* eslint-disable */
/**
 * Ory APIs
 * Documentation for all public and administrative Ory APIs. Administrative APIs can only be accessed with a valid Personal Access Token. Public APIs are mostly used in browsers.
 *
 * The version of the OpenAPI document: v1.15.7
 * Contact: support@ory.sh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { RequiredError } from "./base";
/**
 *
 * @export
 */
export const DUMMY_BASE_URL = 'https://example.com';
/**
 *
 * @throws {RequiredError}
 * @export
 */
export const assertParamExists = function (functionName, paramName, paramValue) {
  if (paramValue === null || paramValue === undefined) {
    throw new RequiredError(paramName, `Required parameter ${paramName} was null or undefined when calling ${functionName}.`);
  }
};
/**
 *
 * @export
 */
export const setApiKeyToObject = function (object, keyParamName, configuration) {
  return __awaiter(this, void 0, void 0, function* () {
    if (configuration && configuration.apiKey) {
      const localVarApiKeyValue = typeof configuration.apiKey === 'function' ? yield configuration.apiKey(keyParamName) : yield configuration.apiKey;
      object[keyParamName] = localVarApiKeyValue;
    }
  });
};
/**
 *
 * @export
 */
export const setBasicAuthToObject = function (object, configuration) {
  if (configuration && (configuration.username || configuration.password)) {
    object["auth"] = {
      username: configuration.username,
      password: configuration.password
    };
  }
};
/**
 *
 * @export
 */
export const setBearerAuthToObject = function (object, configuration) {
  return __awaiter(this, void 0, void 0, function* () {
    if (configuration && configuration.accessToken) {
      const accessToken = typeof configuration.accessToken === 'function' ? yield configuration.accessToken() : yield configuration.accessToken;
      object["Authorization"] = "Bearer " + accessToken;
    }
  });
};
/**
 *
 * @export
 */
export const setOAuthToObject = function (object, name, scopes, configuration) {
  return __awaiter(this, void 0, void 0, function* () {
    if (configuration && configuration.accessToken) {
      const localVarAccessTokenValue = typeof configuration.accessToken === 'function' ? yield configuration.accessToken(name, scopes) : yield configuration.accessToken;
      object["Authorization"] = "Bearer " + localVarAccessTokenValue;
    }
  });
};
function setFlattenedQueryParams(urlSearchParams, parameter, key = "") {
  if (parameter == null) return;
  if (typeof parameter === "object") {
    if (Array.isArray(parameter)) {
      parameter.forEach(item => setFlattenedQueryParams(urlSearchParams, item, key));
    } else {
      Object.keys(parameter).forEach(currentKey => setFlattenedQueryParams(urlSearchParams, parameter[currentKey], `${key}${key !== '' ? '.' : ''}${currentKey}`));
    }
  } else {
    if (urlSearchParams.has(key)) {
      urlSearchParams.append(key, parameter);
    } else {
      urlSearchParams.set(key, parameter);
    }
  }
}
/**
 *
 * @export
 */
export const setSearchParams = function (url, ...objects) {
  const searchParams = new URLSearchParams(url.search);
  setFlattenedQueryParams(searchParams, objects);
  url.search = searchParams.toString();
};
/**
 *
 * @export
 */
export const serializeDataIfNeeded = function (value, requestOptions, configuration) {
  const nonString = typeof value !== 'string';
  const needsSerialization = nonString && configuration && configuration.isJsonMime ? configuration.isJsonMime(requestOptions.headers['Content-Type']) : nonString;
  return needsSerialization ? JSON.stringify(value !== undefined ? value : {}) : value || "";
};
/**
 *
 * @export
 */
export const toPathString = function (url) {
  return url.pathname + url.search + url.hash;
};
/**
 *
 * @export
 */
export const createRequestFunction = function (axiosArgs, globalAxios, BASE_PATH, configuration) {
  return (axios = globalAxios, basePath = BASE_PATH) => {
    var _a;
    const axiosRequestArgs = Object.assign(Object.assign({}, axiosArgs.options), {
      url: (axios.defaults.baseURL ? '' : (_a = configuration === null || configuration === void 0 ? void 0 : configuration.basePath) !== null && _a !== void 0 ? _a : basePath) + axiosArgs.url
    });
    return axios.request(axiosRequestArgs);
  };
};