import { Directive, ElementRef, inject, OnInit } from '@angular/core';

import { MODAL_DATA } from './modal-injector';
import { ModalRef } from './modal-ref';

@Directive({ standalone: true })
export abstract class AbstractModalComponent implements OnInit {
  protected elementRef = inject(ElementRef);
  protected modalRef = inject(ModalRef);
  protected modalData = inject<any>(MODAL_DATA);
  heading = '';
  text = '';
  textAsHTML = false;
  maxWidth: string | null = null;

  ngOnInit(): void {
    // if heading has been injected use the injected data
    if (undefined !== this.modalData.heading && null !== this.modalData.heading && this.modalData.heading.length > 0) {
      this.heading = this.modalData.heading;
    }
    // use the injected text data as modal content or warn because the is no injected text
    if (undefined === this.modalData.text || null === this.modalData.text || this.modalData.text.length === 0) {
      throw new Error('Implemented modal without content, looks like text is missing');
    } else {
      this.text = this.modalData.text;
    }
    // if textAsHTML has been injected use the injected data
    if (
      undefined !== this.modalData.textAsHTML &&
      null !== this.modalData.textAsHTML &&
      typeof this.modalData.textAsHTML === typeof true
    ) {
      this.textAsHTML = this.modalData.textAsHTML;
    }

    if (undefined !== this.modalData.maxWidth) {
      this.maxWidth = this.modalData.maxWidth;
    }
  }
}
