import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  AcademyBooking,
  AcademyCertification,
  Appointment,
  Attendee,
  Examination,
  ListResult,
  SbpException,
  ShopwareCertification,
  Training,
} from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { ApiService } from '../../services';
import { HttpParamsBuilder } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class AcademyGateway {
  private readonly apiService = inject(ApiService);

  getAcademyCertificatesForCompany(
    companyId: number,
    metaData: RequestMetaData
  ): Observable<ListResult<ShopwareCertification[]>> {
    const params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    return this.apiService.getList(`/companies/${companyId}/shopwarecertifications`, params);
  }

  getAppointmentsForCompany(companyId: number, metaData: RequestMetaData): Observable<Appointment[]> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    params = params.set('userId', companyId.toString());
    return this.apiService.get(`/companies/${companyId}/academy/appointments`, params);
  }

  getBookingsForCompany(companyId: number, metaData: RequestMetaData): Observable<ListResult<AcademyBooking[]>> {
    const params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    return this.apiService.getList(`/companies/${companyId}/academy/bookings`, params);
  }

  getExaminationList(
    metaData: RequestMetaData,
    filter: Record<string, any> = {}
  ): Observable<ListResult<Examination[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    params = HttpParamsBuilder.applyFilters(params, filter);
    return this.apiService.getList('/academy/examinations', params);
  }

  getTrainingList(metaData: RequestMetaData, filter: Record<string, any> = {}): Observable<ListResult<Training[]>> {
    let params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    params = HttpParamsBuilder.applyFilters(params, filter);
    return this.apiService.getList('/academy/trainings', params);
  }

  updateAppointmentAttendee(companyId: number, appointment: Appointment, attendee: Attendee): Observable<Attendee> {
    return this.apiService.put(
      `/companies/${companyId}/academy/appointments/${appointment.id}/attendees/${attendee.id}`,
      attendee
    );
  }

  getOnlineCertificationsForCompany(companyId: number, metaData: RequestMetaData): Observable<AcademyCertification[]> {
    const params = HttpParamsBuilder.applyMetaData(new HttpParams(), metaData);
    return this.apiService.get(`/companies/${companyId}/certifications`, params);
  }

  resendOnlineCertificationAccessDataMail(companyId: number, certification: AcademyCertification): Observable<void> {
    return this.apiService.post(`/companies/${companyId}/certifications/${certification.id}/accessDataMail`);
  }

  downloadLegacyCertificatePdf(
    companyId: number,
    certification: ShopwareCertification,
    locale: string
  ): Observable<Blob | SbpException> {
    const params = new HttpParams().set('locale', locale);
    return this.apiService.download(
      `/companies/${companyId}/shopwarecertifications/${certification.id}/document`,
      params
    );
  }
}
