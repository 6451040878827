import { BasketFacade } from '@account/core/facades';
import {
  Attendee,
  Basket,
  BasketPosition,
  BasketPositionAppointment,
  BasketPositionExamination,
  BasketPositionVoucher,
  BasketShop,
  SbpException,
} from '@account/core/models';
import { LocaleCurrencyPipe } from '@account/shared/pipes';
import { ArrayValidators, StringValidators } from '@account/shared/validators';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

import { LoadingSpinnerDirective } from '../../../../shared/directives/loading-spinner.directive';
import { TrackingDataService } from '../../../services/tracking-data.service';
import { AlertComponent } from '../../alert';
import { ModalComponent, ModalRef } from '../../modal';
import { SwIconComponent } from '../../sw-icon';
import { ToastService } from '../../toast';
import { ShoppingCartComponent } from '../shopping-cart.component';

interface AttendeeForm {
  eMail: FormControl<string>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
}

interface AttendeesGroup {
  [key: string]: FormArray<FormGroup<AttendeeForm>>;
}

interface CheckoutForm {
  shop: FormControl<BasketShop | null>;
  voucher: FormControl<string>;
  requirements?: FormGroup<AttendeesGroup>;
}

@Component({
  selector: 'shopping-cart-modal',
  templateUrl: './shopping-cart-modal.component.html',
  styleUrl: './shopping-cart-modal.component.less',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    AsyncPipe,
    FormsModule,
    AlertComponent,
    ModalComponent,
    TranslateModule,
    SwIconComponent,
    LocaleCurrencyPipe,
    ReactiveFormsModule,
    ShoppingCartComponent,
    MatTooltip,
    LoadingSpinnerDirective,
  ],
})
export class ShoppingCartModalComponent implements OnInit {
  private readonly modalRef = inject(ModalRef);
  private readonly basketFacade = inject(BasketFacade);
  private readonly formBuilder = inject(FormBuilder);
  private readonly toastService = inject(ToastService);
  private readonly translateService = inject(TranslateService);
  private readonly router = inject(Router);
  private readonly trackingDataService = inject(TrackingDataService);
  basket$: Observable<Basket | null>;
  shops$: Observable<BasketShop[]>;
  form: FormGroup<CheckoutForm>;
  bookingShopBalance: number | null = null;
  bookingShopDomain: string | null = null;
  isLoading = false;
  isEditingPosition = false;
  preferredBillingShop: BasketShop | null = null;
  reedemVoucherDisabled = false;
  redeemVoucherSuccess: boolean;

  ngOnInit(): void {
    this.form = this.formBuilder.group<CheckoutForm>({
      shop: this.formBuilder.control<BasketShop | null>(null, [Validators.required]),
      voucher: this.formBuilder.control<string>(''),
    });

    this.basket$ = this.basketFacade.getStoredBasket().pipe(
      tap((basket: Basket | null) => {
        if (!basket) {
          return;
        }

        this.addRequirementsFormControl(basket);
        this.reedemVoucherDisabled = this.basketHasVoucherPosition(basket);
      })
    );

    this.basket$.pipe(take(1)).subscribe({
      next: (basket: Basket) => this.trackingDataService.viewCart(basket),
    });

    this.form.get('shop').valueChanges.subscribe((shop: BasketShop | null) => {
      if (shop === null) {
        this.bookingShopBalance = null;
        this.bookingShopDomain = null;
        return;
      }

      this.bookingShopBalance = shop.balance;
      this.bookingShopDomain = shop.domain;
    });

    this.shops$ = combineLatest([this.basketFacade.getShops(), this.basketFacade.getStoredBasket()]).pipe(
      switchMap(([shops, basket]: [BasketShop[], Basket | null]) => {
        if (shops.length === 0 || !basket || basket.totalPrice === 0) {
          return of(shops);
        }

        this.preferredBillingShop = shops.find((shop: BasketShop) => shop.domain === 'www.shopware-ag.de') || shops[0];

        let availableDispo: number = shops[0].dispo || 0;
        shops
          .filter((shop: BasketShop) => shop.balance < 0)
          .forEach((shop: BasketShop) => (availableDispo += shop.balance));

        return of(
          shops.filter(
            (shop: BasketShop) => (shop.balance > 0 ? shop.balance : 0) + availableDispo >= basket.totalPrice
          )
        );
      }),
      tap((shops: BasketShop[]) => {
        const field = this.form.get('shop');
        const shop =
          shops.find((shop: BasketShop) => shop.id === field.value?.id || shop.domain === 'www.shopware-ag.de') ||
          shops[0];

        if (!shop) {
          field.setValue(null);

          return;
        }

        field.setValue(shop);
      })
    );
  }

  closeModal(): void {
    this.modalRef.close();
  }

  removePosition(position: BasketPosition): void {
    this.isLoading = true;

    this.basket$.pipe(take(1)).subscribe({
      next: (basket: Basket) => this.trackingDataService.removeFromCart(position.orderNumber, basket),
    });

    this.basketFacade.removeFromBasket(position.orderNumber).subscribe({
      next: (basket: Basket | null) => {
        this.isLoading = false;
        if (!basket) {
          this.closeModal();
          return;
        }
        if (basket.positions.length === 0) {
          this.closeModal();

          return;
        }

        this.resetRequirements();
      },
      error: () => (this.isLoading = false),
    });
  }

  updatePosition(position: BasketPosition): void {
    const attendees = this.getAttendeesByPosition(position);

    this.isLoading = true;
    const positionData = attendees
      ? {
          attendees: attendees.controls.map((group: FormGroup<AttendeeForm>) => group.value),
          quantity: attendees.controls.length,
        }
      : {};
    this.basketFacade.updateBasketPosition(position.orderNumber, positionData).subscribe({
      next: (basket: Basket) => {
        if (position.quantity < positionData.quantity) {
          this.trackingDataService.addToCart(position.orderNumber, basket);
        }

        this.resetRequirements();
        this.isLoading = false;
      },
      error: () => (this.isLoading = false),
    });
  }

  instanceOfBasketPositionExamination(position: BasketPosition): position is BasketPositionExamination {
    return 'examination' in position;
  }

  instanceOfBasketPositionAppointment(position: BasketPosition): position is BasketPositionAppointment {
    return 'appointment' in position;
  }

  instanceOfBasketPositionVoucher(position: BasketPosition): position is BasketPositionVoucher {
    return 'voucher' in position;
  }

  addAttendee(position: BasketPosition): void {
    this.isEditingPosition = true;
    const attendees = this.getAttendeesByPosition(position);

    attendees.push(
      this.formBuilder.group<AttendeeForm>({
        eMail: this.formBuilder.control<string>('', [Validators.required, StringValidators.email]),
        firstName: this.formBuilder.control<string>('', Validators.required),
        lastName: this.formBuilder.control<string>('', Validators.required),
      })
    );
  }

  removeAttendee(position: BasketPosition, index: number): void {
    const attendees = this.getAttendeesByPosition(position);

    attendees.removeAt(index);

    this.basket$.pipe(take(1)).subscribe({
      next: (basket: Basket) => {
        this.trackingDataService.removeFromCart(position.orderNumber, basket, 1);
      },
    });

    this.updatePosition(position);
  }

  editRow(position: BasketPosition, index: number): void {
    this.isEditingPosition = true;
    const requirements = this.form.get('requirements') as FormGroup<AttendeesGroup>;
    const orderNumbers = Object.keys(requirements.controls);

    orderNumbers.forEach((currentOrderNumber: string) => {
      const attendees = requirements.get(currentOrderNumber) as FormArray<FormGroup<AttendeeForm>>;

      attendees.controls.forEach((group: FormGroup<AttendeeForm>, currentIndex: number) => {
        if (position.orderNumber === currentOrderNumber && currentIndex === index) {
          group.get('eMail').enable();
          group.get('firstName').enable();
          group.get('lastName').enable();
          return;
        }

        group.get('eMail').disable();
        group.get('firstName').disable();
        group.get('lastName').disable();
      });
    });
  }

  isRowEditable(position: BasketPosition, index: number): boolean {
    const attendees = this.getAttendeesByPosition(position);

    return attendees.at(index).get('eMail').enabled;
  }

  resetRequirements(): void {
    this.isEditingPosition = false;
    this.form.removeControl('requirements');

    this.basket$.pipe(take(1)).subscribe((basket: Basket | null) => {
      if (!basket) {
        return;
      }

      this.addRequirementsFormControl(basket);
    });
  }

  checkoutBasket(): void {
    this.isLoading = true;
    const shopId = this.form.get('shop').value.id;

    this.basketFacade.checkoutBasket(shopId).subscribe({
      next: (basket: Basket) => {
        this.toastService.success(
          this.translateService.instant('COMMON.BASKET.MODAL.CHECKOUT_SUCCESS.TITLE'),
          this.translateService.instant('COMMON.BASKET.MODAL.CHECKOUT_SUCCESS.MESSAGE')
        );

        this.trackingDataService.purchase(basket);

        this.closeModal();
        this.router.navigate(['academy', 'bookings']);
      },
      error: (exception: SbpException) => {
        this.isLoading = false;
        if (exception.code === 'OrdersException-4' && exception.context && 'reason' in exception.context) {
          const context = exception.context as Record<string, any>;
          if (context.reason === 'Examination is not bookable') {
            this.toastService.error(
              this.translateService.instant('COMMON.BASKET.MODAL.CHECKOUT_EXAMINATION_NOT_BOOKABLE_ERROR.TITLE'),
              this.translateService.instant('COMMON.BASKET.MODAL.CHECKOUT_EXAMINATION_NOT_BOOKABLE_ERROR.MESSAGE')
            );
            return;
          }
        }
        this.toastService.error(
          this.translateService.instant('COMMON.BASKET.MODAL.CHECKOUT_ERROR.TITLE'),
          this.translateService.instant('COMMON.BASKET.MODAL.CHECKOUT_ERROR.MESSAGE')
        );
      },
    });
  }

  goToBilling(event: Event): void {
    event.preventDefault();

    this.closeModal();

    if (this.preferredBillingShop) {
      this.router.navigate(['shops', 'shops', this.preferredBillingShop.id, 'account']);

      return;
    }

    this.router.navigate(['settings', 'billing']);
  }

  isAttendeeLimitReached(position: BasketPosition): boolean {
    if (!this.instanceOfBasketPositionAppointment(position)) {
      return false;
    }

    return position.registrees.length + position.appointment.attendeeCount >= position.appointment.numberOfAttendees;
  }

  validateVoucher(): void {
    this.isLoading = true;
    const voucherCode: string = this.form.get('voucher').value;

    this.basketFacade.validateVoucher(voucherCode).subscribe({
      next: () => {
        this.redeemVoucherSuccess = true;
        this.isLoading = false;

        this.trackingDataService.trackData({
          event: 'add_coupon',
          payload: { coupon: voucherCode },
        });
      },
      error: () => {
        this.redeemVoucherSuccess = false;
        this.isLoading = false;
      },
    });
  }

  sortBasketPositions(basket: Basket): BasketPosition[] {
    return basket.positions.slice().sort((a: BasketPosition, b: BasketPosition) => {
      if (this.instanceOfBasketPositionVoucher(a)) {
        return 1;
      }

      if (this.instanceOfBasketPositionVoucher(b)) {
        return -1;
      }

      return 0;
    });
  }

  basketHasVoucherPosition(basket: Basket): boolean {
    return basket.positions.some((position: BasketPosition) => this.instanceOfBasketPositionVoucher(position));
  }

  private addRequirementsFormControl(basket: Basket): void {
    const requirements: FormGroup = this.formBuilder.group({});
    const positions: BasketPosition[] = basket.positions ?? [];

    positions.forEach((position: BasketPosition) => {
      if (!this.instanceOfBasketPositionExamination(position) && !this.instanceOfBasketPositionAppointment(position)) {
        return;
      }

      requirements.addControl(
        position.orderNumber,
        this.formBuilder.array(
          position.registrees.map((attendee: Attendee) =>
            this.formBuilder.group<AttendeeForm>({
              eMail: this.formBuilder.control<string>({ value: attendee.eMail, disabled: true }, [
                Validators.required,
                StringValidators.email,
              ]),
              firstName: this.formBuilder.control<string>(
                { value: attendee.firstName, disabled: true },
                Validators.required
              ),
              lastName: this.formBuilder.control<string>(
                { value: attendee.lastName, disabled: true },
                Validators.required
              ),
            })
          ),
          ArrayValidators.minLength(1)
        )
      );
    });

    if (Object.values(requirements).length === 0) {
      return;
    }

    this.form.addControl('requirements', requirements);
  }

  private getAttendeesByPosition(position: BasketPosition): FormArray<FormGroup<AttendeeForm>> | null {
    return this.form.get('requirements')?.get(position.orderNumber) as FormArray<FormGroup<AttendeeForm>> | null;
  }
}
