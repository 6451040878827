import { FeatureFlagService } from '@account/core/services';
import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FeatureFlagPipe } from '../../../../shared/pipes';
import { Workspace, WorkspaceItem } from '../../../models';
import { NavigationEntryComponent } from './navigation-entry/navigation-entry.component';

@Component({
  selector: 'navigation-workspace',
  templateUrl: './navigation-workspace.component.html',
  styleUrl: './navigation-workspace.component.less',
  standalone: true,
  imports: [CommonModule, NavigationEntryComponent, FeatureFlagPipe, TranslateModule],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'flex-1 overflow-y-auto scrollbar-hide p-4',
  },
  styles: `
    :host.scrollbar-hide::-webkit-scrollbar {
      display: none;
    }
    :host.scrollbar-hide {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  `,
})
export class NavigationWorkspaceComponent {
  private readonly featureFlagService: FeatureFlagService = inject(FeatureFlagService);

  @Input() workspace: Workspace;
  @Input() workspaceItems: WorkspaceItem[];
  @Input() activeWorkspaceItem: WorkspaceItem;

  showNavigationEntry(workspace: WorkspaceItem): boolean {
    if (workspace.featureFlag) {
      return this.featureFlagService.flagActivated(workspace.featureFlag);
    }

    if (workspace.whenFeatureFlagDeactivated) {
      return !this.featureFlagService.flagActivated(workspace.whenFeatureFlagDeactivated);
    }

    return true;
  }

  trackByWorkspaceItem(index: number, item: WorkspaceItem): string {
    return item.label;
  }
}
