<account-modal-wizard
  minHeight="60vh"
  maxWidth="860px"
  [heading]="wizardHeading"
  [formGroup]="form"
  [loading]="loading"
  [loadingText]="loadingText"
  [labelCancelButton]="'COMMON.SHOP.SHOP_CREATION_MODAL.ACTION.CANCEL' | translate"
  [labelCompleteButton]="'COMMON.SHOP.SHOP_CREATION_MODAL.HEADING.REGISTER' | translate"
  [closeableOnEscape]="false"
  [preserveStateAfterCompletion]="true"
  [contentHasPadding]="activeStep !== 'booking'"
  [hidePagination]="activeStep === 'booking'"
  (onAbort)="close()"
  (onCompleted)="createShopAndClose()">
  <wizard-steps>
    <wizard-step
      [formGroup]="form.get('domain')"
      (onNext)="domainRenamingStepFinished()"
      (onInit)="initializeDomainRenamingStep()"
      [skipStepIf]="shopType !== 'self-hosted'">
      <ng-container *ngIf="softwareVersions$ | async as softwareVersions">
        <ng-container *ngIf="shopEnvironments$ | async as shopEnvironments">
          <shop-creation-modal-domain-naming-step
            [active]="'domainName' === activeStep"
            [form]="form.get('domain')"
            [softwareVersions]="softwareVersions"
            [shopwareMajorVersion]="config.shopwareMajorVersion"
            [shopEnvironments]="shopEnvironments" />
        </ng-container>
      </ng-container>
    </wizard-step>

    <wizard-step
      (onInit)="initializeInstructionStep()"
      (onPrev)="returnToDomainRenamingStep()"
      [skipStepIf]="shopType !== 'self-hosted'">
      <domain-verification-instruction-element
        *ngIf="domainVerificationTokenData"
        [domainVerificationTokenData]="domainVerificationTokenData"
        [newDomainName]="domainName"
        [shopwareVersion]="form.get('domain').get('shopwareVersion').value" />
    </wizard-step>

    <wizard-step
      [formGroup]="form.get('verification')"
      (onInit)="initializeVerificationStep()"
      (onPrev)="returnToDomainRenamingStep()"
      [skipStepIf]="shopType !== 'self-hosted'">
      <shop-creation-modal-domain-verification-step
        [form]="form.get('verification')"
        [assignee]="config.assignee"
        [shopAssignationType]="config.context"
        [domainName]="domainName"
        [domainVerified]="isDomainVerified()"
        [verificationRequired]="domainVerificationRequired()"
        [verificationResult]="verificationResult"
        [domainVerificationTokenData]="domainVerificationTokenData"
        (onVerifyDomain)="verifyDomain()" />
    </wizard-step>

    <wizard-step [skipStepIf]="shopwareVersion?.major === 'Shopware 6'">
      <shop-creation-modal-teaser-step />
    </wizard-step>

    <wizard-step [skipStepIf]="shopwareVersion?.major === 'Shopware 5'" (onInit)="initializeBookingStep()">
      <account-plan-booking-modal-base
        *ngIf="activeStep === 'booking'"
        [modalData]="planBookingConfig"
        [commercialPlansFn]="commercialPlansFn"
        [heading]="wizardHeading"
        [isWizardStep]="true"
        [skipLabel]="'COMMON.SHOP.SHOP_CREATION_MODAL.STEP.BOOKING.SKIP_LABEL' | translate"
        [isShopSelectionRequired]="false"
        [isGtcAcceptanceRequired]="false"
        (onClose)="close()"
        (onCreate)="createShopAndClose($event)"
        (onSalesContact)="createShopAndClose()">
        <ng-template>
          <p>
            {{ 'COMMON.SHOP.SHOP_CREATION_MODAL.STEP.BOOKING.DESCRIPTION' | translate }}
          </p>
        </ng-template>
      </account-plan-booking-modal-base>
    </wizard-step>
  </wizard-steps>
</account-modal-wizard>
