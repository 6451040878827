<button
  class="a_button is_small padding_small"
  type="button"
  (click)="toggleFilterVisibility()"
  [class.is_disabled]="disabled"
  [class.is_active]="hasActiveFilters">
  <account-sw-icon icon="filter" size="14px" />
</button>

<div class="filters" [class.is--active]="visible" (click)="onPanelClick()">
  <div class="search-container" [class.border-bottom]="filterGroupContentChildren.length > 0">
    <input
      type="search"
      [placeholder]="intl.searchLabel"
      [formControl]="searchInput"
      (search)="onSearch($event.target.value)"
      [class.border-bottom]="filterGroupContentChildren.length > 0"
      #searchInputChild />
  </div>
  <div [formGroup]="formGroup" *ngIf="filterGroupContentChildren.length > 0" class="filter-group-list">
    <ng-content select="sbp-table-filter-group" />
  </div>
  <div class="filter-actions">
    <button class="a_button is_small" type="button" (click)="resetFilter()">{{ intl.resetFilterLabel }}</button>
    <button class="a_button is_small is_primary" type="button" (click)="applyFilter()">
      {{ intl.applyFilterLabel }}
    </button>
  </div>
</div>
