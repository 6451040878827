import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ToastService } from '../../../shared/components/toast/toast.service';
import { PluginReleaseRequestGateway } from '../../gateways/plugins';
import { ReleaseRequest, ReleaseRequestAttachment, ReleaseRequestForCreation, SbpException } from '../../models';
import { ExceptionFormatterService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class PluginReleaseRequestFacade {
  private readonly gateway = inject(PluginReleaseRequestGateway);
  private readonly translateService = inject(TranslateService);
  private readonly toastService = inject(ToastService);
  private readonly exceptionFormatterService = inject(ExceptionFormatterService);

  getReleaseRequestsForPlugin(pluginId: number): Observable<ReleaseRequest[]> {
    return this.gateway.getReleaseRequestsForPlugin(pluginId);
  }

  getPositionInReleaseRequestQueue(pluginId: number): Observable<number> {
    return this.gateway.getPositionInReleaseRequestQueue(pluginId);
  }

  createReleaseRequest(pluginId: number, releaseRequest: ReleaseRequestForCreation): Observable<ReleaseRequest> {
    return this.gateway.createReleaseRequest(pluginId, releaseRequest);
  }

  uploadReleaseRequestAttachments(pluginId: number, attachments: File[]): Observable<ReleaseRequestAttachment[]> {
    const attachmentRequests: Observable<null | ReleaseRequestAttachment>[] = attachments.map((attachment: File) =>
      this.gateway.uploadReleaseRequestAttachment(pluginId, attachment).pipe(
        catchError((exception: SbpException) => {
          switch (exception.code) {
            case 'ValidatorException-5':
              this.toastService.error(
                this.translateService.instant('COMMON.TOAST.ERROR.TITLE.UPLOAD_FAILED'),
                this.exceptionFormatterService.getMessageByContext(exception, null),
                true
              );
              break;
            // skip default
          }
          return of(null);
        })
      )
    );

    return forkJoin(attachmentRequests).pipe(
      map((uploadResults: (ReleaseRequestAttachment | null)[]) => uploadResults.filter((result) => !!result))
    );
  }

  downloadReleaseRequestAttachment(remoteLink: string): Observable<Blob | SbpException> {
    return this.gateway.downloadReleaseRequestAttachment(remoteLink);
  }
}
