import { TimeZone } from '@account/core/models';
import { inject, Injectable, Pipe, PipeTransform } from '@angular/core';

import { DateTimezoneService } from '../services/date-timezone.service';

@Pipe({
  name: 'formatDateFns',
  standalone: true,
})
@Injectable()
export class FormatDateFnsPipe implements PipeTransform {
  private readonly dateTimeZoneService = inject(DateTimezoneService);

  transform(date: string | Date, timezone: string): string {
    return this.dateTimeZoneService.formatDateFns(date, timezone);
  }
}

@Pipe({
  name: 'timeZoneNameWithUTCOffset',
  standalone: true,
})
@Injectable()
export class TimeZoneNameWithUTCOffsetPipe implements PipeTransform {
  private readonly dateTimeZoneService = inject(DateTimezoneService);

  transform(date: string | Date, timezone: TimeZone): string {
    return this.dateTimeZoneService.getTimeZoneNameWithUTCOffset(date, timezone);
  }
}
