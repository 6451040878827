<div *ngIf="showValue && valuePosition === 'top'" data-margin-bottom>
  <progress-bar-label
    [label]="label"
    [height]="height"
    [value]="value"
    [maxValue]="maxValue"
    [showMaxValue]="showMaxValue"
    [unit]="unit"
    [showUnit]="showUnit"
    [txtColor]="txtColor"
    [valueAlignment]="valueAlignment"
    [minMaxValueSeparator]="minMaxValueSeparator"
    [separateMinMaxValuesWithWhitespace]="separateMinMaxValuesWithWhitespace"
    [separateValueAndUnitWithWhitespace]="separateValueAndUnitWithWhitespace" />
</div>
<div class="flex-container flex-row flex-align-center">
  <div *ngIf="reachedGoal" class="reachedGoal" [innerHtml]="reachedGoal" data-margin-right></div>
  <div class="flex-fill">
    <div
      class="progress-bar"
      aria-valuemin="0"
      [style.height]="height ? height + 'px' : ''"
      [style.background]="containerBgColor ? containerBgColor : ''"
      [attr.aria-valuenow]="value"
      [attr.aria-valuemax]="maxValue">
      <div
        class="progress-bar-value progress-bar-value-animate"
        [class.reversed]="reversed"
        [style.width]="valueInPercent + '%'"
        [style.background]="bgColor ? bgColor : ''"
        [class.hasArrow]="hasArrow"
        [class.arrowReversed]="arrowReversed">
        <div class="sticky" *ngIf="valuePosition === 'sticky'">
          <progress-bar-label
            [label]="label"
            [height]="height"
            [value]="value"
            [maxValue]="maxValue"
            [showMaxValue]="showMaxValue"
            [isInlineLabel]="true"
            [unit]="unit"
            [showUnit]="showUnit"
            [txtColor]="txtColor"
            [valueAlignment]="valueAlignment"
            [minMaxValueSeparator]="minMaxValueSeparator"
            [separateMinMaxValuesWithWhitespace]="separateMinMaxValuesWithWhitespace"
            [separateValueAndUnitWithWhitespace]="separateValueAndUnitWithWhitespace" />
        </div>
      </div>
      <progress-bar-label
        *ngIf="showValue && valuePosition === 'inline'"
        [label]="label"
        [height]="height"
        [value]="value"
        [maxValue]="maxValue"
        [showMaxValue]="showMaxValue"
        [isInlineLabel]="true"
        [unit]="unit"
        [showUnit]="showUnit"
        [txtColor]="txtColor"
        [valueAlignment]="valueAlignment"
        [minMaxValueSeparator]="minMaxValueSeparator"
        [separateMinMaxValuesWithWhitespace]="separateMinMaxValuesWithWhitespace"
        [separateValueAndUnitWithWhitespace]="separateValueAndUnitWithWhitespace" />
    </div>
  </div>
  <div *ngIf="stretchGoal" class="stretchGoal" [innerHtml]="stretchGoal" data-margin-left></div>
</div>
<div *ngIf="showValue && valuePosition === 'bottom'">
  <progress-bar-label
    [label]="label"
    [height]="height"
    [value]="value"
    [maxValue]="maxValue"
    [showMaxValue]="showMaxValue"
    [unit]="unit"
    [showUnit]="showUnit"
    [txtColor]="txtColor"
    [valueAlignment]="valueAlignment"
    [minMaxValueSeparator]="minMaxValueSeparator"
    [separateMinMaxValuesWithWhitespace]="separateMinMaxValuesWithWhitespace"
    [separateValueAndUnitWithWhitespace]="separateValueAndUnitWithWhitespace" />
</div>
