import { NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  forwardRef,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import * as pell from 'pell';
import { pellAction, pellCustomActionConfig } from 'pell';

import { isUrl } from '../../util';
import { SwIconComponent } from '../sw-icon/sw-icon.component';
import { ToggleButtonComponent } from '../toggle-button/toggle-button.component';
import { ContentEditableDirective } from './content-editable.directive';

type PellCustomAction = pellAction & ('heading3' | 'heading4');

export const EDITOR_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EditorComponent),
  multi: true,
};

@Directive({
  selector: 'editor-footer',
  standalone: true,
})
export class AccountTextEditorFooterDirective {}

@Component({
  selector: 'account-editor',
  // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
  // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
  template: `
    <div class="editor" [class.focused]="focused">
      <div class="editor-header" data-clearfix [class.focused]="focused">
        <a
          *ngIf="fullScreenSupported"
          class="a_button is_small button-fullscreen padding_small"
          (click)="toggleFullscreen()">
          <account-sw-icon *ngIf="isFullscreen" icon="compress-arrows" size="12px" />
          <account-sw-icon *ngIf="!isFullscreen" icon="expand-arrows" size="12px" />
        </a>
      </div>
      <div class="editor-container textBreak" [hidden]="htmlModeControl.value" #editorContainer></div>
      <div
        contenteditable
        class="content-editable editor-container"
        [hidden]="!htmlModeControl.value"
        (focus)="onFocus()"
        (blur)="onInputBlur()"
        tabindex="{{ !htmlModeControl.value ? -1 : 0 }}"
        [ngModel]="content"
        [disabled]="isDisabled"
        (ngModelChange)="update($event)"></div>
      <div class="editor-footer">
        <div>
          <ng-content select="editor-footer" />
        </div>
        <label class="header-controls">
          <toggle-button [formControl]="htmlModeControl">Switch to HTML-Editor</toggle-button>
        </label>
      </div>
    </div>
  `,
  styleUrl: './editor.component.less',
  encapsulation: ViewEncapsulation.None,
  providers: [EDITOR_CONTROL_VALUE_ACCESSOR],
  standalone: true,
  imports: [NgIf, SwIconComponent, ContentEditableDirective, FormsModule, ToggleButtonComponent, ReactiveFormsModule],
})
export class EditorComponent implements OnDestroy, AfterViewInit, ControlValueAccessor {
  private readonly renderer = inject(Renderer2);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  @Input() fullScreenSupported = true;

  @ViewChild('editorContainer', { read: ElementRef, static: true }) editorContainer: ElementRef;

  @HostBinding('class.disabled') isDisabled = false;
  @HostBinding('class.fullscreen') isFullscreen = false;

  content: any;
  htmlModeControl = new UntypedFormControl(false);
  focused = false;

  private readonly link: pellAction = {
    name: 'link',
    icon: '<i class="mi-regular-link"></i>',
    title: 'Link',
    result: () => {
      let url = window.prompt('Enter the link URL:');
      while (!isUrl(url, true)) {
        url = window.prompt('The given value is no valid URL. Please enter the link URL:', url);
      }

      const text = window.getSelection();
      if (url) {
        const startsWithHttp = /^https?:\/\//.test(url);
        let urlLink = url;
        if (!startsWithHttp) {
          urlLink = `//${url}`;
        }
        pell.exec(
          'insertHTML',
          `<a href="${urlLink}" target="_blank" rel="noreferrer">${text.anchorNode?.nodeType !== null && text.anchorNode?.nodeType === text.anchorNode?.TEXT_NODE && !text.isCollapsed ? text : url}</a>`,
        );
      }
    },
  };

  private readonly heading3: pellAction = {
    name: 'heading3',
    icon: '<b>H<sub>3</sub></b>',
    title: 'Heading 3',
    result: () => pell.exec('formatBlock', '<h3>'),
  };

  private readonly heading4: pellCustomActionConfig = {
    name: 'heading4',
    icon: '<b>H<sub>4</sub></b>',
    title: 'Heading 4',
    result: () => pell.exec('formatBlock', '<h4>'),
  };

  // @TODO b, i, u etc. icons do not work
  private _actions: pellAction[] = [
    'heading1',
    'heading2',
    {
      name: 'bold',
      icon: '<i class="mi-regular-bold"></i>',
      result: () => pell.exec('bold'),
    },
    {
      name: 'italic',
      icon: '<i class="mi-regular-italic"></i>',
      result: () => pell.exec('italic'),
    },
    {
      name: 'underline',
      icon: '<i class="mi-regular-underline"></i>',
      result: () => pell.exec('underline'),
    },
    {
      name: 'ulist',
      icon: '<i class="mi-regular-list-unordered-xs"></i>',
      result: () => pell.exec('insertUnorderedList'),
    },
    this.link,
  ];

  private editor: any;
  private editorElement: HTMLElement;
  private readonly escKeyCode = 27;
  private onTouchedCallback: () => void;
  private onChangeCallback: (fn: any) => void;
  private unbindDocumentEscListener: () => void;

  @Input()
  set actions(actions: pellAction[] | PellCustomAction[]) {
    this._actions = actions.map((action: pellAction | PellCustomAction) => {
      if (typeof action !== 'string') {
        return action;
      }
      switch (action) {
        case 'link':
          return this.link;
        case 'heading3':
          return this.heading3;
        case 'heading4':
          return this.heading4;
        default:
          return action;
      }
    });
  }

  get actions(): pellAction[] | PellCustomAction[] {
    return this._actions;
  }

  ngAfterViewInit(): void {
    this.editorElement = this.editorContainer.nativeElement;
    this.editor = pell.init({
      element: this.editorElement,
      defaultParagraphSeparator: 'p',
      onChange: (html: string) => {
        if (!this.isDisabled) {
          this.content = html;
          this.onChangeCallback(html);
        }
      },
      actions: this.actions,
    });

    this.editor.content.addEventListener('focus', () => {
      this.onFocus();
    });

    this.editor.content.addEventListener('blur', () => {
      this.onInputBlur();
    });

    this.editor.content.addEventListener('keydown', () => {
      // to override default pell behavior where the tab key is disabled
    });

    this.editorElement.addEventListener('DOMNodeInserted', this.onDOMNodeInserted);

    const buttons = this.editorElement.children.item(0).children;
    for (let i = 0; i < buttons.length; i++) {
      this.renderer.setAttribute(buttons.item(i), 'tabindex', '-1');
      this.renderer.setAttribute(buttons.item(i), 'type', 'button');
    }

    if (this.content) {
      this.editor.content.innerHTML = this.stripWhitespaceBetweenHtmlTags(this.content);
    }

    if (this.isDisabled) {
      this.setDisabled(true);
    }
  }

  onDOMNodeInserted = (event: any): void => {
    if (event.target.tagName === 'SPAN') {
      const content = event.target.innerHTML;
      event.target.remove();
      event.relatedNode.innerHTML = event.relatedNode.innerHTML + content;
    }
  };

  update(content: string): void {
    this.writeValue(content);
    if (this.htmlModeControl.value) {
      this.onChangeCallback(this.stripWhitespaceBetweenHtmlTags(content));
    } else {
      this.onChangeCallback(this.content);
    }
  }

  writeValue(value: any): void {
    this.content = value;
    if (this.editor) {
      if (value !== undefined && value !== null) {
        this.editor.content.innerHTML = this.stripWhitespaceBetweenHtmlTags(this.content);
      } else {
        this.editor.content.innerHTML = '';
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (this.editorElement) {
      this.setDisabled(this.isDisabled);
    }
  }

  ngOnDestroy(): void {
    this.editorElement.removeEventListener('DOMNodeInserted', this.onDOMNodeInserted);

    this.editor = null;
    this.editorElement = null;
  }

  onFocus(): void {
    if (!this.isDisabled) {
      this.focused = true;
      this.onTouchedCallback();
    }
  }

  onInputBlur(): void {
    if (!this.isDisabled) {
      this.focused = false;
      this.onTouchedCallback();
    }
  }

  toggleFullscreen(): void {
    if (this.fullScreenSupported) {
      if (this.isFullscreen && this.unbindDocumentEscListener) {
        this.unbindFullScreenListener();
      } else {
        this.bindFullScreenListener();
      }
      this.isFullscreen = !this.isFullscreen;
    }
  }

  private stripWhitespaceBetweenHtmlTags(subject: string): string {
    return subject.replace(/>\s+</g, '><');
  }

  private setDisabled(disabled: boolean): void {
    this.editorElement.querySelector('.pell-content').setAttribute('contenteditable', disabled ? 'false' : 'true');
  }

  private bindFullScreenListener(): void {
    this.unbindDocumentEscListener = this.renderer.listen('document', 'keydown', (event) => {
      if (event.keyCode === this.escKeyCode) {
        this.toggleFullscreen();
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  private unbindFullScreenListener(): void {
    this.unbindDocumentEscListener();
    this.unbindDocumentEscListener = null;
  }
}
