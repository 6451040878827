<ng-template [ngIf]="item.externalLink" [ngIfThen]="external" [ngIfElse]="internal" />

<ng-template #external>
  <a [href]="item.externalLink | translate" target="_blank" rel="noreferrer" class="entry" [ngClass]="customClass()">
    <account-sw-icon class="entry--icon" [icon]="item.icon" [color]="color() ? color() : workspace?.color" />
    <span class="entry--title text-ellipsis">{{ item.label | translate }}</span>
    @if (isDropdown()) {
      <ng-template [ngTemplateOutlet]="hasDropdownTemplate"></ng-template>
    }
    <account-sw-icon class="entry--external-icon ml-2" icon="external-link" size="16px" color="var(--sw-paragraph)" />
    @if (item.hasNotification) {
      <ng-template [ngTemplateOutlet]="hasNotificationTemplate"></ng-template>
    }
  </a>
</ng-template>

<ng-template #internal>
  <ng-template [ngIf]="isWorkspace" [ngIfElse]="menuEntry">
    <a class="entry" [routerLink]="item.path" [ngClass]="customClass()">
      <account-sw-icon class="entry--icon" [icon]="item.icon" [color]="color() ? color() : item.color" />
      <span class="entry--title text-ellipsis">{{ item.label | translate }}</span>
      @if (isDropdown()) {
        <ng-template [ngTemplateOutlet]="hasDropdownTemplate"></ng-template>
      }
      @if (item.hasNotification) {
        <ng-template [ngTemplateOutlet]="hasNotificationTemplate"></ng-template>
      }
    </a>
  </ng-template>
  <ng-template #menuEntry>
    <a [routerLink]="item.path" [class.is--active]="isActive" class="entry" [ngClass]="customClass()">
      <account-sw-icon class="entry--icon" [icon]="item.icon" [color]="color() ? color() : workspace?.color" />
      <span class="entry--title text-ellipsis">{{ item.label | translate }}</span>
      @if (isDropdown()) {
        <ng-template [ngTemplateOutlet]="hasDropdownTemplate"></ng-template>
      }
      @if (item.hasNotification) {
        <ng-template [ngTemplateOutlet]="hasNotificationTemplate"></ng-template>
      }
    </a>
  </ng-template>
</ng-template>

<ng-template #hasNotificationTemplate>
  <div class="size-2 bg-blue-400 rounded-full animate-pulse"></div>
</ng-template>

<ng-template #hasDropdownTemplate>
  <ao-icon name="chevron-down-s" class="size-3 text-white" />
</ng-template>
