import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { PartnersGateway } from '../../gateways/partner';
import { ListResult, SbpException, SelfHostedShop, ShopMigrationData } from '../../models';
import { RequestMetaData } from '../../models/api/request-meta-data.model';
import { CompaniesFacade } from '../company';
import { ShopsFacade } from '../shop';
import { PartnersFacade } from './partners.facade';

@Injectable({
  providedIn: 'root',
})
export class PartnerShopsFacade {
  private readonly partnersFacade = inject(PartnersFacade);
  private readonly companiesFacade = inject(CompaniesFacade);
  private readonly shopsFacade = inject(ShopsFacade);
  private readonly partnersGateway = inject(PartnersGateway);

  getShop(): Observable<SelfHostedShop> {
    return this.partnersFacade
      .getPartnerIdOnce()
      .pipe(switchMap((partnerId: number) => this.partnersGateway.getPartnerShop(partnerId)));
  }

  getPartnerShops(
    requestMetaData: RequestMetaData,
    filter: Record<string, any> = {}
  ): Observable<ListResult<SelfHostedShop[]>> {
    return this.shopsFacade.getSelfHostedShops(requestMetaData, filter).pipe(
      map((shopList: ListResult<SelfHostedShop[]>) => {
        shopList.list = shopList.list.filter((shop: SelfHostedShop) => shop.domain !== 'www.shopware-ag.de');
        shopList.total--;

        return shopList;
      })
    );
  }

  migrateShop(migrationData: ShopMigrationData): Observable<SelfHostedShop | SbpException> {
    return this.companiesFacade.getCompanyIdOnce().pipe(
      switchMap((companyId: number) => {
        migrationData.oldOwnerId = companyId;
        return this.shopsFacade.migrateShop(migrationData);
      })
    );
  }

  renameShopDomain(migrationData: ShopMigrationData): Observable<SelfHostedShop | SbpException> {
    return this.shopsFacade.migrateShop(migrationData);
  }
}
