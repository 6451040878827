import { DomainVerificationTokenData } from '@account/core/models';
import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { Company } from '../../../../../../core/models/company/company.model';
import { SafePipe } from '../../../../../../shared/pipes';
import { LicenseGtcElementComponent } from '../../../shared-elements/domain-verification/license-gtc-element/license-gtc-element.component';
import { VerificationElementComponent } from '../../../shared-elements/domain-verification/verification-element/verification-element.component';

@Component({
  selector: 'shop-creation-modal-domain-verification-step',
  templateUrl: './domain-verification-step.component.html',
  styleUrl: './domain-verification-step.component.less',
  standalone: true,
  imports: [NgIf, VerificationElementComponent, LicenseGtcElementComponent, TranslateModule, SafePipe],
})
export class ShopCreationModalDomainVerificationStepComponent {
  @Input() form: UntypedFormGroup;
  @Input() assignee: Company;
  @Input() shopAssignationType: 'partner' | 'partnerCustomer';
  @Input() domainName: string;
  @Input() domainVerified = false;
  @Input() verificationRequired = true;
  @Input() verificationResult: boolean | null = null;
  @Input() domainVerificationTokenData: DomainVerificationTokenData;

  @Output() readonly onVerifyDomain = new EventEmitter<void>();
}
