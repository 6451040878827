import { LanguagesFacade } from '@account/core/facades';
import { AfterContentInit, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { EnvironmentService } from '../../../core/services';
import { ToastService } from '../toast/toast.service';

declare let hbspt: any;

@Component({
  selector: 'account-hubspot-form',
  templateUrl: './hubspot-form.component.html',
  styleUrl: './hubspot-form.component.less',
  standalone: true,
})
export class HubspotFormComponent implements OnInit, AfterContentInit {
  private readonly toastService = inject(ToastService);
  private readonly translateService = inject(TranslateService);
  private readonly languagesFacade = inject(LanguagesFacade);
  private readonly environmentService = inject(EnvironmentService);
  @Input() formId: string;
  @Input() formIdEnglish: string;
  @Input() showSuccessToast: boolean;
  @Input() customCssClass: string;
  @Output() readonly onSubmitted = new EventEmitter<any>();
  @Output() readonly hubspotFormLoaded = new EventEmitter<any>();
  @Output() readonly showFallback = new EventEmitter<any>();
  performedSubmission = false;
  event = (event: any): void => {
    if (event.data.type === 'hsFormCallback') {
      if (event.data.eventName === 'onFormSubmitted' && !this.performedSubmission) {
        this.emitSubmitted();
      } else if (event.data.eventName === 'onFormReady') {
        this.hubspotFormLoaded.emit();
      } else if (event.data.eventName === 'onFormDefinitionFetchError') {
        this.showFallback.emit();
      }
    } else if (event.data?.accepted === false && event.data?.validationResults) {
      if (this.regularFieldsValid(event.data.validationResults) && !this.performedSubmission) {
        this.emitSubmitted();
      }
    }
  };

  ngOnInit(): void {
    try {
      if (!hbspt) {
        this.showFallback.emit();
        return;
      }
    } catch {
      this.showFallback.emit();
      return;
    }

    if (this.customCssClass) {
      const styles = document.createElement('style');
      styles.innerHTML =
        `.${this.customCssClass} .input { margin-top: 11px; }` +
        `.${this.customCssClass} .hs-input { margin-left: 5px; margin-right: 10px; }` +
        `.${this.customCssClass} .hs-input[type=text] { width: 410px; }` +
        `.${this.customCssClass} ul li { font-size: 14px; margin-right: 10px; }` +
        `.${this.customCssClass} .inputs-list { list-style: none; padding: 0; }` +
        `.${this.customCssClass} .hs-form-booleancheckbox { margin-bottom: -5px; }` +
        `.${this.customCssClass} .hs-button { border-radius: 3px !important; margin-top: 5px; margin-bottom: 3px; float:right; }` +
        `.${this.customCssClass} hr { margin: 30px -32px 20px; border: 0; border-top: 1px solid #c9c7c7; }` +
        `.${this.customCssClass} .hs-message { width: 94%; margin: auto; }` +
        `.${this.customCssClass} .hs-message textarea { resize: none; }` +
        `.${this.customCssClass} .hs-error-msgs { margin-left: 0px; margin-top: 8px; }` +
        `.${this.customCssClass} .submitted-message p { font-size: 18px; }` +
        `.${this.customCssClass} .hs-email { margin-bottom: 25px; margin-top: 17px; }` +
        `.${this.customCssClass} .hs-email label span { margin-left: 5px; }` +
        `.${this.customCssClass} .hs-fieldtype-radio { margin-left: 32px; margin-bottom: 15px; }` +
        `.${this.customCssClass} .hs-fieldtype-radio label { font-size: 14px; }` +
        `.${this.customCssClass} .hs-fieldtype-text:not(.hs-email) { margin-left: 25px; margin-bottom: 15px; }` +
        `.${this.customCssClass} .hs-fieldtype-text label { font-size: 15px; margin-left: 5px; }` +
        `.${this.customCssClass} .hs-fieldtype-textarea label { font-size: 15px; margin-left: 5px; }` +
        `.${this.customCssClass} .hs-form-field { margin-top: 7px; }` +
        `.${this.customCssClass} .hs-fieldtype-textarea textarea { resize: none; }` +
        '.hs_was_war_dann_dein_grund_die_miete_zu_kundigen_ { font-size: 15px; margin-left: 26px; }' +
        '.hs_was_war_dann_dein_grund_die_miete_zu_kundigen_ textarea { width: 410px; }';
      document.body.appendChild(styles);
    }
  }

  ngAfterContentInit(): void {
    this.languagesFacade.getSelectedLanguage().subscribe((e) => {
      if (!('hbspt' in window) || hbspt === null) {
        this.showFallback.emit();
        return;
      }
      switch (e.key) {
        case 'de':
          hbspt.forms.create({
            portalId: this.environmentService.current.hubspot_environment,
            formId: this.formId,
            cssClass: this.customCssClass,
            target: '#hubspotForm',
            locale: 'de',
            translations: {
              de: {
                required: ' ',
              },
            },
          });
          break;

        case 'en':
          hbspt.forms.create({
            portalId: this.environmentService.current.hubspot_environment,
            formId: this.formIdEnglish,
            cssClass: this.customCssClass,
            target: '#hubspotForm',
            locale: 'en',
            translations: {
              en: {
                required: ' ',
              },
            },
          });
          break;
        // skip default
      }
    });

    window.addEventListener('message', this.event);
  }

  private emitSubmitted(): void {
    this.performedSubmission = true;
    this.onSubmitted.emit();
    if (this.showSuccessToast) {
      this.toastService.success(
        this.translateService.instant('COMMON.HUBSPOT_FORM.TOAST.TITLE'),
        this.translateService.instant('COMMON.HUBSPOT_FORM.TOAST.MESSAGE')
      );
    }
  }

  private regularFieldsValid(validationResults: Record<string, any>[]): boolean {
    let regularFieldsValid = true;
    validationResults.map((validationResult) => {
      if (validationResult.fieldName !== 'email') {
        regularFieldsValid = false;
      }
    });
    return regularFieldsValid;
  }
}
