import { ProducersFacade } from '@account/core/facades';
import { Producer, ProducerContractLifecycleStatus } from '@account/core/models';
import { ModalConfig, ModalService } from '@account/shared/components';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { share, switchMap } from 'rxjs/operators';

import { ProducerMailModalComponent } from '../../views/producer/shared/producer-mail-modal';

@Injectable({
  providedIn: 'root',
})
export class ProducersService {
  private readonly modalService = inject(ModalService);
  private readonly producersFacade = inject(ProducersFacade);
  private readonly translateService = inject(TranslateService);

  hasSignedContract(producer: Producer): boolean {
    return producer && producer.contract && producer.contract.shopwareApproved && producer.contract.producerApproved;
  }

  openMailModal(producer: Producer = null): void {
    const modalConfig: ModalConfig = {
      data: {
        producer: null !== producer ? Object.assign({}, producer) : null,
      },
    };
    this.modalService.open(ProducerMailModalComponent, modalConfig);
  }

  getContractLifecycleTooltip(ignoreProducerApproved = false): Observable<string> {
    return this.producersFacade.getProducerContractLifecycleStatus().pipe(
      share(),
      switchMap((status: ProducerContractLifecycleStatus) => {
        if (status.contractCancellationInProgress) {
          return this.translateService.stream('PRODUCER.CONTRACT_INFO.CONTRACT_CANCELLATION_IN_PROGRESS');
        }
        if (!status.approvedByShopware) {
          return this.translateService.stream('PRODUCER.CONTRACT_INFO.NOT_APPROVED_BY_SHOPWARE');
        }

        if (ignoreProducerApproved) {
          return of(null);
        }
        if (!status.approvedByProducer) {
          return this.translateService.stream('PRODUCER.CONTRACT_INFO.NOT_APPROVED_BY_PRODUCER');
        }
        if (!status.newestApprovedByProducer) {
          return this.translateService.stream('PLUGINS.OVERVIEW.CONTRACT_EMPTY_STATE.BUTTON');
        }
        return of(null);
      })
    );
  }

  isContractApprovedAndNotCancelled(): Observable<boolean> {
    return this.producersFacade
      .getProducerContractLifecycleStatus()
      .pipe(
        switchMap((status: ProducerContractLifecycleStatus) =>
          of(!status.contractCancellationInProgress || !status.newestApprovedByProducer || status.approvedByShopware)
        )
      );
  }
}
