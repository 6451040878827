import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ContactComponent } from '../../../../../../shared/components/contact/contact.component';

@Component({
  selector: 'shop-creation-modal-teaser-step',
  templateUrl: './teaser-step.component.html',
  styleUrl: './teaser-step.component.less',
  standalone: true,
  imports: [TranslateModule, ContactComponent],
})
export class ShopCreationModalTeaserStepComponent {}
