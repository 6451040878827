import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'account-topbar',
  template: '<ng-content  />',
  styleUrl: './topbar.component.less',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class TopbarComponent {}
