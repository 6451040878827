import { NoAccessContext } from '@account/core/models';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NavigationAccessMapper } from '../../components/navigation/navigation-access.mapper';
import { LocalStorageService } from '../../services';
import { AbstractAclFacade } from './abstract-acl.facade';

@Injectable({
  providedIn: 'root',
})
export class GuardsAclFacade extends AbstractAclFacade {
  isAccessGrantedForUserAccount(url: string): Observable<boolean> {
    return this.isUrlAccessGrantedForUserAccount(url);
  }

  isAccessGrantedForCompanyGTC(): Observable<boolean> {
    return this.isAccessGrantedForCompanyByGTC();
  }

  isAccessGrantedForCompanyMasterData(url: string): Observable<boolean> {
    return this.isUrlAccessGrantedForCompanyMasterData(url);
  }

  isAccessGrantedForCompanyAllocations(url: string): Observable<boolean> {
    return this.isUrlAccessGrantedForCompanyAllocations(url);
  }

  isAccessGrantedForPartnerStatus(url: string): Observable<boolean> {
    return this.isUrlAccessGrantedForPartnerStatus(url);
  }

  private readonly localStorageService = inject(LocalStorageService);

  isAccessGrantedForCurrentPermissions(url: string): Observable<boolean> {
    return this.isUrlAccessGrantedForCurrentPermissions(url);
  }

  setNoAccessTranslationKey(url: string, context: NoAccessContext): void {
    let message: null | string;
    switch (context) {
      case 'partnerStatus':
        message = NavigationAccessMapper.urlToPermissionDeniedTranslationKeyForPartnerStatus(url);
        break;
      case 'permissions':
        message = NavigationAccessMapper.urlToPermissionDeniedTranslationKey(url);
        break;
      default:
        message = null;
    }
    this.localStorageService.setItem('no-access-message', message);
  }
}
